import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { t } from "locale/dictionary";
import { FaCaretUp, FaCaretDown, FaCaretRight, FaEye, FaPen, FaCopy } from "react-icons/fa";
import { OwnerTypes, ReportOutputTypes, getOwnerTypes } from "utilities/reportEditor";
import { getDateDisplayValue } from "utilities/dateTime";
import Delete from "components/global/Delete";
import Tooltip from "components/global/Tooltip";
import iconWord from "images/icon-word.png";
import iconExcel from "images/icon-excel.png";
import iconPdf from "images/icon-pdf.png";
import iconText from "images/icon-text.png";
import { Link, useNavigate } from "react-router-dom";
import { deleteReport } from "api/report";
import { useSelector } from "react-redux";
import ReportCopyPopup from "../common/ReportCopyPopup";
import { findById, idsAreEqual } from "utilities/stringAndArray";

function ReportListGroup({ group, handleGroupExpanderClick, isEditor, onSelect }) {
  const customerState = useSelector((state) => state.customer);
  const navigate = useNavigate();

  const [sortCol, setSortCol] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [reportsSorted, setReportsSorted] = useState(null);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [reportToCopy, setReportToCopy] = useState(null);

  useEffect(() => {
    setReportsSorted(group.reports);
  }, [group.reports]);

  const middleTierRoute = isEditor ? "/reportmenu" : "/results";

  const handleColHeaderClick = (fieldToSort) => {
    const sortAscLocal = sortCol === fieldToSort ? !sortAsc : true;
    setSortAsc(sortAscLocal);
    setSortCol(fieldToSort);

    const compareBySortCol = (a, b) => {
      let sortValueA = null;
      let sortValueB = null;
      sortValueA = a[fieldToSort];
      sortValueB = b[fieldToSort];
      if (typeof sortValueA === "string") sortValueA = sortValueA.toLowerCase();
      if (typeof sortValueB === "string") sortValueB = sortValueB.toLowerCase();

      if (sortValueA < sortValueB || (!sortValueA && sortValueB)) {
        return sortAscLocal ? -1 : 1;
      }
      if (sortValueA > sortValueB || (sortValueA && !sortValueB)) {
        return sortAscLocal ? 1 : -1;
      }
      return 0;
    };

    let dataToSort = [...group.reports];
    dataToSort.sort(compareBySortCol);
    setReportsSorted(dataToSort);
  };

  const renderHeaderCell = (fieldName, displayName, width) => {
    return (
      <td
        className={`data-panel__table-header data-panel__table-header--${width}`}
        onClick={() => handleColHeaderClick(fieldName)}
      >
        {t(displayName)}
        {sortCol === fieldName ? sortAsc ? <FaCaretUp /> : <FaCaretDown /> : null}
      </td>
    );
  };

  const handleMainClick = (report) => {
    navigate(`${middleTierRoute}/reporteditor/` + report.id, { state: { source: middleTierRoute } });
  };

  const handleCopyClick = (report) => {
    setShowCopyPopup(true);
    setReportToCopy(report);
  };

  const handleDeleteClick = (report) => {
    deleteReport(report.id);
  };

  const renderReportLine = (report) => {
    const ownerType = findById(getOwnerTypes(), report.ownerType);
    const displayDelete = report.ownerType === OwnerTypes.USER;
    const rowClassNameBase = "data-panel__table-row";

    let createdBy = "n/a";
    let lastUpdatedBy = "n/a";
    if (customerState.users) {
      createdBy = customerState.users?.find((u) => idsAreEqual(u.userId, report.createdUserId))?.fullName;
      lastUpdatedBy = customerState.users?.find((u) => idsAreEqual(u.userId, report.lastUpdatedUserId))?.fullName;
    }

    const renderClickableValueDisplayTableCell = (displayValue) => {
      return (
        <td
          className="data-panel__table-cell clickable"
          onClick={() => (isEditor ? handleMainClick(report) : onSelect(report, ReportOutputTypes.DEFAULT))}
        >
          {displayValue}
        </td>
      );
    };

    return (
      <tr key={report.id} className={rowClassNameBase}>
        {renderClickableValueDisplayTableCell(report.name)}
        {renderClickableValueDisplayTableCell(report.description)}
        {renderClickableValueDisplayTableCell(ownerType.displayValue)}
        {renderClickableValueDisplayTableCell(getDateDisplayValue(report.createdUtcDate))}
        {renderClickableValueDisplayTableCell(createdBy)}
        {renderClickableValueDisplayTableCell(getDateDisplayValue(report.lastUpdatedUtcDate))}
        {renderClickableValueDisplayTableCell(lastUpdatedBy)}
        <td className="data-panel__table-cell clickable">
          <div className="item__actions">
            {!isEditor && (
              <div className="actions__item-action flex-row-center">
                <Tooltip direction="left" content={"Preview report"}>
                  {onSelect ? (
                    <span className="flex-row-center" onClick={() => onSelect(report, ReportOutputTypes.DEFAULT)}>
                      <FaEye />
                    </span>
                  ) : (
                    <Link
                      className="flex-row-center"
                      to={`${middleTierRoute}/reporteditor/` + report.id}
                      state={{ source: middleTierRoute }}
                    >
                      <FaEye />
                    </Link>
                  )}
                </Tooltip>
              </div>
            )}
            <div className="actions__item-action">
              <Tooltip direction="left" content={"Edit Report"}>
                <Link
                  className="flex-row-center"
                  to={`${middleTierRoute}/reporteditor/` + report.id}
                  state={{ source: middleTierRoute }}
                >
                  <FaPen />
                </Link>
              </Tooltip>
            </div>
            {isEditor ? (
              <Tooltip content="Copy Report" direction="left">
                <div className="actions__item-action flex-row-center" onClick={() => handleCopyClick(report)}>
                  <FaCopy />
                </div>
              </Tooltip>
            ) : (
              <>
                <Tooltip content="Download report as MS Word" direction="left">
                  <div
                    className="actions__item-action flex-row-center"
                    onClick={() => onSelect(report, ReportOutputTypes.WORD)}
                  >
                    <img src={iconWord} alt="Download report as MS Word" />
                  </div>
                </Tooltip>
                <Tooltip content="Download report as MS Excel" direction="left">
                  <div
                    className="actions__item-action flex-row-center"
                    onClick={() => onSelect(report, ReportOutputTypes.EXCEL)}
                  >
                    <img src={iconExcel} alt="Download report as MS Excel" />
                  </div>
                </Tooltip>
                <Tooltip content="Download report as PDF" direction="left">
                  <div
                    className="actions__item-action flex-row-center"
                    onClick={() => onSelect(report, ReportOutputTypes.PDF)}
                  >
                    <img src={iconPdf} alt="Download report as PDF" />
                  </div>
                </Tooltip>
                <Tooltip content="Download report as HTML" direction="left">
                  <div
                    className="actions__item-action flex-row-center"
                    onClick={() => onSelect(report, ReportOutputTypes.HTML)}
                  >
                    <img src={iconText} alt="Download report as HTML" />
                  </div>
                </Tooltip>
              </>
            )}
            {isEditor && (
              <div className="actions__item-action actions__item-action--delete">
                {displayDelete && (
                  <Delete
                    message={`${t("Delete report")} - ${report.name}`}
                    onConfirm={() => handleDeleteClick(report)}
                  />
                )}
              </div>
            )}
          </div>
        </td>
      </tr>
    );
  };

  const renderReportsGroup = (
    <>
      <div className="report-menu__group">
        <div className="group__header" onClick={() => handleGroupExpanderClick(group.id)}>
          {group.isExpanded ? <FaCaretDown /> : <FaCaretRight />}
          <div className="group__title">{`${group.title} (${group.reports.length})`}</div>
        </div>
        <div className="group__body">
          {group.isExpanded && (
            <div className="data-panel__table">
              <table className="data-panel__table-inner">
                <thead>
                  <tr className="data-panel__table-row">
                    {renderHeaderCell("name", "Name", 20)}
                    {renderHeaderCell("description", "Description", 20)}
                    {renderHeaderCell("ownerType", "Owner Type", 10)}
                    {renderHeaderCell("createdUtcDate", "Created On", 10)}
                    {renderHeaderCell("createdUserId", "Created By", 10)}
                    {renderHeaderCell("lastUpdatedUtcDate", "Last Edited On", 10)}
                    {renderHeaderCell("lastUpdatedUserId", "Last Edited By", 10)}
                    <td className="data-panel__table-header data-panel__table-header--10">&nbsp;</td>
                  </tr>
                </thead>
                <tbody>{reportsSorted?.length > 0 && reportsSorted.map((report) => renderReportLine(report))}</tbody>
              </table>
            </div>
          )}
          {showCopyPopup && (
            <ReportCopyPopup report={reportToCopy} clickSource="list" onClose={() => setShowCopyPopup(false)} />
          )}
        </div>
      </div>
    </>
  );
  return renderReportsGroup;
}

ReportListGroup.propTypes = {
  group: PropTypes.object,
  handleGroupExpanderClick: PropTypes.func,
};

export default ReportListGroup;
