import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getCountryIsoCodeFromAlpha2Code } from "utilities/countries";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { baseMenuItems } from "utilities/charting";
import { idsAreEqual } from "utilities/stringAndArray";

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/data")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

const HighchartsMap2d = ({ widgetId, output, onClick }) => {
  const lookup = useSelector((state) => state.lookup);
  const mapData = useSelector((state) => state.mapData);
  const homeState = useSelector((state) => state.home);

  const chartRef = useRef(null);

  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (chartRef?.current) {
      const chart = chartRef.current.chart;
      if (chart) {
        chart.reflow();
      }
    }
  }, [isZoomed]);

  useEffect(() => {
    if (idsAreEqual(homeState.zoomedWidgetId, widgetId)) {
      if (!isZoomed) setIsZoomed(true);
    } else if (isZoomed) {
      setIsZoomed(false);
    }
  }, [homeState.zoomedWidgetId]);

  const [mapOptions, setMapOptions] = useState(null);

  useEffect(() => {
    let map = null;
    let series = {};

    switch (output.options.type) {
      case "us":
        const countryMap = mapData.countryMapData.find((map) => map.countryCode === output.options.type);
        map = countryMap ? countryMap.mapData : null;
        series = [
          {
            name: "",
            cursor: "pointer",
            joinBy: ["hc-key", "code"],
            data: output.data.map((stateData) => ({
              code: "us-" + stateData.key.toLowerCase(),
              value: stateData.value,
            })),
          },
        ];
        break;
      default:
        map = mapData.worldMapData;
        series = [
          {
            name: "",
            cursor: "pointer",
            joinBy: ["iso-a2", "hc-key"],
            data: output.data.map((countryData) => [countryData.key, countryData.value]),
          },
        ];
        break;
    }

    if (!map) {
      return;
    }

    let plotOptions = {};
    switch (output.options.type) {
      case "us":
        plotOptions = {
          series: {
            events: {
              click: function (event) {
                if (onClick) {
                  const filters = [
                    {
                      fieldName: "matter_State",
                      operator: "=",
                      valueArray: [event.point.code.slice(-2).toUpperCase()],
                    },
                  ];

                  onClick(filters);
                }
              },
            },
          },
        };
        break;
      default:
        plotOptions = {
          series: {
            events: {
              click: function (event) {
                if (onClick) {
                  const countryId = getCountryIsoCodeFromAlpha2Code(event.point["iso-a2"]);
                  const filters = [{ fieldName: "matterCountry_Id", operator: "=", valueArray: [countryId] }];

                  onClick(filters);
                }
              },
            },
          },
        };
        break;
    }

    const recordCount = output.data.reduce((accumulator, countryData) => accumulator + countryData.value, 0);

    setMapOptions({
      title: {
        text: `Total: ${recordCount} records`,
        style: { fontSize: "1.25rem", fontWeight: "bold" },
      },
      chart: {
        backgroundColor: "#E5F5FF",
        type: "map",
        map: map,
      },
      colorAxis: {
        min: 0,
        minColor: "#BFCFAD",
        maxColor: "#31784B",
      },
      credits: {
        enabled: false,
      },
      legend: {
        layout: "horizontal",
        align: "center",
        verticalAlign: "bottom",
        enabled: true,
      },
      mapNavigation: {
        enabled: true,
        enableButtons: true,
      },
      tooltip: {
        pointFormatter: function () {
          return this.name + "- Count " + this.value;
        },
      },
      plotOptions: plotOptions,
      series: series,
      exporting: {
        buttons: {
          contextButton: {
            menuItems: baseMenuItems,
          },
        },
      },
    });
  }, [output, mapData, onClick, lookup.global, isZoomed]);

  return mapOptions ? (
    <HighchartsReact
      ref={chartRef}
      containerProps={{
        style: { height: "100%", width: isZoomed ? "100%" : document.documentElement.clientWidth > 1800 ? 425 : 350 },
      }}
      highcharts={Highcharts}
      constructorType={"mapChart"}
      options={mapOptions}
      allowChartUpdate={false}
    />
  ) : null;
};

HighchartsMap2d.propTypes = {
  widgetId: PropTypes.number,
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default HighchartsMap2d;
