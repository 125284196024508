import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getMatterById } from "api/matter";
import { useDispatch } from "react-redux";
import { addRecord, setComparing, setRecordPinned } from "redux/matterSlice";
import { sectionTypes } from "utilities/constants";
import MatterRecordUniversalLink from "../UniversalLink";

// Container Component for the display of a link field within a matter data panel
function PanelFieldLink({ keyProps, displayName }) {
  const dispatch = useDispatch();

  const onConnectedMatterClick = async () => {
    let matterLinkId = null;
    if (keyProps.field.fieldName === "matterLink_Name") matterLinkId = keyProps.rowData.matterLink_MatterId;
    else if (keyProps.field.fieldName === "matterCompanyLink_Name")
      matterLinkId = keyProps.rowData.matterCompanyLink_MatterId;
    else if (keyProps.field.fieldName === "matterContactLink_FullName")
      matterLinkId = keyProps.rowData.matterContactLink_MatterId;
    const matter = await getMatterById(matterLinkId);
    dispatch(addRecord({ matter, browseTo: true }));
    dispatch(setRecordPinned({ recordId: keyProps.record.id, newPinnedStatus: true, reset: true }));
    dispatch(setComparing({ isComparing: true, numberComparing: 2 }));
  };

  let externalLink = "";
  if (keyProps.field.fieldName.toLowerCase().includes("email")) externalLink = "email";
  if (keyProps.field.fieldName.toLowerCase().includes("website")) externalLink = "website";

  const displayValue =
    keyProps.rowData.hasOwnProperty(keyProps.field.fieldName) && keyProps.rowData[keyProps.field.fieldName];

  let renderValueContents = null;
  if (keyProps.isProject) {
    let recordId = Number(keyProps.rowData.matterCompanyLink_MatterId);
    // TODO: other use cases? change recordId field here...
    renderValueContents = <MatterRecordUniversalLink recordId={recordId}>{displayValue}</MatterRecordUniversalLink>;
  } else if (
    keyProps.field.fieldName === "matterLink_Name" ||
    keyProps.field.fieldName === "matterCompanyLink_Name" ||
    keyProps.field.fieldName === "matterContactLink_FullName"
  ) {
    renderValueContents = <a onClick={onConnectedMatterClick}>{displayValue}</a>;
  } else if (externalLink === "email" || externalLink === "website") {
    renderValueContents = (
      <div
        className="link"
        onClick={() => window.open(`${externalLink === "email" ? "mailto" : "https"}://${displayValue}`, "_blank")}
      >
        {displayValue}
      </div>
    );
  } else {
    const linkName = keyProps.field["linkPropertyName"];
    const linkUrl = keyProps.rowData[linkName];
    renderValueContents = linkUrl && <Link to={linkUrl}>{displayValue}</Link>;
  }

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <>{renderValueContents}</>
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value">{renderValueContents}</div>
    </>
  );
}

PanelFieldLink.propTypes = {
  keyProps: PropTypes.object,
  displayName: PropTypes.string,
};

export default PanelFieldLink;
