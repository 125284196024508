import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { accessRightFilterAdd, accessRightFilterDelete, accessRightFilterUpdate } from "api/security";
import DataPanelFieldNameSearch from "components/content/matter-record/field-display-types/PanelFieldNameSearch";
import Delete from "components/global/Delete";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { getCountryCodeAlpha2FromIsoCode } from "utilities/countries";
import { getCompanyLinkTypesAll } from "utilities/links";
import MatterRecordUniversalLink from "components/content/matter-record/UniversalLink";
import { findById, idsAreEqual } from "utilities/stringAndArray";

// TODO: (BR) This component is a complete nightmare of complexity and should be refactored to separate responsibilities
// for company link types and company links. Nested tables also for visual improvement (i.e. each company link type to have its own table of company links)
export default function CompaniesAccessPanel() {
  const securityState = useSelector((state) => state.security);
  const localeState = useSelector((state) => state.locale);
  const [selectedLinkTypeId, setSelectedLinkTypeId] = useState(null);
  const [isAddingCompanyLinkType, setIsAddingCompanyLinkType] = useState(false);
  const [isAddingCompanyLink, setIsAddingCompanyLink] = useState(false);
  const [addingCompanyLinkByFieldName, setAddingCompanyLinkByFieldName] = useState({});
  const [companyLinkTypeOptions, setCompanyLinkTypeOptions] = useState([]);

  const currentUserGroup = securityState?.currentUserGroup;
  const links = getCompanyLinkTypesAll();

  const companyLinkTypes = links?.map((companyLinkType) => ({
    ...companyLinkType,
    id: companyLinkType.id,
    displayValue: localeState.translations[companyLinkType.translationCode],
    fieldName: `matterLinks-${companyLinkType.id}-Name`,
  }));

  useEffect(() => {
    const options = companyLinkTypes.filter(
      (companyLinkType) =>
        !currentUserGroup?.accessRight?.filters?.some((filter) => filter.fieldName === companyLinkType.fieldName)
    );
    setCompanyLinkTypeOptions(options);
  }, [currentUserGroup?.accessRight?.filters]);

  const addNewCompanyLinkType = (searchResult) => {
    accessRightFilterAdd(securityState.currentCustomer.id, currentUserGroup.accessRight.id, {
      fieldName: `matterLinks-${selectedLinkTypeId}-Name`,
      operator: "=",
      valueArray: [searchResult.matterId],
      qualified: true,
    });
    setIsAddingCompanyLinkType(false);
    setIsAddingCompanyLink(false);
    setSelectedLinkTypeId(null);
  };

  const addNewCompanyLink = (fieldName, searchResult) => {
    const accessRightFilters = currentUserGroup?.accessRight?.filters;
    const companyLinkTypeFilter = accessRightFilters?.find((arf) => arf.fieldName === fieldName);
    if (accessRightFilters?.find((arf) => arf.fieldName === fieldName) === undefined) return;
    accessRightFilterUpdate(
      securityState.currentCustomer.id,
      currentUserGroup.accessRight.id,
      companyLinkTypeFilter.id,
      "valueArray",
      companyLinkTypeFilter.valueArray,
      [...companyLinkTypeFilter.valueArray, searchResult.matterId],
      {
        id: searchResult.matterId,
        name: searchResult.name,
        fullDescription: `${searchResult.name} - ${searchResult.city}, ${
          searchResult.state
        }, ${getCountryCodeAlpha2FromIsoCode(searchResult.countryId)}`,
      }
    );
    setAddingCompanyLinkByFieldName({}); // ...addingCompanyLinkByFieldName, [fieldName]: false });
  };

  const removeCompanyLink = (fieldName, valueId) => {
    const accessRightFilters = currentUserGroup?.accessRight?.filters;
    const companyLinkTypeFilter = accessRightFilters?.find((arf) => arf.fieldName === fieldName);
    const newValueArray = companyLinkTypeFilter.valueArray.filter((value) => value !== valueId);

    if (newValueArray.length === 0) {
      accessRightFilterDelete(
        securityState.currentCustomer.id,
        currentUserGroup.accessRight.id,
        companyLinkTypeFilter.id
      );
    } else {
      accessRightFilterUpdate(
        securityState.currentCustomer.id,
        currentUserGroup.accessRight.id,
        companyLinkTypeFilter.id,
        "valueArray",
        companyLinkTypeFilter.valueArray,
        newValueArray
      );
    }
  };

  const handleCompanyLinkTypeChange = (id) => {
    setSelectedLinkTypeId(id);
    setIsAddingCompanyLink(true);
  };

  const renderAddingCompanyLinkType = (
    <>
      {isAddingCompanyLinkType && (
        <>
          <tr>
            <td>
              <SuperSelect
                selectedOptionId={selectedLinkTypeId ?? null}
                id={"companyLinkTypeSelect"}
                options={companyLinkTypeOptions ?? []}
                placeholderText="Select Link Type"
                onChange={(id) => handleCompanyLinkTypeChange(id)}
              />
            </td>
          </tr>
          {isAddingCompanyLink && (
            <tr>
              <td>
                <DataPanelFieldNameSearch
                  searchOnRecord={false}
                  keyProps={{
                    record: { customerId: securityState.currentCustomer.id },
                    controlId: "namesearch",
                    field: { fieldName: "matterCompanyLink_Name" },
                    // TODO: Add an exclusion list of the ids for companies that are already linked to this link type
                    //exclusionList: [...currentUserGroup?.accessRight?.filters?.map((filter) => filter.valueArray) ?? []],
                  }}
                  onChange={(searchField, searchResult) => addNewCompanyLinkType(searchResult)}
                />
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );

  const renderCompanyLinks = (accessTypeFilter) => (
    <>
      <tr>
        <td className="company-link-access__link-type" colSpan={2}>
          {companyLinkTypes?.find((clt) => clt.fieldName === accessTypeFilter.fieldName)?.displayValue}
        </td>
      </tr>
      {accessTypeFilter.valueArray.map((value) => {
        const matterId = Number(value);
        const item = findById(accessTypeFilter?.qualifiedDictionary, matterId);
        const displayValue = item?.fullDescription ?? matterId;

        return (
          <tr key={`${accessTypeFilter.fieldName}-${value}`}>
            <td className="company-link-access__company">
              <MatterRecordUniversalLink recordId={item.id}>{displayValue}</MatterRecordUniversalLink>
            </td>
            <td>
              <Delete
                message={"remove this Company Link"}
                onConfirm={() => removeCompanyLink(accessTypeFilter.fieldName, value)}
              />
            </td>
          </tr>
        );
      })}

      <tr>
        <td>
          {addingCompanyLinkByFieldName[accessTypeFilter.fieldName] && (
            <DataPanelFieldNameSearch
              searchOnRecord={false}
              keyProps={{
                record: { customerId: securityState.currentCustomer.id },
                controlId: "namesearch",
                field: { fieldName: "matterCompanyLink_Name" },
              }}
              existingIdsForLinkType={accessTypeFilter.valueArray}
              onChange={(searchField, searchResult) => addNewCompanyLink(accessTypeFilter.fieldName, searchResult)}
            />
          )}
        </td>
      </tr>
    </>
  );

  const renderCompanyAccessTypes = () => {
    const accessRightCompanyLinkTypes =
      currentUserGroup?.accessRight?.filters?.filter((f) => f.fieldName.includes("matterLinks-")) ?? [];
    return (
      <table className="security__company-link-access">
        <tbody>
          {accessRightCompanyLinkTypes.map((accessTypeFilter) => (
            <>
              {renderCompanyLinks(accessTypeFilter)}
              {!addingCompanyLinkByFieldName[accessTypeFilter.fieldName] && (
                <tr>
                  <td>
                    <button
                      className="company-link-access__add-company"
                      onClick={() =>
                        setAddingCompanyLinkByFieldName({
                          ...addingCompanyLinkByFieldName,
                          [accessTypeFilter.fieldName]: true,
                        })
                      }
                    >
                      {t("Add Company")}
                    </button>
                  </td>
                </tr>
              )}
            </>
          ))}
          {renderAddingCompanyLinkType}
        </tbody>
      </table>
    );
  };

  // MAIN RENDER
  return (
    <div className="security__section">
      <div className="security__section-title">{t("Access To Companies")}</div>
      <div className="security__section-body">
        {currentUserGroup?.accessRight && renderCompanyAccessTypes()}
        {!isAddingCompanyLinkType && (
          <button onClick={() => setIsAddingCompanyLinkType(true)}>{t("Add Link Type")}</button>
        )}
      </div>
    </div>
  );
}
