import PropTypes from "prop-types";
import FormatListItem from "./FormatListItem";

function FormatList({ format, matterTypeIds }) {
  const renderGrid = () => {
    let grid = new Array(10).fill(null);
    return grid.map((item, i) => {
      const field = format?.listColumns[i];
      const key = field?.id ?? "u" + i;

      return <FormatListItem key={key} field={field} listIndex={i} matterTypeIds={matterTypeIds} maxEntries={10} />;
    });
  };

  return <div className="quick-report-grid">{renderGrid()}</div>;
}

FormatList.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.arrayOf(PropTypes.number),
};

export default FormatList;
