import {
  createMatterObjectAccessRight,
  deleteMatterObjectAccessRight,
  updateMatterObjectAccessRight,
} from "api/security";
import Delete from "components/global/Delete";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { idsAreEqual } from "utilities/stringAndArray";

export default function SectionOveridesAccessPanel() {
  const securityState = useSelector((state) => state.security);

  const currentUserGroup = securityState.currentUserGroup;

  const [isAdding, setIsAdding] = useState(false);

  const permissions = getAllLookupValuesForSource("Permissions");
  const matterObjects = getAllLookupValuesForSource("MatterObjects");

  const handleCreateNewMatterObjectAccessRight = (matterObjectId) => {
    createMatterObjectAccessRight(securityState.currentCustomer.id, currentUserGroup.accessRight.id, matterObjectId);
    setIsAdding(false);
  };

  const renderSectionAccessOverridesList =
    currentUserGroup?.accessRight?.matterObjects?.length > 0 ? (
      currentUserGroup?.accessRight?.matterObjects?.map((matterObject) => {
        const displayValue = matterObjects?.find((object) =>
          idsAreEqual(object.id, matterObject.matterObjectId)
        )?.displayValue;

        return (
          <tr key={matterObject.id}>
            <td>{displayValue}</td>

            <td>
              <SuperSelect
                id={displayValue}
                onChange={(id) =>
                  updateMatterObjectAccessRight(
                    securityState.currentCustomer.id,
                    currentUserGroup.accessRight.id,
                    matterObject.id,
                    matterObject.permissionId,
                    id
                  )
                }
                selectedOptionId={matterObject.permissionId ?? ""}
                options={permissions ?? []}
              />
            </td>

            <td>
              <Delete
                message={"remove this Matter Object Access"}
                onConfirm={() =>
                  deleteMatterObjectAccessRight(
                    securityState.currentCustomer.id,
                    currentUserGroup.accessRight.id,
                    matterObject.id
                  )
                }
              />
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td>
          <h4>{t("No Section Overrides")}</h4>
        </td>
      </tr>
    );

  const renderAddMatterObjectAccessRow = () => {
    const filteredMatterObjects = matterObjects.filter(
      (mo) => !currentUserGroup?.accessRight?.matterObjects?.some((armo) => idsAreEqual(armo.matterObjectId, mo.id))
    );
    return (
      <tr>
        <td>
          <SuperSelect
            id={`sectionAccessSelect`}
            placeholderText="Select Matter Object"
            selectedOptionId={null}
            options={filteredMatterObjects ?? []}
            onChange={handleCreateNewMatterObjectAccessRight}
          />
        </td>
      </tr>
    );
  };

  //MAIN RENDER
  return (
    <div className="security__section">
      <div className="security__section-title">{t("Section Access Overrides")}</div>
      <div className="security__section-body">
        {permissions.length > 0 && (
          <table>
            <tbody>
              {renderSectionAccessOverridesList}
              {isAdding && renderAddMatterObjectAccessRow()}
            </tbody>
          </table>
        )}
        {!isAdding && <button onClick={() => setIsAdding(true)}>{t("Add")}</button>}
      </div>
    </div>
  );
}
