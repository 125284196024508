import { fetchWrapper } from "utilities/fetchWrapper";

// User-related API calls here
export async function signIn(email, password) {
  const body = {
    Email: email,
    Password: password,
  };
  let response = null;
  try {
    response = await fetchWrapper.post("Authentication/signin", body);
  } catch {
    return false;
  }
  return response;
}

export async function passwordResetRequest(email) {
  const body = {
    email,
  };
  let response = null;
  try {
    response = await fetchWrapper.post("Authentication/PasswordResetRequest", body);
  } catch (error) {
    return { success: false, error };
  }
  return { success: true, response };
}

export async function passwordReset(email, password, token) {
  const body = {
    email,
    token,
    password,
  };
  let response = null;
  try {
    response = await fetchWrapper.post("Authentication/PasswordReset", body);
  } catch (error) {
    return { success: false, error };
  }
  return { success: true, response };
}
