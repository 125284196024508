import MatterRecordHeader from "./Header";
import PanelList from "./PanelList";
import { RiCloseFill, RiPushpinFill, RiPushpin2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { removeRecord, setComparing, setRecordPinned } from "redux/matterSlice";
import PropTypes from "prop-types";
import LoadingModal from "components/global/LoadingModal";
import ConnectedMatterSearchPopup from "components/content/matter-record/ConnectedMatterSearchPopup";
import { useEffect, useState } from "react";
import { getPanelIdString } from "utilities/stringAndArray";
import { setIsShowingFullScreenModal, setDocumentTitle } from "redux/appSlice";

// Container Component for the list of data panels for a matter record
function MatterRecord({ record, section }) {
  const matterState = useSelector((state) => state.matter);
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const [isConnectedMatterPopupVisible, setIsConnectedMatterPopupVisible] = useState(false);

  if (section) {
    const elementId = getPanelIdString(section);
    const el = document.getElementById(elementId);
    if (el) el.scrollIntoView();
  }

  useEffect(() => {
    // if a matter record is loaded then set the page title to the matter name
    //console.log("MatterRecord useEffect, record.name:" + JSON.stringify(record));
    if (record && appState.pageTitle !== record.matter_Name) {
      dispatch(setDocumentTitle(record.matter_Name));
    }
  }, [record, appState.pageTitle]);

  useEffect(() => {
    if (matterState?.records?.length === 1 && matterState.isComparing === true) {
      dispatch(setComparing(false));
    }
  }, [matterState.records]);

  const onTogglePinnedStatus = () => {
    dispatch(setRecordPinned({ recordId: record.id, newPinnedStatus: !record.isPinned }));
  };

  const onRemoveFromCompare = () => {
    dispatch(removeRecord(record.id));
    //dispatch(selectedChange(record.id));
  };

  const handleChangeConnectedMatterPopupVisible = (visible) => {
    setIsConnectedMatterPopupVisible(visible);
    dispatch(setIsShowingFullScreenModal(visible));
  };

  const classNameBase = "record__matter-record";
  let className = classNameBase;
  if (record?.isPinned) {
    className += ` ${classNameBase}--pinned`;
  }

  return (
    <>
      <div className={className}>
        {matterState?.isComparing && (
          <div className="matter-record__actions-container">
            <div className="matter-record__compare-actions">
              <div className="matter-record__compare-action" onClick={onTogglePinnedStatus}>
                {record?.isPinned ? (
                  <span className="compare-action__pinned">
                    <RiPushpin2Fill />
                  </span>
                ) : (
                  <RiPushpinFill />
                )}
              </div>
              <div className="matter-record__compare-action" onClick={onRemoveFromCompare}>
                <RiCloseFill />
              </div>
            </div>
          </div>
        )}
        {record ? (
          <>
            <MatterRecordHeader record={record} />
            <div className="record__data-panel-list">
              <PanelList record={record} onSetConnectedMatterPopupVisible={handleChangeConnectedMatterPopupVisible} />
            </div>
          </>
        ) : (
          <LoadingModal />
        )}
      </div>
      {isConnectedMatterPopupVisible && record && (
        <ConnectedMatterSearchPopup record={record} onSetPopupVisible={handleChangeConnectedMatterPopupVisible} />
      )}
    </>
  );
}

MatterRecord.propTypes = {
  record: PropTypes.object,
  section: PropTypes.string,
};

export default MatterRecord;
