import PropTypes from "prop-types";
import { dndTypes } from "utilities/constants";
import { useDrop } from "react-dnd";

// Container Component for the interstice (gap between) thumbnails
function PanelFieldThumbnailInterstice({ keyProps, imageId }) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: dndTypes.THUMBNAIL,
      drop: () => ({ droppedBeforeImageId: imageId }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [keyProps.record?.images]
  );

  const classNameBase = "thumbnail-list__interstice";
  const className = isOver ? `${classNameBase} ${classNameBase}--over` : classNameBase;

  return (
    <div ref={drop} className={className}>
      &nbsp;
    </div>
  );
}

PanelFieldThumbnailInterstice.propTypes = {
  keyProps: PropTypes.object,
  imageId: PropTypes.number,
};

export default PanelFieldThumbnailInterstice;
