import Widget from "components/content/home/Widget";
import { t } from "locale/dictionary";
import { useDispatch, useSelector } from "react-redux";
import { getDateDisplayValue } from "utilities/dateTime";
import { searchViewTypes, widgetTypes } from "utilities/constants";
import { setCurrentPage } from "redux/homeSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Paginator from "components/global/Paginator";
import { clearResults, setViewTypeSearchResults } from "redux/searchResultsSlice";
import { idsAreEqual } from "utilities/stringAndArray";

// Placeholder for the Home Component
export default function Home() {
  const appState = useSelector((state) => state.app);
  const customerState = useSelector((state) => state.customer);
  const widgets = useSelector((state) => state.customer).widgets;
  const homeState = useSelector((state) => state.home);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const widgetPageNumber = homeState.currentPage;

  const widgetGridMaxCells = 6;
  const widgetDiary = widgets.find((w) => idsAreEqual(w.widgetTypeId, widgetTypes.DIARY));
  let nonDiaryWidgets = widgets.filter((w) => !idsAreEqual(w.widgetTypeId, widgetTypes.DIARY));
  const widgetPageNumberMax = Math.floor(nonDiaryWidgets.length / widgetGridMaxCells);
  nonDiaryWidgets = nonDiaryWidgets.slice(
    widgetPageNumber * widgetGridMaxCells,
    (widgetPageNumber + 1) * widgetGridMaxCells
  );

  useEffect(() => {
    if (appState.isSysAdmin) navigate("/customers");
    dispatch(setViewTypeSearchResults(searchViewTypes.MAIN));
    dispatch(clearResults());
  }, []);

  const handlePageFirstClick = () => {
    dispatch(setCurrentPage(0));
  };

  const handlePagePrevClick = () => {
    dispatch(setCurrentPage(widgetPageNumber - 1));
  };

  const handlePageNextClick = () => {
    dispatch(setCurrentPage(widgetPageNumber + 1));
  };

  const handlePageLastClick = () => {
    dispatch(setCurrentPage(widgetPageNumberMax));
  };

  return (
    <div className="home">
      <div className="home__status-bar">
        <div className="status-bar__item">{`${t("Hello")} ${
          customerState.fullName ? customerState.fullName : ""
        }!`}</div>
        <Paginator
          totalPages={widgetPageNumberMax + 1}
          currentPage={widgetPageNumber}
          onPageFirstClick={handlePageFirstClick}
          onPagePrevClick={handlePagePrevClick}
          onPageNextClick={handlePageNextClick}
          onPageLastClick={handlePageLastClick}
        />
        <div className="status-bar__item">{`${t("Today's Date")}: ${getDateDisplayValue(new Date())}`}</div>
      </div>
      <div className="home__widgets">
        <div className="home__widgets-grid-container">
          <div className="home__widgets-grid">
            {nonDiaryWidgets.map((widget) => (
              <Widget key={widget.id} widget={widget} />
            ))}
          </div>
        </div>
        <div className="home__widget-diary">
          {widgetDiary && <Widget key={widgetTypes.DIARY} widget={widgetDiary} />}
        </div>
      </div>
    </div>
  );
}
