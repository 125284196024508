import { useSelector } from "react-redux";
import { updateBatchImportSearchRowSelect, updateBatchImportSearchRowSelectAll } from "api/batchImport";
import BatchImportPrepare from "./BatchImportPrepare";
import SearchResultsHeader from "components/content/search-results/SearchResultsHeader";
import SearchResultsDataGrid from "components/content/search-results/SearchResultsDataGrid";
import SearchResultsFooter from "components/content/search-results/SearchResultsFooter";
import { projectStatuses } from "utilities/constants";

export default function BatchImportSearchResults() {
  const appState = useSelector((state) => state.app);
  const batchImportState = useSelector((state) => state.batchImport);

  return (
    <>
      <div className="batch-import__search-results__header-container">
        <SearchResultsHeader />
      </div>
      <div className="batch-import__search-results__body-container">
        <SearchResultsDataGrid
          isBatchImport={true}
          onSelectChange={(id, isSelected) =>
            updateBatchImportSearchRowSelect(appState.customerId, batchImportState.project.id, id, isSelected)
          }
          onSelectAllChange={(isAllSelected) =>
            updateBatchImportSearchRowSelectAll(appState.customerId, batchImportState.project.id, isAllSelected)
          }
          isReadOnly={batchImportState.project.project_Status === projectStatuses.MATTER_GENERATED}
        />
      </div>
      <div className="batch-import__search-results__footer-container">
        <SearchResultsFooter />
        <BatchImportPrepare />
      </div>
    </>
  );
}
