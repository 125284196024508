import React from "react";
import { useSelector } from "react-redux";
import { labelTypes } from "utilities/constants";
import { getDataTypeForField, getLookupValueForField } from "utilities/datafield";
import { getDateDisplayValue } from "utilities/dateTime";
import { getDisplayNameForField } from "utilities/reportEditor";
import { t } from "locale/dictionary";
import { getCountryNameFromIsoCode } from "utilities/countries";
import MatterRecordUniversalLink from "components/content/matter-record/UniversalLink";
import { useState } from "react";
import { useEffect } from "react";
import { getImageData } from "api/image";

function BatchCreateTemplateDetails() {
  const batchCreateState = useSelector((state) => state.batchCreate);

  const project = batchCreateState?.project;
  const fieldNames = project?.linkedMatterRecords?.header?.dataFieldNames;

  const [imageData, setImageData] = useState([]);

  // TODO: This is a kludge - far easier for the backend to return the base64 data with the "linkedMatters" data set
  useEffect(() => {
    const runGetImageData = async (image) => {
      const matterId = project?.linkedMatters?.[0]?.matterId;
      const imageId =
        project?.linkedMatterRecords?.body?.[0]?.values?.[
          project?.linkedMatterRecords?.header?.dataFieldNames.indexOf("matterImage_FirstMatterImageId")
        ];
      let base64data = null;
      base64data = await getImageData(imageId, matterId, true);
      setImageData(base64data);
    };
    runGetImageData();
  }, [project.linkedMatterRecords]);

  const renderPriority = (
    <div className="batch-create__bold-one-liner">
      {project.project_IsPriorityClaimed ? t(`Priority claimed`) : t("Priority NOT claimed")}
    </div>
  );

  const renderLinkedTableHeader = (
    <thead>
      <tr>
        {fieldNames?.map((fieldName) => (
          <th key={fieldName}>{getDisplayNameForField(fieldName)}</th>
        ))}
      </tr>
    </thead>
  );

  const renderLinkedTableRow = (row, rowIndex) => {
    return (
      <tr key={rowIndex}>
        {row?.values?.map((val, index) => {
          let displayVal = val;
          const fieldName = fieldNames[index];
          if (getDataTypeForField(fieldName) === labelTypes.DATE) displayVal = getDateDisplayValue(val);
          let renderValue = displayVal;
          if (fieldName === "matter_Name") {
            const matterId = project?.linkedMatters[rowIndex]?.matterId;
            if (matterId)
              renderValue = <MatterRecordUniversalLink recordId={matterId}>{displayVal}</MatterRecordUniversalLink>;
          }
          if (fieldName === "matter_StatusId") {
            renderValue = getLookupValueForField(fieldName, displayVal);
          }
          if (fieldName === "matterCountry_Id") {
            renderValue = getCountryNameFromIsoCode(displayVal);
          }
          if (fieldName === "matterGoods_Class") {
            renderValue = displayVal.join(", ");
          }
          if (fieldName === "matterImage_FirstMatterImageId") {
            renderValue = <img key={displayVal} className="data-grid__img" src={imageData} alt="matter thumbnail" />;
          }

          return <td key={`${rowIndex}-${index}`}>{renderValue}</td>;
        })}
      </tr>
    );
  };

  const renderLinkedTableBody = project?.linkedMatterRecords?.body.length > 0 && (
    <tbody>{project.linkedMatterRecords.body.map((row, rowIndex) => renderLinkedTableRow(row, rowIndex))}</tbody>
  );

  const renderManualTableBody = project?.newLinkedMatters?.length > 0 && (
    <>
      <thead>
        <tr>
          <th>{t("Country")}</th>
          <th>{t("Application No")}</th>
          <th>{t("Application Date")}</th>
          <th>{t("Class")}</th>
        </tr>
      </thead>
      <tbody>
        {project.newLinkedMatters.map((matter) => (
          <tr key={matter.id}>
            <td>{getCountryNameFromIsoCode(project.project_NewLinkedCountryId)}</td>
            <td>{matter.applicationNo}</td>
            <td>{getDateDisplayValue(matter.applicationDate)}</td>
            <td>{matter.class}</td>
          </tr>
        ))}
      </tbody>
    </>
  );

  return (
    <div>
      {renderPriority}
      {project.newLinkedMatters?.length === 0 && project.linkedMatters.length === 0 ? (
        <div className="batch-create__bold-one-liner">{t("No base record used")}</div>
      ) : (
        <table className="table__inner">
          {renderLinkedTableHeader}
          {renderLinkedTableBody}
          {renderManualTableBody}
        </table>
      )}
    </div>
  );
}

export default BatchCreateTemplateDetails;
