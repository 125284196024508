import { t } from "locale/dictionary";
import { useState } from "react";
import MatterContactEditor from "../ContactEditor";
import PropTypes from "prop-types";
import { getLookupValueBySource } from "utilities/lookup";
import { sectionTypes } from "utilities/constants";
import { getDisplayNameForField } from "utilities/datafield";
import { setIsShowingFullScreenModal } from "redux/appSlice";
import { useDispatch } from "react-redux";

// Container Component for the display of a compound object field within a matter data panel
function PanelFieldObject({ keyProps }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const displayName = getDisplayNameForField(keyProps.field.fieldName, [keyProps.record.matter_MatterTypeId]);

  const getDataRowDisplay = (dataRow, field) => {
    let rowDataDisplay =
      field.objectProps.propsList &&
      field.objectProps.propsList.map((objectProp) => {
        let outputText = "";
        if (dataRow.hasOwnProperty(objectProp)) {
          outputText = dataRow[objectProp];
        }
        if (objectProp === "matterActionContactLink_FullName" || objectProp === "matterCommentContactLink_FullName") {
          if (dataRow.hasOwnProperty("matterActionContactLink_LegalTeamContactLinkTypeId")) {
            outputText += ` (${getLookupValueBySource(
              "LegalTeamContactLinkTypes",
              dataRow.matterActionContactLink_LegalTeamContactLinkTypeId
            )})`;
          }
          if (dataRow.hasOwnProperty("matterCommentContactLink_CommentContactLinkTypeId")) {
            outputText += ` (${getLookupValueBySource(
              "CommentContactLinkTypes",
              dataRow.matterCommentContactLink_CommentContactLinkTypeId
            )})`;
          }
        }
        return outputText;
      });
    return rowDataDisplay.join(field.objectProps.delimiter);
  };

  const handleModalVisibility = (visibility) => {
    setShowModal(visibility);
    dispatch(setIsShowingFullScreenModal(visibility));
  };

  let displayDataArray = [];
  if (Array.isArray(keyProps.data)) {
    displayDataArray = keyProps.data.map((dataRow) => {
      return getDataRowDisplay(dataRow, keyProps.field);
    });
  } else getDataRowDisplay(keyProps.data, keyProps.field);

  let displayValue = displayDataArray.join(", ");
  let styleAsError = false;
  if (displayValue.length === 0) {
    if (keyProps.isAdding) displayValue = t("Please complete required fields in this row before adding contacts");
    else {
      displayValue = t("Click here to add a contact");
      if (keyProps.tableName === "actions") styleAsError = true;
    }
  }

  const objectListClassNameBase = "object-list";
  let objectListClassName = objectListClassNameBase;
  if (styleAsError) objectListClassName = `${objectListClassNameBase} ${objectListClassNameBase}--error`;

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <>
      <div className={objectListClassName} onClick={() => !keyProps.isAdding && handleModalVisibility(true)}>
        {displayValue}
      </div>
      {showModal === true && (
        <MatterContactEditor
          keyProps={{ ...keyProps, parentRowId: keyProps.rowData.id }}
          onClose={() => handleModalVisibility(false)}
        />
      )}
    </>
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value">{displayValue}</div>
    </>
  );
}

PanelFieldObject.propTypes = {
  keyProps: PropTypes.object,
};

export default PanelFieldObject;
