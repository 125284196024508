import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { executeReport } from "api/report";
import { ReportOutputTypes, ResultSetTypes } from "utilities/reportEditor";
import { base64ToBlob } from "utilities/stringAndArray";
import { saveAs } from "file-saver";
import ReportList from "../report-editor/menu/ReportList";
import ReportOutput from "../report-output/ReportOutput";
import PopupHeader from "components/global/PopupHeader";

// Container Component for the report viewer for the search results page
function ReportViewer({ resultViewType }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const searchResults = useSelector((state) => state.searchResults).currentSearch;

  const [output, setOutput] = useState(null);
  const [reportName, setReportName] = useState(null);

  const queryTypeId = simpleSearch?.queryType?.id;

  const handleSelect = async (report, outputType) => {
    setReportName(report.name);
    switch (outputType) {
      case ReportOutputTypes.HTML:
      case ReportOutputTypes.PDF:
        alert("Not yet implemented");
        return;
      default:
    }

    const reportRun = {
      outputType,
      searchLogId: searchResults.logId,
      selectedKeys: searchResults.selected,
    };

    const response = await executeReport(report.id, reportRun);

    switch (response.type) {
      case ResultSetTypes.EXCEL:
      case ResultSetTypes.WORD:
        setOutput(null);
        saveAs(base64ToBlob(response.file.base64String), response.file.fileName);
        break;
      case ResultSetTypes.MAP:
      case ResultSetTypes.PIVOT:
      case ResultSetTypes.TABLE:
        setOutput(response);
        break;
      default:
    }
  };

  return (
    queryTypeId && (
      <>
        <div className="search-results__report-viewer">
          <div className="report-viewer__body">
            <ReportList
              queryTypeId={queryTypeId}
              formatTypeId={resultViewType}
              isEditor={false}
              onSelect={handleSelect}
              selectedPreview={output}
            />
          </div>
        </div>
        {output && (
          <div className="modal-mask">
            <div className="report-modal">
              <PopupHeader title={`Preview of ${reportName}`} onClose={() => setOutput(null)} />
              <div className="report-modal__body">
                <ReportOutput output={output} />
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
}

ReportViewer.propTypes = {
  resultViewType: PropTypes.number,
};

export default ReportViewer;
