import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { t } from "locale/dictionary";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { addMatterChildTableLink, deleteMatterChildTableLink, saveMatterChildTableLinksChanges } from "api/matter";
import PopupHeader from "components/global/PopupHeader";
import Delete from "components/global/Delete";
import PropTypes from "prop-types";
import SuperSelect from "components/global/SuperSelect";
import { nanoid } from "nanoid";
import { initializeAddingRowData } from "utilities/matter";
import { getDateDisplayValue } from "utilities/dateTime";
import { getLookupValueForField } from "utilities/datafield";

// Modal component for the editing of contact references to data rows
function MatterContactEditor({ keyProps, onClose }) {
  const record = keyProps.record;
  const legalTeam = useSelector((state) => state.customer.legalTeam).map((person) => {
    return { id: person.id, displayValue: `${person.givenName} ${person.familyName}` };
  });

  const [isAdding, setIsAdding] = useState(false);
  const [newContactId, setNewContactId] = useState(null);
  const [newRoleId, setNewRoleId] = useState(null);

  let roles = [];
  switch (keyProps.tableName) {
    case "comments":
      roles = getAllLookupValuesForSource("CommentContactLinkTypes");
      break;
    default:
      roles = getAllLookupValuesForSource("LegalTeamContactLinkTypes");
      break;
  }

  let popupHeaderTitle = "";
  let contactIdField = "matterActionContactLink_MatterContactId";
  let roleIdField = "matterActionContactLink_LegalTeamContactLinkTypeId";
  if (keyProps.tableName === "comments") {
    contactIdField = "matterCommentContactLink_MatterContactId";
    roleIdField = "matterCommentContactLink_CommentContactLinkTypeId";
    popupHeaderTitle = `Legal Team - ${getDateDisplayValue(keyProps.rowData.matterComment_Date)} - ${
      keyProps.rowData.matterComment_Text
    }`;
  } else {
    popupHeaderTitle = `Legal Team - ${getDateDisplayValue(
      keyProps.rowData.matterAction_ActionDate
    )} - ${getLookupValueForField("matterAction_ActionTypeId", keyProps.rowData.matterAction_ActionTypeId)}`;
  }
  if (popupHeaderTitle.length > 50) popupHeaderTitle = popupHeaderTitle.substring(0, 50) + "...";

  let numRows = keyProps.data.length;

  const fieldsToValidate = keyProps.fieldList.filter((field) => field.fieldName === "contactLinks");
  const fieldNamesToValidate = fieldsToValidate?.map((field) => field.fieldName);

  useEffect(() => {
    if (numRows === 0) {
      beginAddNew();
    }
  }, [numRows]);

  // Auto-save new row if both dropdowns have been filled out
  useEffect(() => {
    if (!newContactId || !newRoleId) return;
    const newObject = {
      [contactIdField]: newContactId,
      [roleIdField]: newRoleId,
    };

    addMatterChildTableLink(
      record.id,
      fieldNamesToValidate,
      keyProps.tableName,
      keyProps.parentRowId,
      "contactLinks",
      newObject
    );
    setIsAdding(false);
  }, [newContactId, newRoleId]);

  const beginAddNew = () => {
    const fieldList = [{ fieldName: contactIdField }, { fieldName: roleIdField }];
    const newAddRowData = initializeAddingRowData(record, fieldList, nanoid());
    setNewContactId(newAddRowData[contactIdField]);
    setNewRoleId(newAddRowData[roleIdField]);
    setIsAdding(true);
  };

  const onSelectChange = (contactLinkId, fieldName, optionId, initialValue) => {
    saveMatterChildTableLinksChanges(
      record.id,
      keyProps.tableName,
      keyProps.parentRowId,
      "contactLinks",
      contactLinkId,
      fieldName,
      optionId,
      initialValue
    );
  };

  const onDeleteContact = (contactLinkId) => {
    deleteMatterChildTableLink(
      record.id,
      fieldNamesToValidate,
      keyProps.tableName,
      keyProps.parentRowId,
      "contactLinks",
      contactLinkId
    );
  };

  const headerRender = (
    <tr className="data-panel__table-row">
      <td className="data-panel__table-header data-panel__table-header--40">{t("Name")}</td>
      <td className="data-panel__table-header data-panel__table-header--40">{t("Role")}</td>
      <td className="data-panel__table-header data-panel__table-header--10"></td>
    </tr>
  );

  const renderRows =
    keyProps.data &&
    keyProps.data.map((contact) => {
      return (
        <tr key={contact.id} className="data-panel__table-row">
          <td className="data-panel__table-cell data-panel__table-cell--40">
            <SuperSelect
              id={`contactEditorLegalTeam${contact.id}`}
              options={legalTeam}
              selectedOptionId={contact[contactIdField]}
              onChange={(optionId) => onSelectChange(contact.id, contactIdField, optionId, contact[contactIdField])}
            />
          </td>
          <td className="data-panel__table-cell data-panel__table-cell--40">
            <SuperSelect
              id={`contactEditorRoles${contact.id}`}
              options={roles}
              selectedOptionId={contact[roleIdField]}
              onChange={(optionId) => onSelectChange(contact.id, roleIdField, optionId, contact[roleIdField])}
            />
          </td>
          <td className="data-panel__table-cell data-panel__table-cell--action delete clickable">
            <Delete message="Delete this reference to this contact" onConfirm={() => onDeleteContact(contact.id)} />
          </td>
        </tr>
      );
    });

  const renderAddRow = isAdding ? (
    <tr className="data-panel__table-row">
      <td className="data-panel__table-cell data-panel__table-cell--40">
        <SuperSelect
          id="contactEditorLegalTeamAdd"
          options={legalTeam}
          selectedOptionId={newContactId}
          onChange={(optionId) => setNewContactId(optionId)}
        />
      </td>
      <td className="data-panel__table-cell data-panel__table-cell--40">
        <SuperSelect
          id="contactEditorRolesAdd"
          options={roles}
          selectedOptionId={newRoleId}
          onChange={(optionId) => setNewRoleId(optionId)}
        />
      </td>
      <td className="data-panel__table-cell data-panel__table-cell--5 clickable data-panel__save-row">&nbsp;</td>
    </tr>
  ) : (
    <tr>
      <td colSpan={3}>
        <button onClick={beginAddNew}>Add New</button>
      </td>
    </tr>
  );

  return (
    <div className="modal-mask">
      <div className="contact-editor__popup">
        <PopupHeader title={popupHeaderTitle} onClose={onClose} />
        <div className="data-panel__table">
          <table className="data-panel__table-inner">
            <thead>{headerRender}</thead>
            <tbody>
              {renderRows}
              {renderAddRow}
              {numRows === 0 && !isAdding && (
                <tr>
                  <td>
                    <h4 className="data-panel__table-row">{t("No Contacts")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

MatterContactEditor.propTypes = {
  keyProps: PropTypes.object,
  onClose: PropTypes.func,
};

export default MatterContactEditor;
