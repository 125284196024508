import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import Content from "./components/content/Content";
import Authentication from "components/user/Authentication";
import Toast from "components/global/Toast";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AuthenticationRoutes from "components/user/AuthenticationRoutes";
import { useEffect } from "react";

export default function App() {
  const appState = useSelector((state) => state.app);
  const localeState = useSelector((state) => state.locale);

  const className = `app ${appState.theme === "dark" ? "app--dark" : "app--light"}`;

  useEffect(() => {
    document.title = `${appState.documentTitle} - Cedar IP`;
  }, [appState.documentTitle]);

  // useEffect(() => {
  //   const handleFocus = (event) => {
  //     console.log("Focus changed to:", event.target);
  //   };

  //   // Attach the event listener
  //   document.addEventListener("focus", handleFocus, true);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("focus", handleFocus, true);
  //   };
  // }, []);

  const renderMainContent = () => {
    return (
      <>
        <div className={className}>
          {/* localeState doesn't do anything in Header, but is needed to force state refresh for label translations when locale selector is changed */}
          <Header locale={localeState} />
          <Content />
          {/* <div className="test-footer">Testing Purposes Only!</div> */}
          <Toast />
        </div>
        {appState.isShowingFullScreenModal && <div className="full-screen-modal-mask"></div>}
      </>
    );
  };

  //main render
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <AuthenticationRoutes />
          {appState.customerId ? renderMainContent() : null}
        </BrowserRouter>
      </DndProvider>
    </>
  );
}
