import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fieldTextValueChange,
  inputTextValueChange,
  labelSelectedChange,
  popupVisibleChange,
  searchValueChange,
  setSavedSearchLoadedId,
  setKeyboardFocusedSelection,
  setPopupWholeSection,
} from "redux/simpleSearchSlice";
import { simpleSearchKeyboardFocusedPopup } from "utilities/constants";
import PopupHeader from "components/global/PopupHeader";
import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { focusFirstTabbedChild } from "utilities/dom";
import { useRef } from "react";
import SuggestedFieldPopupContent from "./SuggestedFieldPopupContent";

/*  Suggested Field Popup Container. This displays to the right-hand-side when the user clicks on a suggested field match label 
    and contains one or more Suggested Field Popup Content elements  
 */
function SuggestedFieldPopupContainer({ fields, sectionDisplayName, parent, isFieldNameMatch, filter, title }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const [isApplicable, setIsApplicable] = useState(false); //filter ? false : field.dataType === labelTypes.BOOLEAN);
  const [isApplying, setIsApplying] = useState(false);
  const [hasApplied, setHasApplied] = useState([]);

  useEffect(() => {
    if (simpleSearch.isKeyboardFocusedSelection === simpleSearchKeyboardFocusedPopup.RHS && containerRef.current) {
      focusFirstTabbedChild(containerRef.current);
    }
  }, [simpleSearch.isKeyboardFocusedSelection]);

  // useEffect for checking when all elements have completed applying, close the RHS popup and reset hasApplied to empty
  useEffect(() => {
    if (hasApplied.length > 0) {
      const allApplied = hasApplied.every((field) => field.hasApplied);
      if (allApplied) {
        dispatch(labelSelectedChange(false));
        dispatch(searchValueChange({ text: "", locale: "", fieldsQualifiedValues: [] }));
        dispatch(inputTextValueChange(""));
        dispatch(fieldTextValueChange(""));
        //dispatch(setSavedSearchLoadedId(null));
        dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.SEARCH_PANEL));

        // Close the main popup and re-focus the search input control
        document.getElementsByClassName("simple-search__input")[0].focus();
        dispatch(popupVisibleChange(false));
        if (parent === "Section") {
          dispatch(setPopupWholeSection(null));
        }

        setIsApplying(false);
        setHasApplied([]);
      }
    }
  }, [hasApplied]);

  const handleCloseClick = () => {
    if (parent === "Section") {
      dispatch(setPopupWholeSection(null));
    }
    dispatch(labelSelectedChange(false));
    dispatch(fieldTextValueChange(""));
  };

  const handleApplyClearClick = async () => {
    if (!isApplicable || isApplying) return;
    setIsApplying(true);
    setHasApplied(fields.map((field) => ({ fieldName: field.fieldName, hasApplied: false })));
  };

  // update the hasApplied array with the fieldName and hasApplied value when a child component sends back that that it has completed applying
  const handleHasAppliedField = (fieldName) => {
    if (!isApplying) return;
    const hasAppliedLocal = hasApplied;
    hasAppliedLocal.forEach((field) => {
      if (field.fieldName === fieldName) {
        field.hasApplied = true;
        setHasApplied(hasAppliedLocal);
      }
    });
  };

  const handleIsApplicableChange = (isApplicableIn) => {
    setIsApplicable(isApplicableIn);
  };

  return (
    (parent === simpleSearch.labelSelected.clickSource || parent === "Section") && (
      <div ref={containerRef}>
        <PopupHeader title={fields.length === 1 ? fields[0].displayName : title} onClose={handleCloseClick} />

        {fields.map((field) => (
          <SuggestedFieldPopupContent
            key={field.fieldName}
            field={field}
            sectionDisplayName={sectionDisplayName}
            parent={parent}
            isFieldNameMatch={isFieldNameMatch}
            filter={filter}
            handleIsApplicableChangeParent={handleIsApplicableChange}
            isApplying={isApplying}
            handleHasAppliedField={handleHasAppliedField}
            handleForceApply={handleApplyClearClick}
          />
        ))}

        <button className={isApplicable ? "" : "disabled"} onClick={handleApplyClearClick} tabIndex={0}>
          {t("Apply Filter")}
        </button>
      </div>
    )
  );
}

SuggestedFieldPopupContainer.propTypes = {
  fields: PropTypes.array,
  sectionDisplayName: PropTypes.string,
  parent: PropTypes.string,
  isFieldNameMatch: PropTypes.bool,
  filter: PropTypes.object,
  title: PropTypes.string,
  handleCloseClickSection: PropTypes.func,
};

export default SuggestedFieldPopupContainer;
