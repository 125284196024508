import { getDisplayNameForField } from "utilities/reportEditor";
import { getTranslation } from "utilities/reportShared";

export const baseMenuItems = ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"];

export function getChartLabel(resultRow, key) {
  let label;
  if (!resultRow.keys[key.keyColumn]) {
    label = "No " + getDisplayNameForField(key.keyDataFieldName);
  } else if (key.labelDataFieldName === null) {
    label = resultRow.keys[key.keyColumn];
  } else {
    label = getTranslation(resultRow.labels[key.labelColumn]);
  }
  return label;
}

export function getAxisKeys(axisArray, pivotData) {
  let axisKeys = [];
  if (axisArray.length > 0) {
    const axisColumn = axisArray[0];
    axisKeys = pivotData
      .map((pivotRow) => ({
        keyColumn: axisColumn.keyColumn,
        label: getChartLabel(pivotRow, axisColumn),
        value: pivotRow.keys[axisColumn.keyColumn],
      }))
      .filter((value, index, self) => self.findIndex((testValue) => testValue.value === value.value) === index);
  }

  axisKeys.sort((a, b) => a.label && b.label && a.label.localeCompare(b.label, undefined, { sensitivity: "base" }));

  return axisKeys;
}

export function getHighchartsGlobeGraticule() {
  const data = [];

  // Meridians
  for (let x = -180; x <= 180; x += 15) {
    data.push({
      geometry: {
        type: "LineString",
        coordinates:
          x % 90 === 0
            ? [
                [x, -90],
                [x, 0],
                [x, 90],
              ]
            : [
                [x, -80],
                [x, 80],
              ],
      },
    });
  }

  // Latitudes
  for (let y = -90; y <= 90; y += 10) {
    const coordinates = [];
    for (let x = -180; x <= 180; x += 5) {
      coordinates.push([x, y]);
    }
    data.push({
      geometry: {
        type: "LineString",
        coordinates,
      },
      lineWidth: y === 0 ? 2 : undefined,
    });
  }

  return data;
}

export function highchartsGlobeRenderSea(chart) {
  const verb = !chart.sea ? "attr" : "animate";

  if (!chart.sea) {
    chart.sea = chart.renderer
      .circle()
      .attr({
        fill: {
          radialGradient: {
            cx: 0.4,
            cy: 0.4,
            r: 1,
          },
          stops: [
            [0, "white"],
            [1, "lightblue"],
          ],
        },
        zIndex: -1,
      })
      .add(chart.get("graticule").group);
  }

  const bounds = chart.get("graticule").bounds,
    p1 = chart.mapView.projectedUnitsToPixels({
      x: bounds.x1,
      y: bounds.y1,
    }),
    p2 = chart.mapView.projectedUnitsToPixels({
      x: bounds.x2,
      y: bounds.y2,
    });
  chart.sea[verb]({
    cx: (p1.x + p2.x) / 2,
    cy: (p1.y + p2.y) / 2,
    r: Math.min(p2.x - p1.x, p1.y - p2.y) / 2,
  });
}
