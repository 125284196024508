import { t } from "locale/dictionary";
import { FaEquals } from "react-icons/fa";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { useDispatch, useSelector } from "react-redux";
import SearchPanel from "components/simple-search/SearchPanel";
import { searchViewTypes } from "utilities/constants";
import { useEffect } from "react";
import { queryTypeChange } from "redux/simpleSearchSlice";
import { setProjectId } from "redux/searchResultsSlice";
import { processDataFields } from "utilities/simpleSearch";
import { findById, idsAreEqual } from "utilities/stringAndArray";

export default function BatchImportSearchPanel() {
  const batchImportState = useSelector((state) => state.batchImport);
  const localeState = useSelector((state) => state.locale);
  const currentSearchState = useSelector((state) => state.simpleSearch).currentSearch;
  const searchResultsState = useSelector((state) => state.searchResults);
  const dispatch = useDispatch();

  const batchImportSources = getAllLookupValuesForSource("ProjectImportSourceTypes");
  const queryTypes = getAllLookupValuesForSource("QueryTypes");
  const projectDataSource = findById(batchImportSources, batchImportState.project?.project_ImportSourceTypeId);

  useEffect(() => {
    const queryType = findById(queryTypes, projectDataSource?.queryTypeId);
    dispatch(queryTypeChange(queryType));
  }, [projectDataSource?.queryTypeId]);

  useEffect(() => {
    dispatch(setProjectId(batchImportState?.project?.id));
  }, [batchImportState?.project?.id]);

  useEffect(() => {
    processDataFields();
  }, [currentSearchState.queryType]);

  let searchRowClass = "batch-import__search-row";
  if (searchResultsState?.currentSearch?.results?.length === 0) {
    searchRowClass += " batch-import__search-row--search-only";
  }

  return (
    <div className={searchRowClass}>
      <div className="batch-import__field-filter-panel">
        <div className="batch-import__data-source__container">
          <div className="batch-import__data-source__header">
            {t("Data Source")}&nbsp;
            <FaEquals />
          </div>
          <div className="batch-import__data-source__body">
            {t(localeState.translations[projectDataSource?.translationCode])}
          </div>
        </div>
        <SearchPanel
          readOnly={searchResultsState.currentSearch?.selected?.length > 0}
          isEmbedded={true}
          parentContainer="batch-import__field-filter-panel"
          viewType={searchViewTypes.BATCH_IMPORT}
          matterTypeId={batchImportState.project?.project_MatterTypeId}
        />
      </div>
    </div>
  );
}
