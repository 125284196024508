import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { getImageData } from "api/image";

// Container Component for the image thumbnail within a table row
function PanelFieldRowImage({ keyProps }) {
  const [base64data, setBase64Data] = useState(null);

  useEffect(() => {
    const runGetImageData = async () => {
      const data = await getImageData(
        keyProps.rowData.matterLink_FirstMatterImageId,
        keyProps.rowData.matterLink_MatterId
      );
      setBase64Data(data);
    };
    keyProps &&
      keyProps.rowData &&
      keyProps.rowData.matterLink_FirstMatterImageId &&
      keyProps.rowData.matterLink_MatterId &&
      runGetImageData();
  }, []);

  return <div className="table-cell__image">{base64data?.length > 0 && <img src={base64data} alt={keyProps} />}</div>;
}

PanelFieldRowImage.propTypes = {
  keyProps: PropTypes.object,
};

export default PanelFieldRowImage;
