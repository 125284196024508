import SearchPanel from "components/simple-search/SearchPanel";
import { t } from "locale/dictionary";
import { useSelector } from "react-redux";
import { searchViewTypes } from "utilities/constants";

export default function AdvancedFiltersAccessPanel() {
  const simpleSearchState = useSelector((state) => state.simpleSearch);

  return (
    <div className="security__section">
      <div className="security__section-title"> {t("Advanced Filtering Rules")} </div>
      {simpleSearchState?.currentSearch?.viewType === searchViewTypes.ACCESS_RIGHTS && (
        <SearchPanel
          isEmbedded={true}
          parentContainer="security__field-filter-panel"
          viewType={searchViewTypes.ACCESS_RIGHTS}
        />
      )}
    </div>
  );
}
