import { saveMatterChildObjectFieldChanges, saveMatterSimpleFieldChanges } from "api/matter";
import { t } from "locale/dictionary";
import Switch from "components/global/Switch";
import PropTypes from "prop-types";
import { sectionTypes } from "utilities/constants";

// Container Component for the display of a boolean field within a matter data panel
function PanelFieldBoolean({ keyProps, canEdit, displayName, onChange }) {
  const onSwitchChange = () => {
    if (keyProps.isProject) onChange(keyProps.field.fieldName, keyProps.rowData, !keyProps.data);
    else if (keyProps.sectionType === sectionTypes.TABLE) {
      saveMatterChildObjectFieldChanges(
        keyProps.record.id,
        keyProps.tableName,
        keyProps.rowData.id,
        keyProps.field.fieldName,
        !keyProps.data,
        keyProps.data
      );
    } else saveMatterSimpleFieldChanges(keyProps.record.id, keyProps.field.fieldName, !keyProps.data, keyProps.data);
  };

  return canEdit ? (
    <Switch
      isOn={keyProps.data}
      text={keyProps.field.hideDisplayName ? "" : displayName}
      onSwitchChange={onSwitchChange}
      tabIndex={0}
    />
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value">{keyProps.data === true ? t("Yes") : t("No")}</div>
    </>
  );
}

PanelFieldBoolean.propTypes = {
  keyProps: PropTypes.object,
  canEdit: PropTypes.bool,
  displayName: PropTypes.string,
  onChange: PropTypes.func,
};

export default PanelFieldBoolean;
