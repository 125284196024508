import store from "redux/store";

const dictionary = [
  {
    localeCode: "TEST",
    localeName: "TEST",
    phrases: [],
  },
  {
    localeCode: "en-GB",
    localeName: "English (UK)",
    phrases: [],
  },
  {
    localeCode: "en-US",
    localeName: "English (US)",
    phrases: [],
  },
  {
    localeCode: "de-DE",
    localeName: "Deutsch",
    phrases: [
      { key: "Yes", value: "Ja" },
      { key: "No", value: "Nein" },
    ],
  },
  {
    localeCode: "fr-FR",
    localeName: "Français",
    phrases: [
      { key: "Yes", value: "Oui" },
      { key: "No", value: "Non" },
      { key: "Search for anything here", value: "Rechercher n'importe quoi ici" },
      { key: "Search", value: "Rechercher" },
      { key: "Search", value: "Rechercher" },
      { key: "Advanced Search", value: "Recherche Avancée" },
      { key: "View Favourites", value: "Voir les Favoris" },
      { key: "Add to Favourites", value: "Ajouter aux Favoris" },
      { key: "View History", value: "Voir l'historique" },
      { key: "result found", value: "résultat trouvé" },
      { key: "results found", value: "résultats trouvés" },
      { key: "selected", value: "choisi" },
      { key: "Data", value: "Donnés" },
      { key: "Cards", value: "Fiches" },
      { key: "Map", value: "Carte" },
      { key: "Graphs", value: "Graphiques" },
      { key: "Run report", value: "Rapport d'exécution" },
      { key: "of", value: "de" },
    ],
  },
  {
    localeCode: "zh-CN",
    localeName: "中文",
    phrases: [],
  },
];

export function t(phrase, localeCode) {
  if (!localeCode) {
    const state = store.getState();
    localeCode = state.locale.selectedLocale;
  }

  if (localeCode === "TEST") return `*_${phrase}`;

  let matchingLocale = dictionary.find((item) => item.localeCode === localeCode);
  if (!matchingLocale) return phrase;
  let translatedPhrase = matchingLocale.phrases.find((item) => item.key === phrase);
  if (!translatedPhrase) return phrase;
  return translatedPhrase.value;
}

export function getLocales() {
  return dictionary.map((entry) => {
    return { name: entry.localeName, code: entry.localeCode };
  });
}
