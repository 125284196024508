import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { formatInsertSubsection, setGridSectionSelected } from "redux/reportSlice";
import { getDisplayNameForField } from "utilities/reportEditor";
import { getSectionTypeTitle, isSectionSelected, SectionTypeEnum } from "utilities/reportEditor";
import { t } from "locale/dictionary";
import { FaChevronDown } from "react-icons/fa";
import useComponentVisible from "components/global/useComponentVisible";
import ReportSubsectionPicker from "./ReportSubsectionPicker";

function ReportSectionHeader({ format, section, sectionGroup, firstSection, matterTypeIds, isTopLevel }) {
  const reportState = useSelector((state) => state.report);
  const selectedGridElements = reportState.selectedGridElements;

  const dispatch = useDispatch();

  const [refSubsectionPicker, showSubsectionPicker, setShowSubsectionPicker] = useComponentVisible(false);

  const [headerSelectedClassName, setHeaderSelectedClassName] = useState("");

  useEffect(() => {
    setHeaderSelectedClassName(isSectionSelected(selectedGridElements, section.id) ? "table__object--selected" : "");
  }, [selectedGridElements, section.id]);

  const [title, setTitle] = useState("");

  useEffect(() => {
    const sectionTitle = getSectionTypeTitle(section.sectionTypeId);

    switch (section.sectionTypeId) {
      case SectionTypeEnum.GROUP_HEADER:
      case SectionTypeEnum.GROUP_FOOTER:
        const sortColumn = format.sortColumns.find((sortColumn, index) => index === section.index);
        if (sortColumn) {
          setTitle(`${sectionTitle} (${getDisplayNameForField(sortColumn.fieldName, matterTypeIds)})`);
        } else {
          setTitle(sectionTitle);
        }
        break;
      default:
        setTitle(sectionTitle);
    }
  }, [section.sectionTypeId, section.index, format.sortColumns, matterTypeIds]);

  const onSubsectionInsertClick = (dataSource) => {
    setShowSubsectionPicker(false);
    dispatch(formatInsertSubsection({ dataSourceName: dataSource.name }));
  };

  const renderAddSubReport = () => {
    return (
      <div className="section-header-row__add-sub-report">
        <div className="add-sub-report__button" onClick={() => setShowSubsectionPicker(!showSubsectionPicker)}>
          {t("Add Sub-Report")}&nbsp;&nbsp;
          <FaChevronDown />
          <ReportSubsectionPicker
            onSubsectionInsertClick={onSubsectionInsertClick}
            inputRef={refSubsectionPicker}
            show={showSubsectionPicker}
            setShow={setShowSubsectionPicker}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <tr
        className={["body__section-header-row", headerSelectedClassName].join(" ")}
        onClick={(event) => {
          event.stopPropagation();
          dispatch(setGridSectionSelected({ ctrlKey: event.ctrlKey, section: section, sectionGroup: sectionGroup }));
        }}
      >
        <td colSpan={isTopLevel ? 2 : 1} className="section-header-row__cell  section-header-row__cell--first">
          {title}
        </td>
        <td className="section-header-row__cell section-header-row__cell--last" colSpan={format.gridColumns.length}>
          {title === "Body" && isTopLevel && renderAddSubReport()}
        </td>
      </tr>
    </>
  );
}

ReportSectionHeader.propTypes = {
  format: PropTypes.object,
  section: PropTypes.object,
  sectionGroup: PropTypes.object,
  firstSection: PropTypes.bool,
  matterTypeIds: PropTypes.array,
  isTopLevel: PropTypes.bool,
};

export default ReportSectionHeader;
