const BorderQuarter = ({ topWidth = 0, rightWidth = 0, bottomWidth = 0, leftWidth = 0 }) => {
  const style = {};

  if (topWidth > 0) {
    style.borderTop = topWidth + "px solid black";
  } else {
    style.borderTop = "0.2px dotted gray";
  }
  if (rightWidth > 0) {
    style.borderRight = rightWidth + "px solid black";
  } else {
    style.borderRight = "0.2px dotted gray";
  }
  if (bottomWidth > 0) {
    style.borderBottom = bottomWidth + "px solid black";
  } else {
    style.borderBottom = "0.2px dotted gray";
  }
  if (leftWidth > 0) {
    style.borderLeft = leftWidth + "px solid black";
  } else {
    style.borderLeft = "0.2px dotted gray";
  }

  return <div className="item__quarter" style={style} />;
};

export default BorderQuarter;
