// Translations API calls

import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";
import { setTranslations, setShortTranslations } from "redux/localeSlice";

export async function loadTranslations() {
  const state = store.getState();
  const selectedLocale = state.locale.selectedLocale;
  const customerId = state.app.customerId;

  fetchWrapper.get(`customers/${customerId}/Translations?locale=${selectedLocale}`).then((data) => {
    store.dispatch(setTranslations(data));
  });

  fetchWrapper.get(`customers/${customerId}/shorttranslations?locale=${selectedLocale}`).then((data) => {
    store.dispatch(setShortTranslations(data));
  });
}
