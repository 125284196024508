// Contact related API calls here
import {} from "redux/matterSlice";
import store from "redux/store";
//import store from "redux/store";

import { fetchWrapper } from "utilities/fetchWrapper";

// Get a list of contact records belonging to a matter (company or legal team tables)
export async function getMatterContacts(customerId, matterId) {
  //if (!customerId || !matterId) return null;
  const response = await fetchWrapper.get(`customers/${customerId}/matters/${matterId}/contacts`);
  return response;
}

// Get the extended list of all contacts within the customer's system based on the user search text
export async function getMatterContactsExtended(customerId, accountId, searchWords) {
  const response = await fetchWrapper.get(
    `customers/${customerId}/contacts?searchWords=${searchWords}&accountId=${accountId}`
  );
  return response;
}

export async function getMatterContactfromId(matterId, contactId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/matters/${matterId}/contacts/${contactId}`);
  return response;
}
