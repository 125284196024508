import PropTypes from "prop-types";
import PivotAxisFields from "./PivotAxisFields";

function PivotAxes({ format, matterTypeIds }) {
  return (
    <div className="report-editor__pivot-container">
      <div className="pivot-container__pivot-axes">
        <PivotAxisFields
          className="pivot-axes__column-axis"
          label="Columns"
          type="column"
          fields={format.formatPivot.columnFields}
          matterTypeIds={matterTypeIds}
        />
        <PivotAxisFields
          className="pivot-axes__row-axis"
          label="Rows"
          type="row"
          fields={format.formatPivot.rowFields}
          matterTypeIds={matterTypeIds}
        />
        <PivotAxisFields
          className="pivot-axes__data-axis"
          label="Data"
          type="data"
          fields={format.formatPivot.dataFields}
          matterTypeIds={matterTypeIds}
          dataField={true}
        />
      </div>
    </div>
  );
}

PivotAxes.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default PivotAxes;
