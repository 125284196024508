import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { setFormatProperty } from "redux/reportSlice";
import ToolbarElement from "./ToolbarElement";
import Tooltip from "components/global/Tooltip";

function ToolbarPropertyButton({ name, value, selected, style, tooltipText, children }) {
  const dispatch = useDispatch();

  return (
    <ToolbarElement tooltipText={tooltipText}>
      <div
        className={["toolbar__button", selected ? "toolbar__object--selected" : ""].join(" ")}
        style={style}
        onClick={() => dispatch(setFormatProperty({ name: name, value: value }))}
      >
        {children}
      </div>
    </ToolbarElement>
  );
}

ToolbarPropertyButton.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  selected: PropTypes.bool,
  style: PropTypes.object,
};

export default ToolbarPropertyButton;
