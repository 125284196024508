import { createSlice } from "@reduxjs/toolkit";
import { filterOutOnId, findById } from "utilities/stringAndArray";

// Redux global state component for home page settings, and no, not your "best buddy"
export const homeSlice = createSlice({
  name: "home",
  initialState: {
    widgetDirectSearches: [],
    zoomedWidgetId: null,
    pageZoomedWidgetId: [],
    currentPage: 0,
  },
  reducers: {
    setWidgetDirectSearch: (state, action) => {
      let widgetDirectSearchesLocal = [...state.widgetDirectSearches];
      const existingWidgetDirectSearch = findById(widgetDirectSearchesLocal, action.payload.id);
      if (existingWidgetDirectSearch)
        widgetDirectSearchesLocal = filterOutOnId(widgetDirectSearchesLocal, action.payload.id);
      widgetDirectSearchesLocal.push({ id: action.payload.id, isDirectSearch: action.payload.isDirectSearch });
      state.widgetDirectSearches = widgetDirectSearchesLocal;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
      state.zoomedWidgetId =
        state.currentPage < state.pageZoomedWidgetId.length ? state.pageZoomedWidgetId[state.currentPage] : 0;
    },
    setZoomedWidgetId: (state, action) => {
      state.zoomedWidgetId = action.payload;
      if (state.currentPage >= state.pageZoomedWidgetId.length) {
        state.pageZoomedWidgetId.length = state.currentPage + 1;
      }
      state.pageZoomedWidgetId[state.currentPage] = action.payload;
    },
  },
});

export const { setCurrentPage, setWidgetDirectSearch, setZoomedWidgetId } = homeSlice.actions;

export default homeSlice.reducer;
