import Tooltip from "components/global/Tooltip";
import PropTypes from "prop-types";

function ToolbarElement({ tooltipText, tooltipDirection, children }) {
  return (
    <Tooltip content={tooltipText} direction={tooltipDirection ?? "left"}>
      {children}
    </Tooltip>
  );
}

ToolbarElement.propTypes = {
  tooltipText: PropTypes.string,
  tooltipDirection: PropTypes.string,
  children: PropTypes.element,
};

export default ToolbarElement;
