import PropTypes from "prop-types";
import ReportSection from "./ReportSection";
import ReportSubsectionHeader from "./ReportSubsectionHeader";

function ReportSectionGroup({
  format,
  sectionGroup,
  styleItems,
  columnStyleItems,
  matterTypeIds,
  isTopLevel,
  onDragMouseDown,
  dragState,
}) {
  let sectionCount = 0;

  const blankRowClassNameBase = "body__blank-row";
  let blankRowClassName = blankRowClassNameBase;
  if (!isTopLevel) blankRowClassName = `${blankRowClassNameBase} ${blankRowClassNameBase}--sub-table`;

  return (
    <>
      {sectionGroup.dataSourceName && <ReportSubsectionHeader format={format} sectionGroup={sectionGroup} />}
      {sectionGroup.sections
        .filter((section) => !section.isHidden)
        .map((section) => {
          ++sectionCount;
          return (
            <>
              <ReportSection
                key={section.id}
                format={format}
                section={section}
                sectionGroup={sectionGroup}
                firstSection={!sectionGroup.subqueryAlias && sectionCount === 1}
                styleItems={styleItems}
                columnStyleItems={columnStyleItems}
                matterTypeIds={matterTypeIds}
                isTopLevel={isTopLevel}
                onDragMouseDown={onDragMouseDown}
                dragState={dragState}
              />
              <tr className={blankRowClassName}>
                <td colSpan={99}>&nbsp;</td>
              </tr>
            </>
          );
        })}
    </>
  );
}

ReportSectionGroup.propTypes = {
  format: PropTypes.object,
  sectionGroup: PropTypes.object,
  styleItems: PropTypes.array,
  columnStyleItems: PropTypes.array,
  matterTypeIds: PropTypes.array,
  isTopLevel: PropTypes.bool,
  onDragMouseDown: PropTypes.func,
  dragState: PropTypes.object,
};

export default ReportSectionGroup;
