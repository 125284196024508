import { useState } from "react";
import SuggestedFieldMatch from "./SuggestedFieldMatch";
import { FaArrowRight, FaCaretDown, FaCaretRight } from "react-icons/fa";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useRef } from "react";
import SuggestedFieldPopupContainer from "./SuggestedFieldPopupContainer";
import { useDispatch, useSelector } from "react-redux";
import { labelSelectedChange, setPopupWholeSection } from "redux/simpleSearchSlice";

/* Container for the general search fields display
 */

function SearchFieldSection({
  section,
  title,
  fields,
  isCollapsible,
  isInitiallyVisible,
  isMatchingSection,
  onEnterKeyPressDone,
  isEnterKeyPressed,
}) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const dispatch = useDispatch();
  const refSectionHeader = useRef(null);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(!isCollapsible || isInitiallyVisible);
  }, [isInitiallyVisible]);

  const handleSectionClick = () => {
    dispatch(labelSelectedChange(false));
    dispatch(setPopupWholeSection(simpleSearch.popupWholeSection === title ? null : title));
  };

  const listRender =
    fields &&
    fields.map((field) => {
      return (
        <SuggestedFieldMatch
          key={`${field.fieldName}${field.matterTypeId}`}
          section={section}
          sectionDisplayName={title}
          field={field}
          isEnterKeyPressed={isEnterKeyPressed}
          onEnterKeyPressDone={onEnterKeyPressDone}
        />
      );
    });

  const classNameTitleBase = "simple-search__section-title";
  let classNameTitle = classNameTitleBase;
  if (isMatchingSection) classNameTitle = `${classNameTitleBase} ${classNameTitleBase}--match`;

  const rectContainer = refSectionHeader?.current?.getBoundingClientRect();

  //if (title === "Actions") console.log("SearchFieldSection, fields: ", fields);
  return (
    <div className="simple-search__suggested-fields-container" ref={refSectionHeader}>
      {isCollapsible ? (
        <div className="simple-search__section-title-container">
          <div className={`${classNameTitle} clickable`} onClick={() => setIsVisible(!isVisible)}>
            <span>
              {isVisible ? <FaCaretDown /> : <FaCaretRight />}&nbsp;{title}&nbsp;&nbsp;&nbsp;
            </span>
          </div>
          {simpleSearch.inputText?.length === 0 && (
            <div className="simple-search__arrow" onClick={handleSectionClick}>
              <FaArrowRight />
            </div>
          )}
        </div>
      ) : (
        <div className={classNameTitle}>{title}&nbsp;&nbsp;&nbsp;</div>
      )}
      {isVisible && listRender}
      {simpleSearch.popupWholeSection === title && (
        <div className="suggested-field__popup-container" style={{ top: "4.7rem", left: rectContainer?.right }}>
          <SuggestedFieldPopupContainer fields={fields} parent="Section" title={title} />
        </div>
      )}
    </div>
  );
}

SearchFieldSection.propTypes = {
  section: PropTypes.string,
  title: PropTypes.string,
  fields: PropTypes.array,
  isCollapsible: PropTypes.bool,
  onEnterKeyPressDone: PropTypes.func,
  isEnterKeyPressed: PropTypes.bool,
};

export default SearchFieldSection;
