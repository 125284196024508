import { useSelector } from "react-redux";
import { t } from "locale/dictionary";
import { setPageSelected } from "redux/matterSlice";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Component for the matter record page selector
function MatterRecordPageSelector({ page, text }) {
  const matter = useSelector((state) => state.matter);
  const dispatch = useDispatch();

  const pageClass = "record-header__page-selector";
  const pageClassSelected = `${pageClass} ${pageClass}--selected`;

  return (
    <div
      className={matter.pageSelected === page ? pageClassSelected : pageClass}
      onClick={() => dispatch(setPageSelected(page))}
    >
      {t(text)}
    </div>
  );
}

MatterRecordPageSelector.propTypes = {
  page: PropTypes.string,
  text: PropTypes.string,
};

export default MatterRecordPageSelector;
