import { useDrag } from "react-dnd";
import PropTypes from "prop-types";
import { showPartialMatchHighlight } from "utilities/stringAndArray";

function FieldListItem({ fieldName, label, filterInput }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "box",
    item: { name: fieldName, type: "box" },
    end: (item, monitor) => {},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;
  const matchingRenderArray = showPartialMatchHighlight(label, [filterInput]);
  return (
    <div ref={drag} className="groups__item" style={{ opacity }}>
      {matchingRenderArray[0]}
      <span className="matching-chars">{matchingRenderArray[1]}</span>
      {matchingRenderArray[2]}
    </div>
  );
}

FieldListItem.propTypes = {
  fieldName: PropTypes.string,
  label: PropTypes.string,
  filterInput: PropTypes.string,
};

export default FieldListItem;
