import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { createBatchNewLinkedMatter, updateBatchLinkedMatter } from "api/batchCreate";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewLinkedMatter, setNewLinkedCountryId, updateNewLinkedMatter } from "redux/batchCreateSlice";
import DatePicker from "components/global/DatePicker";
import { toISOStringWithOffset } from "utilities/dateTime";
import { updateBatchProject } from "api/project";
import { set } from "date-fns";

function BatchCreateManualPriority({ onCanBeCommittedChange }) {
  const batchCreateState = useSelector((state) => state.batchCreate);
  const project = batchCreateState?.project;
  const allCountriesAndCodes = batchCreateState?.allCountriesAndCodes;

  const [hasAddedFirstRow, setHasAddedFirstRow] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    onCanBeCommittedChange(project.newLinkedMatters?.length > 0);
  }, [project.newLinkedMatters]);

  const handleAddNewLinkedMatter = async () => {
    const newNewLinkedMatter = await createBatchNewLinkedMatter(project.id);
    dispatch(addNewLinkedMatter(newNewLinkedMatter));
    onCanBeCommittedChange(true);
  };

  const handleTextChange = (value, newLinkedMatterId, changedFieldName) => {
    dispatch(updateNewLinkedMatter({ newLinkedMatterId, changedFieldName, value }));
  };

  const handleInputBlur = (value, newLinkedMatterId, changedFieldName) => {
    updateBatchLinkedMatter(project.id, newLinkedMatterId, changedFieldName, value);
  };

  const handleDatePickerBlur = (dateObject, newLinkedMatterId, changedFieldName) => {
    //debugger;
    const dateString = toISOStringWithOffset(dateObject);
    updateBatchLinkedMatter(project.id, newLinkedMatterId, changedFieldName, dateString);
    dispatch(updateNewLinkedMatter({ newLinkedMatterId, changedFieldName, value: dateString }));
  };

  const handleCountryChange = (id) => {
    updateBatchProject(project.id, "project_NewLinkedCountryId", id);
    dispatch(setNewLinkedCountryId(id));
  };

  const renderHeaderRow = () => {
    return (
      <thead>
        <tr>
          <th>{t("Country")}</th>
          <th>{t("Application No")}</th>
          <th>{t("Application Date")}</th>
          <th>{t("ClassNo")}</th>
        </tr>
      </thead>
    );
  };

  const renderNewLinkedMatterRow = (newLinkedMatter, index) => {
    const fieldCell = (fieldName) => {
      let displayValue = newLinkedMatter[fieldName];
      if (fieldName === "applicationDate") {
        const dateObject = displayValue && new Date(displayValue);

        return (
          <td>
            <DatePicker
              selected={dateObject}
              onDateChange={(date) => handleDatePickerBlur(date, newLinkedMatter.id, fieldName)}
            />
          </td>
        );
      }
      return (
        <td>
          <input
            className="table__cell-input"
            type="text"
            value={displayValue ?? ""}
            onChange={(e) => handleTextChange(e.target.value, newLinkedMatter.id, fieldName)}
            onBlur={(e) => handleInputBlur(e.target.value, newLinkedMatter.id, fieldName)}
          />
        </td>
      );
    };
    return (
      <tr key={index}>
        <td>
          <div className="table__cell-select">
            <SuperSelect
              id="country-manual-priority-select"
              options={batchCreateState?.availableCountries}
              selectedOptionId={project.project_NewLinkedCountryId}
              onChange={handleCountryChange}
            />
          </div>
        </td>
        {fieldCell("applicationNo")}
        {fieldCell("applicationDate")}
        {fieldCell("class")}
      </tr>
    );
  };

  const renderNewLinkedMatters = () => {
    return (
      <table className="manual-addition__table">
        {renderHeaderRow()}
        <tbody>{project?.newLinkedMatters?.map((nlm, index) => renderNewLinkedMatterRow(nlm, index))}</tbody>
      </table>
    );
  };

  return (
    <div className="section__manual-addition">
      <h3>{t("Manual Addition of Priority Reference(s)")}</h3>
      {renderNewLinkedMatters()}
      <button onClick={handleAddNewLinkedMatter}>{t("Add Another Priority Application")}</button>
    </div>
  );
}

BatchCreateManualPriority.propTypes = {
  onCanBeCommittedChange: PropTypes.func,
};

export default BatchCreateManualPriority;
