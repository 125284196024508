import { setUsers } from "redux/customerSlice";
import {
  addCustomer,
  setCustomers,
  modifyCustomerAccount,
  addAccount,
  removeAccount,
  addUser,
  updateUser,
  addUserGroup,
  removeUserGroup,
  updateUsersUserGroup,
  appendUserToAccount,
  updateCurrentUserGroup,
  removeUserFromAccount,
  setMasterAccessPermissionId,
  addMatterTypeAccess,
  removeMatterTypeAccess,
  addAccessRight,
  updateCustomer,
  updateMatterObjectAccess,
  removeMatterObjectAccess,
  addMatterObjectAccess,
  updateMatterTypeAccess,
  addAccessRightFilter,
  updateAccessRightFilter,
  deleteAccessRightFilter,
} from "redux/securitySlice";
import store from "redux/store";
import { accessRightTypes } from "utilities/constants";
import { fetchWrapper } from "utilities/fetchWrapper";

//CUSTOMERS
export async function getAllCustomers() {
  const response = await fetchWrapper.get(`Customers`);
  store.dispatch(setCustomers(response));
}

export async function getCustomerById(id) {
  const response = await fetchWrapper.get(`Customers/${id}`);
  //new access rights are set with null filters. This is to prevent errors when trying to access filters that don't exist
  response.accounts.forEach((account) => {
    account.userGroups.forEach((group) => {
      if (group?.accessRight) {
        if (!group?.accessRight?.filters) group.accessRight.filters = [];
        //create inital prevFilters for access rights
        group.accessRight.prevFilters = group?.accessRight?.filters ?? [];
      }
    });
  });
  return response;
}

export async function createCustomer({ name, customerStatusId }) {
  const body = { name, customerStatusId };
  const response = await fetchWrapper.post(`Customers`, body);
  store.dispatch(addCustomer(response));
}

export async function updateCustomerInCustomersList(fieldName, initialValue, value, customer) {
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`Customers/${customer.id}`, body);
  store.dispatch(updateCustomer(customer));
}

// ACCOUNTS
export async function createCustomerAccount(id, accountName) {
  const body = { name: accountName };
  const response = await fetchWrapper.post(`customers/${id}/accounts`, body);
  store.dispatch(addAccount(response));
}

export async function updateCustomerAccount(customerId, accountId, fieldName, initialValue, value) {
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/accounts/${accountId}`, body);
  store.dispatch(modifyCustomerAccount({ accountId, value, fieldName }));
}

export async function deleteCustomerAccount(customerId, accountId) {
  await fetchWrapper.delete(`customers/${customerId}/accounts/${accountId}`);
  store.dispatch(removeAccount(accountId));
}

// USERS
export async function getUsersFromCustomer(customerId) {
  const response = await fetchWrapper.get(`Customers/${customerId}/Users`);
  store.dispatch(setUsers(response));
}

export async function createNewUserInCustomer(id, newUser) {
  const response = await fetchWrapper.post(`Customers/${id}/Users`, newUser);
  store.dispatch(addUser(response));
}

export async function updateUserDetails(customerId, userId, fieldName, initialValue, value) {
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`Customers/${customerId}/Users/${userId}`, body);
  store.dispatch(updateUser({ userId, customerId, fieldName, value }));
}

export async function deleteUserFromAccount(customerId, accountId, userId) {
  await fetchWrapper.delete(`Customers/${customerId}/accounts/${accountId}/users/${userId}`);
  store.dispatch(removeUserFromAccount({ customerId, accountId, userId }));
}

//USER GROUPS
export async function createUserGroup(customerId, accountId, name) {
  const body = { name };
  const response = await fetchWrapper.post(`Customers/${customerId}/accounts/${accountId}/usergroups`, body);
  store.dispatch(addUserGroup(response));
}

export async function editUserGroup(customerId, accountId, userGroupId, fieldName, initialValue, value) {
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/accounts/${accountId}/usergroups/${userGroupId}`, body);
  store.dispatch(updateCurrentUserGroup({ fieldName, value }));
}

export async function deleteUserGroup(customerId, accountId, userGroupId) {
  await fetchWrapper.delete(`Customers/${customerId}/accounts/${accountId}/usergroups/${userGroupId}`);
  store.dispatch(removeUserGroup({ customerId, accountId, userGroupId }));
}

export async function addUserToAccount(customerId, accountId, customerUserId) {
  const body = {
    customerUserId,
  };
  const response = await fetchWrapper.post(`Customers/${customerId}/accounts/${accountId}/users`, body);
  store.dispatch(appendUserToAccount({ user: response, accountId }));
  return response;
}

export async function changeUsersUserGroup(customerId, accountId, userId, fieldName, initialValue, value) {
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/accounts/${accountId}/users/${userId}`, body);
  store.dispatch(updateUsersUserGroup({ customerId, accountId, userId, fieldName, value }));
}

export async function deleteUserFromGroup(customerId, accountId, userId) {
  await fetchWrapper.delete(`Customers/${customerId}/accounts/${accountId}/users/${userId}`);
  store.dispatch(removeUserFromAccount({ userId, accountId, customerId }));
}

//ACCESS RIGHTS
export async function createAccessRight(customerId, userGroupId) {
  const body = {
    //TODO change systemLevelTypeId for access rights at a different level. ie user level
    systemLevelTypeId: 40,
    linkId: userGroupId,
    permissionId: accessRightTypes.EDIT_AND_CREATE,
  };
  const response = await fetchWrapper.post(`customers/${customerId}/accessrights`, body);
  store.dispatch(addAccessRight(response));
}

export async function updateAccessRight(idsObject, initialValue, value) {
  const fieldName = "permissionId";
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${idsObject.customerId}/accessrights/${idsObject.accessRightId}`, body);
  store.dispatch(setMasterAccessPermissionId({ accessRightId: idsObject.accessRightId, permissionId: value }));
}

//++ MATTER TYPES
export async function createMatterTypeAccessRight(customerId, accessRightId, matterTypeId) {
  const body = { matterTypeId, permissionId: accessRightTypes.NO_ACCESS };
  const response = await fetchWrapper.post(`customers/${customerId}/accessrights/${accessRightId}/mattertypes`, body);
  store.dispatch(addMatterTypeAccess(response));
}

export async function updateMatterTypeAccessRight(customerId, accessRightId, matterTypeId, initialValue, value) {
  const fieldName = "permissionId";
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/accessrights/${accessRightId}/mattertypes/${matterTypeId}`, body);
  store.dispatch(updateMatterTypeAccess({ matterTypeId, fieldName, value }));
}

export async function deleteMatterTypeAccessRight(customerId, accessRightId, matterTypeId) {
  await fetchWrapper.delete(`customers/${customerId}/accessrights/${accessRightId}/mattertypes/${matterTypeId}`);
  store.dispatch(removeMatterTypeAccess(matterTypeId));
}

//++ MATTER OBJECTS
export async function createMatterObjectAccessRight(customerId, accessRightId, matterObjectId) {
  const body = {
    matterObjectId,
    permissionId: accessRightTypes.NO_ACCESS,
  };

  const response = await fetchWrapper.post(`customers/${customerId}/accessrights/${accessRightId}/matterobjects`, body);
  store.dispatch(addMatterObjectAccess(response));
}

export async function updateMatterObjectAccessRight(customerId, accessRightId, matterObjectId, initialValue, value) {
  const fieldName = "permissionId";
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(
    `customers/${customerId}/accessrights/${accessRightId}/matterobjects/${matterObjectId}`,
    body
  );
  store.dispatch(updateMatterObjectAccess({ matterObjectId, fieldName, value }));
}

export async function deleteMatterObjectAccessRight(customerId, accessRightId, matterObjectId) {
  await fetchWrapper.delete(`customers/${customerId}/accessrights/${accessRightId}/matterobjects/${matterObjectId}`);
  store.dispatch(removeMatterObjectAccess(matterObjectId));
}

//++FIELD FILTERING
export async function accessRightFilterAdd(customerId, accessRightId, body) {
  const response = await fetchWrapper.post(`customers/${customerId}/accessrights/${accessRightId}/filters`, body);
  store.dispatch(addAccessRightFilter(response));

  return response;
}

export async function accessRightFilterUpdate(
  customerId,
  accessRightId,
  filterId,
  fieldName,
  initialValue,
  value,
  qualifiedDictionaryEntry = null
) {
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];

  await fetchWrapper.patch(`customers/${customerId}/accessrights/${accessRightId}/filters/${filterId}`, body);

  store.dispatch(updateAccessRightFilter({ id: filterId, fieldName, value, qualifiedDictionaryEntry }));
}

export async function accessRightFilterDelete(customerId, accessRightId, filterId) {
  await fetchWrapper.delete(`customers/${customerId}/accessrights/${accessRightId}/filters/${filterId}`);

  store.dispatch(deleteAccessRightFilter(filterId));
}

//++ EXPORT MATTER DATA
export async function exportMatterData() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const requestOptions = {
    method: "GET",
    headers: { Authorization: state.app.bearerToken },
  };
  const response = await fetch(`${process.env.REACT_APP_API_ROOT}customers/${customerId}/exportmatter`, requestOptions);

  return await response.blob();
}
