import useComponentVisible from "components/global/useComponentVisible";
import { t } from "locale/dictionary";

import { FaCommentAlt } from "react-icons/fa";

// Container for the messages menu/popupin the site header's sys tray
export default function MessagesPopup() {
  const [refPopup, isPopupVisible, setIsPopupVisible] = useComponentVisible(false);

  return (
    <div className="header__messages-dropdown" onClick={() => setIsPopupVisible(!isPopupVisible)} ref={refPopup}>
      <FaCommentAlt />
      {isPopupVisible && (
        <div className="messages-dropdown__popup">
          <p>{t("Latest Messages List")}</p>
          <p>{t("Will Display Here")}</p>
        </div>
      )}
    </div>
  );
}
