import PropTypes from "prop-types";
import FileUploader from "components/global/FileUploader";
import PanelFieldThumbnail from "./field-display-types/PanelFieldThumbnail";
import PanelFieldThumbnailInterstice from "./field-display-types/PanelFieldThumbnailInterstice";
import { saveMatterChildObjectFieldChanges } from "api/matter";
import { compareByMatterImageSortIndex, findById, idsAreEqual } from "utilities/stringAndArray";
import { useEffect, useState } from "react";

// Container Component for the table type data panel for a matter keyProps.record
function PanelThumbnails({ keyProps, onChange, onDelete, onAddNew }) {
  const [imagesSorted, setImagesSorted] = useState(null);

  useEffect(() => {
    if (keyProps.record?.images) {
      let imagesTemp = [...keyProps.record.images];
      const imagesSortedTemp = imagesTemp.sort(compareByMatterImageSortIndex);
      setImagesSorted(imagesSortedTemp);
    }
  }, [keyProps.record, keyProps.record.images /*, sortOrderChanged*/]);

  const onDrop = (imageBeingDroppedId, droppedBeforeImageId) => {
    let images = [...keyProps.record.images];
    const imageBeingDropped = findById(images, imageBeingDroppedId);
    let droppedBeforeSortIndex = null;
    if (droppedBeforeImageId) {
      const droppedBeforeImage = findById(images, droppedBeforeImageId);
      droppedBeforeSortIndex = droppedBeforeImage.matterImage_SortIndex;
    } else {
      droppedBeforeSortIndex = keyProps.record.images.length + 1;
    }
    const beingDroppedSortIndex = imageBeingDropped.matterImage_SortIndex;
    const newSortIndexPrimary =
      beingDroppedSortIndex > droppedBeforeSortIndex ? droppedBeforeSortIndex : droppedBeforeSortIndex - 1;
    // Update sortIndex of dropped image
    if (keyProps.isProject) onChange("sortIndex", imageBeingDroppedId, newSortIndexPrimary);
    else
      saveMatterChildObjectFieldChanges(
        keyProps.record.id,
        "images",
        imageBeingDropped.id,
        "matterImage_SortIndex",
        newSortIndexPrimary,
        beingDroppedSortIndex
      );

    // Update sortIndices of images in the order after dropped image

    let imagesAfterDrop =
      beingDroppedSortIndex > droppedBeforeSortIndex
        ? images.filter(
            (image) =>
              image.matterImage_SortIndex >= droppedBeforeSortIndex &&
              image.matterImage_SortIndex < beingDroppedSortIndex
          )
        : images.filter(
            (image) =>
              image.matterImage_SortIndex < droppedBeforeSortIndex &&
              image.matterImage_SortIndex > beingDroppedSortIndex
          );
    imagesAfterDrop.forEach((image) => {
      const newSortIndex =
        beingDroppedSortIndex > droppedBeforeSortIndex
          ? image.matterImage_SortIndex + 1
          : image.matterImage_SortIndex - 1;
      if (keyProps.isProject) onChange("sortIndex", image.id, newSortIndex);
      else
        saveMatterChildObjectFieldChanges(
          keyProps.record.id,
          "images",
          image.id,
          "matterImage_SortIndex",
          newSortIndex,
          image.matterImage_SortIndex
        );
    });
  };

  const renderThumbnailList =
    imagesSorted &&
    imagesSorted.map((image) => {
      return (
        <div className="flex-row-center" key={image.id}>
          <PanelFieldThumbnailInterstice keyProps={keyProps} imageId={image.id} />
          <PanelFieldThumbnail
            keyProps={keyProps}
            image={image}
            onDrop={onDrop}
            onChange={onChange}
            onDelete={onDelete}
          />
        </div>
      );
    });

  return (
    <div className="data-panel__table">
      {keyProps.record && (
        <>
          {!keyProps.isPanelReadOnly && <FileUploader keyProps={keyProps} isImage={true} onAddNew={onAddNew} />}
          <div className="thumbnail-list">
            {renderThumbnailList}
            <PanelFieldThumbnailInterstice keyProps={keyProps} />
          </div>
        </>
      )}
    </div>
  );
}

PanelThumbnails.propTypes = {
  keyProps: PropTypes.object,
  onAddNew: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PanelThumbnails;
