import React, { useState, useEffect, forwardRef } from "react";
import PropTypes from "prop-types";
import useDebounce from "./useDebounce";

// Input wrapper that only triggers the onChange callback when the a certain amount of time has passed since the last change (i.e. "debouncing").
// Defaults to a 500 millisecond time threshold if not specified.
const InputDebounced = forwardRef(
  ({ className, value, onChange, onClick, onFocus, placeholder, onKeyDown, timeThreshold }, ref) => {
    const [inputValue, setInputValue] = useState(value ?? "");

    // Debounce the input value - only trigger the onChange callback when the input value has not changed for the time threshold
    // Note that the onChange callback is not called for the initial value
    useDebounce(inputValue, timeThreshold, onChange);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const handleChange = (event) => {
      setInputValue(event.target.value);
    };

    return (
      <input
        type="text"
        className={className}
        value={inputValue}
        onChange={handleChange}
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        autoComplete="off"
        tabIndex={0}
        placeholder={placeholder}
        ref={ref}
      />
    );
  }
);

InputDebounced.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  timeThreshold: PropTypes.number,
  placeholder: PropTypes.string,
};

InputDebounced.defaultProps = {
  timeThreshold: 500,
};

export default InputDebounced;
