import PropTypes from "prop-types";

function ReportColumnGroup({ format, dragState }) {
  return (
    <colgroup>
      <col
        style={{
          width: "20px",
        }}
      />
      <col
        style={{
          width: "20px",
        }}
      />
      {format.gridColumns.map((col) => {
        const colWidth = dragState && dragState.column === col.column ? dragState.newWidth : col.width;
        return (
          <col
            key={col.column}
            style={{
              width: colWidth + "px",
            }}
          />
        );
      })}
      <col
        style={{
          width: "20px",
        }}
      />
    </colgroup>
  );
}

ReportColumnGroup.propTypes = {
  format: PropTypes.object,
  dragState: PropTypes.object,
};

export default ReportColumnGroup;
