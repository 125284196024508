// export function findNextTabStop(el) {
//   var universe = document.querySelectorAll("input, button, select, textarea, a[href]");
//   var list = Array.prototype.filter.call(universe, function (item) {
//     return item.tabIndex >= "0";
//   });
//   var index = list.indexOf(el);
//   return list[index + 1] || list[0];
// }

export function findNextInput(el, findPrev) {
  var universe = document.querySelectorAll("input, div.elem-to-focus");
  var list = Array.prototype.filter.call(universe, function (item) {
    return item.tabIndex >= "0";
  });
  var index = list.indexOf(el);
  const nextInput = (findPrev ? list[index - 1] : list[index + 1]) || list[0];
  return nextInput;
}
