import { useEffect, useState } from "react";
import { findSection, findSectionGroup } from "utilities/reportEditor";
import PropTypes from "prop-types";
import { FaImage } from "react-icons/fa";
import Tooltip from "components/global/Tooltip";
import ImageBrowser from "components/global/ImageBrowser";
import PopupHeader from "components/global/PopupHeader";

function ToolbarSectionCell({ format, selected }) {
  const [imageMode, setImageMode] = useState("CREATE");
  const [showGlobalImagePopup, setShowGlobalImagePopup] = useState(false);

  useEffect(() => {
    selected.forEach((selectedElement, index) => {
      const section = findSection(format.gridSectionGroup, selectedElement.sectionId);
      const sectionGroup = findSectionGroup(format.gridSectionGroup, selectedElement.sectionId);

      let cell = null;
      if (section.cells) {
        cell = section.cells.find((cell) => cell.row === selectedElement.row && cell.column === selectedElement.col);
      }

      if (cell?.fieldName?.startsWith("customerImage")) {
        setImageMode("EDIT");
      } else {
        setImageMode("CREATE");
      }
    });
  }, [format.gridSectionGroup, selected]);

  const imageCommandText = `${imageMode === "EDIT" ? "Change current" : "Add an"} image`;

  return (
    <>
      <Tooltip content={imageCommandText} direction="left">
        <div className="toolbar__button" onClick={() => setShowGlobalImagePopup(true)}>
          <FaImage className="button__icon" />
        </div>
      </Tooltip>
      {showGlobalImagePopup && (
        <div className="popup__image-store">
          <PopupHeader title={imageCommandText} onClose={() => setShowGlobalImagePopup(false)} />
          <ImageBrowser isSelectionMode={true} onClose={() => setShowGlobalImagePopup(false)} />
        </div>
      )}
    </>
  );
}

ToolbarSectionCell.propTypes = {
  format: PropTypes.object,
  selected: PropTypes.arrayOf(PropTypes.object),
};

export default ToolbarSectionCell;
