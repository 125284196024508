import { t } from "locale/dictionary";
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleCollapsedSection } from "redux/projectSlice";

function ProjectSection({ title, children }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const projectState = useSelector((state) => state.project);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsExpanded(projectState?.collapsedSections?.includes(title) ? false : true);
  }, [projectState?.collapsedSections]);

  const handleExpandedChange = () => {
    dispatch(toggleCollapsedSection(title));
  };

  const classNameHeaderBase = "data-panel__header";
  let classNameHeader = classNameHeaderBase;
  if (isExpanded) {
    classNameHeader = `${classNameHeaderBase} ${classNameHeaderBase}--expanded`;
  }

  return (
    <div className="data-panel-list__data-panel">
      <div className={classNameHeader}>
        <div className="data-panel__header-lhs">
          <div className="data-panel__caret clickable" onClick={handleExpandedChange}>
            {isExpanded ? <FaCaretDown /> : <FaCaretRight />}
          </div>
          <div className="data-panel__title">{t(title)}</div>
        </div>
        {/* {isExpanded && <div className="header-bar">{children}</div>} */}
      </div>
      {isExpanded && (
        <div className="data-panel__form">
          <div className="batch-create__section">{children}</div>
        </div>
      )}
    </div>
  );
}

ProjectSection.propTypes = {
  keyProps: PropTypes.object,
  children: PropTypes.node,
  onSetConnectedMatterPopupVisible: PropTypes.func,
};

export default ProjectSection;
