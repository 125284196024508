import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sectionDefinitions } from "utilities/sectionDefinitions";
import {
  createBatchCompanyContactLink,
  createBatchCompanyLink,
  deleteBatchCompanyContactLink,
  deleteBatchCompanyLink,
  getBatchCompanyLinks,
  updateBatchCompanyContactLink,
  updateBatchCompanyLink,
} from "api/batchCreate";
import {
  addCompanyLinkContact,
  deleteCompanyLink,
  deleteCompanyLinkContact,
  setCompanyLinks,
  updateCompanyLinkContact,
} from "redux/batchCreateSlice";
import Panel from "components/content/matter-record/Panel";
import { findById, hasMatchingId, idsAreEqual } from "utilities/stringAndArray";
import { projectStatuses } from "utilities/constants";
import { setNewCompanyPopupRecordId } from "redux/projectSlice";
import MatterRecordUniversalPopup from "components/content/matter-record/UniversalPopup";

function BatchCreateCompanyLinks() {
  const projectState = useSelector((state) => state.project);
  const batchCreateState = useSelector((state) => state.batchCreate);
  const project = batchCreateState.project;

  const dispatch = useDispatch();

  const [keyProps, setKeyProps] = useState(null);

  const secDef = findById(sectionDefinitions, 50);

  useEffect(() => {
    refreshKeyProps();
  }, [project]);

  const refreshKeyProps = () => {
    // Trick the data panel into thinking we have a standard matter company links with contact link subtable
    if (project?.id) {
      const projectMatterMapped = {
        ...project,
        companyLinks: project.companyLinks.map((company) => {
          return {
            id: company.id,
            matterCompanyLink_MatterId: company.matterId,
            matterCompanyLink_LinkTypeId: company.linkTypeId,
            matterCompanyLink_AssignedDate: company.assignedDate,
            matterCompanyLink_Address1: company.address1,
            matterCompanyLink_Address2: company.address2,
            matterCompanyLink_Address3: company.address3,
            matterCompanyLink_City: company.city,
            matterCompanyLink_Country: company.country,
            matterCompanyLink_Reference: company.fileReferenceNumber,
            matterCompanyLink_Name: company.name,
            matterCompanyLink_PostalCode: company.postalCode,
            matterCompanyLink_State: company.state,
            matterCompanyLink_Website: company.website,
            contactLinks: company.contactLinks?.map((contact) => {
              return {
                id: contact.id,
                matterCompanyLinkContactLink_MatterContactId: contact.matterContactId,
                matterCompanyLinkContactLink_CompanyLinkContactLinkTypeId: contact.companyLinkContactLinkTypeId,
                matterCompanyLinkContactLink_Email: contact.email,
                matterCompanyLinkContactLink_FullName: contact.fullName,
                matterCompanyLinkContactLink_FamilyName: contact.familyName,
                matterCompanyLinkContactLink_GivenName: contact.givenName,
                matterCompanyLinkContactLink_LegalTeam: contact.legalTeam,
                matterCompanyLinkContactLink_Phone1: contact.phone1,
                matterCompanyLinkContactLink_Phone2: contact.phone2,
                matterCompanyLinkContactLink_MatterId: contact.matterId,
              };
            }),
          };
        }),
      };

      setKeyProps({
        sectionType: secDef.type,
        sectionName: secDef.name,
        sectionId: secDef.id,
        tableName: secDef.tableName,
        fieldList: secDef.fieldList,
        childTable: secDef.childTable,
        record: projectMatterMapped,
        isProject: true,
      });
    }
  };

  const handleCompanyLinkFieldChange = async (changedFieldName, changedRowData) => {
    let newCompanyLink = null;
    const companyLink = {
      id: changedRowData.id,
      matterId: changedRowData.matterCompanyLink_MatterId,
      linkTypeId: changedRowData.matterCompanyLink_LinkTypeId,
    };

    if (!companyLink.matterId || !companyLink.linkTypeId) return;

    let companyLinksLocal = [...project.companyLinks];

    let changedFieldNameBatchCreate = changedFieldName === "matterCompanyLink_MatterId" ? "matterId" : "linkTypeId";

    // We can't mutate the array of companyLinks so we create a new array and modify the matching item in the process
    companyLinksLocal = companyLinksLocal.map((company) => {
      if (idsAreEqual(company.id, companyLink.id)) {
        // Found the matching contact, create a new object with the updated field
        const updatedCompany = {
          ...company,
          [changedFieldNameBatchCreate]: companyLink[changedFieldNameBatchCreate],
        };

        // Optionally call update function if needed
        updateBatchCompanyLink(
          project.id,
          companyLink.id,
          changedFieldNameBatchCreate,
          companyLink[changedFieldNameBatchCreate]
        );

        return updatedCompany;
      }
      return company; // Return the original contact if it's not the one we're looking for
    });

    // Check if the matching item was found and updated, if not, add a new one
    if (!companyLinksLocal.some((company) => idsAreEqual(company.id, companyLink.id))) {
      newCompanyLink = await createBatchCompanyLink(project.id, companyLink);
      companyLinksLocal.push(newCompanyLink);
    }
    dispatch(setCompanyLinks(companyLinksLocal));
    return newCompanyLink;
  };

  const handleContactLinkFieldChange = async (changedFieldName, changedRowData) => {
    const changedFieldNameBatchCreate =
      changedFieldName === "matterCompanyLinkContactLink_CompanyLinkContactLinkTypeId"
        ? "companyLinkContactLinkTypeId"
        : "matterContactId";

    const companyLinkContactLink = {
      id: changedRowData.id,
      matterContactId: changedRowData.matterCompanyLinkContactLink_MatterContactId,
      companyLinkContactLinkTypeId: changedRowData.matterCompanyLinkContactLink_CompanyLinkContactLinkTypeId,
    };

    if (!companyLinkContactLink.matterContactId || !companyLinkContactLink.companyLinkContactLinkTypeId) return;

    // first get the company link from the changedRowData.parentRowId
    // then get the contactLinks from the company link
    // then update the contactLinks with the changedRowData
    // then update the company link with the new contactLinks
    // then update the project with the new company link

    //find companyLink
    const companyLink = project.companyLinks.find((company) =>
      idsAreEqual(
        company.matterId,
        changedRowData.matterCompanyLink_MatterId ?? changedRowData.matterCompanyLinkContactLink_MatterId
      )
    );
    if (!companyLink) return;

    let companyLinkContactLinks = [...companyLink.contactLinks];

    // We can't mutate the array of companyLinkContactLinks so we create a new array and modify the matching item in the process
    companyLinkContactLinks = companyLinkContactLinks.map((contact) => {
      if (idsAreEqual(contact.id, companyLinkContactLink.id)) {
        // Found the matching contact, create a new object with the updated field
        const updatedContact = {
          ...contact,
          [changedFieldNameBatchCreate]: companyLinkContactLink[changedFieldNameBatchCreate],
        };

        updateBatchCompanyContactLink(
          project.id,
          companyLink.id,
          companyLinkContactLink.id,
          changedFieldNameBatchCreate,
          companyLinkContactLink[changedFieldNameBatchCreate]
        );
        dispatch(
          updateCompanyLinkContact({
            companyId: companyLink.id,
            contactId: companyLinkContactLink.id,
            changedFieldName: changedFieldNameBatchCreate,
            value: companyLinkContactLink[changedFieldNameBatchCreate],
          })
        );
        return updatedContact;
      }
      return contact; // Return the original contact if it's not the one we're looking for
    });

    // Check if the matching item was found and updated, if not, add a new one
    if (!hasMatchingId(companyLinkContactLinks, companyLinkContactLink.id)) {
      const newLegalTeamContactLink = await createBatchCompanyContactLink(
        project.id,
        companyLink.id,
        companyLinkContactLink
      );
      companyLinkContactLinks.push(newLegalTeamContactLink);
      dispatch(addCompanyLinkContact({ companyId: companyLink.id, contact: newLegalTeamContactLink }));
    }
  };

  const handleFieldChange = async (changedFieldName, rowData, value, tableName) => {
    const changedRowData = { ...rowData, [changedFieldName]: value };

    const newEntity =
      tableName === "companyLinks"
        ? handleCompanyLinkFieldChange(changedFieldName, changedRowData)
        : handleContactLinkFieldChange(changedFieldName, changedRowData);
    return newEntity;
  };

  const handleRecordDelete = async (id, tableName, parentRowId) => {
    if (tableName === "companyLinks") {
      deleteBatchCompanyLink(project.id, id);
      dispatch(deleteCompanyLink(id));
    } else if (tableName === "contactLinks") {
      deleteBatchCompanyContactLink(project.id, parentRowId, id);
      dispatch(deleteCompanyLinkContact({ companyId: parentRowId, contactId: id }));
    }
  };

  const handleNewCompanyPopupClose = async () => {
    //debugger;
    dispatch(setNewCompanyPopupRecordId(null));
    const companyLinks = await getBatchCompanyLinks(project.id);
    dispatch(setCompanyLinks(companyLinks));
  };

  return (
    keyProps !== null && (
      <>
        <Panel
          keyProps={keyProps}
          onChange={handleFieldChange}
          onDelete={handleRecordDelete}
          isReadOnly={project?.project_Status === projectStatuses.MATTER_GENERATED}
        />
        {projectState?.newCompanyPopupRecordId && (
          <MatterRecordUniversalPopup
            recordId={projectState.newCompanyPopupRecordId}
            onClose={handleNewCompanyPopupClose}
          />
        )}
      </>
    )
  );
}

export default BatchCreateCompanyLinks;
