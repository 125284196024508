import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { copyReportRecord } from "api/report";
import { t } from "locale/dictionary";
import { useDispatch, useSelector } from "react-redux";
import PopupHeader from "components/global/PopupHeader";
import { addRecord } from "redux/reportSlice";

function ReportCopyPopup({ report, clickSource, onClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reportState = useSelector((state) => state.report);

  const [hasCopiedOk, setHasCopiedOk] = useState(false);
  const [copyName, setCopyName] = useState(`COPY ${report?.name}`);
  const [copyDescription, setCopyDescription] = useState(`COPY ${report?.description}`);

  const handleCopyReportClick = async () => {
    let reportSource = { ...report, name: copyName, description: copyDescription };
    const reportCopy = await copyReportRecord(reportSource);

    if (reportCopy && reportCopy.id) {
      if (clickSource === "editor") navigate(`/reportmenu/reporteditor/${reportCopy.id}`, { replace: true });
      setHasCopiedOk(true);
      dispatch(addRecord({ report: reportCopy, selected: true, replace: true }));
    }
  };

  const handleConfirmationCopyCloseClick = () => {
    onClose();
    setHasCopiedOk(false);
  };

  const renderCopyForm = (
    <>
      <div className="modal__selection-grid modal__selection-grid--100">
        <p>{t("New Report Name")}</p>
        <textarea
          className="modal__input"
          type="text"
          rows="3"
          value={copyName}
          onChange={(e) => setCopyName(e.target.value)}
        />
        <p>{t("New Report Description")}</p>
        <textarea
          className="modal__input"
          type="text"
          rows="4"
          value={copyDescription}
          onChange={(e) => setCopyDescription(e.target.value)}
        />
      </div>
      <div className="modal__selection-grid modal__selection-grid--50">
        <button onClick={handleCopyReportClick}>{t("COPY")}</button>
        <button onClick={onClose}>{t("Cancel")}</button>
      </div>
    </>
  );

  const renderCopyConfirmation = (
    <>
      <div className="modal__selection-row modal__selection-row--success">{t("Report copied successfully")}</div>
      <div className="modal__selection-row">
        <button onClick={handleConfirmationCopyCloseClick}>{t("Close")}</button>
      </div>
    </>
  );

  return (
    <div className="modal-mask">
      <div className="modal__selection-popup modal__selection-popup--wide">
        <PopupHeader title="Copy Report" onClose={onClose} />
        <div className="modal__selection-body">{hasCopiedOk ? renderCopyConfirmation : renderCopyForm}</div>
      </div>
    </div>
  );
}

ReportCopyPopup.propTypes = {
  report: PropTypes.object,
  onClose: PropTypes.func,
  clickSource: PropTypes.string,
};

export default ReportCopyPopup;
