import { useEffect, useState } from "react";
import PropTypes from "prop-types";

/* Control for displaying a numeric slider
  props:
    minValue - the lower numeric bound of the slider
    maxValue - the upper numeric bound of the slider
    text - the label text
  states:
    position - the current numeric position of the slider
  callbacks:
    onChangeValue - when the active segment is changed, fire parent's method to reflect the changed value
*/
function Slider({ value, minValue, maxValue, text, onChangeValue }) {
  const [position, setPosition] = useState(value);

  const onChangeActiveSegment = (n) => {
    setPosition(n);
    onChangeValue(n);
  };

  useEffect(() => {
    setPosition((prevPosition) => value);
  }, [value]);

  let segmentsRender = [];
  for (let n = minValue; n < maxValue + 1; n++) {
    segmentsRender.push(
      <div
        key={n}
        className="slider-segment"
        onClick={() => onChangeActiveSegment(n)}
        onDragEnter={(e) => onChangeActiveSegment(n)}
      >
        <div className="slider-bar"></div>
        {position === n && <div className="slider-pointer" draggable="true"></div>}
      </div>
    );
  }
  return (
    <div className="slider-container">
      <div className="slider-label">
        {text}&nbsp;{position}
      </div>
      <div className="slider-core">
        <div className="slider-segments">{segmentsRender}</div>
      </div>
    </div>
  );
}

Slider.propTypes = {
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  text: PropTypes.string,
  onChangeValue: PropTypes.func,
};

export default Slider;
