import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { buildPivotAxis, getDisplayNameForField } from "utilities/reportEditor";
import { t } from "locale/dictionary";
import { createDrillFilter } from "utilities/reportEditor";

const PivotTable = ({ output, onClick }) => {
  const localeState = useSelector((state) => state.locale);
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);

  useEffect(() => {
    setXAxis(buildPivotAxis(output.xAxisColumns, output.rowData));
    setYAxis(buildPivotAxis(output.yAxisColumns, output.rowData));
  }, [output.xAxisColumns, output.yAxisColumns, output.rowData, localeState.translations]);

  const handleClick = (xAxis, yAxis) => {
    if (onClick) {
      let parameters = [];

      if (xAxis) {
        parameters = xAxis.keys.map((key, index) =>
          createDrillFilter(output.xAxisColumns[index].keyDataFieldName, key)
        );
      }

      if (yAxis) {
        parameters = parameters.concat(
          yAxis.keys.map((key, index) => createDrillFilter(output.yAxisColumns[index].keyDataFieldName, key))
        );
      }
      onClick(parameters);
    }
  };

  let grandTotal = 0;
  const groupTotals = new Array(xAxis.length).fill(0);

  if (yAxis.length === 0) {
    xAxis.forEach((xAxis, index) => {
      const resultSetRow = output.rowData.find((resultRow) =>
        xAxis.keys.every((key, index) => resultRow.keys[xAxis.keyColumns[index]] === key)
      );
      if (resultSetRow) {
        grandTotal += resultSetRow.values[0];
        groupTotals[index] += resultSetRow.values[0];
      }
    });
  }

  const yAxisIndexes = output.yAxisColumns.map((axis, index) => index);

  // NOTE: // For simplicity keys are set from array indexes as this is currently a static table.
  // If this table becomes dynamic then a dynamic key could be added at root level to force a complete redraw
  return (
    <table className="output-container__pivot-table">
      <thead className="pivot-table__header">
        {output.xAxisColumns.length > 1 &&
          output.xAxisColumns.map((xAxisColumn, index) => {
            return (
              <tr key={index}>
                {index === output.xAxisColumns.length &&
                  output.yAxisColumns.map((yAxisColumn, index) => {
                    return (
                      <td key={index} className="pivot-table__root">
                        {getDisplayNameForField(yAxisColumn.keyDataFieldName)}
                      </td>
                    );
                  })}

                {index < output.xAxisColumns.length && output.yAxisColumns.length > 0 && (
                  <td className="pivot-table__root" rowSpan={output.yAxisColumns.length} />
                )}
                {xAxis
                  .filter((xAxis) => !xAxis.inSpan[index])
                  .map((xAxis, index) => {
                    return (
                      <td key={index} className="pivot-table__header-cell" colSpan={xAxis.span[index]}>
                        {xAxis.labels[index]}
                      </td>
                    );
                  })}
                {index === 0 && (
                  <td
                    className="pivot-table__header-cell"
                    style={{ fontWeight: "bold" }}
                    rowSpan={output.xAxisColumns.length}
                  >
                    {t("Total")}
                  </td>
                )}
              </tr>
            );
          })}
        {output.xAxisColumns.length <= 1 && (
          <tr>
            {output.yAxisColumns.map((yAxisColumn, index) => {
              return (
                <td key={index} className="pivot-table__header-cell">
                  {getDisplayNameForField(yAxisColumn.keyDataFieldName)}
                </td>
              );
            })}
            {xAxis.map((xAxis, index) => {
              return (
                <td key={index} className="pivot-table__header-cell">
                  {xAxis.labels[0]}
                </td>
              );
            })}
            <td className="pivot-table__header-cell">Total</td>
          </tr>
        )}
      </thead>
      <tbody>
        {yAxis.map((yAxis, index) => {
          let groupTotal = 0;

          if (xAxis.length === 0) {
            const totalResultSetRow = output.rowData.find((resultRow) =>
              yAxis.keys.every((key, index) => resultRow.keys[yAxis.keyColumns[index]] === key)
            );
            if (totalResultSetRow) {
              groupTotal = totalResultSetRow.values[0];
              grandTotal += groupTotal;
            }
          }

          return (
            <tr key={index}>
              {yAxisIndexes
                .filter((index) => !yAxis.inSpan[index])
                .map((index) => {
                  return (
                    <td key={index} className="pivot-table__label-cell" rowSpan={yAxis.span[index]}>
                      {yAxis.labels[index]}
                    </td>
                  );
                })}
              {xAxis.map((xAxis, index) => {
                const resultSetRow = output.rowData.find(
                  (resultRow) =>
                    xAxis.keys.every((key, index) => resultRow.keys[xAxis.keyColumns[index]] === key) &&
                    yAxis.keys.every((key, index) => resultRow.keys[yAxis.keyColumns[index]] === key)
                );

                let value = null;
                if (resultSetRow) {
                  value = resultSetRow.values[0];
                  groupTotal += value;
                  grandTotal += value;
                  if (!groupTotals[index]) {
                    groupTotals[index] = 0;
                  }
                  groupTotals[index] += value;
                }

                return (
                  <td
                    key={index}
                    className="pivot-table__data-cell"
                    onClick={() => (resultSetRow ? handleClick(xAxis, yAxis) : null)}
                  >
                    {value}
                  </td>
                );
              })}
              <td className="pivot-table__total-cell" onClick={() => handleClick(null, yAxis)}>
                {groupTotal}
              </td>
            </tr>
          );
        })}
        <tr>
          <td className="pivot-table__label-cell" style={{ fontWeight: "bold" }} colSpan={yAxisIndexes.length}>
            {t("Total")}
          </td>
          {xAxis.map((xAxis, index) => (
            <td key={index} className="pivot-table__total-cell" onClick={() => handleClick(xAxis, null)}>
              {groupTotals[index]}
            </td>
          ))}
          <td className="pivot-table__total-cell" onClick={() => handleClick(null, null)}>
            {grandTotal}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

PivotTable.propTypes = {
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default PivotTable;
