import PropTypes from "prop-types";
import Row from "./Row";

function Header({ output }) {
  let rowCount = 0;

  return (
    <thead>
      {output.header?.map((row) => (
        <Row key={rowCount} output={output} rowCount={++rowCount} row={row} />
      ))}
    </thead>
  );
}

Header.propTypes = {
  output: PropTypes.object,
};

export default Header;
