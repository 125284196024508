import { accessRightFilterAdd, accessRightFilterDelete, accessRightFilterUpdate } from "api/security";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useSelector } from "react-redux";
import { accessRightCategories, superSelectOperationTypes } from "utilities/constants";
import { getAllListDisplayValues } from "utilities/lookup";
import { filterOutOnId, idsAreEqual } from "utilities/stringAndArray";

export default function CategoryAccessPanel() {
  const securityState = useSelector((state) => state.security);
  const currentUserGroup = securityState.currentUserGroup;

  const categories = [
    {
      id: accessRightCategories.UNIT,
      title: "Unit",
      options: getAllListDisplayValues(accessRightCategories.UNIT),
    },
    {
      id: accessRightCategories.DIVISION,
      title: "Division",
      options: getAllListDisplayValues(accessRightCategories.DIVISION),
    },
    {
      id: accessRightCategories.REGION,
      title: "Region",
      options: getAllListDisplayValues(accessRightCategories.REGION),
    },
  ];

  const handleCategoryChange = (optionId, action, categoryId) => {
    const accessRightFilters = currentUserGroup.accessRight.filters;
    const fieldName = `matterList-${categoryId}-ListTypeId`;
    const categoryFilter = accessRightFilters?.find((arf) => arf.fieldName === fieldName);

    if (action === superSelectOperationTypes.ADD) {
      if (categoryFilter) {
        accessRightFilterUpdate(
          securityState.currentCustomer.id,
          currentUserGroup.accessRight.id,
          categoryFilter.id,
          "valueArray",
          categoryFilter.valueArray,
          [...categoryFilter.valueArray, optionId]
        );
      } else {
        accessRightFilterAdd(securityState.currentCustomer.id, currentUserGroup.accessRight.id, {
          fieldName: fieldName,
          operator: "=",
          valueArray: [optionId],
        });
      }
    }

    if (action === superSelectOperationTypes.REMOVE) {
      const newValueArray = filterOutOnId(categoryFilter.valueArray, optionId);
      if (newValueArray.length === 0) {
        accessRightFilterDelete(securityState.currentCustomer.id, currentUserGroup.accessRight.id, categoryFilter.id);
      } else {
        accessRightFilterUpdate(
          securityState.currentCustomer.id,
          currentUserGroup.accessRight.id,
          categoryFilter.id,
          "valueArray",
          categoryFilter.valueArray,
          newValueArray
        );
      }
    }
  };

  const renderAccessByCategory = categories?.map((category) => {
    const selectedOptionsIds = currentUserGroup?.accessRight?.filters?.find(
      (filter) => filter.fieldName === `matterList-${category.id}-ListTypeId`
    );
    return (
      <tr key={category.title}>
        <td className="security__access-rights__categories--title">
          {t(category.title)}

          <SuperSelect
            id={`access-${category.title}-Select`}
            selectedOptionIds={selectedOptionsIds?.valueArray?.map((option) => parseInt(option)) ?? []}
            onChangeMulti={(id, action) => handleCategoryChange(id, action, category.id)}
            isMultiValued={true}
            options={category?.options}
            placeholderText={"Select Category Value..."}
          />
        </td>
      </tr>
    );
  });

  //MAIN RENDER
  return (
    <div className="security__section">
      <div className="security__section-title">{t("Access By Category")}</div>
      <div className="security__section-body">
        <table>
          <tbody>{currentUserGroup?.accessRight?.id && renderAccessByCategory}</tbody>
        </table>
      </div>
    </div>
  );
}
