import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setViewTypeSimpleSearch } from "redux/simpleSearchSlice";
import { searchViewTypes } from "utilities/constants";
import MatterTypeAccessPanel from "./MatterTypeAccessPanel";
import SectionOveridesAccessPanel from "./SectionOveridesAccessPanel";
import AdvancedFiltersAccessPanel from "./AdvancedFiltersAccessPanel";
import { createAccessRight } from "api/security";
import CategoryAccessPanel from "./CategoryAccessPanel";
import CountryAccessPanel from "./CountryAccessPanel";
import MasterAccessPanel from "./MasterAccessPanel";
import CompaniesAccessPanel from "./CompaniesAccessPanel";
import { processDataFields } from "utilities/simpleSearch";

export default function AccessRights() {
  const dispatch = useDispatch();
  const securityState = useSelector((state) => state.security);
  const currentCustomer = securityState.currentCustomer;
  const currentUserGroup = securityState.currentUserGroup;

  useEffect(() => {
    dispatch(setViewTypeSimpleSearch(searchViewTypes.ACCESS_RIGHTS));
    processDataFields();
  }, []);

  useEffect(() => {
    // check if group already has an access right assigned if not it will create one.
    if (
      securityState.currentAccount?.id &&
      !currentUserGroup?.accessRight?.id &&
      currentUserGroup?.id &&
      currentCustomer?.id
    ) {
      createAccessRight(currentCustomer.id, currentUserGroup.id);
    }
  }, []);

  //MAIN RENDER
  return (
    <>
      {currentCustomer?.id && currentUserGroup?.accessRight?.id && (
        <>
          <MasterAccessPanel />
          <MatterTypeAccessPanel />
          <CompaniesAccessPanel />
          <CategoryAccessPanel />
          <CountryAccessPanel />
          <SectionOveridesAccessPanel />
          <AdvancedFiltersAccessPanel />
        </>
      )}
    </>
  );
}
