import { useEffect, useState } from "react";
import { saveMatterReferenceChanges } from "api/matter";
import PropTypes from "prop-types";
import { sectionTypes } from "utilities/constants";
import { getReferenceDisplayNameFromTypeId } from "utilities/lookup";
import { idsAreEqual } from "utilities/stringAndArray";

// Container Component for the display of a reference field within a matter data panel
function PanelFieldReference({ keyProps, canEdit }) {
  //const locale = useSelector((state) => state.locale);

  const [value, setValue] = useState("");
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    if (keyProps.field.referenceTypeId && keyProps.data) {
      const reference = keyProps.data.find((ref) =>
        idsAreEqual(ref.matterReference_ReferenceTypeId, keyProps.field.referenceTypeId)
      );

      setValue(reference ? reference.matterReference_ReferenceNumber : "");
      setInitialValue(reference ? reference.matterReference_ReferenceNumber : null);
    } else {
      setValue(null);
      setInitialValue(null);
    }
  }, [keyProps.data, keyProps.field.referenceTypeId]);

  const saveTextChanges = (value) => {
    saveMatterReferenceChanges(keyProps.record.id, keyProps.field.referenceTypeId, value, initialValue);
  };

  let displayName = getReferenceDisplayNameFromTypeId(keyProps.field.referenceTypeId);

  const renderValueContents = canEdit ? (
    <input
      type="text"
      value={value ?? ""}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => saveTextChanges(e.target.value)}
      tabIndex={0}
      autoComplete="off"
    />
  ) : (
    value
  );

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <>{renderValueContents}</>
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value">{renderValueContents}</div>
    </>
  );
}

PanelFieldReference.propTypes = {
  keyProps: PropTypes.object,
  canEdit: PropTypes.bool,
};

export default PanelFieldReference;
