import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { setOutputStyle } from "utilities/reportOutput";
import { setBorderStyle } from "utilities/reportShared";
import Cell from "./Cell";

function Row({ output, rowCount, row }) {
  const [rowStyle, setRowStyle] = useState({});

  useEffect(() => {
    let rowStyle = {};

    const rowProp = output.rowProperties[row.p];

    if (output.style !== null) {
      const tableStyle = output.styles.formats[output.style];
      const border = output.styles.borders[tableStyle.borderId];

      if (rowCount > 1) {
        setBorderStyle(rowStyle, border.innerHorizontalBorder, "borderTop");
      }

      const totalRowCount = output.header.length + output.body.length;

      if (rowCount < totalRowCount) {
        setBorderStyle(rowStyle, border.innerHorizontalBorder, "borderBottom");
      }
    }

    if (rowProp.style !== null) {
      rowStyle = setOutputStyle(output, rowStyle, rowProp.style);
    }

    const rowHeight = rowProp.height ? rowProp.height : 12;

    rowStyle.height = rowHeight + "px";
    rowStyle.maxHeight = rowHeight + "px";

    setRowStyle(rowStyle);
  }, [rowCount, row, row.style, output]);

  const [cells, setCells] = useState([]);

  useEffect(() => {
    const rowProp = output.rowProperties[row.p];
    const mergedCells = rowProp.mergedCells !== null ? output.mergedCells[rowProp.mergedCells] : [];

    const processedCells = row.cells?.map((cell) => {
      const cellProps = cell.p !== null ? output.cellProperties[cell.p] : null;

      return { col: cellProps.column, props: cellProps, value: cell.v };
    });

    const cells = [];
    for (let col = 1; col <= output.columnCount; col++) {
      if (mergedCells?.find((colVal) => colVal === col)) {
        continue;
      }
      const cell = processedCells.find((cell) => cell.col === col) ?? { col, props: null, v: null };
      cells.push(<Cell key={col} cell={cell} row={row} output={output} />);
    }
    setCells(cells);
  }, [output, row]);

  return <tr style={rowStyle}>{cells}</tr>;
}

Row.propTypes = {
  output: PropTypes.object,
  rowCount: PropTypes.number,
  row: PropTypes.object,
};

export default Row;
