import { useDispatch } from "react-redux";
import {
  clearAllFilters,
  inputTextValueChange,
  fieldTextValueChange,
  labelSelectedChange,
  popupVisibleChange,
  resetReloadFieldsForMatterTypes,
  searchValueChange,
  setSavedSearchLoadedId,
  setKeyboardFocusedSelection,
} from "redux/simpleSearchSlice";
import { useSelector } from "react-redux";
import SearchTypeDropdown from "components/simple-search/SearchTypeDropdown";
import SimpleSearchPopup from "components/simple-search/SimpleSearchPopup";
import SelectedFilter from "components/simple-search/SelectedFilter";
import { clearResults, resultViewChange } from "redux/searchResultsSlice";
import { useNavigate } from "react-router-dom";
import { searchAndSetResults, qualifySearch } from "api/search";
import { FormatTypes, labelTypes, searchViewTypes, simpleSearchKeyboardFocusedPopup } from "utilities/constants";
import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import useComponentVisible from "components/global/useComponentVisible";
import Delete from "components/global/Delete";
import { useEffect, useState, useRef } from "react";
import { getMatterTypeIds, reloadFieldsAndFilterOnMatterType } from "utilities/simpleSearch";
import { FaChevronDown, FaChevronRight, FaRegStar, FaStar } from "react-icons/fa";
import SavedSearchesPopup from "components/header/SavedSearchesPopup";
import { isAccessRightField } from "utilities/datafield";
import { setBatchImportLoading } from "redux/batchImportSlice";
import InputDebounced from "components/global/InputDebounced";

// Container for the simple search control
function SearchPanel({ viewType, isEmbedded, matterTypeId, disabled, readOnly, onFilterChange }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const securityState = useSelector((state) => state.security);
  const batchImportState = useSelector((state) => state.batchImport);
  const locale = useSelector((state) => state.locale).selectedLocale;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const [refPopup, isPopupVisible, setIsPopupVisible] = useComponentVisible(false);
  const [refSavedPopup, showSavedPopup, setShowSavedPopup] = useComponentVisible(false);
  const [isExpandingAllFilters, setIsExpandingAllFilters] = useState(false);

  // GLOBAL VARIABLES
  const fields = simpleSearch.fields;
  const isAccessRights = viewType === searchViewTypes.ACCESS_RIGHTS;

  const filtersRef = {
    [searchViewTypes.MAIN]: simpleSearch?.filters,
    [searchViewTypes.CONNECTED_MATTERS]: simpleSearch?.filters,
    [searchViewTypes.ACCESS_RIGHTS]: securityState?.currentUserGroup?.accessRight?.filters,
    [searchViewTypes.BATCH_IMPORT]: batchImportState?.project?.searchFilters,
  };

  let filters = [...filtersRef[viewType]];
  if (isEmbedded) {
    filters = filters.filter((f) => f.fieldName !== "matter_MatterTypeId");
  }
  if (isAccessRights) {
    filters = filters.filter((f) => isAccessRightField(f.fieldName));
  } else {
    const filterMatterTypeId = filters.find((f) => f.fieldName === "matter_MatterTypeId");
    if (filterMatterTypeId) {
      filters = [filterMatterTypeId, ...filters.filter((f) => f.fieldName !== "matter_MatterTypeId")];
    }
  }

  // USE EFFECTS
  useEffect(() => {
    if (simpleSearch.reloadFieldsForMatterTypes === true) {
      reloadFieldsAndFilterOnMatterType();
      dispatch(resetReloadFieldsForMatterTypes());
    }
  }, [simpleSearch.reloadFieldsForMatterTypes]);

  useEffect(() => {
    if (simpleSearch.viewType === viewType && isPopupVisible !== simpleSearch.isPopupVisible) {
      setIsPopupVisible(simpleSearch.isPopupVisible);
    }
  }, [simpleSearch.isPopupVisible]);

  useEffect(() => {
    if (simpleSearch.viewType === viewType && isPopupVisible !== simpleSearch.isPopupVisible) {
      dispatch(popupVisibleChange(isPopupVisible));
    }
  }, [isPopupVisible]);

  useEffect(() => {
    if (
      simpleSearch.isKeyboardFocusedSelection === simpleSearchKeyboardFocusedPopup.SEARCH_PANEL &&
      viewType !== searchViewTypes.ACCESS_RIGHTS
    ) {
      inputRef?.current?.focus();
      setIsPopupVisible(true);
    }
  }, [simpleSearch.isKeyboardFocusedSelection]);

  // EVENT HANDLERS

  const handleToggleExpandAllFilters = () => {
    setIsExpandingAllFilters(!isExpandingAllFilters);
  };

  const handleClearAllFiltersClick = () => {
    dispatch(clearAllFilters());
    dispatch(setSavedSearchLoadedId(null));
  };

  const handleInputClick = () => {
    if (!isPopupVisible) setIsPopupVisible(true);
    dispatch(labelSelectedChange(false));
    if (simpleSearch.isKeyboardFocusedSelection !== simpleSearchKeyboardFocusedPopup.SEARCH_PANEL)
      dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.SEARCH_PANEL));
  };

  const handleInputTextChange = async (text) => {
    if (!isPopupVisible) setIsPopupVisible(true);
    const matterTypeIds = getMatterTypeIds();
    const fieldsQualifiedValues = await qualifySearch(text, simpleSearch.queryType.id, matterTypeIds);
    dispatch(searchValueChange({ text, locale, fieldsQualifiedValues }));
    dispatch(inputTextValueChange(text));
    dispatch(fieldTextValueChange(text));
  };

  const handleInputKeyDown = (e) => {
    //console.log("key down in search panel: " + e.code);
    switch (e.code) {
      case "ArrowDown":
      case "Tab": {
        dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.MAIN));
        e.preventDefault();
        break;
      }
      case "Enter": {
        if (simpleSearch.isKeyboardFocusedSelection === simpleSearchKeyboardFocusedPopup.SEARCH_PANEL) {
          handleSearchClick();
          e.preventDefault();
        }
      }
      default:
        break;
    }
  };

  // TODO: Sort out "values/valueArray" issue

  const handleSearchClick = async () => {
    if (simpleSearch.inputText.length > 0) {
      const mainInput = document.querySelector(".simple-search__input");
      mainInput.focus();
      mainInput.selectionStart = 0;
      mainInput.selectionEnd = simpleSearch.inputText.length;
      return;
    }

    const searchFilters = buildSearchFilters();
    const queryObject = buildQueryObject(searchFilters);

    if (searchViewTypes.BATCH_IMPORT === viewType) return handleBatchImport(queryObject);
    if (isEmbedded) {
      searchFilters.push({
        fieldName: "matter_MatterTypeId",
        operator: "=",
        valueArray: [matterTypeId],
      });
    } else {
      navigate("/results", { replace: true });
      dispatch(resultViewChange(FormatTypes.LIST));
    }

    searchAndSetResults(queryObject, viewType);
  };

  const buildSearchFilters = () => {
    const searchFilters = [];

    filters.forEach((filter) => {
      const field = fields.find((f) => f.fieldName === filter.fieldName);

      const fieldName = filter.fieldName;
      const operator = filter.operator;
      const dataType = field.dataType;
      const qualified = filter.qualified;

      let valueArray = [];
      if (filter.valueArray?.length > 0) valueArray = filter.valueArray;
      else if (dataType === labelTypes.STRING) {
        const stringValuesArray = filter.valueArray;
        stringValuesArray.forEach((stringValue) => valueArray.push(stringValue.trim()));
      } else if (dataType === labelTypes.DATE || dataType === labelTypes.BOOLEAN) valueArray = filter.valueArray;

      searchFilters.push({ fieldName, operator, valueArray, qualified });
    });

    return searchFilters;
  };

  const buildQueryObject = (query) => {
    const projectId = searchViewTypes.BATCH_IMPORT === viewType ? batchImportState?.project?.id : null;
    return {
      queryType: simpleSearch.queryType.id,
      formatType: FormatTypes.LIST,
      ...(projectId !== null && { projectId }),
      searchFilters: query,
    };
  };

  const handleBatchImport = (queryObject) => {
    dispatch(setBatchImportLoading(true));
    dispatch(clearResults());
    searchAndSetResults(queryObject, viewType);
  };

  // SECTION RENDERS
  const renderFiltersSection = (
    <>
      {!readOnly && (
        <div className="simple-search__expand-all-filters" onClick={handleToggleExpandAllFilters}>
          {isExpandingAllFilters ? <FaChevronDown /> : <FaChevronRight />}
        </div>
      )}
      {filters.map((filter) => (
        <SelectedFilter
          key={filter.fieldName}
          filter={filter}
          isExpandedText={!readOnly ? isExpandingAllFilters : true}
          readOnly={readOnly}
        />
      ))}
      {filters?.length > 1 && !readOnly && (
        <Delete
          forceAbsoluteTopPosition={true}
          message="Remove all search filters"
          onConfirm={handleClearAllFiltersClick}
          customCssClass="simple-search-filter__clear-all"
        />
      )}
    </>
  );

  const renderMainInput = () => {
    let inputPlaceholder = t("Search for anything here");
    if (isEmbedded) {
      inputPlaceholder = t("Search for any matter here");
    }
    return (
      <div className="simple-search__input-container">
        {disabled ? (
          <div className="simple-search__input">&nbsp;</div>
        ) : (
          <InputDebounced
            className="simple-search__input"
            value={simpleSearch.inputText}
            onChange={handleInputTextChange}
            onClick={handleInputClick}
            // onFocus={() => setIsPopupVisible(true)}
            onKeyDown={handleInputKeyDown}
            placeholder={t(inputPlaceholder)}
            ref={inputRef}
          />
        )}
        <SimpleSearchPopup isVisible={isPopupVisible} inputRef={refPopup} />
      </div>
    );
  };

  const renderSearchButton = (
    <div className="simple-search__main-search-button">
      {simpleSearch.viewType !== searchViewTypes.ACCESS_RIGHTS && (
        <button
          className={disabled ? "disabled" : ""}
          onClick={() => {
            !disabled && handleSearchClick();
          }}
        >
          {t("Search")}
        </button>
      )}
    </div>
  );

  const renderSavedSearchButton = !isEmbedded && (
    <div className="simple-search__actions" onClick={() => setShowSavedPopup(true)} ref={refSavedPopup}>
      {simpleSearch.savedSearchLoadedId ? (
        <span className="color-text-yellow">
          <FaStar />
        </span>
      ) : (
        <FaRegStar />
      )}
    </div>
  );

  const classNameMainBase = "search-panel__simple-search";
  let classNameMain = classNameMainBase;
  if (showSavedPopup) classNameMain = `${classNameMainBase} ${classNameMainBase}--saved-search-attached`;
  if (readOnly) classNameMain = `${classNameMainBase} ${classNameMainBase}--read-only`;

  // FINAL RENDER
  return (
    <div className={classNameMain}>
      {!isEmbedded && <SearchTypeDropdown />}
      <div className="simple-search__filters">
        {renderFiltersSection}
        {!readOnly && renderMainInput()}
      </div>
      {!readOnly && renderSearchButton}
      {!readOnly && renderSavedSearchButton}
      {!readOnly && showSavedPopup && <SavedSearchesPopup inputRef={refSavedPopup} />}
    </div>
  );
}

SearchPanel.propTypes = {
  viewType: PropTypes.number,
  isEmbedded: PropTypes.bool,
  matterTypeId: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default SearchPanel;
