import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import PropTypes from "prop-types";
import { saveUploadedDocument } from "api/matter";
import { saveUploadedCustomerImage, saveUploadedMatterImage } from "api/image";
import { t } from "locale/dictionary";
import { stringArrayBufferToBase64 } from "utilities/stringAndArray";

function FileUploader({ keyProps, isImage, isHeaderControl, children, onAddNew /*, setSelectedIndexLast*/ }) {
  const [numExistingImages, setNumExistingImages] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles) => {
      let newSortIndex = numExistingImages;
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const base64String = stringArrayBufferToBase64(reader.result);
          if (isImage) {
            newSortIndex++;
            if (keyProps.isProject) {
              onAddNew({ fileName: file.name, description: file.name, base64: base64String, sortIndex: newSortIndex });
            } else {
              keyProps?.record
                ? saveUploadedMatterImage(keyProps?.record?.id, file.name, base64String, newSortIndex)
                : saveUploadedCustomerImage(file.name, base64String, newSortIndex);
            }
          } else saveUploadedDocument(keyProps?.record?.id, file.name, base64String);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [numExistingImages]
  );

  useEffect(() => {
    if (keyProps?.record?.images) setNumExistingImages(keyProps.record.images.length);
  }, [keyProps?.record?.images]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: isHeaderControl });

  // TODO: Awaiting correct MIME types from the server
  // const validFiles = lookupState.global.find((lookup) => lookup.name === "FileExtensions").lookup;
  //let accept = { "application/pdf": [".pdf"] };
  // validFiles.forEach((validFile) => {
  //   accept[validFile.code] = validFile.extensions.map((extension) => `.${extension}`);
  // });
  //const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: isHeaderControl, accept });

  const outerClassNameBase = "file-uploader";
  let outerClassName = outerClassNameBase;

  if (isDragActive) outerClassName += ` ${outerClassNameBase}--is-dragging`;

  return (
    <div className={outerClassName} {...getRootProps()}>
      <input {...getInputProps()} />
      {isImage ? (
        isHeaderControl ? (
          <div>{children}</div>
        ) : (
          <div className="flex-column-center">
            <button className="flex-row-center">
              {t("UPLOAD IMAGE(S)")}&nbsp; &nbsp;
              <FaUpload />
            </button>
            <p>{t("Or drop image(s) into this panel")}</p>
          </div>
        )
      ) : (
        <div className="flex-column-center">
          <button className="flex-row-center">
            {t("UPLOAD FILE(S)")}&nbsp; &nbsp;
            <FaUpload />
          </button>
          <p>{t("Or drop file(s) into this panel")}</p>
        </div>
      )}
    </div>
  );
}

FileUploader.propTypes = {
  keyProps: PropTypes.object,
  isImage: PropTypes.bool,
  isHeaderControl: PropTypes.bool,
  children: PropTypes.element,
  setSelectedIndexLast: PropTypes.func,
  onAddNew: PropTypes.func,
};

export default FileUploader;
