import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { loadMruRecords } from "api/customer";
import DataTable from "./DataTable";

// Displays clickable list of Most Recently Used (MRU) records
export default function MruRecords() {
  const bearerToken = useSelector((state) => state.app.bearerToken);

  const [mruRecords, setMruRecords] = useState([]);

  useEffect(() => {
    if (bearerToken) loadMruRecords().then((response) => setMruRecords(response));
  }, [bearerToken]);

  return (
    <DataTable fields={["matterId", "matterAudit_UtcDate", "matter_Name", "matter_MatterTypeId"]} data={mruRecords} />
  );
}
