import { Routes, Route } from "react-router";
import Authentication from "./Authentication";
import NewUser from "./NewUser";

// Routing for authentications
export default function AuthenticationRoutes() {
  return (
    <Routes>
      <Route exact path="/new-user" element={<NewUser />} />
      <Route path="*" element={<Authentication />} />
    </Routes>
  );
}
