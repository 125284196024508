import { createSlice } from "@reduxjs/toolkit";

// Redux global state component for handling Data Fields
export const dataFieldSlice = createSlice({
  name: "Data Field",
  initialState: {
    dataFields: [],
    groups: [],
    translatedOverrides: [],
  },
  reducers: {
    setDataFields: (state, action) => {
      state.dataFields = action.payload;
    },
    setDataFieldGroups: (state, action) => {
      state.groups = action.payload;
    },
    setTranslatedOverrides: (state, action) => {
      state.translatedOverrides = action.payload;
    },
  },
});

export const { setDataFields, setDataFieldGroups, setTranslatedOverrides } = dataFieldSlice.actions;

export default dataFieldSlice.reducer;
