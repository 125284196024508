import PropTypes from "prop-types";
import SearchFieldSection from "./SearchFieldSection";
import { labelTypes, searchViewTypes, simpleSearchKeyboardFocusedPopup } from "utilities/constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { labelSelectedChange, setKeyboardFocusedSelection } from "redux/simpleSearchSlice";
import { useState } from "react";
import { useRef } from "react";
import { FaCaretDown, FaCaretRight } from "react-icons/fa";
import { t } from "locale/dictionary";

// Container for the simple search suggestions popup
function SimpleSearchPopup({ isVisible, inputRef }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const batchImportState = useSelector((state) => state.batchImport);
  //const securityState = useSelector((state) => state.security);
  const dataFieldGroupsRaw = useSelector((state) => state.dataField.groups);
  const primaryGroupName = simpleSearch?.queryType?.primaryDataFieldGroupName;

  const dispatch = useDispatch();

  const [displayOrder, setDisplayOrder] = useState([]);
  const [isEnterKeyPressed, setIsEnterKeyPressed] = useState(false);
  const [isExpandAllSections, setIsExpandAllSections] = useState(
    simpleSearch.viewType === searchViewTypes.ACCESS_RIGHTS
  );

  const containerRef = useRef(null);
  const dataFieldGroups = useRef(null);
  //const childElementsRef = useRef([]);

  // Put to the top of the group list the primary group if choosing a different search type from "Matter"
  dataFieldGroups.current = dataFieldGroupsRaw;
  if (primaryGroupName) {
    const primaryGroup = primaryGroupName && dataFieldGroupsRaw.find((group) => group.name === primaryGroupName);
    dataFieldGroups.current = [primaryGroup, ...dataFieldGroupsRaw.filter((group) => group.name !== primaryGroupName)];
  }

  let matchingFields = [...simpleSearch.fieldsMatchingAll];
  let fields = [...simpleSearch.fields];

  // Exclude any fields already selected as an active filter
  // TODO: Remove this if we want to allow the same field to be selected multiple times
  if (simpleSearch.viewType === searchViewTypes.BATCH_IMPORT)
    fields = fields.filter(
      (field) => !batchImportState?.project?.searchFilters?.some((filter) => filter.fieldName === field.fieldName)
    );
  else fields = fields.filter((field) => !simpleSearch.filters.some((filter) => filter.fieldName === field.fieldName));

  // Exclude any fields that are part of the Matching Search Fields list
  fields = fields.filter((gf) => !matchingFields.some((fm) => fm.fieldName === gf.fieldName));

  // Exclude the matter type field from connected matter searches
  if (simpleSearch.viewType === searchViewTypes.CONNECTED_MATTERS) {
    fields = fields.filter((gf) => gf.fieldName !== "matter_MatterTypeId");
    matchingFields = matchingFields.filter((fm) => fm.fieldName !== "matter_MatterTypeId");
  }

  if (simpleSearch.inputText) {
    // Exclude any fields that don't match the possible type of the input
    fields = fields.filter((gf) => {
      switch (gf.dataType) {
        case labelTypes.BOOLEAN:
          return simpleSearch.inputValues.some((inputValue) => inputValue.isBoolean);
        case labelTypes.DATE:
          return simpleSearch.inputValues.some((inputValue) => inputValue.isDate);
        case labelTypes.DECIMAL:
          return simpleSearch.inputValues.some((inputValue) => inputValue.isNumber);
        case labelTypes.LOOKUP:
          return false;
        case labelTypes.STRING:
          return true;
        default:
          return false;
      }
    });
  }

  // useEffect(() => {
  //   if (containerRef.current && !eventListenerAdded) {
  //     containerRef.current.addEventListener("keydown", handleKeyDown);
  //     setEventListenerAdded(true);
  //   }
  // }, [containerRef.current, eventListenerAdded]);

  //if keyboard navigating and no selection, select the first item of matching fields group, if any, then the first item of the first group
  useEffect(() => {
    if (simpleSearch.isKeyboardFocusedSelection === simpleSearchKeyboardFocusedPopup.MAIN && displayOrder.length > 0) {
      if (!simpleSearch.labelSelected) {
        dispatch(
          labelSelectedChange({
            section: displayOrder[0].section,
            fieldName: displayOrder[0].fieldName,
            clickSource: "Field",
          })
        );
      }
      containerRef?.current?.focus();
    }
  }, [simpleSearch.isKeyboardFocusedSelection, displayOrder]);

  // Iterate through matching grouped fields then non-matching grouped fields to produce an array of fields in the order they should be displayed, exactly same as the final return statement in this component
  useEffect(() => {
    const displayOrderLocal = [];
    dataFieldGroups.current.forEach((group) => {
      const fieldsInThisGroup = matchingFields.filter((field) => field.group === group.name);
      if (fieldsInThisGroup.length > 0) {
        fieldsInThisGroup.forEach((field) =>
          displayOrderLocal.push({ section: group.name, fieldName: field.fieldName })
        );
      }
    });
    dataFieldGroups.current.forEach((group) => {
      const fieldsInThisGroup = fields.filter((field) => field.group === group.name);
      if (fieldsInThisGroup.length > 0) {
        fieldsInThisGroup.forEach((field) =>
          displayOrderLocal.push({ section: group.name, fieldName: field.fieldName })
        );
      }
    });
    setDisplayOrder(displayOrderLocal);
  }, [dataFieldGroups, simpleSearch.fieldsMatchingAll]);

  // On up/down arrows, select the previous/next item in the display order using labelSelectedChange
  const handleKeyDown = (e) => {
    if (simpleSearch.isKeyboardFocusedSelection === simpleSearchKeyboardFocusedPopup.MAIN) {
      const currentSection = simpleSearch.labelSelected.section;
      const currentFieldName = simpleSearch.labelSelected.fieldName;
      const currentIndex = displayOrder.findIndex(
        (displayItem) => displayItem.section === currentSection && displayItem.fieldName === currentFieldName
      );

      let increment = 0;
      if (e.key === "ArrowUp") {
        if (currentIndex > 0) increment = -1;
        else if (currentIndex === 0) {
          dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.SEARCH_PANEL));
          return;
        }
      } else if (e.key === "ArrowDown" && currentIndex < displayOrder.length - 1) increment = 1;
      else if (e.key === "ArrowRight" || e.key === "Tab") {
        dispatch(setKeyboardFocusedSelection(simpleSearchKeyboardFocusedPopup.RHS));
        return;
      } else if (e.key === "Enter") {
        setIsEnterKeyPressed(true);
        return;
      } else return;

      e.preventDefault();

      dispatch(
        labelSelectedChange({
          section: displayOrder[currentIndex + increment].section,
          fieldName: displayOrder[currentIndex + increment].fieldName,
          clickSource: "Field",
        })
      );
    }
  };

  const handleEnterKeyPressDone = () => {
    setIsEnterKeyPressed(false);
  };

  const handleExpandAllSectionsClick = () => {
    setIsExpandAllSections(!isExpandAllSections);
  };

  const renderMatchingGroupsAndFields = dataFieldGroups.current.map((group) => {
    // Get the matching fields in this group, excluding any already in a selected filter
    const fieldsInThisGroup = matchingFields.filter(
      (matchingField) =>
        matchingField.group === group.name &&
        !simpleSearch.filters.some((filter) => filter.fieldName === matchingField.fieldName)
    );

    return (
      fieldsInThisGroup.length > 0 && (
        <SearchFieldSection
          key={group.name}
          section={group.name}
          title={group.displayName}
          isCollapsible={true}
          isInitiallyVisible={true}
          fields={fieldsInThisGroup}
          isMatchingSection={true}
          isEnterKeyPressed={isEnterKeyPressed}
          onEnterKeyPressDone={handleEnterKeyPressDone}
        />
      )
    );
  });

  const nonEmptyGroups = dataFieldGroups.current.filter(
    (group) => fields.filter((field) => field.group === group.name).length > 0
  );

  const renderGroupsAndFields =
    nonEmptyGroups &&
    nonEmptyGroups.map((group, index) => {
      const fieldsInThisGroup = fields.filter((field) => field.group === group.name);
      let isInitiallyVisible = index < 1 || isExpandAllSections;
      if (
        !isInitiallyVisible &&
        fieldsInThisGroup.find(
          (fieldInThisGroup) => fieldInThisGroup.fieldName === simpleSearch.labelSelected.fieldName
        )
      )
        isInitiallyVisible = true;

      return (
        <SearchFieldSection
          key={group.name}
          section={group.name}
          title={group.displayName}
          isCollapsible={true}
          isInitiallyVisible={isInitiallyVisible}
          fields={fieldsInThisGroup}
          isEnterKeyPressed={isEnterKeyPressed}
          onEnterKeyPressDone={handleEnterKeyPressDone}
        />
      );
    });

  const classNameBase = "simple-search__popup";
  let className = `${classNameBase}${isVisible ? "" : "--hide"}`;
  if (simpleSearch.viewType === searchViewTypes.ACCESS_RIGHTS) {
    className += ` ${classNameBase}--access-rights`;
  }
  return (
    <div className={className} ref={inputRef}>
      {isVisible && (
        <>
          <div className="simple-search__suggested-fields-container">
            <div className="simple-search__section-title-container">
              <div className="simple-search__section-title clickable" onClick={handleExpandAllSectionsClick}>
                {isExpandAllSections ? (
                  <span>
                    <FaCaretDown />
                    {t("Collapse All")}
                  </span>
                ) : (
                  <span>
                    <FaCaretRight /> {t("Expand All")}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div
            className="simple-search__popup-focus-container"
            ref={containerRef}
            tabIndex={-1}
            onKeyDown={handleKeyDown}
            // onBlur={() => dispatch(setKeyboardFocusedSelection(false))}
          >
            {renderMatchingGroupsAndFields}
            {renderGroupsAndFields}
          </div>
        </>
      )}
    </div>
  );
}

SimpleSearchPopup.propTypes = {
  isVisible: PropTypes.bool,
};

export default SimpleSearchPopup;
