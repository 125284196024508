import PropTypes from "prop-types";
import Row from "./Row";

function Body({ output }) {
  let rowCount = output.header.length;

  return (
    <tbody>
      {output.body?.map((row) => (
        <Row key={row.r} output={output} rowCount={++rowCount} row={row} />
      ))}
    </tbody>
  );
}

Body.propTypes = {
  output: PropTypes.object,
};

export default Body;
