import { getDateDiffNumDays } from "utilities/dateTime";
import InlineAlert from "./InlineAlert";
import PropTypes from "prop-types";

// Control for display warning or error symbols inline with text
// Set level in props to one of the following: "warning", "error"
function DateAlert({ data, hasAlert, hasAbsolutePosition, children }) {
  const dataDate = new Date(data);
  const nowDate = new Date("2022-07-31"); //Date.now(); //
  let alertLevel = null;
  if (hasAlert) {
    if (dataDate < nowDate) alertLevel = "error";
    else if (getDateDiffNumDays(nowDate, dataDate) < 7) alertLevel = "warning";
  }

  return (
    <div className="date-alert flex-row-start">
      {alertLevel && <InlineAlert level={alertLevel} hasAbsolutePosition={hasAbsolutePosition} />}
      {children}
    </div>
  );
}

DateAlert.propTypes = {
  data: PropTypes.any, // TODO: fix so it's only number?
  hasAlert: PropTypes.bool,
  hasAbsolutePosition: PropTypes.bool,
  children: PropTypes.element,
};

export default DateAlert;
