import ReportList from "./menu/ReportList";
import { t } from "locale/dictionary";

function ReportMenu() {
  return (
    <div className="content__report-menu">
      <div className="report-menu__title">{t("Report Editor Menu")}</div>
      <ReportList isEditor={true} />
    </div>
  );
}

export default ReportMenu;
