import React from "react";
import { t } from "locale/dictionary";
import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import PropTypes from "prop-types";

// Universal pagination component
function Paginator({ totalPages, currentPage, onPageFirstClick, onPagePrevClick, onPageNextClick, onPageLastClick }) {
  return (
    <div className="paginator">
      {currentPage > 0 ? (
        <>
          <div className="paginator__page-action" onClick={onPageFirstClick}>
            <BiChevronsLeft />
          </div>
          <div className="paginator__page-action" onClick={onPagePrevClick}>
            <BiChevronLeft />
          </div>
        </>
      ) : (
        <div className="paginator__page-no-action">&nbsp;</div>
      )}
      <div className="paginator__page-number">
        <strong>{currentPage + 1}</strong>&nbsp;{t("of")}&nbsp;
        <strong>{totalPages}</strong>
      </div>
      {currentPage < totalPages - 1 ? (
        <>
          <div className="paginator__page-action" onClick={onPageNextClick}>
            <BiChevronRight />
          </div>
          <div className="paginator__page-action" onClick={onPageLastClick}>
            <BiChevronsRight />
          </div>
        </>
      ) : (
        <div className="paginator__page-no-action">&nbsp;</div>
      )}
    </div>
  );
}

Paginator.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onPageFirstClick: PropTypes.func,
  onPagePrevClick: PropTypes.func,
  onPageNextClick: PropTypes.func,
  onPageLastClick: PropTypes.func,
};

export default Paginator;
