import UserPopup from "components/header/sys-tray/UserPopup";
import SettingsPopup from "components/header/sys-tray/SettingsPopup";
import MessagesPopup from "components/header/sys-tray/MessagesPopup";

// Component that displays the top-right "system tray" of app-wide settings such as locale, theme, etc.
export default function SysTray() {
  return (
    <>
      <div className="header__sys-tray">
        <UserPopup />
        <MessagesPopup />
        <SettingsPopup />
      </div>
    </>
  );
}
