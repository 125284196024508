// Map API calls

import store from "redux/store";
import { setWorldMapData, setWorldMap3dData, setCountryMapData } from "redux/mapDataSlice";

export async function loadWorldMapData() {
  const requestOptions = {
    method: "GET",
  };
  return fetch("https://code.highcharts.com/mapdata/custom/world-palestine-highres.geo.json", requestOptions).then(
    async (response) => {
      if (response.ok) {
        const worldMapData = await response.json();
        store.dispatch(setWorldMapData(worldMapData));
      }
    }
  );
}

export async function loadWorldMap3dData() {
  const requestOptions = {
    method: "GET",
  };
  return fetch("https://code.highcharts.com/mapdata/custom/world.topo.json", requestOptions).then(async (response) => {
    if (response.ok) {
      const worldMap3dData = await response.json();
      store.dispatch(setWorldMap3dData(worldMap3dData));
    }
  });
}

export async function loadCountryMapData(countryCode) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `https://code.highcharts.com/mapdata/countries/${countryCode}/${countryCode}-all.topo.json`,
    requestOptions
  ).then(async (response) => {
    if (response.ok) {
      const mapData = await response.json();
      store.dispatch(setCountryMapData({ mapData, countryCode }));
    }
  });
}
