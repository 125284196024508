import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import PivotAxisField from "./PivotAxisField";
import { addPivotField, removePivotField } from "redux/reportSlice";
import { t } from "locale/dictionary";

function PivotAxisFields({ className, type, label, fields, matterTypeIds, isDataField = false }) {
  const dispatch = useDispatch();

  const onDeleteClick = (field) => {
    dispatch(removePivotField({ type, id: field.id }));
  };

  const [, drop] = useDrop(() => ({
    accept: "box",
    drop(item) {
      dispatch(addPivotField({ type, fieldName: item.name }));
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div className={["pivot-axes__axis", className].join(" ")}>
      <div className="axis__label">{t(label)}</div>
      <div className="axis__fields-container" ref={drop}>
        {fields.map((field) => {
          return (
            <PivotAxisField
              key={field.id}
              field={field}
              onDeleteClick={onDeleteClick}
              isDataField={isDataField}
              matterTypeIds={matterTypeIds}
            />
          );
        })}
      </div>
    </div>
  );
}

PivotAxisFields.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  isDataField: PropTypes.bool,
  matterTypeIds: PropTypes.array,
};

export default PivotAxisFields;
