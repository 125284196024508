import store from "redux/store";

// Utility functions relating to error message handling

/* Error messages arrive from the API in this format:
{
  "SOME_UNIQUE_CODE_1": [
    "api.error.someissue",
    "api.error.someotherissue"
  ],
  "SOME_UNIQUE_CODE_2": [
    "api.error.anotherissue"
  ]
}
*/
export function formatErrorMessage(errors) {
  const state = store.getState();
  let outputMessages = [];
  Object.values(errors).forEach((error, index) => {
    const translatedErrors = error.map((issue) => state.locale.translations[issue]).join(". ");
    const errorsWithCode = `${translatedErrors} (${Object.keys(errors)[index]})`;
    outputMessages.push(errorsWithCode);
  });

  return outputMessages.join(". ");
}
