import { getPanelIdString } from "./stringAndArray";

// Utility functions relating to the DOM
export function isVisibleWithinScrollableView(el, container) {
  var rectEl = el.getBoundingClientRect();
  var rectCont = container.getBoundingClientRect();

  if (!rectEl || !rectCont) return false;
  // Only completely visible elements return true:
  var isVisible = rectEl.top >= rectCont.top && rectEl.bottom < rectCont.bottom;
  return isVisible;
}

export function isInViewport(el) {
  if (!el) return true;
  var rect = el.getBoundingClientRect();

  if (!rect) return false;
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export function getParentElemWidth(el) {
  const container = el?.container.current;
  const parent = container?.parentElement;
  const width = parent?.getBoundingClientRect().width;
  return width;
}

export function focusFirstTabbedChild(container) {
  const focusableElements = container.querySelectorAll('[tabindex]:not([tabindex="-1"])');
  //console.log(focusableElements[0]);
  if (focusableElements.length > 0) {
    focusableElements[0].focus();
  }
}

export function navigateToSection(sectionName) {
  const elementId = getPanelIdString(sectionName);
  document.getElementById(elementId).scrollIntoView();
}
