import { addImage, updateImageField, deleteImage } from "redux/customerSlice";
import { addNewChildObject } from "redux/matterSlice";
import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";

export async function getImageData(imageId, matterId, asBase64 = false) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const requestOptions = {
    method: "GET",
    headers: { Authorization: state.app.bearerToken },
  };

  let url = `${process.env.REACT_APP_API_ROOT}customers/${customerId}/`;
  if (matterId) url += `matters/${matterId}/`;
  url += `imagedata/${imageId}`;
  if (!asBase64) url += "/formdata";

  const response = await fetch(url, requestOptions);

  if (asBase64) {
    const base64response = await response.text();
    return `data:image;base64,${base64response}`;
  }
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

export async function saveUploadedMatterImage(matterId, filename, base64stream, newSortIndex, description) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = {
    matterImage_Base64: base64stream,
    matterImage_Description:
      description ?? filename.lastIndexOf(".") === -1 ? filename : filename.substring(0, filename.lastIndexOf(".")),
    matterImage_Filename: filename,
    matterImage_Primary: false,
    matterImage_ImageId: 0,
    matterImage_SortIndex: newSortIndex,
  };
  const response = await fetchWrapper.post(`customers/${customerId}/matters/${matterId}/images`, body);
  store.dispatch(addNewChildObject({ matterId, tableName: "images", newObject: response }));
  return response;
}

export async function saveUploadedCustomerImage(filename, base64stream, newSortIndex) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = {
    base64: base64stream,
    description: filename.lastIndexOf(".") === -1 ? filename : filename.substring(0, filename.lastIndexOf(".")),
    filename: filename,
    primary: false,
    imageId: 0,
    sortIndex: newSortIndex,
  };
  const response = await fetchWrapper.post(`customers/${customerId}/images`, body);
  store.dispatch(addImage({ response }));
  return response;
}

export async function getCustomerImages() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/images`);
  return response;
}

export async function saveImageFieldChanges(imageId, fieldName, value, initialValue) {
  // If no material change in value, do not save
  if (value === initialValue || (!value && initialValue === "") || (value === "" && !initialValue)) return;

  const state = store.getState();
  const customerId = state.app.customerId;

  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/images/${imageId}`, body);

  store.dispatch(updateImageField({ fieldName, imageId, value }));
}

export async function deleteCustomerImage(imageId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  await fetchWrapper.delete(`customers/${customerId}/images/${imageId}`);
  store.dispatch(deleteImage(imageId));
}
