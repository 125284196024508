import React from "react";
import { sectionDefinitions } from "utilities/sectionDefinitions";
import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createBatchGood, deleteBatchGood, refreshBatchSummary, updateBatchGood } from "api/batchCreate";
import { addGood, deleteGood, updateGoodField } from "redux/batchCreateSlice";
import { exportedSectionDefinitions, projectStatuses } from "utilities/constants";
import Panel from "components/content/matter-record/Panel";
import { findById, hasMatchingId, idsAreEqual } from "utilities/stringAndArray";

function BatchCreateGoods() {
  const batchCreateState = useSelector((state) => state.batchCreate);
  const project = batchCreateState.project;

  const dispatch = useDispatch();

  const [keyProps, setKeyProps] = useState(null);

  const secDef = findById(sectionDefinitions, exportedSectionDefinitions.GOODS_FOR_BATCH_CREATE);

  useEffect(() => {
    // On any change in project.goods, refresh the batch summary table
    if (project?.project_Status !== projectStatuses.MATTER_GENERATED) refreshBatchSummary(project);
  }, [project?.goods?.length]);

  useEffect(() => {
    // Trick the data panel into thinking we have a standard classes matter section
    if (project?.id) {
      const projectMatterMapped = {
        ...project,
        goods: project?.goods?.map((good) => {
          return {
            id: good.id,
            matterGoods_Class: good.class,
            matterGoods_Goods: good.goods,
            matterGoods_LanguageCode: good.languageCode ?? "en",
            matterGoods_Local: good.local,
          };
        }),
      };

      setKeyProps({
        sectionType: secDef.type,
        sectionName: secDef.name,
        sectionId: secDef.id,
        tableName: secDef.tableName,
        fieldList: secDef.fieldList,
        record: projectMatterMapped,
        isProject: true,
      });
    }
  }, [project]);

  const handleFieldChange = async (changedFieldName, rowData, value) => {
    const changedRowData = { ...rowData, [changedFieldName]: value };

    let newGood = null;
    const goodToAddOrUpdate = {
      id: changedRowData.id,
      class: parseInt(changedRowData.matterGoods_Class),
      goods: changedRowData.matterGoods_Goods ?? "en",
      languageCode: changedRowData.matterGoods_LanguageCode,
      local: changedRowData.matterGoods_Local,
    };

    if (!goodToAddOrUpdate.class) return;

    let goodsLocal = [...project.goods];

    let changedFieldNameBatchCreate = null;
    switch (changedFieldName) {
      case "matterGoods_Class": {
        changedFieldNameBatchCreate = "class";
        refreshBatchSummary(project); // Update the batch summary table to reflect change in goods class
        break;
      }
      case "matterGoods_Goods":
        changedFieldNameBatchCreate = "goods";
        break;
      case "matterGoods_LanguageCode":
        changedFieldNameBatchCreate = "languageCode";
        break;
      case "matterGoods_Local":
        changedFieldNameBatchCreate = "local";
        break;
      default:
        break;
    }

    // We can't mutate the array of goods so we create a new array and modify the matching item in the process
    goodsLocal = goodsLocal.map((good) => {
      if (idsAreEqual(goodToAddOrUpdate.id, good.id)) {
        // Found the matching good, create a new object with the updated field
        const updatedGood = {
          ...good,
          [changedFieldNameBatchCreate]: goodToAddOrUpdate[changedFieldNameBatchCreate],
        };

        // Optionally call update function if needed
        updateBatchGood(
          project.id,
          updatedGood.id,
          changedFieldNameBatchCreate,
          updatedGood[changedFieldNameBatchCreate]
        );
        dispatch(
          updateGoodField({
            goodId: updatedGood.id,
            changedFieldName: changedFieldNameBatchCreate,
            value: updatedGood[changedFieldNameBatchCreate],
          })
        );

        return updatedGood;
      }
      return good; // Return the original good if it's not the one we're looking for
    });

    // Check if the matching item was found and updated, if not, add a new one
    if (!hasMatchingId(goodsLocal, goodToAddOrUpdate.id)) {
      newGood = await createBatchGood(project.id, goodToAddOrUpdate);
      goodsLocal.push(newGood);
      dispatch(addGood(newGood));
    }
    return newGood;
  };

  const handleRecordDelete = async (id) => {
    deleteBatchGood(project.id, id);
    dispatch(deleteGood(id));
  };

  return (
    keyProps !== null && (
      <Panel
        keyProps={keyProps}
        onChange={handleFieldChange}
        onDelete={handleRecordDelete}
        isReadOnly={project?.project_Status === projectStatuses.MATTER_GENERATED}
      />
    )
  );
}

BatchCreateGoods.propTypes = {
  project: PropTypes.object,
};

export default BatchCreateGoods;
