import PropTypes from "prop-types";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";

// A standard header for all popup menus and windows
function PopupHeader({ title, onClose }) {
  // Function to handle the Escape key press
  const handleKeyPress = (event) => {
    if (event.key === "Escape") {
      onClose();
    }
  };

  // Set up the event listener for key press event
  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onClose]); // Dependency array to ensure effect is rerun if onClose changes

  return (
    <div className="popup-header">
      <div className="popup-header__title">{title}</div>
      <div className="popup-header__close-x">
        <div onClick={onClose}>
          <FaTimes />
        </div>
      </div>
    </div>
  );
}

PopupHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default PopupHeader;
