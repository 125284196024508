import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { t } from "locale/dictionary";
import { addSavedSearch, getSavedSearches } from "api/search";
import { setSavedSearchLoadedId, setSavedSearches } from "redux/simpleSearchSlice";
import { compareByName, idsAreEqual } from "utilities/stringAndArray";
import Checkbox from "components/global/Checkbox";
import { useState } from "react";
import { FaSearch, FaStar } from "react-icons/fa";
import { useEffect } from "react";
import SavedSearchesTable from "./SavedSearchesTable";
import { getLookupValueForField } from "utilities/datafield";

// Container Component for the saved searches popup
function SavedSearchesPopup({ inputRef }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const dispatch = useDispatch();

  const [searchToAddName, setSearchToAddName] = useState("");
  const [searchWithinSavedText, setSearchWithinSavedText] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [responseMessageHasError, setResponseMessageHasError] = useState("");
  const [savedSearchesFiltered, setSavedSearchesFiltered] = useState([]);
  const [savedSearchesGrouped, setSavedSearchesGrouped] = useState([]);
  const [isGroupingByMatterType, setIsGroupingByMatterType] = useState(false);
  const [pinnedOrDefaultChanged, setPinnedOrDefaultChanged] = useState(false);
  const [isShowingAllCriteria, setIsShowingAllCriteria] = useState(true);

  let savedSearches = simpleSearch.savedSearches;
  savedSearches = savedSearches?.length > 0 ? [...savedSearches].sort(compareByName) : [];
  const filters = simpleSearch.filters;
  const pinnedSearches = savedSearches
    ? savedSearches.filter(
        (savedSearch) => savedSearch.pinned || idsAreEqual(savedSearch.id, simpleSearch.defaultSearchId)
      )
    : [];
  const unpinnedSearches = savedSearches
    ? savedSearches.filter(
        (savedSearch) => !savedSearch.pinned && !idsAreEqual(savedSearch.id, simpleSearch.defaultSearchId)
      )
    : [];

  // useEffect(() => {
  //   if (bearerToken) getSavedSearches().then((response) => dispatch(setSavedSearches(response)));
  // }, [bearerToken]);

  useEffect(() => {
    let savedSearchesGroupedLocal = [];
    let savedSearchesLocal = savedSearchesFiltered.length > 0 ? savedSearchesFiltered : unpinnedSearches;
    if (isGroupingByMatterType) {
      savedSearchesLocal.forEach((savedSearch) => {
        const matterTypeFilter = savedSearch.filters.find((filter) => filter.fieldName === "matter_MatterTypeId");
        let matterTypeIds = matterTypeFilter ? matterTypeFilter.valueArray : [0];
        matterTypeIds.forEach((matterTypeId) => {
          if (!savedSearchesGroupedLocal[matterTypeId]) {
            savedSearchesGroupedLocal[matterTypeId] = [];
          }
          savedSearchesGroupedLocal[matterTypeId].push(savedSearch);
        });
      });
    }
    setSavedSearchesGrouped(savedSearchesGroupedLocal);
    if (pinnedOrDefaultChanged) setPinnedOrDefaultChanged(false);
  }, [isGroupingByMatterType, pinnedOrDefaultChanged, savedSearchesFiltered]);

  const handleSearchToAddNameChange = (e) => {
    setSearchToAddName(e.target.value);
  };

  const handleAddToSaved = async () => {
    let nameToAdd = searchToAddName;
    const existingWithSameNameLength = savedSearches.filter(
      (savedSearch) =>
        savedSearch.name === searchToAddName ||
        (savedSearch.name.includes(searchToAddName) && savedSearch.name.includes("(") && savedSearch.name.includes(")"))
    ).length;
    if (existingWithSameNameLength > 0) {
      nameToAdd = searchToAddName + `(${existingWithSameNameLength})`;
    }

    const response = await addSavedSearch(nameToAdd, /*savedSearchPinned*/ false, filters);
    if (response.id) {
      setResponseMessage(`${t("Saved favourite search")}: "${nameToAdd}"`);
      let savedSearchesLocal = [...savedSearches];
      savedSearchesLocal.push(response);
      dispatch(setSavedSearches(savedSearchesLocal));
      dispatch(setSavedSearchLoadedId(response.id));
      setSearchToAddName("");
    }
  };

  const handleSearchWithinSavedClick = () => {
    const searchWithinSavedCommittedText = searchWithinSavedText;
    const savedSearchesFilteredLocal = unpinnedSearches.filter((savedSearch) =>
      savedSearch.name.toLowerCase().includes(searchWithinSavedCommittedText.toLowerCase())
    );
    setSavedSearchesFiltered(savedSearchesFilteredLocal);
    let responseMessageLocal = "Filtering results on text: " + searchWithinSavedCommittedText;
    let responseMessageHasErrorLocal = false;
    if (savedSearchesFilteredLocal.length === 0) {
      responseMessageLocal = `No saved searches matching "${searchWithinSavedCommittedText}". Displaying all unfiltered.`;
      responseMessageHasErrorLocal = true;
    }
    setResponseMessage(responseMessageLocal);
    setResponseMessageHasError(responseMessageHasErrorLocal);
  };

  const handleClearSavedWithinSearchClick = () => {
    setSavedSearchesFiltered([]);
    setResponseMessage("Search filter cleared");
  };

  const renderSavedSearchesGrouped =
    savedSearchesGrouped?.length > 0 &&
    savedSearchesGrouped.map((savedSearchGroup) => (
      <SavedSearchesTable
        savedSearchesInTable={savedSearchGroup}
        title={getLookupValueForField("matter_MatterTypeId", savedSearchesGrouped.indexOf(savedSearchGroup))}
        onPinnedOrDefaultChange={() => setPinnedOrDefaultChanged(true)}
        isShowingAllCriteria={isShowingAllCriteria}
      />
    ));

  const renderSavedSearchesUngrouped = (
    <SavedSearchesTable
      savedSearchesInTable={savedSearchesFiltered.length > 0 ? savedSearchesFiltered : unpinnedSearches}
      title="All"
      onPinnedOrDefaultChange={() => setPinnedOrDefaultChanged(true)}
      isShowingAllCriteria={isShowingAllCriteria}
    />
  );

  const responseMessageClassBase = "saved-searches__response-message";
  let responseMessageClass = responseMessageClassBase;
  if (responseMessageHasError) responseMessageClass = `${responseMessageClassBase} ${responseMessageClassBase}--error`;
  const renderBody = (
    <div className="saved-searches__body">
      <div className={responseMessageClass}>
        {responseMessage}
        {savedSearchesFiltered.length > 0 && (
          <>
            &nbsp;
            <span className="link" onClick={handleClearSavedWithinSearchClick}>
              {t("Clear")}
            </span>
          </>
        )}
      </div>
      {pinnedSearches?.length > 0 && (
        <SavedSearchesTable
          savedSearchesInTable={pinnedSearches}
          title="Pinned/Default"
          onPinnedOrDefaultChange={() => setPinnedOrDefaultChanged(true)}
          isShowingAllCriteria={isShowingAllCriteria}
        />
      )}
      {isGroupingByMatterType ? renderSavedSearchesGrouped : renderSavedSearchesUngrouped}
    </div>
  );

  return (
    <div className="saved-searches" ref={inputRef}>
      <div className="saved-searches__header">
        <div className="header__title">{t("Saved Searches")}</div>
        <div className="header__search-panel">
          <input placeholder="Saved Search Name" value={searchToAddName} onChange={handleSearchToAddNameChange} />
          <button onClick={handleAddToSaved}>
            <span className="color-text-yellow">
              <FaStar />
            </span>
            &nbsp;{t("Save Active Search")}
          </button>
        </div>

        <Checkbox
          isChecked={isGroupingByMatterType}
          label="Group by Matter Type"
          onCheckChange={() => setIsGroupingByMatterType(!isGroupingByMatterType)}
          tabIndex={0}
        />
        <Checkbox
          isChecked={isShowingAllCriteria}
          label="Show All Criteria"
          onCheckChange={() => setIsShowingAllCriteria(!isShowingAllCriteria)}
          tabIndex={0}
        />
        <div className="header__search-panel">
          <input
            placeholder="Search within Saved"
            value={searchWithinSavedText}
            onChange={(e) => setSearchWithinSavedText(e.target.value)}
          />
          <button onClick={handleSearchWithinSavedClick}>
            <FaSearch />
            &nbsp;
            {t("Search Saved")}
          </button>
        </div>
      </div>
      {renderBody}
    </div>
  );
}

SavedSearchesPopup.propTypes = {
  inputRef: PropTypes.object,
};

export default SavedSearchesPopup;
