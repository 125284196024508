import React from "react";
import { useSelector } from "react-redux";
import { accessRightFilterAdd, accessRightFilterDelete, accessRightFilterUpdate } from "api/security";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { getAllCountriesAndCodes } from "utilities/countries";
import { superSelectOperationTypes } from "utilities/constants";

export default function CountryAccessPanel() {
  const securityState = useSelector((state) => state.security);

  const currentUserGroup = securityState.currentUserGroup;
  const countryFilterFieldName = "matterCountry_Id";
  const countriesAccessRightFilterValues =
    currentUserGroup?.accessRight?.filters?.find((f) => f.fieldName === countryFilterFieldName)?.valueArray ?? [];

  const handleUpdateSelectedCountries = (optionId, action) => {
    let accessRightFilters = currentUserGroup?.accessRight?.filters;
    let countriesFilter = accessRightFilters?.find((arf) => arf.fieldName === countryFilterFieldName);

    if (action === superSelectOperationTypes.ADD) {
      if (countriesFilter) {
        accessRightFilterUpdate(
          securityState.currentCustomer.id,
          currentUserGroup.accessRight.id,
          countriesFilter.id,
          "valueArray",
          countriesFilter.valueArray,
          [...countriesFilter.valueArray, optionId]
        );
      } else {
        accessRightFilterAdd(securityState.currentCustomer.id, currentUserGroup.accessRight.id, {
          fieldName: countryFilterFieldName,
          operator: "=",
          valueArray: [optionId],
        });
      }
    }

    if (action === superSelectOperationTypes.REMOVE) {
      const newValueArray = countriesFilter.valueArray.filter((va) => va !== optionId.toString());
      if (newValueArray.length === 0) {
        accessRightFilterDelete(securityState.currentCustomer.id, currentUserGroup.accessRight.id, countriesFilter.id);
      } else {
        accessRightFilterUpdate(
          securityState.currentCustomer.id,
          currentUserGroup.accessRight.id,
          countriesFilter.id,
          "valueArray",
          countriesFilter.valueArray,
          newValueArray
        );
      }
    }
  };

  //MAIN RENDER
  return (
    <div className="security__section">
      <div className="security__section-title">{t("Access By Countries")}</div>
      <div className="security__section-body">
        <SuperSelect
          id={"countryAccessRightsSelect"}
          selectedOptionIds={countriesAccessRightFilterValues.map((c) => parseInt(c))}
          isMultiValued={true}
          options={getAllCountriesAndCodes().map((c) => ({ id: c.id, displayValue: c.displayName })) ?? []}
          placeholderText={"Select Countries..."}
          onChangeMulti={handleUpdateSelectedCountries}
        />
      </div>
    </div>
  );
}
