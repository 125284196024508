import React from "react";
import ProjectSection from "../ProjectSection";
import { useSelector } from "react-redux";
import { projectStatuses } from "utilities/constants";
import BatchCreateBuildTemplate from "./BatchCreateBuildTemplate";
import BatchCreateTemplateDetails from "./BatchCreateTemplateDetails";

function BatchCreateMasterRecord() {
  const batchCreateState = useSelector((state) => state.batchCreate);

  const project = batchCreateState?.project;

  return (
    <ProjectSection title="Master Record">
      {project?.project_Status === projectStatuses.CREATED ? (
        <BatchCreateBuildTemplate />
      ) : (
        <BatchCreateTemplateDetails />
      )}
    </ProjectSection>
  );
}

BatchCreateMasterRecord.propTypes = {};

export default BatchCreateMasterRecord;
