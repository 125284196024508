import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { fetchWrapper } from "utilities/fetchWrapper";

// Testing various concepts - not for production use
export default function Test() {
  // const [output, setOutput] = useState("");

  // const getUsers = async () => {
  //   const users = await fetchWrapper.get("users");
  //   setOutput(JSON.stringify(users.data));
  // };

  // const createUser = async () => {
  //   await fetchWrapper
  //     .post("users", {
  //       name: "SLOCOMBE NINE1",
  //       gender: "male",
  //       email: "slocombe91@trashbat.com",
  //       status: "active",
  //     })
  //     .then((data) => setOutput(`Success! ${JSON.stringify(data)}`))
  //     .catch((error) => setOutput(`There was an error! ${JSON.stringify(error)}`));
  // };

  // const updateUser = async () => {
  //   await fetchWrapper
  //     .put("users/2137", {
  //       name: "SLOCOMBE NINE",
  //       gender: "male",
  //       email: "slocombe9@trashbat.com",
  //       status: "active",
  //     })
  //     .then((data) => setOutput(`Success! ${JSON.stringify(data)}`))
  //     .catch((error) => setOutput(`There was an error! ${JSON.stringify(error)}`));
  // };

  const containerRef = useRef(null);
  const childElementsRef = useRef([]);

  const handleKeyPress = (e) => {
    const currentIndex = childElementsRef.current.findIndex((el) => el === document.activeElement);

    if (e.key === "ArrowDown") {
      e.preventDefault();
      const nextIndex = (currentIndex + 1) % childElementsRef.current.length;
      childElementsRef.current[nextIndex].focus();
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      const prevIndex = (currentIndex - 1 + childElementsRef.current.length) % childElementsRef.current.length;
      childElementsRef.current[prevIndex].focus();
    }
  };

  useEffect(() => {
    containerRef.current.addEventListener("keydown", handleKeyPress);
  }, []);

  return (
    <>
      {/* <h2>API Test</h2>
      <button onClick={getUsers}>Get Users</button>
      <button onClick={createUser}>Create User</button>
      <button onClick={updateUser}>Update User</button>
      <p>{output}</p> */}
      <div
        className="container"
        ref={containerRef}
        onClick={() => console.log("Clicked container")}
        onFocus={() => console.log("Container is focused")}
        tabIndex={0}
      >
        <div className="child-element" ref={(el) => (childElementsRef.current[0] = el)} tabIndex={-1}>
          Child Elem 0
        </div>
        <div className="child-element" ref={(el) => (childElementsRef.current[1] = el)} tabIndex={-1}>
          Child Elem 1
        </div>
        <div className="child-element" ref={(el) => (childElementsRef.current[2] = el)} tabIndex={-1}>
          Child Elem 2
        </div>
      </div>
    </>
  );
}
