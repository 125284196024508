import { setAllMatterTypes } from "redux/appSlice";
import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";

export async function loadAllMatterTypes() {
  const state = store.getState();
  if (state.app.allMatterTypes.length > 0) return;
  const response = await fetchWrapper.get("matterTypes");
  store.dispatch(setAllMatterTypes(response));
}
