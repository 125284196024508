import { t } from "locale/dictionary";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import useComponentVisible from "components/global/useComponentVisible";
import { useSelector } from "react-redux";

// Container for the settings menu/popupin the site header's sys tray
export default function SettingsPopup() {
  const appState = useSelector((state) => state.app);
  const [refPopup, isPopupVisible, setIsPopupVisible] = useComponentVisible(false);

  return (
    <div className="header__settings-dropdown" onClick={() => setIsPopupVisible(!isPopupVisible)} ref={refPopup}>
      <FaBars />
      {isPopupVisible && (
        <div className="settings-dropdown__popup">
          <p>{t("Settings")}</p>
          <p>
            <Link to="/configuration">{t("Configuration")}</Link>
          </p>

          <p>{t("Automate")}</p>
          <p>{t("Analytics")}</p>
          {appState.isCustAdmin && (
            <p>
              <Link to={`/customers/${appState.customerId}`}>{t("User Administration")}</Link>
            </p>
          )}
          <p>
            <Link to="/batch-import">{t("Batch Import")}</Link>
          </p>
          <p>
            <Link to="/reportmenu">{t("Report Editor")}</Link>
          </p>
          <p>
            <Link to="/imagestore">{t("Image Store")}</Link>
          </p>
        </div>
      )}
    </div>
  );
}
