import PopupHeader from "components/global/PopupHeader";
import Tooltip from "components/global/Tooltip";
import useComponentVisible from "components/global/useComponentVisible";
import { t } from "locale/dictionary";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaCopy } from "react-icons/fa";
import { MdShare } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setIsShowingFullScreenModal } from "redux/appSlice";
import { severities } from "utilities/constants";
import { displayToast } from "utilities/toast";

export default function CreateRecordShareLink({ recordId }) {
  const [refLinkPopup, showLinkPopup, setShowLinkPopup] = useComponentVisible(false);
  const [currentLink, setCurrentLink] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (recordId !== undefined) {
      let root = window.location.href;
      root = root.slice(0, root.lastIndexOf("/"));
      setCurrentLink(root + "/" + recordId);
    }
  }, [recordId]);

  useEffect(() => {
    dispatch(setIsShowingFullScreenModal(showLinkPopup));
  }, [showLinkPopup]);

  const openLinkPopup = () => {
    setShowLinkPopup(true);
    navigator.clipboard.writeText(currentLink);
    displayToast("Link copied to clipboard", severities.INFO);
  };

  return (
    <>
      <div className="record-header__action-icon" onClick={() => openLinkPopup()}>
        <Tooltip content="Share">
          <MdShare />
        </Tooltip>
      </div>
      {showLinkPopup ? (
        <div className="modal-mask">
          <div ref={refLinkPopup} className="link-copy-modal__popup">
            <PopupHeader title={t("Copy Link")} onClose={() => setShowLinkPopup(false)} />
            <div className="link-copy-modal__body">
              <div className="link-copy-modal__row">{t("Link copied to clipboard:")}</div>
              <div className="link-copy-modal__row">
                {" "}
                <input
                  autoFocus
                  onFocus={(e) => e.target.select()}
                  value={currentLink}
                  onChange={() => {}}
                  type="text"
                ></input>
                <Tooltip content="Copy Link">
                  <button onClick={() => navigator.clipboard.writeText(currentLink)}>
                    <FaCopy />
                  </button>
                </Tooltip>
              </div>
              <div className="link-copy-modal__row">
                <button onClick={() => setShowLinkPopup(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
