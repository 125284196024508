import { headerCellTypes, legalTypes, linkTypes, matterTypes, referenceTypes } from "utilities/constants";
/////////////////////////
// HEADERS BY MATTER TYPE
/////////////////////////

export const headerDefinitions = [
  // COPYRIGHT
  {
    matterTypeId: matterTypes.COPYRIGHTS,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // DESIGN
  {
    matterTypeId: matterTypes.DESIGNS,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.AGENT },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // DOMAIN NAME
  {
    matterTypeId: matterTypes.DOMAINNAME,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.SERVICE_PROVIDER },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // PATENT
  {
    matterTypeId: matterTypes.PATENT,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      // Patent Category?
      // Patent Type?
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // SOCIAL MEDIA
  {
    matterTypeId: matterTypes.SOCIALMEDIA,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // TRADEMARK
  {
    matterTypeId: matterTypes.TRADEMARK,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      {
        cellType: headerCellTypes.TABLE_FIELD_LIST,
        tableName: "goods",
        fieldName: "matterGoods_Class",
        scrollToSection: "Classes and goods/services",
      },
      { cellType: headerCellTypes.SINGLE_FIELD, fieldName: "matter_ApplicationNo" },
      { cellType: headerCellTypes.SINGLE_FIELD, fieldName: "matter_RegistrationNo" },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.AGENT },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.CLIENT },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // CUSTOMS RECORDALS
  {
    matterTypeId: matterTypes.CUSTOMSRECORDALS,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // GENERAL MATTER
  {
    matterTypeId: matterTypes.GENERALMATTER,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.AGENT },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // OTHER PARTY REFERENCE
  {
    matterTypeId: matterTypes.OTHERPARTYREFERENCE,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // ASSIGNMENTS AND RECORDALS
  {
    matterTypeId: matterTypes.ASSIGNMENTANDRECORDALS,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // BRAND PROTECTION
  {
    matterTypeId: matterTypes.BRANDPROTECTION,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.SERVICE_PROVIDER },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // CONTRACT
  {
    matterTypeId: matterTypes.CONTRACTS,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OTHER_PARTY },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // CLEARANCE SEARCH
  {
    matterTypeId: matterTypes.CLEARANCESEARCHES,
    hasImage: true,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.CLIENT },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.SERVICE_PROVIDER },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },

  // DISPUTE
  {
    matterTypeId: matterTypes.DISPUTE,
    cellDefinitions: [
      { cellType: headerCellTypes.COUNTRIES },
      { cellType: headerCellTypes.TYPE_AND_SUBTYPE },
      { cellType: headerCellTypes.STATUS_AND_SUBSTATUS },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.FILE },
      { cellType: headerCellTypes.REFERENCE, referenceTypeId: referenceTypes.CASE },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OWNER },
      { cellType: headerCellTypes.LINK, linkTypeId: linkTypes.OTHER_PARTY },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.ATTORNEY,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.SUPERVISOR,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.PARALEGAL,
      },
      {
        cellType: headerCellTypes.LEGAL_TEAM,
        legalTypeId: legalTypes.MANAGING_PARTNER,
      },
      { cellType: headerCellTypes.NEXT_ACTION },
    ],
  },
];
