import PropTypes from "prop-types";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import noImagePlaceholder from "images/330px-No-Image-Placeholder.png";
import { useEffect, useState } from "react";
import { getImageData } from "api/image";
import FileUploader from "components/global/FileUploader";
import { t } from "locale/dictionary";
import { compareByMatterImageSortIndex } from "utilities/stringAndArray";

// Container Component for the image thumbnail that occurs in some matter headers
function MatterRecordHeaderThumbnail({ record }) {
  const [base64data, setBase64Data] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imagesSorted, setImagesSorted] = useState(null);

  const imagesLength = record?.images && record.images.length;

  const refreshCurrentImage = () => {
    if (imagesSorted && selectedImageIndex !== null) {
      const runGetImageData = async () => {
        const selectedImage = imagesSorted[selectedImageIndex];
        if (selectedImage) {
          const data = await getImageData(selectedImage.id, selectedImage.matterId);
          setBase64Data(data);
        }
      };
      runGetImageData();
    }
  };

  useEffect(() => {
    let imagesTemp = [...record.images];
    const imagesSortedTemp = imagesTemp.sort(compareByMatterImageSortIndex);

    let imageIndex = imagesSortedTemp.findIndex((image) => image.matterImage_Primary === true);
    if (imageIndex === -1) imageIndex = 0;
    setSelectedImageIndex(imageIndex);
    setImagesSorted(imagesSortedTemp);
  }, [record]);

  useEffect(() => {
    refreshCurrentImage();
  }, [selectedImageIndex, imagesSorted]);

  const onChangeSelectedIndex = (increment) => {
    if (selectedImageIndex === 0 && increment === -1) setSelectedImageIndex(imagesLength - 1);
    else if (selectedImageIndex === imagesLength - 1 && increment === 1) setSelectedImageIndex(0);
    else setSelectedImageIndex(selectedImageIndex + increment);
  };

  return (
    <div className="thumbnail thumbnail--header">
      <FileUploader record={record} isImage={true} isHeaderControl={true}>
        <>
          <div className="thumbnail__body">
            {imagesLength > 0 && selectedImageIndex !== null ? (
              <img
                src={base64data}
                alt={imagesSorted[selectedImageIndex] && imagesSorted[selectedImageIndex].matterImage_Filename}
              />
            ) : (
              <div className="flex-column-center">
                <img src={noImagePlaceholder} alt="no-placeholder" />
                <div className="no-image-text">{t("drag and drop image here")}</div>
              </div>
            )}
            <div className="body__overlay body__overlay--header">
              <div className="overlay__items">
                <div className="overlay__item" onClick={() => onChangeSelectedIndex(-1)}>
                  <FaChevronLeft />
                </div>
                <div className="overlay__item" onClick={() => onChangeSelectedIndex(1)}>
                  <FaChevronRight />
                </div>
              </div>
            </div>
          </div>
          {imagesLength > 0 && (
            <div className="thumbnail__header-pagination">{`${selectedImageIndex + 1} of ${imagesLength}`}</div>
          )}
        </>
      </FileUploader>
    </div>
  );
}

MatterRecordHeaderThumbnail.propTypes = {
  record: PropTypes.object,
};

export default MatterRecordHeaderThumbnail;
