import { setBatchCreateProject } from "redux/batchCreateSlice";
import { setBatchImportProject } from "redux/batchImportSlice";
import {
  addLegalTeamContactLink,
  deleteLegalTeamContactLink,
  deleteProject,
  setDraftProjects,
  setProject,
  updateLegalTeamContactLink,
} from "redux/projectSlice";
import store from "redux/store";
import { projectTypes } from "utilities/constants";
import { fetchWrapper } from "utilities/fetchWrapper";
import { idsAreEqual } from "utilities/stringAndArray";

// PROJECT
export async function createBatchProject(accountId, matterTypeId, projectName, projectTypeId, importSourceTypeId) {
  const state = store.getState();
  const body = {
    accountId,
    project_MatterTypeId: matterTypeId,
    project_ProjectName: projectName,
    project_ProjectTypeId: projectTypeId,
    project_ImportSourceTypeId: importSourceTypeId,
  };
  const response = await fetchWrapper.post(`customers/${state.app.customerId}/projects`, body);
  idsAreEqual(projectTypeId, projectTypes.BATCH_CREATE)
    ? store.dispatch(setBatchCreateProject(response))
    : store.dispatch(setBatchImportProject(response));
  store.dispatch(setProject(response));
  return response;
}

export async function getBatchProjects() {
  const state = store.getState();
  const response = await fetchWrapper.get(`customers/${state.app.customerId}/projects`);
  store.dispatch(setDraftProjects(response));
}

export async function getBatchProject(projectId) {
  const state = store.getState();
  const response = await fetchWrapper.get(`customers/${state.app.customerId}/projects/${projectId}`);
  return response;
}

export async function updateBatchProject(projectId, fieldName, value) {
  const state = store.getState();
  const body = [
    //  { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  const response = await fetchWrapper.patch(`customers/${state.app.customerId}/projects/${projectId}`, body);
  return response;
}

export async function deleteBatchProject(projectId) {
  const state = store.getState();
  await fetchWrapper.delete(`customers/${state.app.customerId}/projects/${projectId}`);
  store.dispatch(deleteProject(projectId));
}

// LEGAL TEAM CONTACTS

export async function getProjectLegalTeamContacts(projectId) {
  const state = store.getState();
  const response = await fetchWrapper.get(
    `customers/${state.app.customerId}/projects/${projectId}/legalteamcontactlinks`
  );
  return response;
}

export async function createProjectLegalTeamContact(projectId, batchLegalTeamContact) {
  const state = store.getState();
  const body = { ...batchLegalTeamContact };
  const response = await fetchWrapper.post(
    `customers/${state.app.customerId}/projects/${projectId}/legalteamcontactlinks`,
    body
  );
  store.dispatch(addLegalTeamContactLink(response));
  return response;
}

export async function updateProjectLegalTeamContact(projectId, legalTeamContactLinkId, fieldName, value) {
  const state = store.getState();
  const body = [
    //  { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  const response = await fetchWrapper.patch(
    `customers/${state.app.customerId}/projects/${projectId}/legalteamcontactlinks/${legalTeamContactLinkId}`,
    body
  );
  store.dispatch(
    updateLegalTeamContactLink({ legalTeamContactId: legalTeamContactLinkId, changedFieldName: fieldName, value })
  );
  return response;
}

export async function deleteProjectLegalTeamContact(projectId, legalTeamContactLinkId) {
  const state = store.getState();
  const response = await fetchWrapper.delete(
    `customers/${state.app.customerId}/projects/${projectId}/legalteamcontactlinks/${legalTeamContactLinkId}`
  );
  store.dispatch(deleteLegalTeamContactLink(legalTeamContactLinkId));
  return response;
}

// GENERATE MATTER

export async function generateMatter(projectId) {
  const state = store.getState();
  const response = await fetchWrapper.post(`customers/${state.app.customerId}/projects/${projectId}/generatematter`);
  return response;
}
