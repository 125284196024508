import { t } from "locale/dictionary";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadBookmarks } from "api/customer";
import DataTable from "./DataTable";

// Displays clickable list of Bookmarked Records
export default function Bookmarks() {
  const bearerToken = useSelector((state) => state.app.bearerToken);
  const customerState = useSelector((state) => state.customer);

  const bookmarks = customerState?.bookmarks;

  useEffect(() => {
    if (bearerToken) loadBookmarks();
  }, [bearerToken]);

  return <DataTable fields={["matterId", "matter_Name", "matter_MatterTypeId"]} data={bookmarks} />;
}
