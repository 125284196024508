import { t } from "locale/dictionary";
import { FaBriefcase, FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setIsShowingOverview } from "redux/matterSlice";
import { validationStates } from "utilities/constants";
import { getDateDisplayValue } from "utilities/dateTime";
import { navigateToSection } from "utilities/dom";

// Container Component for the matter record overview block top right
export default function MatterRecordOverview() {
  const matterState = useSelector((state) => state.matter);
  const localeState = useSelector((state) => state.locale);

  const dispatch = useDispatch();

  const record = matterState.records[matterState.selectedRecordIndex];

  const renderSectionList =
    record &&
    record.sections &&
    record.sections.map((section) => {
      let message = "";
      const classSectionBase = "overview__section";
      let classModifier = "";
      let fieldErrorStringArray = [];
      if (section.fields) {
        section.fields.forEach((field) => {
          field.validationStates.forEach((vs) => {
            if (vs.state === validationStates.ERROR) {
              const errorString = localeState.translations[vs.string];
              fieldErrorStringArray.push(errorString);
            }
          });
        });

        message = fieldErrorStringArray.join(". ");
        if (message.length > 0) classModifier = "--error";
        // } else if (
        //   section.fields.every(
        //     (field) =>
        //       field.validationState === validationStates.EMPTY_OR_NULL ||
        //       field.validationState === validationStates.UNKNOWN
        //   )
        // ) {
        //   message = t("You haven't filled out any fields in this section yet");
        //   classModifier = "--warning";
      }

      return (
        <div
          key={section.sectionName}
          className={`${classSectionBase} ${classSectionBase}${classModifier}`}
          onClick={() => navigateToSection(section.sectionName)}
        >
          <div className="overview__section-title">{t(section.sectionName)}</div>
          {/* <div className="overview__section-message">{message}</div> */}
        </div>
      );
    });

  const renderOverviewCollapsed = (
    <div className="record__overview-panel record__overview-panel--collapsed">
      <div className="overview-panel__expander" onClick={() => dispatch(setIsShowingOverview(true))}>
        <FaChevronCircleRight />
      </div>
    </div>
  );

  const renderOverviewExpanded = (
    <div className="record__overview-panel">
      <div className="overview-panel__expander" onClick={() => dispatch(setIsShowingOverview(false))}>
        <FaChevronCircleLeft />
      </div>
      <div className="overview-panel__title">
        <span className="overview-panel__icon">
          <FaBriefcase />
        </span>
        {t("Your Record Overview")}
      </div>
      {record?.matter_Name?.slice(0, 4) === "Copy" ? (
        <div className="overview__copy-flag">
          <span className="overview__copy-flag-text">COPY</span>
        </div>
      ) : null}

      {record && (
        <div className="overview__summary-block">
          <div className="overview__summary-label">{t("ID")}:</div>
          <div className="overview__summary-data">{record.id}</div>
          <div className="overview__summary-label">{t("Author")}:</div>
          <div className="overview__summary-data">{record.createdFullName ?? t("Unknown")}</div>
          <div className="overview__summary-label">{t("Created")}:</div>
          <div className="overview__summary-data">
            {record.createdUtcDate ? getDateDisplayValue(record.createdUtcDate, true) : t("Unknown")}
          </div>
          <div className="overview__summary-label">{t("Last edited by")}:</div>
          <div className="overview__summary-data">{record.lastUpdatedFullName ?? t("Unknown")}</div>
          <div className="overview__summary-label">{t("Last Updated")}:</div>
          <div className="overview__summary-data">
            {record.lastUpdatedUtcDate ? getDateDisplayValue(record.lastUpdatedUtcDate, true) : t("Unknown")}
          </div>
          <div className="overview__summary-label">{t("Last synced")}:</div>
          <div className="overview__summary-data">TODO</div>
        </div>
      )}
      <div className="overview__section-list">{renderSectionList}</div>
    </div>
  );

  return record ? matterState.isShowingOverview ? renderOverviewExpanded : renderOverviewCollapsed : <div>&nbsp;</div>;
}
