import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getReportById, saveReportFieldChanges, saveReportFormat } from "api/report";
import { addRecord } from "redux/reportSlice";
import { FormatTypes, superSelectOperationTypes } from "utilities/constants";
import { getAllLookupValuesForSource } from "utilities/lookup";
import SuperSelect from "components/global/SuperSelect";
//import { getFormatTypes } from "utilities/reportEditor";
import { t } from "locale/dictionary";
import RadioHorizontal from "components/global/RadioHorizontal";
import Checkbox from "components/global/Checkbox";
import FieldList from "./field-list/FieldList";
import { useDispatch, useSelector } from "react-redux";
import FormatList from "./format-list/FormatList";
import { filterOutOnId } from "utilities/stringAndArray";

function ReportEditorBasic() {
  const appState = useSelector((state) => state.app);
  const reportState = useSelector((state) => state.report);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [reportId, setReportId] = useState(null);
  const queryTypes = getAllLookupValuesForSource("QueryTypes");
  const matterTypes = getAllLookupValuesForSource("MatterTypes");

  const [queryObject, setQueryObject] = useState(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [queryTypeId, setQueryTypeId] = useState(1);
  const [matterTypeIds, setMatterTypeIds] = useState(null);

  const [headerOptionId, setHeaderOptionId] = useState(1);
  const [formatTypeId, setFormatTypeId] = useState(FormatTypes.GRID);
  //const [formatTypes] = useState(getFormatTypes());
  const [footerRecordCount, setFooterRecordCount] = useState(false);
  const [footerCriteria, setFooterCriteria] = useState(false);
  const [footerRunDate, setFooterRunDate] = useState(false);
  const [footerRunBy, setFooterRunBy] = useState(false);

  const [customHeaderText, setCustomHeaderText] = useState("");
  const [validationError, setValidationError] = useState(null);

  const initialName = "New Report";
  const initialDescription = "New Report";

  const { param } = useParams();

  const isDraft = true;

  useEffect(() => {
    const loadReport = async () => {
      const report = await getReportById(param);
      dispatch(addRecord({ report, selected: true, replace: true }));
    };

    if (appState.customerId /*&& matterState.requiredMatterData*/) {
      loadReport();
    }
  }, [param, appState.customerId, /*matterState.requiredMatterData,*/ dispatch]);

  useEffect(() => {
    const report = reportState.records[reportState.selectedRecordIndex];
    if (report) {
      setReportId(report.id);
      setName(report.name);
      setDescription(report.description);
      setQueryTypeId(report.queryTypeId);
      setMatterTypeIds(report.matterTypeIds);
      setFormatTypeId(report.formatTypeId);
      setQueryObject(report.queryObject);
    }
  }, [reportState]);

  // const handleCancel = async () => {
  //   onClose();
  //   deleteReport(reportId);
  //   // TODO: Return user to previous screen
  // };

  const handleClose = async () => {
    //onClose();
    //navigate(`/reportmenu/reporteditor/${reportId}`, { replace: true });
    navigate("/reportmenu/");
  };

  const handleChangeBasicProperty = (fieldName, valueIn) => {
    let value = valueIn;
    // Copy the new user's report name to the description if the description is still the default
    if (fieldName === "name") {
      const report = reportState.records[reportState.selectedRecordIndex];
      setValidationError(
        reportState.records.find((r) => r.name === value && r.id !== report.id) ? "Report name already exists" : ""
      );

      if (description === initialDescription) {
        saveReportFieldChanges("description", value);
        setDescription(value);
      }
    } else if (fieldName === "queryTypeId") {
      setQueryTypeId(value);
    }

    if (value.length === 0) {
      if (fieldName === "name") {
        value = initialName;
        setName(value);
        setDescription(value);
      }
      if (fieldName === "description") {
        value = initialDescription;
        setDescription(value);
      }
    }
    saveReportFieldChanges(fieldName, value);
  };

  const handleFieldFocus = (fieldName) => {
    if (fieldName === "name" && name === initialName) {
      setName("");
    }
    // if (fieldName === "description" && description === initialDescription) {
    //   setDescription("");
    // }
  };

  const handleSaveQueryObject = () => {
    saveReportFormat();
  };

  const handleChangeMultiMatter = (optionId, op) => {
    let optionIds = matterTypeIds ? [...matterTypeIds] : [];
    if (op === superSelectOperationTypes.ADD) {
      optionIds.push(optionId);
    } else {
      optionIds = filterOutOnId(optionIds, optionId);
    }
    setMatterTypeIds(optionIds);
    handleChangeBasicProperty("matterTypeIds", optionIds);
  };

  const handleHeaderOptionChange = (id) => {
    setHeaderOptionId(id);
  };

  const classNameBase = "section-body__field";
  const className = validationError?.length > 0 ? `${classNameBase} ${classNameBase}--error` : classNameBase;

  const renderBasics = (
    <>
      <div className="report-menu__section-header">
        <div className="header__title">{t("Basic Details")}</div>
      </div>
      <div className="report-menu__section-body">
        <div className="section-body__row">
          <div className={className}>
            <div className="field__display-name">{t("Name")}:</div>
            <div className="field__display-value">
              <input
                id="report-name"
                type="text"
                value={name}
                onFocus={() => handleFieldFocus("name")}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => handleChangeBasicProperty("name", name)}
                tabIndex="0"
              />
              {validationError?.length > 0 && <div className="field__error">{validationError}</div>}
            </div>
          </div>
          <div className="section-body__field">
            <div className="field__display-name">{t("Description")}:</div>
            <div className="field__display-value">
              <input
                id="report-description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() => handleChangeBasicProperty("description", description)}
              />
            </div>
          </div>
        </div>

        <div className="section-body__row">
          <div className="section-body__field">
            <div className="field__display-name">{t("Data type")}:</div>
            <div className="field__display-value">
              {queryTypes && (
                <SuperSelect
                  id="queryType"
                  options={queryTypes}
                  selectedOptionId={queryTypeId}
                  onChange={(optionId) => handleChangeBasicProperty("queryTypeId", optionId)}
                  isMultiValued={false}
                />
              )}
            </div>
          </div>
          <div className="section-body__field">
            <div className="field__display-name">{t("Matter type")}:</div>
            <div className="field__display-value">
              {matterTypes && (
                <SuperSelect
                  id="matterType"
                  options={matterTypes}
                  selectedOptionIds={matterTypeIds}
                  onChangeMulti={handleChangeMultiMatter}
                  isMultiValued={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const renderHeaders = (
    <>
      <div className="report-menu__section-header">
        <div className="header__title">{t("Header")}</div>
      </div>
      <div className="report-menu__section-body">
        <div className="section-body__row">
          <RadioHorizontal
            groupName="header-options"
            onRadioChange={(id) => handleHeaderOptionChange(id)}
            optionsArray={[
              { id: 1, displayValue: "Report Name" },
              // { id: 2, displayValue: "Header from Branding" },
              { id: 3, displayValue: "Custom Header" },
            ]}
            defaultSelected={headerOptionId}
          />
        </div>
        {headerOptionId === 3 && (
          <div className="section-body__row">
            <div className="section-body__field" style={{ marginLeft: "24rem" }}>
              <div className="field__display-name">{t("Custom Header Text")}:</div>
              <div className="field__display-value">
                <input
                  id="report-custom-header"
                  type="text"
                  value={customHeaderText}
                  onChange={(e) => setCustomHeaderText(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );

  const renderFooters = (
    <>
      <div className="report-menu__section-header">
        <div className="header__title">{t("Footer")}</div>
      </div>
      <div className="report-menu__section-body">
        <div className="section-body__row">
          <div className="section-body__field section-body__field--transparent">
            <Checkbox
              isChecked={footerRecordCount}
              label={t("Record Count")}
              onCheckChange={() => setFooterRecordCount(!footerRecordCount)}
            />
          </div>
          <div className="section-body__field section-body__field--transparent">
            <Checkbox
              isChecked={footerCriteria}
              label={t("Criteria")}
              onCheckChange={() => setFooterCriteria(!footerCriteria)}
            />
          </div>
          <div className="section-body__field section-body__field--transparent">
            <Checkbox
              isChecked={footerRunDate}
              label={t("Run Date")}
              onCheckChange={() => setFooterRunDate(!footerRunDate)}
            />
          </div>
          <div className="section-body__field section-body__field--transparent">
            <Checkbox isChecked={footerRunBy} label={t("Run By")} onCheckChange={() => setFooterRunBy(!footerRunBy)} />
          </div>
        </div>
      </div>
    </>
  );

  const renderLayout = () => {
    return (
      <>
        <div className="report-menu__section-header">
          <div className="header__title">{t("Field Layout")}</div>
        </div>
        <div className="report-menu__section-body">
          <div className="section-body__row">
            <em>Drag fields from the left hand panel onto the right hand grid</em>
          </div>
          <div className="section-body__row">
            {queryObject && (
              <>
                <FieldList format={queryObject} matterTypeIds={matterTypeIds} />
                <FormatList format={queryObject} matterTypeIds={matterTypeIds} />
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="modal-mask">
      <div className="basic-report__content">
        <div className="basic-report__title">{t("Create New Report Template")}</div>
        {isDraft && (
          <div className="report__draft-warning report__draft-warning--basic">
            {t("THIS REPORT IS A DRAFT. In order to run this report you will need to publish it.")}
          </div>
        )}
        {renderBasics}
        {renderHeaders}
        {renderFooters}
        {renderLayout()}
        <div className="flex-row-center">
          {/* {name.length > 0 && queryTypeId ? (
            <button onClick={handleClose}>{t("Create")}</button>
          ) : (
            <button className="disabled">{t("Create")}</button>
          )} */}
          <button onClick={() => navigate(`/reportmenu/reporteditor/${param}`)}>{t("Switch to Advanced View")}</button>
          <button onClick={handleClose}>{t("Close")}</button>
        </div>
      </div>
    </div>
  );
}

ReportEditorBasic.propTypes = {
  //onCancel: PropTypes.func,
};

export default ReportEditorBasic;
