import store from "redux/store";
import { findById, idsAreEqual, hasUniqueElement, hasCommonElement } from "./stringAndArray";

export function getLookupValueBySource(dataSource, value) {
  if (value === null) return "";

  if (Array.isArray(value)) {
    const outputArray = value.map((item) => getLookupValueBySourceSingle(dataSource, item));
    return outputArray.join(", ");
  }

  return getLookupValueBySourceSingle(dataSource, value);
}

function getLookupValueBySourceSingle(dataSource, value) {
  try {
    const state = store.getState();

    const lookupSource = state.lookup.global.find((lookup) => lookup.name === dataSource);
    if (!lookupSource) {
      return value;
    }
    const lookupRow = findById(lookupSource.lookup, value);
    return state.locale.translations[lookupRow.translationCode];
  } catch {
    return value;
  }
}

export function getAllLookupValuesForSource(dataSource) {
  try {
    const state = store.getState();
    const lookupSource = state.lookup.global.find((lookup) => lookup.name === dataSource);
    if (!lookupSource) {
      return null;
    }
    return lookupSource.lookup.map((lookupRow) => {
      return { ...lookupRow, displayValue: state.locale.translations[lookupRow.translationCode] };
    });
  } catch {
    return null;
  }
}

export function getAllListTypes() {
  try {
    const state = store.getState();
    const lookupSource = state.lookup.global.find((lookup) => lookup.name === "ListTypes");
    return lookupSource.lookup;
  } catch {
    return "N/A";
  }
}

export function getAllReferenceTypes() {
  try {
    const state = store.getState();
    const lookupSource = state.lookup.global.find((lookup) => lookup.name === "ReferenceTypes");
    return lookupSource.lookup;
  } catch {
    return "N/A";
  }
}

export function getListTypeByCode(listTypeCode) {
  try {
    const state = store.getState();
    const lookupSource = state.lookup.global.find((lookup) => lookup.name === "ListTypes");
    const lookupRow = lookupSource.lookup.find((lookup) => lookup.code === listTypeCode);
    const displayName = state.locale.translations[lookupRow.translationCode];
    if (!displayName) console.log("Could not find translation for " + lookupRow.translationCode);
    const id = lookupRow.id;
    const isMultiValued = lookupRow.multivalued;
    return { displayName, id, isMultiValued };
  } catch {
    return "N/A";
  }
}

export function getListDisplayValue(listTypeId, listValueId) {
  try {
    const state = store.getState();

    const lookupSource = state.lookup.global.find((lookup) => lookup.name === "ListValues");
    const lookupRow = lookupSource.lookup.find(
      (lookup) => idsAreEqual(lookup.listTypeId, listTypeId) && idsAreEqual(lookup.id, listValueId)
    );
    return state.locale.translations[lookupRow.translationCode];
  } catch {
    return "N/A";
  }
}

export function getAllListDisplayValues(listTypeId) {
  try {
    const state = store.getState();

    const lookupSource = state.lookup.global.find((lookup) => lookup.name === "ListValues");
    // Only get lookup values that match with the provided listTypeId
    let lookupValues = lookupSource.lookup.filter((lookup) => idsAreEqual(lookup.listTypeId, listTypeId));
    // Now map to format useful for dropdownlist
    lookupValues = lookupValues.map((lookup) => {
      return {
        id: lookup.id,
        displayValue: state.locale.translations.hasOwnProperty(lookup.translationCode)
          ? state.locale.translations[lookup.translationCode]
          : lookup.translationCode,
      };
    });
    return lookupValues;
  } catch {
    return "N/A";
  }
}

export function getFieldsExcludedByMatterTypes(matterTypeIds) {
  const state = store.getState();
  const matterDataFields = state.lookup.global.find((lookup) => lookup.name === "MatterDataFields");
  const excluded = matterDataFields?.lookup?.filter(
    (mdf) =>
      (mdf.defaultVisible === false && hasUniqueElement(matterTypeIds, mdf.exceptions)) ||
      (mdf.defaultVisible === true && hasCommonElement(matterTypeIds, mdf.exceptions))
  );
  return excluded;
}

export function getReferenceDisplayNameFromTypeId(referenceTypeId) {
  try {
    const state = store.getState();

    const lookupSource = state.lookup.global.find((lookup) => lookup.name === "ReferenceTypes");
    const lookupRow = findById(lookupSource.lookup, referenceTypeId);
    return state.locale.translations[lookupRow.translationCode];
  } catch {
    return "N/A";
  }
}
