import store from "redux/store";
import { getDateDisplayValue } from "utilities/dateTime";
import { getTranslation, setBorderStyle } from "utilities/reportShared";
import { getImageData } from "api/image";

export function processCellDataType(dataType) {
  // Input dataType [base data type][multiple data]
  // Output array [base type, multiple]
  if (!dataType || dataType.length === 0) {
    return [null, null];
  } else if (dataType.length === 1) {
    return [dataType.charAt(0), null];
  } else {
    return [dataType.charAt(0), dataType.charAt(1)];
  }
}

export function getCellText(output, cell) {
  const [baseType, multiple] = processCellDataType(cell.props?.type);

  if (baseType && baseType !== "x" && cell?.value !== null) {
    const values = multiple ? output.multiValues[cell.value] : [cell.value];

    switch (baseType) {
      case "d": // date
        return values.map((value) => getDateDisplayValue(output.values[value])).join(", ");
      case "t": // string - translate
        return values.map((value) => getTranslation(output.values[value])).join(", ");
      default:
        return values.map((value) => output.values[value]).join(", ");
    }
  }
}

export async function getCellImage(output, row, cell) {
  const [baseType, multiple] = processCellDataType(cell.props?.type);

  if (baseType !== "x" && cell?.value === null) {
    return null;
  }

  const values = multiple ? output.multiValues[cell.value] : [cell.value];

  return values.map(async (value) => {
    const image = output.images[value];
    const colSpan = cell.props?.colSpan ?? 1;
    const cellWidth =
      (output.columns
        .filter((col) => col.columnId >= cell.col && col.columnId <= cell.col + colSpan - 1)
        .map((col) => col.width)
        .reduce((total, colWidth) => total + colWidth, 0) -
        4) *
      0.75;

    let rowHeight = null;
    if (row.height) {
      // Need to take into account col spanning
      rowHeight = row.height;
    }

    let imageWidth = 0;

    // Maintain the original ratio
    if (!rowHeight) {
      if (image.width <= cellWidth) {
        imageWidth = image.width;
      } else {
        imageWidth = cellWidth;
      }
    } else if (image.width <= cellWidth && image.height <= rowHeight) {
      imageWidth = image.width;
    } else if (image.width / cellWidth > image.height / rowHeight) {
      imageWidth = image.width * (cellWidth / image.width);
    } else {
      imageWidth = image.width * (rowHeight / image.height);
    }

    if (image?.base64Data !== null) {
      return (
        <img
          key={value}
          style={{
            height: "auto",
            width: imageWidth / 0.75,
          }}
          src={`data:image;base64,${image.base64Data}`}
          alt=""
        />
      );
    } else if (image.id !== null) {
      const data = await getImageData(image.id, row.matterId);

      return (
        <img
          key={value}
          style={{
            height: "auto",
            width: imageWidth / 0.75,
          }}
          src={data}
          alt=""
        />
      );
    }
  });
}

export function setOutputStyle(output, style, formatId, type = "cell", cellPosition = null) {
  if (formatId === null) {
    return style;
  }

  const format = output.styles.formats[formatId];

  setOutputAlignmentStyle(output, style, format.alignmentId);
  setOutputBorderStyle(output, style, format.borderId, type, cellPosition);
  setOutputFillStyle(output, style, format.fillId);
  setOutputFontStyle(output, style, format.fontId);

  return style;
}

function setOutputAlignmentStyle(output, style, alignmentId) {
  if (alignmentId === null) {
    return;
  }

  const alignment = output.styles.alignments[alignmentId];

  if (!style.textAlign && alignment.textAlignment) {
    style.textAlign = alignment.textAlignment;
  }

  if (!style.verticalAlign && alignment.verticalAlignment) {
    style.verticalAlign = alignment.verticalAlignment;
  }
}

function setOutputBorderStyle(output, style, borderId, type, cellPosition) {
  if (borderId === null) {
    return;
  }

  const border = output.styles.borders[borderId];

  switch (type) {
    case "cell":
      setBorderStyle(style, border?.border, "border");
      setBorderStyle(style, border?.topBorder, "borderTop");
      setBorderStyle(style, border?.bottomBorder, "borderBottom");
      setBorderStyle(style, border?.leftBorder, "borderLeft");
      setBorderStyle(style, border?.rightBorder, "borderRight");
      break;
    case "column":
    case "row":
      setBorderStyle(style, border?.border, "borderTop");
      setBorderStyle(style, border?.topBorder, "borderTop");
      setBorderStyle(style, border?.border, "borderBottom");
      setBorderStyle(style, border?.bottomBorder, "borderBottom");
      setBorderStyle(style, border?.border, "borderLeft");
      setBorderStyle(style, border?.leftBorder, "borderLeft");
      setBorderStyle(style, border?.border, "borderRight");
      setBorderStyle(style, border?.topBorder, "borderRight");
      break;
    case "section":
      if (cellPosition.sectionFirstRow) {
        setBorderStyle(style, border?.border, "borderTop");
        setBorderStyle(style, border?.topBorder, "borderTop");
      } else {
        setBorderStyle(style, border?.innerHorizontalBorder, "borderTop");
      }
      if (cellPosition.sectionLastRow) {
        setBorderStyle(style, border?.border, "borderBottom");
        setBorderStyle(style, border?.bottomBorder, "borderBottom");
      } else {
        setBorderStyle(style, border?.innerHorizontalBorder, "borderBottom");
      }
      if (cellPosition.firstColumn) {
        setBorderStyle(style, border?.border, "borderLeft");
        setBorderStyle(style, border?.leftBorder, "borderLeft");
      } else {
        setBorderStyle(style, border?.innerVerticalBorder, "borderLeft");
      }
      if (cellPosition.lastColumn) {
        setBorderStyle(style, border?.border, "borderRight");
        setBorderStyle(style, border?.topBorder, "borderRight");
      } else {
        setBorderStyle(style, border?.innerVerticalBorder, "borderRight");
      }
      break;
    case "table":
      setBorderStyle(style, border?.border, "border");

      if (border?.border === null) {
        setBorderStyle(style, border?.bottomBorder, "borderBottom");
        setBorderStyle(style, border?.leftBorder, "borderLeft");
        setBorderStyle(style, border?.rightBorder, "borderRight");
        setBorderStyle(style, border?.topBorder, "borderRight");
      }
      break;
    default:
  }
}

function setOutputFillStyle(output, style, fillId) {
  if (fillId === null) {
    return;
  }

  const fill = output.styles.fills[fillId];

  if (!style.backgroundColor && fill.backgroundColor) {
    style.backgroundColor = fill.backgroundColor;
  }
}

function setOutputFontStyle(output, style, fontId) {
  if (fontId === null) {
    return;
  }

  const font = output.styles.fonts[fontId];

  if (!style.color && font.color) {
    style.color = font.color;
  }

  if (!style.fontFamily && font.family) {
    const state = store.getState();
    const formatFonts = state.lookup.global.find((lookup) => lookup.name === "FormatFonts");

    let formatFont = formatFonts.lookup.find((font) => font.name === font.family);
    if (formatFont) {
      style.fontFamily = formatFont.cssFontFamily;
    } else {
      style.fontFamily = font.family;
    }
  }

  if (!style.fontSize && font.size) {
    style.fontSize = font.size + "px";
  }

  if (!style.fontWeight && font.weight) {
    style.fontWeight = font.weight;
  }

  if (!style.fontStyle && font.style) {
    style.fontStyle = font.style;
  }

  if (!style.textDecorationLine && font.decoration) {
    style.textDecorationLine = font.decoration;
  }
}
