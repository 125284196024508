import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { t } from "locale/dictionary";
import { changeFavouriteSearch, deleteFavouriteSearch, setDefaultSearch } from "api/search";
import Delete from "components/global/Delete";
import { setDefaultSearchId, setSavedSearchLoadedId, setSavedSearches } from "redux/simpleSearchSlice";
import { compareByName, filterOutOnId, findById, idsAreEqual } from "utilities/stringAndArray";
import SavedSearchLink from "components/global/SavedSearchLink";
import Checkbox from "components/global/Checkbox";
import { getDateDisplayValue } from "utilities/dateTime";
import { getFilterDescriptionString } from "utilities/simpleSearch";
import { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useEffect } from "react";
import SearchCriteriaPopup from "./SavedSearchCriteriaPopup";

// Container Component for tables of saved searches with the Saved Searches popup
function SavedSearchesTable({ savedSearchesInTable, title, onPinnedOrDefaultChange, isShowingAllCriteria }) {
  const simpleSearch = useSelector((state) => state.simpleSearch).currentSearch;
  const defaultSearchId = simpleSearch.defaultSearchId;
  const savedSearchesAll = simpleSearch.savedSearches;
  const dispatch = useDispatch();

  const [sortCol, setSortCol] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [savedSearchesSorted, setSavedSearchesSorted] = useState(null);

  const titleToDisplay = !title || title.length === 0 ? "No Matter Type Filter" : title;

  useEffect(() => {
    setSavedSearchesSorted(savedSearchesInTable);
  }, [savedSearchesInTable]);

  // TODO: Remove pinned and default from main list
  const handlePinClick = async (savedSearchId) => {
    let savedSearchesLocal = [...savedSearchesAll];
    const savedSearch = findById(savedSearchesLocal, savedSearchId);
    await changeFavouriteSearch(savedSearchId, "pinned", !savedSearch.pinned, savedSearch.pinned);
    const savedSearchCopy = { ...savedSearch, pinned: !savedSearch.pinned };
    savedSearchesLocal = filterOutOnId(savedSearchesLocal, savedSearchId);
    savedSearchesLocal.push(savedSearchCopy);
    savedSearchesLocal = savedSearchesLocal.sort(compareByName);
    dispatch(setSavedSearches(savedSearchesLocal));
    onPinnedOrDefaultChange();
  };

  const handleDeleteSearch = async (savedSearchId) => {
    await deleteFavouriteSearch(savedSearchId);
    let savedSearchesLocal = [...savedSearchesAll];
    savedSearchesLocal = filterOutOnId(savedSearchesLocal, savedSearchId);
    dispatch(setSavedSearches(savedSearchesLocal));
    if (idsAreEqual(savedSearchId, simpleSearch.savedSearchLoadedId)) dispatch(setSavedSearchLoadedId(null));
  };

  const handleDefaultClick = (id) => {
    let savedSearchId = id;
    if (idsAreEqual(defaultSearchId, savedSearchId)) savedSearchId = null; // Toggle off
    setDefaultSearch(savedSearchId);
    dispatch(setDefaultSearchId(savedSearchId));
    onPinnedOrDefaultChange();
  };

  const handleColHeaderClick = (fieldToSort) => {
    const sortAscLocal = sortCol === fieldToSort ? !sortAsc : true;
    setSortAsc(sortAscLocal);
    setSortCol(fieldToSort);

    const compareBySortCol = (a, b) => {
      let sortValueA = null;
      let sortValueB = null;
      sortValueA = a[fieldToSort];
      sortValueB = b[fieldToSort];
      if (typeof sortValueA === "string") sortValueA = sortValueA.toLowerCase();
      if (typeof sortValueB === "string") sortValueB = sortValueB.toLowerCase();

      if (sortValueA < sortValueB || (!sortValueA && sortValueB)) {
        return sortAscLocal ? -1 : 1;
      }
      if (sortValueA > sortValueB || (sortValueA && !sortValueB)) {
        return sortAscLocal ? 1 : -1;
      }
      return 0;
    };

    let dataToSort = [...savedSearchesInTable];
    setSavedSearchesSorted(dataToSort);
  };

  const renderSavedSearchLine = (savedSearch, index) => {
    let criteriaString = "";
    if (savedSearch?.filters) {
      criteriaString = savedSearch.filters
        .map((filter) => getFilterDescriptionString(filter, true, isShowingAllCriteria))
        .join(" | ");
    }

    let direction = "left";
    if (index === 0 || savedSearch.pinned || idsAreEqual(savedSearch.id, simpleSearch.defaultSearchId))
      direction = "bottom";
    else if (index === savedSearchesSorted.length - 1) direction = "top";

    const rowClassNameBase = "data-panel__table-row";
    let rowClassName = rowClassNameBase;
    if (idsAreEqual(savedSearch.id, simpleSearch.savedSearchLoadedId))
      rowClassName += " data-panel__table-row--selected";

    return (
      <tr key={savedSearch.id} className={rowClassName}>
        <td className="data-panel__table-cell data-panel__table-cell--20 clickable">
          <SavedSearchLink key={savedSearch.id} search={savedSearch} />
        </td>
        <td className="data-panel__table-cell data-panel__table-cell--10 clickable">
          {getDateDisplayValue(savedSearch.dateCreated)}
        </td>
        <td className="data-panel__table-cell data-panel__table-cell--10 clickable">{savedSearch.dateAmended}</td>
        <td className="data-panel__table-cell data-panel__table-cell--10 clickable">{savedSearch.createdBy}</td>
        <td className="data-panel__table-cell data-panel__table-cell--30 clickable">{criteriaString}</td>
        <td className="data-panel__table-cell data-panel__table-cell--10">
          <Checkbox isChecked={savedSearch.pinned} onCheckChange={() => handlePinClick(savedSearch.id)} />
        </td>
        <td className="data-panel__table-cell data-panel__table-cell--10">
          <Checkbox
            isChecked={idsAreEqual(savedSearch.id, defaultSearchId)}
            onCheckChange={() => handleDefaultClick(savedSearch.id)}
          />
        </td>
        <td className="saved-searches__cell saved-searches__cell--15">
          <Delete message="Delete this row of data" onConfirm={() => handleDeleteSearch(savedSearch.id)} />
        </td>
      </tr>
    );
  };

  const renderHeaderCell = (fieldName, displayName, width) => {
    return (
      <td
        className={`data-panel__table-header data-panel__table-header--${width}`}
        onClick={() => handleColHeaderClick(fieldName)}
      >
        {t(displayName)}
        {sortCol === fieldName ? sortAsc ? <FaCaretUp /> : <FaCaretDown /> : null}
      </td>
    );
  };

  return (
    <div className="data-panel__table data-panel__table--100">
      <div className="data-panel__table-group">
        <div className="table-group__header table-group__header--expanded">{t(titleToDisplay)}</div>
        <div className="data-panel__table">
          <table className="data-panel__table-inner">
            <thead>
              <tr className="data-panel__table-row">
                {renderHeaderCell("name", "Name", 20)}
                {renderHeaderCell("dateCreated", "Date Created", 10)}
                {renderHeaderCell("dateAmended", "Date Amended", 10)}
                {renderHeaderCell("createdBy", "Created By", 10)}
                {renderHeaderCell(null, "Criteria", 40)}
                {renderHeaderCell("pinned", "Pinned", 10)}
                {renderHeaderCell(null, "Default", 10)}
                <td className="data-panel__table-header data-panel__table-header--10">&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {savedSearchesSorted?.length > 0 &&
                savedSearchesSorted.map((savedSearch, index) => renderSavedSearchLine(savedSearch, index))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

SavedSearchesTable.propTypes = {
  savedSearchesInTable: PropTypes.array,
  title: PropTypes.string,
  onPinnedOrDefaultChange: PropTypes.func,
  isShowingAllCriteria: PropTypes.bool,
};

export default SavedSearchesTable;
