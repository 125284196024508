import { labelTypes, matterTypes, requiredDataTypes, sectionTypes, validationStates } from "utilities/constants";
import { getDataTypeForField, getDisplayNameForField } from "utilities/datafield";
import PropTypes from "prop-types";
import PanelFieldDate from "./field-display-types/PanelFieldDate";
import PanelFieldText from "./field-display-types/PanelFieldText";
import PanelFieldReference from "./field-display-types/PanelFieldReference";
import PanelFieldLookup from "./field-display-types/PanelFieldLookup";
import PanelFieldBoolean from "./field-display-types/PanelFieldBoolean";
import PanelFieldObject from "./field-display-types/PanelFieldObject";
import PanelFieldList from "./field-display-types/PanelFieldList";
import PanelFieldCountries from "./field-display-types/PanelFieldCountries";
import PanelFieldLink from "./field-display-types/PanelFieldLink";
import PanelFieldContactNameLookup from "./field-display-types/PanelFieldContactNameLookup";
import { useDispatch, useSelector } from "react-redux";
import PanelFieldDocument from "./field-display-types/PanelFieldDocument";
import PanelFieldCompanyAddress from "./field-display-types/PanelFieldCompanyAddress";
import PanelFieldNameSearch from "./field-display-types/PanelFieldNameSearch";
import { setSettingFocusFirstColumnTableName } from "redux/matterSlice";
import PanelFieldRowImage from "./field-display-types/PanelFieldRowImage";
import { useRef } from "react";
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { getUserFullNameFromId } from "utilities/customer";
import { useEffect } from "react";
import { findById, idsAreEqual } from "utilities/stringAndArray";
import { useState } from "react";

// Container Component for the display of a field within a form-type data panel
function PanelField({ keyProps, onChange, isAdding }) {
  const matterState = useSelector((state) => state.matter);
  const localeState = useSelector((state) => state.locale);

  const dispatch = useDispatch();

  const parentContainer = useRef(null);

  const record = keyProps.record;
  const field = keyProps.field;
  const fieldName = field.fieldName;
  const isTable = keyProps.sectionType === sectionTypes.TABLE;

  const [canEdit, setCanEdit] = useState();

  let dataType = getDataTypeForField(fieldName) ?? labelTypes.STRING;

  useEffect(() => {
    const isReadOnly =
      field.isReadOnly ||
      keyProps.isPanelReadOnly ||
      (fieldName === "matterCompanyLink_AssignedDate" && keyProps.rowData.matterCompanyLink_LinkTypeId !== 2200);
    setCanEdit(!isReadOnly);
  }, [keyProps, field.isReadOnly, keyProps.isPanelReadOnly]);

  // Use useEffect to handle focus logic
  useEffect(() => {
    if (
      keyProps.colIndex === 0 &&
      (keyProps.addingRowIndex === 0 || keyProps.addingRowIndex === "0") &&
      keyProps.controlId &&
      keyProps.tableName === matterState.settingFocusFirstColumnTableName
    ) {
      let controlId = keyProps.controlId;
      let daughterInputControl;

      if (dataType === labelTypes.LOOKUP) controlId = `input__${controlId}`;

      if (dataType === labelTypes.DATE) {
        const containerEl = document.getElementById(controlId);
        if (containerEl) daughterInputControl = containerEl.querySelectorAll("input[type=text]")[0];
      } else daughterInputControl = document.getElementById(controlId);

      if (daughterInputControl) {
        daughterInputControl.focus();
        dispatch(setSettingFocusFirstColumnTableName(null));
      }
    }
  }, [keyProps, dataType, matterState.settingFocusFirstColumnTableName, dispatch]);

  let renderContents = <span>Set renderContents in code!</span>;

  if (
    matterState &&
    !matterState.showExtraFieldsGoods &&
    (fieldName === "matterGoods_FirstUseDate" || fieldName === "matterGoods_FirstUseInCommerceDate")
  )
    return null;

  if (
    matterState &&
    !matterState.showExtraFieldsDocuments &&
    (fieldName === "matterDocument_EmailDate" ||
      fieldName === "matterDocument_EmailSubject" ||
      fieldName === "matterDocument_EmailFrom" ||
      fieldName === "matterDocument_EmailTo" ||
      fieldName === "matterDocument_EmailImportance")
  )
    return null;

  if (
    record.matter_MatterTypeId !== matterTypes.ASSIGNMENTANDRECORDALS &&
    fieldName === "matterCompanyLink_AssignedDate"
  )
    return null;

  // NOTE (BR): This function causes issues of popups staying open and month/year calendar popups NOT staying open for more than a second, so I've disabled it for no
  // const focusChild = () => {
  //   const child = parentContainer.current.querySelector("input");
  //   if (child) child.focus();
  // };

  // Countries require special handling
  if (fieldName === "countries") {
    renderContents = <PanelFieldCountries keyProps={keyProps} canEdit={canEdit} />;
  }
  // Company Link and Legal Team Contacts require special handling
  else if (
    (keyProps.tableName === "contactLinks" && fieldName === "matterCompanyLinkContactLink_MatterContactId") ||
    (keyProps.tableName === "legalTeamContactLinks" && fieldName === "matterLegalTeamContactLink_MatterContactId")
  ) {
    dataType = labelTypes.LOOKUP;
    renderContents = <PanelFieldContactNameLookup keyProps={keyProps} canEdit={canEdit} onChange={onChange} />;
  }
  // Company details (address, phone etc.) readonly concatenated label special handling
  else if (keyProps.tableName === "companyLinks" && fieldName === "matterCompanyLink_Address1") {
    dataType = labelTypes.STRING;
    renderContents = <PanelFieldCompanyAddress keyProps={keyProps} />;
  }
  // Company and contact search special handling
  else if (
    isAdding &&
    (keyProps.field.fieldName === "matterCompanyLink_Name" || keyProps.field.fieldName === "matterContactLink_FullName")
  ) {
    renderContents = <PanelFieldNameSearch keyProps={keyProps} onChange={onChange} />;
  }
  // Document download link and icon special
  else if (keyProps.tableName === "documents" && fieldName === "matterDocument_Filename") {
    renderContents = <PanelFieldDocument value={keyProps.data} keyProps={keyProps} />;
  }
  // Inline Row Image (Connected Matters Table)
  else if (fieldName === "matterLink_FirstMatterImageId") {
    renderContents = <PanelFieldRowImage keyProps={keyProps} />;
  }
  // Handle object types (not simple values, but compound objects within each row data)
  else if (field.objectProps) {
    renderContents = <PanelFieldObject keyProps={keyProps} />;
  }
  // Handle list types
  else if (field.isListType === true) {
    // Special handling for Original Filing Basis (only show for US records)
    if (fieldName === "ORIGINALFILINGBASIS" && !findById(record.countries, 840)) return;
    renderContents = <PanelFieldList keyProps={keyProps} canEdit={canEdit} />;
  }
  // Handle User ID fields
  else if (fieldName === "matterComment_CreatedBy") {
    renderContents = keyProps.data ? getUserFullNameFromId(keyProps.data) : "n/a";
  }
  // Assigned Date only for Previous Owner in Assignments
  else if (fieldName === "matterCompanyLink_AssignedDate" && keyProps.rowData.matterCompanyLink_LinkTypeId !== 2200) {
    renderContents = "";
  }
  // Now handle all other cases generically
  else {
    let displayName = getDisplayNameForField(fieldName, [record.matter_MatterTypeId], isTable); // Displays abbreviated names only for form (not table) panels
    if (dataType === labelTypes.LOOKUP) {
      renderContents = (
        <PanelFieldLookup keyProps={keyProps} canEdit={canEdit} displayName={displayName} onChange={onChange} />
      );
    } else if (dataType === labelTypes.DATE) {
      renderContents = (
        <PanelFieldDate keyProps={keyProps} canEdit={canEdit} displayName={displayName} onChange={onChange} />
      );
    } else if (dataType === labelTypes.BOOLEAN) {
      renderContents = (
        <PanelFieldBoolean keyProps={keyProps} canEdit={canEdit} displayName={displayName} onChange={onChange} />
      );
    } else if (fieldName.startsWith("REF")) {
      renderContents = <PanelFieldReference keyProps={keyProps} canEdit={canEdit} />;
    } else if (Array.isArray(keyProps.data)) {
      renderContents = keyProps.data.join(", ");
    } else if (field.hasOwnProperty("linkPropertyName") && keyProps.isAdding !== true) {
      renderContents = <PanelFieldLink keyProps={keyProps} displayName={displayName} />;
    } else {
      renderContents = (
        <PanelFieldText
          //value={keyProps.data}
          keyProps={keyProps}
          canEdit={canEdit}
          displayName={displayName}
          onChange={onChange}
        />
      );
    }
  }

  // Set validation state colors and messages
  let classValidation = "";
  let validationStrings = [];
  if (record?.sections) {
    const sectionWithField = record.sections.find((section) =>
      section.fields.some((sectionField) => sectionField.fieldName === fieldName)
    );
    const fieldInSection = isTable
      ? sectionWithField?.fields.find(
          (sectionField) =>
            sectionField.fieldName === fieldName &&
            sectionField.validationStates?.find((vs) => idsAreEqual(vs.rowId, keyProps.rowData.id))
        )
      : sectionWithField?.fields.find((sectionField) => sectionField.fieldName === fieldName);

    const vss = fieldInSection?.validationStates;
    //if (vss?.length > 0 && vss.some((vs) => vs.state === validationStates.EDITING)) classValidation = "editing";
    if (vss?.length > 0 && vss.some((vs) => vs.state === validationStates.SUCCESS)) classValidation = "success";
    else if (
      (vss?.length > 0 && vss.some((vs) => vs.state === validationStates.ERROR)) ||
      keyProps.hasValidationError
    ) {
      classValidation = "error";
      vss.forEach((vs) => {
        if (vs.type !== requiredDataTypes.ARRAY_VALUE) {
          const translation = localeState.translations && localeState.translations[vs.string];
          validationStrings.push(translation);
        }
      });
    }
  }

  const renderValidationDetails = validationStrings.length > 0 && (
    <div className="field__display-name">{validationStrings.join(", ")}</div>
  );

  const classBaseType = isTable ? "table-cell" : "field";

  let classNameOther = `data-panel__${classBaseType} data-panel__${classBaseType}--${field.width} ${
    canEdit ? "editable" : null
  }`;
  let classNameField = classNameOther;
  if (dataType === labelTypes.BOOLEAN && canEdit) {
    classNameField = isTable
      ? `data-panel__control-field data-panel__control-field--${field.width}`
      : `${classNameOther} data-panel__field--boolean`;
  }

  const classTableFieldContBase = "table-cell__field-container";
  let classTableFieldCont = classTableFieldContBase;
  if (isTable && canEdit && !field.isReadOnly && !field.longText) {
    //classNameField += " data-panel__table-cell--readonly";
    classTableFieldCont = `${classTableFieldContBase} ${classTableFieldContBase}--editable`;
  }

  if (classValidation.length > 0) {
    if (isTable) classTableFieldCont += ` ${classTableFieldContBase}--${classValidation}`;
    else classNameField += ` data-panel__field--${classValidation}`;
  }

  if (field.longText > 0) classTableFieldCont += ` ${classTableFieldContBase}--has-long-text`;

  const renderValidationSymbol = classValidation.length > 0 && (
    <div className={`field__validation-symbol field__validation-symbol--${classValidation}`}>
      {classValidation === "success" ? <FaCheckCircle /> : <FaExclamationTriangle />}
    </div>
  );

  return isTable ? (
    <td className={classNameField}>
      <div className={classTableFieldCont}>
        {renderContents}
        {renderValidationSymbol}
      </div>
    </td>
  ) : (
    //<div ref={parentContainer} className={classNameField} onClick={() => focusChild()}>
    <div ref={parentContainer} className={classNameField}>
      {renderContents}
      {renderValidationDetails}
      {renderValidationSymbol}
    </div>
  );
}

PanelField.propTypes = {
  keyProps: PropTypes.object,
  onChange: PropTypes.func,
  isAdding: PropTypes.bool,
};

export default PanelField;
