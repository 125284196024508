import PropTypes from "prop-types";
import { superSelectOperationTypes } from "utilities/constants";

// A generic component for display a selectable list of options
function List({
  id,
  options,
  selectedOptionId,
  selectedOptionIds,
  onChange,
  onChangeMulti,
  isMultiValued,
  cssClassNameModifier,
}) {
  const elementId = `list__${id}`;

  const classNameBase = "list";
  const className = cssClassNameModifier ? `${classNameBase} ${classNameBase}--${cssClassNameModifier}` : classNameBase;

  return (
    <div id={elementId} className={className}>
      {options &&
        Array.isArray(options) &&
        options.map((option) => {
          let classNameBase = "list__option";
          //console.log("selectedOptionId: ", selectedOptionId);
          const className =
            selectedOptionId && option.id === selectedOptionId
              ? `${classNameBase} ${classNameBase}--selected`
              : classNameBase;

          return (
            <div
              key={option.id}
              className={className}
              onClick={() =>
                isMultiValued ? onChangeMulti(option.id, superSelectOperationTypes.REMOVE) : onChange(option.id)
              }
            >
              {option.displayValue}
            </div>
          );
        })}
    </div>
  );
}

List.propTypes = {
  id: PropTypes.string,
  options: PropTypes.any, // TODO: fix so it's only array
  selectedOptionId: PropTypes.any,
  selectedOptionIds: PropTypes.array,
  onChange: PropTypes.func,
  onChangeMulti: PropTypes.func,
  isMultiValued: PropTypes.bool,
};

export default List;
