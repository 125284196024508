import InputNumberPicker from "./InputNumberPicker";
import { useDispatch } from "react-redux";
import { setGridColumnCount } from "redux/reportSlice";
import PropTypes from "prop-types";

function ToolbarGrid({ format }) {
  const dispatch = useDispatch();

  return (
    <InputNumberPicker
      label="Columns"
      value={format.gridColumnCount}
      onChange={(value) => dispatch(setGridColumnCount({ value: value }))}
      min={1}
      max={99}
      numbers={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 24]}
      tooltipText="Select number of columns"
    />
  );
}

ToolbarGrid.propTypes = {
  format: PropTypes.object,
};

export default ToolbarGrid;
