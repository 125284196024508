//import Logo from "components/header/Logo";
import LogoBlue from "components/header/LogoBlue";
import SysTray from "components/header/SysTray";
import CreateRecord from "components/header/CreateRecord";
import SearchPanel from "components/simple-search/SearchPanel";
import { searchViewTypes } from "utilities/constants";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "components/global/BreadCrumbs";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setViewTypeSimpleSearch } from "redux/simpleSearchSlice";
import { useState } from "react";
import Project from "components/content/project/Project";

// Component that contains all components to be used in the header (logo, menu and sys-tray)
export default function Header() {
  // Only show the search panel on certain pages, and exclude from others, e.g. user administration (customers) and batch import
  const location = useLocation();
  const dispatch = useDispatch();

  const [showSearchPanel, setShowSearchPanel] = useState(false);
  const [showProjectPanel, setShowProjectPanel] = useState(false);

  useEffect(() => {
    const searchPanelExcludePatterns = ["/batch-create", "/batch-import", "/customers", "/reporteditor", "/reportmenu"];
    const isSearchPanelExcluded = searchPanelExcludePatterns.some((pattern) => location.pathname.startsWith(pattern));
    setShowSearchPanel(!isSearchPanelExcluded);
    if (!isSearchPanelExcluded) {
      dispatch(setViewTypeSimpleSearch(searchViewTypes.MAIN));
    }
    const projectPanelIncludePatterns = ["/batch-import", "/batch-create"];
    const isProjectPanelIncluded = projectPanelIncludePatterns.some((pattern) => location.pathname.startsWith(pattern));
    setShowProjectPanel(isProjectPanelIncluded);
  }, [location]);

  // Render the header
  return (
    <div className="header">
      <Tooltip content={t("CedarIP Dashboard")} direction="bottom">
        <LogoBlue />
      </Tooltip>
      {showSearchPanel && (
        <div className="header__search-panel">
          <SearchPanel viewType={searchViewTypes.MAIN} isEmbedded={false} parentContainer="header__search-panel" />
          <CreateRecord />
        </div>
      )}
      {showProjectPanel && <Project />}
      <SysTray />
      <Breadcrumbs />
    </div>
  );
}
