import PropTypes from "prop-types";
import HighchartsMap3d from "./HighchartsMap3d";
import HighchartsMap2d from "./HighchartsMap2d";

const HighchartsMap = ({ widgetId, output, onClick }) => {
  if (output.options.type === "globe") {
    return <HighchartsMap3d widgetId={widgetId} output={output} onClick={onClick} />;
  } else {
    return <HighchartsMap2d widgetId={widgetId} output={output} onClick={onClick} />;
  }
};

HighchartsMap.propTypes = {
  widgetId: PropTypes.number,
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default HighchartsMap;
