import { saveMatterListChanges } from "api/matter";
import { getAllListDisplayValues, getListDisplayValue, getListTypeByCode } from "utilities/lookup";
import PropTypes from "prop-types";
import SuperSelect from "components/global/SuperSelect";
import { sectionTypes, superSelectOperationTypes } from "utilities/constants";
import { filterOutOnId, idsAreEqual } from "utilities/stringAndArray";

// Container Component for the display of list object type fields within a matter data panel
function PanelFieldList({ keyProps, canEdit }) {
  const listType = getListTypeByCode(keyProps.field.fieldName);
  const displayName = listType.displayName ?? "#LIST_TYPE_TRANSLATED_NAME";
  const listValue = keyProps.data?.find((listValue) => idsAreEqual(listValue.matterList_ListTypeId, listType.id));
  const selectedOptionIds = listValue && listValue.matterList_ListValues;

  const onSingleSelectChange = (optionId) => {
    saveMatterListChanges(
      keyProps.record?.id,
      listType.id,
      optionId === null ? [] : [optionId],
      selectedOptionIds,
      false,
      keyProps.field.fieldName
    );
  };

  const onSelectChangeMulti = (optionId, op) => {
    let optionIds = selectedOptionIds ? [...selectedOptionIds] : [];
    if (op === superSelectOperationTypes.ADD) optionIds.push(optionId);
    else optionIds = filterOutOnId(optionIds, optionId);

    saveMatterListChanges(
      keyProps.record?.id,
      listType.id,
      optionIds,
      selectedOptionIds,
      false,
      keyProps.field.fieldName
    );
  };

  const displayValueArray = listValue
    ? listValue.matterList_ListValues.map((listValueInner) => {
        return getListDisplayValue(listType.id, listValueInner);
      })
    : ["N/A"];
  const listAvailableValues = getAllListDisplayValues(listType.id);

  let selectedOptionId = null;
  if (!listType.isMultiValued) {
    selectedOptionId =
      selectedOptionIds && Array.isArray(selectedOptionIds) && selectedOptionIds.length > 0 && selectedOptionIds[0];
  }

  const renderValueContents = canEdit ? (
    <SuperSelect
      id={`${keyProps.field.fieldName}${keyProps.rowData ? keyProps.rowData.id : ""}`}
      options={listAvailableValues}
      selectedOptionIds={selectedOptionIds}
      selectedOptionId={selectedOptionId}
      isMultiValued={listType.isMultiValued}
      onChange={onSingleSelectChange}
      onChangeMulti={onSelectChangeMulti}
      sortValuesById={keyProps.field.sortValuesById}
    />
  ) : (
    displayValueArray.join(", ")
  );

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <>{renderValueContents}</>
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value">{renderValueContents}</div>
    </>
  );
}

PanelFieldList.propTypes = {
  keyProps: PropTypes.object,
  canEdit: PropTypes.bool,
};

export default PanelFieldList;
