import { useEffect, useState } from "react";
import { getDataTypeForField, getDisplayNameForField, getLookupValueForField } from "utilities/datafield";
import { FaCaretDown, FaCaretRight, FaSave, FaUserPlus } from "react-icons/fa";
import PanelField from "./PanelField";
import { labelTypes, matterTypes } from "utilities/constants";
import Tooltip from "components/global/Tooltip";
import { addMatterChildTableLink, deleteChildObject, deleteMatterChildTableLink, saveNewChildObject } from "api/matter";
import { getDateDisplayValue, isDate, toISOStringWithOffset } from "utilities/dateTime";
import Delete from "components/global/Delete";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setChildTableAdding, setLastSoftSaveLocation } from "redux/matterSlice";
import PanelTable from "./PanelTable";
import { formatControlIdExplicit, idsAreEqual } from "utilities/stringAndArray";
import { findNextInput } from "utilities/findNextTabStop";
import { t } from "locale/dictionary";

// Container Component for a data row within a table type data panel for a matter record
/* Has the following responsibilities:
    - Display of existing row data, changes to data and deleting of these rows
    - Display of new rows being added by the user, changes to data while adding, and final saving of data
    - Handling event clicks within the row and bubbling appropriate ones up to the parent table
    - Handling the anchor for the nested display of sub-tables (companyLinks rows -> company contacts)
*/
function PanelTableRow({
  keyProps,
  rowData,
  indexRow,
  isAdding,
  addingRowIndex,
  onSaveNewRow,
  onSetAddRowValidationMessage,
  onChange,
  onDelete,
}) {
  const matterState = useSelector((state) => state.matter);

  const dispatch = useDispatch();

  const record = keyProps.record;
  const tableName = keyProps.tableName;

  const [addingRow, setAddingRow] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [addRowValidationErroredFields, setAddRowValidationErroredFields] = useState([]);

  const fieldsToValidate = keyProps.fieldList.filter((field) => !field.isReadOnly);
  const fieldNamesToValidate = fieldsToValidate?.map((field) => field.fieldName);

  //console.log("🚀 ~ addingRow:", addingRow);

  useEffect(() => {
    setIsExpanded(matterState.childTableIsExpandingAll.includes(keyProps.sectionId));
  }, [matterState.childTableIsExpandingAll]);

  useEffect(() => {
    if (isAdding) {
      setAddingRow(rowData);
    }
    // If the user's data entry and blur of control resulted in a soft-save then we need to focus onto the same control in the newly saved row (which replaced the adding row)
    if (matterState.lastSoftSaveLocation?.tableName === keyProps.tableName) {
      const lssl = matterState.lastSoftSaveLocation;
      const lastSoftSaveElementId = formatControlIdExplicit(record.id, lssl.changedFieldName, lssl.rowDataId);
      const lastSoftSaveElement = document.getElementById(lastSoftSaveElementId);
      if (lastSoftSaveElement) {
        const nextInput = findNextInput(lastSoftSaveElement);
        if (nextInput) {
          nextInput.focus();
          dispatch(setLastSoftSaveLocation({ tableName: null, changedFieldName: null, rowDataId: null }));
        }
      }
    }
  }, [record, rowData, matterState.lastSoftSaveLocation]);

  const handleExpanderClick = (alwaysExpand) => {
    setIsExpanded(!isExpanded || !!alwaysExpand);
  };

  const handleChildTableAddRow = (parentIndexRow, parentRowData) => {
    dispatch(setChildTableAdding({ tableName: keyProps.childTable.tableName, parentIndexRow, parentRowData }));
    setIsExpanded(true);
  };

  const handleAddingFieldChange = (fieldName, value) => {
    let addingRowCopy = { ...addingRow };
    let addingRowFieldName = fieldName;
    // Special case for company search and fill-out
    if (fieldName === "matterCompanyLink_Name") {
      const linkType = addingRowCopy.matterCompanyLink_LinkTypeId;
      addingRowCopy = {
        matterCompanyLink_LinkTypeId: linkType,
        matterCompanyLink_Name: value.name,
        matterCompanyLink_Address1: value.address1,
        matterCompanyLink_City: value.city,
        matterCompanyLink_State: value.state,
        matterCompanyLink_PostalCode: value.postalCode,
        matterCompanyLink_Country: value.countryId,
        matterCompanyLink_MatterId: value.matterId,
      };
    }
    // Special case for contact search and fill-out
    else if (fieldName === "matterContactLink_FullName") {
      const linkType = addingRowCopy.matterContactLink_ContactLinkTypeId;
      addingRowCopy = {
        matterContactLink_ContactLinkTypeId: linkType,
        matterContactLink_FullName: value.fullName,
        matterContactLink_Email: value.email,
        matterContactLink_Phone1: value.phone1,
        matterContactLink_Phone2: value.phone2,
        matterContactLink_MatterContactId: value.matterContactId,
        matterContactLink_MatterId: value.matterId,
      };
    } else {
      if (isDate(value)) {
        value = toISOStringWithOffset(value);
      }
      addingRowCopy[addingRowFieldName] = value;
    }
    setAddingRow(addingRowCopy);

    handleSaveNewRow(true, addingRowFieldName, addingRowCopy);
  };

  // "Soft" save attempts to save the row when user moves out of any row field - if there are validation errors, these are suppressed
  // and no save occurs. No errors, and the row saves
  // "Hard" save is when the user clicks the save row button - validation errors will be displayed if applicable
  const handleSaveNewRow = async (isSoftSave, changedFieldName, addingRowCopy) => {
    //debugger;
    const addingRowLocal = addingRowCopy ?? addingRow;
    let validationMessage = "";
    let validationErroredFields = [...addRowValidationErroredFields];
    let hasErrors = false;
    keyProps.fieldList.forEach((field) => {
      const fieldValue = addingRowLocal[field.fieldName];

      if (field.isRequired && (!fieldValue || fieldValue.length === 0)) {
        validationMessage += `"${getDisplayNameForField(field.fieldName)}" is required. `;
        validationErroredFields.push(field.fieldName);
        hasErrors = true;
      } else {
        validationErroredFields = validationErroredFields.filter((vef) => vef !== field.fieldName);
      }
    });

    // Special case of goods table, which requires either/or on class/description
    if (tableName === "goods") {
      if (addingRowLocal.matterGoods_Class.length === 0 && addingRowLocal.matterGoods_Goods.length === 0) {
        hasErrors = true;
        validationMessage += t("A Class or Goods/services value is required");
      }
    }

    // Special case of contactLinks within companyLinks, which requires both contactLinkTypeId and
    if (tableName === "contactLinks" && keyProps.parentTableName === "companyLinks") {
      if (
        !addingRowLocal.matterCompanyLinkContactLink_CompanyLinkContactLinkTypeId ||
        !addingRowLocal.matterCompanyLinkContactLink_MatterContactId
      ) {
        hasErrors = true;
        validationMessage += t("Contact Link Type and Linked Contact are required");
      }
    }

    // Special case of contacts in companies, must have position id (role) set
    if (tableName === "contacts" && idsAreEqual(record.matter_MatterTypeId, matterTypes.COMPANY)) {
      if (!addingRowLocal.matterContact_ContactPositionId) {
        hasErrors = true;
        validationMessage += t("Contact must have a Position set");
      }
    }

    if (isSoftSave === true) {
      if (hasErrors) return;
    } else {
      setAddRowValidationErroredFields(validationErroredFields);
      if (hasErrors) {
        onSetAddRowValidationMessage(validationMessage);
        return;
      }
    }

    let newRecord = null;
    if (keyProps.isProject) {
      newRecord = onChange(changedFieldName, addingRowLocal, addingRowLocal[changedFieldName], tableName);
    } else {
      onSetAddRowValidationMessage("");
      if (keyProps.parentIdFieldName) {
        newRecord = addMatterChildTableLink(
          record.id,
          fieldNamesToValidate,
          keyProps.parentTableName,
          keyProps.parentRowData.id,
          tableName,
          addingRowLocal
        );
      } else newRecord = await saveNewChildObject(record.id, fieldNamesToValidate, tableName, addingRowLocal);
    }

    if (newRecord) {
      if (isSoftSave) {
        dispatch(setLastSoftSaveLocation({ tableName, changedFieldName, rowDataId: newRecord.id }));
      }
      onSaveNewRow();
    }
  };

  const handleDeleteRow = (childId) => {
    if (keyProps.isProject) {
      onDelete(childId, tableName, keyProps.parentRowData?.id);
      return;
    }

    if (keyProps.parentTableName) {
      const parentRowId = keyProps.parentRowData.id;
      deleteMatterChildTableLink(
        record.id,
        fieldNamesToValidate,
        keyProps.parentTableName,
        parentRowId,
        keyProps.tableName,
        childId
      );
    } else deleteChildObject(record.id, fieldNamesToValidate, tableName, childId);
  };

  /*  Check for any filtering
        filterOptions are defined in DataPanelList.jsx and have the form:
        [{ fieldName: name_of_field_to_filter_on, valueToEqual: value_that_the_data_must_equal }]
    */
  if (
    matterState.filterOptions &&
    matterState.filterOptions.some((filterOption) =>
      keyProps.fieldList.some((field) => {
        return filterOption.fieldName === field.fieldName && filterOption.valueToEqual !== rowData[field.fieldName];
      })
    )
  ) {
    return null;
  }

  const childRowData = keyProps.childTable && rowData[keyProps.childTable.tableName];

  const renderChildTableExpander =
    keyProps.tableName === "companyLinks" ? (
      // TODO: Redux not updating childRowData so can't see 0 number of children in display
      (childRowData && childRowData.length) || isExpanded > 0 ? (
        <td
          className="data-panel__table-cell data-panel__table-cell--expander clickable"
          onClick={() => handleExpanderClick()}
        >
          {isExpanded ? <FaCaretDown /> : <FaCaretRight />}
        </td>
      ) : (
        <td className="data-panel__table-cell data-panel__table-cell--expander">&nbsp;</td>
      )
    ) : null;

  const renderChildTableRecordsInfo = keyProps.childTable && (
    <div className="data-panel__sub-table-contacts">
      <Tooltip content={`Contains ${childRowData && childRowData.length} records`} direction="left">
        <div className="flex-row-center" onClick={() => handleExpanderClick(true)}>
          {childRowData && childRowData.length}
        </div>
      </Tooltip>
      <Tooltip content="Add new child record to this line" direction="left">
        <div className="flex-row-center" onClick={() => handleChildTableAddRow(indexRow, rowData)}>
          <FaUserPlus />
        </div>
      </Tooltip>
    </div>
  );

  let renderChildTableIfExists = null;
  if (tableName === "companyLinks" && isExpanded) {
    let childTableKeyProps = {
      ...keyProps,
      parentTableName: tableName,
      parentRowData: rowData,
      parentIndexRow: indexRow,
      fieldList: keyProps.childTable.fieldList,
      tableName: keyProps.childTable.tableName,
      noActionBar: !keyProps.childTable.hasSpecialActionBar,
      linkIdFieldName: keyProps.childTable.linkIdFieldName,
      parentIdFieldName: keyProps.childTable.parentIdFieldName,
      parentIdOtherFieldName: keyProps.childTable.parentIdOtherFieldName,
    };
    renderChildTableIfExists = (
      <tr>
        <td colSpan={10}>
          <PanelTable
            key={indexRow}
            keyProps={childTableKeyProps}
            indexRow={indexRow}
            onChange={onChange}
            onDelete={onDelete}
          />
        </td>
      </tr>
    );
  }

  // Additional feedback for user when deleting rows.
  // If any fields in the row have "name" in them,
  // it will get the values for those "name" columns and provide them to the Delete Confirmation dialog
  const firstFieldName = keyProps.fieldList[0].fieldName;
  const firstFieldDisplayName = getDisplayNameForField(firstFieldName);
  const firstFieldDataType = getDataTypeForField(firstFieldName);
  let firstFieldValue = rowData[firstFieldName];
  if (firstFieldDataType === labelTypes.LOOKUP) {
    firstFieldValue = getLookupValueForField(firstFieldName, firstFieldValue);
  } else if (firstFieldDataType === labelTypes.DATE) {
    firstFieldValue = getDateDisplayValue(firstFieldValue);
  }
  let deleteRowInfo = `${firstFieldDisplayName} with "${firstFieldValue}"`;
  let fieldsWithName = [];
  Object.keys(rowData).forEach((key) => {
    if (key.toLowerCase().includes("name")) fieldsWithName.push(key);
  });
  if (fieldsWithName.length > 0) {
    deleteRowInfo +=
      ", " +
      fieldsWithName
        .map((fieldWithName) => {
          if (fieldWithName === firstFieldName) return "";
          return `${getDisplayNameForField(fieldWithName)} with "${rowData[fieldWithName]}"`;
        })
        .join(" ");
  }

  // MAIN RENDER FUNCTION FOR A ROW (ADDING OR DISPLAY OF EXISTING)
  const renderRow = (
    <>
      {renderChildTableExpander}

      {keyProps.fieldList &&
        keyProps.fieldList &&
        keyProps.fieldList.map((field, indexField) => {
          if (field.width === 0) return null;
          const controlId = `${record.id}${field.fieldName}${isAdding ? rowData.index : rowData.id}`;

          if (isAdding && addingRow.hasOwnProperty(field.fieldName)) {
            //console.log("ControlId: " + controlId);
            const hasValidationError = addRowValidationErroredFields.some((vef) => vef === field.fieldName);
            const fieldKeyProps = {
              ...keyProps,
              rowData: addingRow,
              addingRowIndex,
              colIndex: indexField,
              field,
              controlId,
              isAdding: true,
              data: addingRow[field.fieldName],
            };
            return (
              <PanelField
                key={field.fieldName}
                keyProps={fieldKeyProps}
                onChange={handleAddingFieldChange}
                hasValidationError={hasValidationError}
                isAdding={isAdding}
              />
            );
          } else if (rowData.hasOwnProperty(field.fieldName)) {
            const fieldKeyProps = { ...keyProps, field, rowData, controlId, data: rowData[field.fieldName] };
            return <PanelField key={field.fieldName} keyProps={fieldKeyProps} onChange={onChange} />;
          }

          return null;
        })}

      {/* Special info for companyLinks table */}
      {tableName === "companyLinks" &&
        (isAdding ? (
          <td className="data-panel__table-cell data-panel__table-cell--action">&nbsp;</td>
        ) : (
          <td className="data-panel__table-cell data-panel__table-cell--action">{renderChildTableRecordsInfo}</td>
        ))}
      {isAdding ? (
        <td className="data-panel__table-cell data-panel__table-cell--action clickable data-panel__save-row"></td>
      ) : (
        <td className="data-panel__table-cell data-panel__table-cell--action">
          {keyProps.isPanelReadOnly ? null : (
            <Delete message={`Delete ${deleteRowInfo}`} onConfirm={() => handleDeleteRow(rowData.id)} />
          )}
        </td>
      )}
    </>
  );

  const renderRowClassNameBase = "data-panel__table-row";
  const renderRowClassName = isAdding
    ? `${renderRowClassNameBase} ${renderRowClassNameBase}--adding`
    : renderRowClassNameBase;

  return (
    <>
      <tr key={indexRow} className={renderRowClassName}>
        {renderRow}
      </tr>
      {renderChildTableIfExists}
    </>
  );
}

PanelTableRow.propTypes = {
  keyProps: PropTypes.object,
  rowData: PropTypes.object,
  indexRow: PropTypes.number,
  isAdding: PropTypes.bool,
  addingRowIndex: PropTypes.string,
  onSaveNewRow: PropTypes.func,
  onSetAddRowValidationMessage: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default PanelTableRow;
