import { t } from "locale/dictionary";
import { useState } from "react";
import TabsHorizontal from "components/global/TabsHorizontal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { deleteUserFromAccount, changeUsersUserGroup, updateUserDetails, addUserToAccount } from "api/security";
import SuperSelect from "components/global/SuperSelect";
import Delete from "components/global/Delete";
import { useLocation } from "react-router-dom";
import { compareByObjectFieldName, findById, idsAreEqual } from "utilities/stringAndArray";
import { setAccounts } from "redux/securitySlice";
import { FaAngleDown, FaAngleUp, FaCaretDown, FaCaretUp } from "react-icons/fa";

export default function UserDetails() {
  const securityState = useSelector((state) => state.security);
  const { customerId, userId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [updatedUserDetails, setUpdatedUserDetails] = useState({ name: null, email: null });
  const [isEditingUserDetails, setIsEditingUserDetails] = useState(false);
  const [isAddingUserToAccount, setIsAddingUserToAccount] = useState(false);
  const [accountsSortOrder, setAccountsSortOrder] = useState({});
  const [userGroupSortOrder, setUserGroupSortOrder] = useState({});

  const isAccountsReadOnly = location.pathname.includes("accounts");
  const isUserGroupsReadOnly = location.pathname.includes("usergroups");
  const userAccounts = securityState.currentCustomer?.accounts?.filter((account) =>
    account?.users?.find((user) => idsAreEqual(user.customerUserId, userId))
  );

  const currentUser = {
    ...securityState.currentCustomer?.users?.find((user) => idsAreEqual(user.id, userId)),
    accounts: userAccounts,
    accountIds: userAccounts.map((account) => account.id),
  };

  const accountOptions = securityState.currentCustomer?.accounts?.map((account) => ({
    id: account.id,
    displayValue: account.name,
  }));

  const handleAddingUserToAccount = (accountId) => {
    addUserToAccount(customerId, accountId, currentUser.id);
    setIsAddingUserToAccount(false);
  };

  const editUserDetails = () => {
    if (updatedUserDetails.name !== currentUser.fullName && updatedUserDetails.name !== null)
      updateUserDetails(customerId, parseInt(userId), "fullName", currentUser.fullName, updatedUserDetails.name);
    if (updatedUserDetails.email !== currentUser.email && updatedUserDetails.email !== null)
      updateUserDetails(customerId, parseInt(userId), "email", currentUser.email, updatedUserDetails.email);
    setIsEditingUserDetails(false);
  };

  const getGroupName = (account) => {
    const groupId = account.users.find((user) => idsAreEqual(user.customerUserId, userId))?.userGroupId ?? t("(None)");
    const groupName = findById(account.userGroups, groupId)?.name ?? t("(None)");
    return groupName;
  };

  const handleSortAccounts = (fieldName) => {
    const sortedAccounts = compareByObjectFieldName(
      [...securityState.currentCustomer.accounts],
      fieldName,
      accountsSortOrder[fieldName]?.isAscending
    );
    dispatch(setAccounts(sortedAccounts));
    setAccountsSortOrder({ [fieldName]: { isAscending: !accountsSortOrder[fieldName]?.isAscending } });
  };

  const handleSortUserGroups = (fieldName) => {
    //special sort for user groups
    const sortAccountsByUserGroups = [...securityState.currentCustomer.accounts].sort((a, b) => {
      //for each account, find the user group that the user belongs to
      const userGroupA = a.userGroups.find((group) =>
        idsAreEqual(group.id, a.users.find((user) => idsAreEqual(user.customerUserId, userId))?.userGroupId)
      );
      const userGroupB = b.userGroups.find((group) =>
        idsAreEqual(group.id, b.users.find((user) => idsAreEqual(user.customerUserId, userId))?.userGroupId)
      );
      //then sort by the user group name
      const comparisonResult = userGroupA?.[fieldName].localeCompare(userGroupB?.[fieldName], undefined, {
        sensitivity: "base",
      });
      return userGroupSortOrder[fieldName]?.isAscending ? comparisonResult : -comparisonResult;
    });

    dispatch(setAccounts(sortAccountsByUserGroups));
    setUserGroupSortOrder({ [fieldName]: { isAscending: !userGroupSortOrder[fieldName]?.isAscending } });
  };

  const renderSortOrderIcon = (table, fieldName) => {
    const isAscending = table === "accounts" ? accountsSortOrder : userGroupSortOrder;
    if (isAscending[fieldName]?.isAscending === undefined) return null;
    return isAscending[fieldName]?.isAscending ? <FaCaretUp /> : <FaCaretDown /> ?? null;
  };

  const renderUserDetails = (
    <>
      <div className="security__section-title">{t("Basic Details")}</div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <th className="security__table__header-row-cell">{t("Name")}</th>
              <th className="security__table__header-row-cell">{`${t("Email")}`}</th>
            </tr>
          </thead>

          <tbody className="security__table__body">
            <tr className="security__table__body-row">
              <td className="security__table__body-row-cell  ">
                {isEditingUserDetails ? (
                  <input
                    className="modal__input"
                    id="editUserNameInput"
                    type="text"
                    onChange={(e) => setUpdatedUserDetails({ ...updatedUserDetails, name: e.target.value })}
                    value={updatedUserDetails.name ?? currentUser?.fullName}
                  ></input>
                ) : (
                  currentUser?.fullName
                )}
              </td>
              <td className="security__table__body-row-cell ">
                {isEditingUserDetails ? (
                  <input
                    className="modal__input"
                    id="editUserEmailInput"
                    type="text"
                    onChange={(e) => setUpdatedUserDetails({ ...updatedUserDetails, email: e.target.value })}
                    value={updatedUserDetails.email ?? currentUser?.email}
                  ></input>
                ) : (
                  currentUser?.email
                )}
              </td>
            </tr>
            <tr>
              {!isEditingUserDetails ? (
                <td>
                  <button onClick={() => setIsEditingUserDetails(true)}>{t("Edit User Details")}</button>{" "}
                </td>
              ) : (
                <td>
                  <button onClick={() => editUserDetails()}>{t("Confirm")}</button>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );

  const renderUserDetailsAccountsBody = (
    <>
      {currentUser?.accounts?.length > 0 ? (
        <>
          {currentUser?.accounts.map((account) => (
            <tr key={account.id} className="security__table__body-row">
              <td className="security__table__body-row-cell link">
                <Link to={`/customers/${securityState.currentCustomer.id}/accounts/${account.id}`}>{account.name}</Link>
              </td>
              {!isUserGroupsReadOnly ? (
                <td className="security__table__body-row-cell  clickable">
                  <SuperSelect
                    id={account.name}
                    selectedOptionId={
                      account.users.find((user) => idsAreEqual(user.customerUserId, userId))?.userGroupId ?? null
                    }
                    options={[
                      ...account.userGroups.map((group) => ({
                        displayValue: group.name,
                        id: group.id,
                      })),
                      { id: null, displayValue: t("(None)") },
                    ]}
                    onChange={(optionId) =>
                      changeUsersUserGroup(
                        customerId,
                        account.id,
                        account.users.find((user) => idsAreEqual(user.customerUserId, userId))?.id,
                        "userGroupId",
                        account.users.find((user) => idsAreEqual(user.customerUserId, userId))?.userGroupId,
                        optionId
                      )
                    }
                  />
                </td>
              ) : (
                <td className="security__table__body-row-cell">{getGroupName(account)}</td>
              )}
              {!isAccountsReadOnly && (
                <td className="security__table__body-row-cell clickable">
                  <Delete
                    message="Remove this Account from this User"
                    onConfirm={() =>
                      deleteUserFromAccount(
                        customerId,
                        account.id,
                        account.users.find((user) => idsAreEqual(user.customerUserId, userId)).id
                      )
                    }
                  />
                </td>
              )}
            </tr>
          ))}
        </>
      ) : (
        <tr>
          <td className="security__table__body__no-data">{t("No Accounts")}</td>
        </tr>
      )}
      {isAddingUserToAccount && (
        <tr className="security__table__body-row">
          <td className="security__table__body-row-cell">
            <SuperSelect
              id="accountName"
              selectedOptionId={null}
              options={accountOptions.filter((account) => !currentUser.accountIds?.includes(account.id)) ?? []}
              placeholderText="Select Account"
              onChange={(accountId) => handleAddingUserToAccount(accountId)}
            />
          </td>
        </tr>
      )}
      {!isAccountsReadOnly && !isAddingUserToAccount && (
        <tr className="security__table__body-row">
          <td className="security__table__body-row-cell">
            <button onClick={() => setIsAddingUserToAccount(true)}>{t("Add This User To Another Account")}</button>
          </td>
        </tr>
      )}
    </>
  );

  const renderUserAccountsDetails = (
    <>
      <div className="security__section-title">{t("Accounts")}</div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              <th
                onClick={() => handleSortAccounts("name")}
                className="security__table__header-row-cell security__table__header-row-cell--clickable"
              >
                {t("Name")}
                {renderSortOrderIcon("accounts", "name")}
              </th>
              <th
                onClick={() => handleSortUserGroups("name")}
                className="security__table__header-row-cell security__table__header-row-cell--clickable"
              >
                {t("User Group")}
                {renderSortOrderIcon("userGroups", "name")}
              </th>
              {!isAccountsReadOnly && <th className="security__table__header-row-cell">{t("Remove from Account")}</th>}
            </tr>
          </thead>
          <tbody className="security__table__body">{renderUserDetailsAccountsBody}</tbody>
        </table>
      </div>
    </>
  );

  const tabArray = [
    {
      id: 1,
      label: "Details",
      body: (
        <>
          {renderUserDetails}
          {renderUserAccountsDetails}
        </>
      ),
    },
  ];

  //MAIN RENDER
  return (
    <>
      {securityState.currentCustomer?.id && (
        <TabsHorizontal tabArray={tabArray} currentTabId={tabArray[0].id}>
          {tabArray.map((tab) => {
            return idsAreEqual(tab?.id, tabArray[0].id) ? tab.body : null;
          })}
        </TabsHorizontal>
      )}
    </>
  );
}
