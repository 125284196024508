import { addLookupEntry, deleteLookupEntry, updateLookupEntry } from "api/lookup";
import Delete from "components/global/Delete";
import List from "components/global/List";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useState } from "react";
import { superSelectOperationTypes } from "utilities/constants";
import { getAllLookupValuesForSource } from "utilities/lookup";

// Component for customizing lookups
export default function Lookups() {
  const configurableLookups = [
    { id: "ActionTypes", displayValue: "Action Types" },
    { id: "RecordSubtypes", displayValue: "Record Subtypes" },
    { id: "ListTypes", displayValue: "List Types" },
    { id: "ReferenceTypes", displayValue: "Reference Types" },
    { id: "Statuses", displayValue: "Statuses" },
    { id: "Substatuses", displayValue: "Substatuses" },
  ];

  const [selectedLookupId, setSelectedLookupId] = useState(null);
  const [lookupValues, setLookupValues] = useState(null);
  const [selectedValueId, setSelectedValueId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedValueText, setSelectedValueText] = useState(null);
  const [selectedMatterTypeIds, setSelectedMatterTypeIds] = useState(null);

  const matterTypes = getAllLookupValuesForSource("MatterTypes");

  const handleTableSelectionChange = (selected) => {
    setSelectedLookupId(selected);
    const lookupValuesLocal = getAllLookupValuesForSource(selected);
    setLookupValues(lookupValuesLocal);
    setSelectedValueId(null);
    setSelectedValueText(null);
    setIsAdding(false);
  };

  const handleValueSelectionChange = (selected) => {
    setSelectedValueId(selected);
    const lookupValue = lookupValues.find((value) => value.id === selected);
    const selectedLookupDisplayValue = lookupValue?.displayValue;
    setSelectedMatterTypeIds(lookupValue?.matterTypeIds);
    setSelectedValueText(selectedLookupDisplayValue);
    setIsAdding(false);
  };

  const handleAddNewValueClick = () => {
    setSelectedValueId(null);
    setSelectedValueText("");
    setIsAdding(true);
  };

  const handleValueTextChange = (e) => {
    setSelectedValueText(e.target.value);
  };

  const handleValueTextBlur = async () => {
    const lookupTable = configurableLookups.find((lookup) => lookup.id === selectedLookupId);
    if (isAdding) {
      const response = await addLookupEntry(lookupTable.id, selectedValueText);
      if (response) {
        const lookupValuesLocal = getAllLookupValuesForSource(selectedLookupId);
        setLookupValues(lookupValuesLocal);
        setSelectedValueId(response.id);
        setIsAdding(false);
      }
    } else updateLookupEntry(selectedLookupId, selectedValueId, "displayName", selectedValueText);
  };

  const handleMatterTypeIdsChange = (selected, operation) => {
    let selectedMatterIdsLocal = null;
    if (operation === superSelectOperationTypes.ADD)
      selectedMatterIdsLocal = selectedMatterTypeIds?.length > 0 ? [...selectedMatterTypeIds, selected] : [selected];
    else selectedMatterIdsLocal = selectedMatterTypeIds.filter((id) => id !== selected);
    setSelectedMatterTypeIds(selectedMatterIdsLocal);

    updateLookupEntry(selectedLookupId, selectedValueId, "matterTypeIds", selectedMatterIdsLocal);
  };

  const handleValueDelete = async () => {
    deleteLookupEntry(selectedLookupId, selectedValueId);
  };

  const renderTables = (
    <div className="lookup__column">
      <div className="column__title">{t("Lookup Table")}</div>
      <List
        id="lookups"
        options={configurableLookups}
        selectedOptionId={selectedLookupId}
        onChange={handleTableSelectionChange}
      />
    </div>
  );

  const renderValues = (
    <div className="lookup__column">
      <div className="column__title">{t("Table Values")}</div>
      <List
        id="lookup-values"
        options={lookupValues}
        selectedOptionId={selectedValueId}
        onChange={handleValueSelectionChange}
        cssClassNameModifier="lookup-values"
      />
      <button className="column__button" onClick={handleAddNewValueClick}>
        {t("Add New Value")}
      </button>
    </div>
  );

  const renderEdit = (selectedValueId || isAdding) && (
    <div className="lookup__column">
      <div className="column__title">{t("Value Details")}</div>
      <div className="edit__row">
        <div className="edit__label">{t("Display Name")}</div>
        <div className="edit__value">
          <input value={selectedValueText} onChange={handleValueTextChange} onBlur={handleValueTextBlur} />
        </div>
      </div>
      {!isAdding && (
        <div className="edit__row">
          <div className="edit__label">{t("Valid Matter Types")}</div>
          <div className="edit__value">
            <SuperSelect
              options={matterTypes}
              selectedOptionIds={selectedMatterTypeIds}
              isMultiValued={true}
              onChangeMulti={handleMatterTypeIdsChange}
            />
          </div>
        </div>
      )}
      <div className="edit__row">
        <div className="edit__label">{t("Delete this table value")}</div>
        <div className="edit__value edit__value--control">
          <Delete message="Delete this table entry" onConfirm={handleValueDelete} showTrashIconInstead={true} />
        </div>
      </div>
    </div>
  );

  return (
    <div className="configuration__lookups">
      <div className="lookups__title">{t("Lookups")}</div>
      <div className="lookups__columns">
        {renderTables}
        {renderValues}
        {renderEdit}
      </div>
    </div>
  );
}
