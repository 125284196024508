import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { setOutputStyle } from "utilities/reportOutput";
import Body from "./Body";
import ColumnGroup from "./ColumnGroup";
import Header from "./Header";

function GridOutput({ output }) {
  const [tableStyle, setTableStyle] = useState({});

  useEffect(() => {
    const gridWidth = output.columns.reduce((total, column) => {
      const columnProp = output.columnProperties[column.p];
      return total + columnProp.width;
    }, 0);

    const style = {
      width: gridWidth + "px",
    };

    if (output.style !== null) {
      setOutputStyle(output, style, output.style, "table");
    }
    setTableStyle(style);
  }, [output]);

  return (
    <table className="output-container__table" style={tableStyle}>
      <ColumnGroup output={output} />
      <Header output={output} />
      <Body output={output} />
    </table>
  );
}

GridOutput.propTypes = {
  output: PropTypes.object,
};

export default GridOutput;
