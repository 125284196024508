// Lookup API calls

import store from "redux/store";
import { fetchWrapper } from "utilities/fetchWrapper";
import { setLookup } from "redux/lookupSlice";
import { queryTypeChange, setDefaultSearchId } from "redux/simpleSearchSlice";

export async function loadAllGlobalLookups() {
  const state = store.getState();
  const customerId = state.app.customerId;

  // DO NOT DELETE
  // Admin Level functions - causing exceptions when non-Admin is logged in. Uncomment once we have access to user admin level info
  // fetchWrapper.get("AccessFilters").then((data) => {
  //   store.dispatch(setLookup({ name: "AccessFilters", lookup: data }));
  // });

  // fetchWrapper.get("ExtendableTables").then((data) => {
  //   store.dispatch(setLookup({ name: "ExtendableTables", lookup: data }));
  // });
  // Admin Level functions - causing exceptions when non-Admin is logged in. Uncomment once we have access to user admin level info

  // These special lookups must be loaded asynchronously, as they are needed in the next global initialisation call
  const dataSources = await fetchWrapper.get("DataSources");
  store.dispatch(setLookup({ name: "DataSources", lookup: dataSources }));

  fetchWrapper.get(`customers/${customerId}/accountnumbertypes`).then((data) => {
    store.dispatch(setLookup({ name: "AccountNumberTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/Accounts`).then((data) => {
    store.dispatch(setLookup({ name: "Accounts", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/actiontypes`).then((data) => {
    store.dispatch(setLookup({ name: "ActionTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/commentcontactlinktypes`).then((data) => {
    store.dispatch(setLookup({ name: "CommentContactLinkTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/CommentTypes`).then((data) => {
    store.dispatch(setLookup({ name: "CommentTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/CompanyLinkContactLinkTypes`).then((data) => {
    store.dispatch(setLookup({ name: "CompanyLinkContactLinkTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/contactlinktypes`).then((data) => {
    store.dispatch(setLookup({ name: "ContactLinkTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/ContactPositions`).then((data) => {
    store.dispatch(setLookup({ name: "ContactPositions", lookup: data }));
  });

  fetchWrapper.get("CorsearchStatuses").then((data) => {
    store.dispatch(setLookup({ name: "CorsearchStatuses", lookup: data }));
  });

  fetchWrapper.get("Countries").then((data) => {
    store.dispatch(setLookup({ name: "Countries", lookup: data }));
  });

  fetchWrapper.get("CountryDataFields").then((data) => {
    store.dispatch(setLookup({ name: "CountryDataFields", lookup: data }));
  });

  fetchWrapper.get("CountryGroupDataFieldMaps").then((data) => {
    store.dispatch(setLookup({ name: "CountryGroupDataFieldMaps", lookup: data }));
  });

  fetchWrapper.get("CustomerStatuses").then((data) => {
    store.dispatch(setLookup({ name: "CustomerStatuses", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/datafieldgroups`).then((data) => {
    store.dispatch(setLookup({ name: "DataFieldGroups", lookup: data }));
  });

  fetchWrapper.get("DataFieldOverrides").then((data) => {
    store.dispatch(setLookup({ name: "DataFieldOverrides", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/directcontactlinktypes`).then((data) => {
    store.dispatch(setLookup({ name: "DirectContactLinkTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/documenttypes`).then((data) => {
    store.dispatch(setLookup({ name: "DocumentTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/fileextensions`).then((data) => {
    store.dispatch(setLookup({ name: "FileExtensions", lookup: data }));
  });

  fetchWrapper.get("FormatFonts").then((data) => {
    store.dispatch(setLookup({ name: "FormatFonts", lookup: data }));
  });

  fetchWrapper.get("FormatSectionTypes").then((data) => {
    store.dispatch(setLookup({ name: "FormatSectionTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/GoodsTypes`).then((data) => {
    store.dispatch(setLookup({ name: "GoodsTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/Goods`).then((data) => {
    store.dispatch(setLookup({ name: "Goods", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/UserGroups`).then((data) => {
    store.dispatch(setLookup({ name: "Groups", lookup: data }));
  });

  fetchWrapper.get("Languages").then((data) => {
    store.dispatch(setLookup({ name: "Languages", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/LegalTeam`).then((data) => {
    store.dispatch(setLookup({ name: "LegalTeam", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/legalteamcontactlinktypes`).then((data) => {
    store.dispatch(setLookup({ name: "LegalTeamContactLinkTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/LinkTypes`).then((data) => {
    store.dispatch(setLookup({ name: "LinkTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/ListTypes`).then((data) => {
    store.dispatch(setLookup({ name: "ListTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/ListValues`).then((data) => {
    store.dispatch(setLookup({ name: "ListValues", lookup: data }));
  });

  fetchWrapper.get("MatterDataFields").then((data) => {
    store.dispatch(setLookup({ name: "MatterDataFields", lookup: data }));
  });

  fetchWrapper.get("MatterObjects").then((data) => {
    store.dispatch(setLookup({ name: "MatterObjects", lookup: data }));
  });

  fetchWrapper.get("MatterTypes").then((data) => {
    store.dispatch(setLookup({ name: "MatterTypes", lookup: data }));
  });

  fetchWrapper.get("NiceClasses").then((data) => {
    store.dispatch(setLookup({ name: "NiceClasses", lookup: data }));
  });

  fetchWrapper.get("Permissions").then((data) => {
    store.dispatch(setLookup({ name: "Permissions", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/ProjectImportSourceTypes`).then((data) => {
    store.dispatch(setLookup({ name: "ProjectImportSourceTypes", lookup: data }));
  });

  fetchWrapper.get("ProjectTypes").then((data) => {
    store.dispatch(setLookup({ name: "ProjectTypes", lookup: data }));
  });

  fetchWrapper.get("ProjectStatuses").then((data) => {
    store.dispatch(setLookup({ name: "ProjectStatuses", lookup: data }));
  });

  fetchWrapper.get("QueryTypes").then((data) => {
    store.dispatch(setLookup({ name: "QueryTypes", lookup: data }));
    store.dispatch(queryTypeChange(data[0]));
  });

  fetchWrapper.get(`customers/${customerId}/RecordSubtypes`).then((data) => {
    store.dispatch(setLookup({ name: "RecordSubtypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/RecordTypes`).then((data) => {
    store.dispatch(setLookup({ name: "RecordTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/ReferenceTypes`).then((data) => {
    store.dispatch(setLookup({ name: "ReferenceTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/RoyaltyFrequencyTypes`).then((data) => {
    store.dispatch(setLookup({ name: "RoyaltyFrequencyTypes", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/Statuses`).then((data) => {
    store.dispatch(setLookup({ name: "Statuses", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/Substatuses`).then((data) => {
    store.dispatch(setLookup({ name: "Substatuses", lookup: data }));
  });

  fetchWrapper.get("TimeZones").then((data) => {
    store.dispatch(setLookup({ name: "TimeZones", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/Users`).then((data) => {
    store.dispatch(setLookup({ name: "Users", lookup: data }));
  });

  fetchWrapper.get(`customers/${customerId}/usersettings`).then((data) => {
    store.dispatch(setLookup({ name: "UserSettings", lookup: data }));
    store.dispatch(setDefaultSearchId(data.defaultSearchFilterId));
  });

  fetchWrapper.get("WidgetTypes").then((data) => {
    store.dispatch(setLookup({ name: "WidgetTypes", lookup: data }));
  });
}

export async function addLookupEntry(tableName, displayName) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { displayName };

  return fetchWrapper.post(`customers/${customerId}/${tableName}`, body);
}

export async function updateLookupEntry(tableName, lookupId, fieldName, value) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { path: `/${fieldName}`, op: "replace", value };

  return fetchWrapper.patch(`customers/${customerId}/${tableName}/${lookupId}`, body);
}

export async function deleteLookupEntry(tableName, lookupId) {
  const state = store.getState();
  const customerId = state.app.customerId;

  return fetchWrapper.delete(`customers/${customerId}/${tableName}/${lookupId}`);
}
