import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { baseMenuItems, getAxisKeys } from "utilities/charting";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { createDrillFilter } from "utilities/reportEditor";
import { idsAreEqual } from "utilities/stringAndArray";

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);

function getSeries(seriesKeys, pivotData, categoryKey = null) {
  let seriesData = [];
  if (seriesKeys.length > 0) {
    seriesData = seriesKeys.map((seriesKey) => {
      let value = 0;

      if (categoryKey === null) {
        value = pivotData
          .filter((resultRow) => resultRow.keys[seriesKey.keyColumn] === seriesKey.value)
          .reduce((accumulator, currentValue) => accumulator + currentValue.values[0], 0);
      } else {
        value = pivotData
          .filter(
            (resultRow) =>
              resultRow.keys[seriesKey.keyColumn] === seriesKey.value &&
              resultRow.keys[categoryKey.keyColumn] === categoryKey.value
          )
          .reduce((accumulator, currentValue) => accumulator + currentValue.values[0], 0);
      }
      return {
        serieskey: seriesKey.value,
        catkey: categoryKey ? categoryKey.value : null,
        y: value === 0 ? null : value,
      };
    });
  }

  if (categoryKey !== null) {
    return { name: categoryKey.label ?? categoryKey.value, x: categoryKey.value, data: seriesData };
  } else {
    return { data: seriesData };
  }
}

function HighchartBar({ widgetId, output, onClick }) {
  const [options, setOptions] = useState(null);

  const homeState = useSelector((state) => state.home);

  const chartRef = useRef(null);

  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (chartRef?.current) {
      const chart = chartRef.current.chart;
      if (chart) {
        chart.reflow();
      }
    }
  }, [isZoomed]);

  useEffect(() => {
    if (idsAreEqual(homeState.zoomedWidgetId, widgetId)) {
      if (!isZoomed) setIsZoomed(true);
    } else if (isZoomed) {
      setIsZoomed(false);
    }
  }, [homeState.zoomedWidgetId]);

  useEffect(() => {
    const categoryKeyValues = getAxisKeys(output.xAxisColumns, output.rowData);
    const seriesKeyValues = getAxisKeys(output.yAxisColumns, output.rowData);

    let drillKey1 = output.yAxisColumns.length > 0 ? output.yAxisColumns[0] : output.xAxisColumns[0];
    let drillKey2 = null;
    if ((output.yAxisColumns.length > 0) & (output.xAxisColumns.length > 0)) {
      drillKey2 = output.xAxisColumns[0];
    }

    let stacked = false;
    let series = [];
    if (seriesKeyValues.length > 0 && categoryKeyValues.length > 0) {
      for (let i = 0; i < categoryKeyValues.length; i++) {
        series.push(getSeries(seriesKeyValues, output.rowData, categoryKeyValues[i]));
      }
      stacked = true;
    } else if (seriesKeyValues.length > 0) {
      series.push(getSeries(seriesKeyValues, output.rowData));
    } else if (categoryKeyValues.length > 0) {
      series.push(getSeries(categoryKeyValues, output.rowData));
    }

    let categories = [];
    if (seriesKeyValues.length > 0) {
      categories = seriesKeyValues.map((axis) => axis.label ?? axis.value);
    } else if (categoryKeyValues.length > 0) {
      categories = categoryKeyValues.map((axis) => axis.label ?? axis.value);
    }

    const displayTotal = series.reduce(
      (accumulator, currentValue) => accumulator + currentValue.data.reduce((acc, cv) => acc + cv.y, 0),
      0
    );

    let plotOptions = null;
    if (stacked) {
      plotOptions = {
        series: {
          events: {
            click: function (event) {
              if (onClick) {
                const point = event.point.options;
                const filters = [];
                filters.push(createDrillFilter(drillKey1.keyDataFieldName, point.serieskey));
                if (drillKey2) {
                  filters.push(createDrillFilter(drillKey2.keyDataFieldName, point.catkey));
                }
                onClick(filters);
              }
            },
          },
        },
      };
      if (output.chartType === "column") {
        plotOptions.column = { stacking: "normal" };
      } else {
        plotOptions.series.stacking = "normal";
      }
    } else {
      plotOptions = {
        series: {
          borderWidth: 0,
          colorByPoint: true,
          dataLabels: {
            enabled: true,
            format: "{point.y}",
          },
          events: {
            click: function (event) {
              if (onClick) {
                const point = event.point.options;
                const filters = [];
                filters.push(createDrillFilter(drillKey1.keyDataFieldName, point.serieskey));
                onClick(filters);
              }
            },
          },
        },
      };
    }

    setOptions({
      chart: {
        type: output.chartType ?? "column",
        options3d: {
          enabled: true,
          alpha: 1,
          beta: 1,
          viewDistance: 25,
          depth: 40,
        },
      },
      title: {
        text: `Total: ${displayTotal} records`,
        style: { fontSize: "1.25rem", fontWeight: "bold" },
      },
      xAxis: {
        categories: categories,
      },
      series: series,
      credits: {
        enabled: false,
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        borderWidth: 1,
        shadow: true,
      },
      plotOptions: plotOptions,
      tooltip: {
        headerFormat: "<b>{series.name}</b><br/>",
        pointFormat: "{point.category}: {point.y}",
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: baseMenuItems,
          },
        },
      },
    });
  }, [output, onClick, isZoomed]);

  return options ? (
    <HighchartsReact
      ref={chartRef}
      containerProps={{
        style: { height: "100%", width: isZoomed ? "100%" : document.documentElement.clientWidth > 1800 ? 425 : 350 },
      }}
      highcharts={Highcharts}
      options={options}
      allowChartUpdate={false}
    />
  ) : null;
}

HighchartBar.propTypes = {
  widgetId: PropTypes.number,
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default HighchartBar;
