import SearchResultsDataGrid from "components/content/search-results/SearchResultsDataGrid";
import SearchResultsFooter from "components/content/search-results/SearchResultsFooter";
import SearchResultsHeader from "components/content/search-results/SearchResultsHeader";
import { useSelector } from "react-redux";
import { FormatTypes } from "utilities/constants";
import ReportViewer from "./ReportViewer";
import PropTypes from "prop-types";

// Container for the simple search results page
function SearchResults({ isEmbedded, searchedByLinkTypeId }) {
  const searchResultsState = useSelector((state) => state.searchResults);
  const classNameBase = "content__search-results";
  const className = isEmbedded ? `${classNameBase} ${classNameBase}--embedded` : classNameBase;

  const resultViewType = searchResultsState?.currentSearch && searchResultsState.currentSearch.resultViewType;
  return (
    <div className={className}>
      {!isEmbedded && <SearchResultsHeader />}
      {resultViewType === FormatTypes.LIST ? (
        <>
          <SearchResultsDataGrid isEmbedded={isEmbedded} searchedByLinkTypeId={searchedByLinkTypeId} />
          <SearchResultsFooter />
        </>
      ) : (
        <ReportViewer resultViewType={resultViewType} />
      )}
    </div>
  );
}

SearchResults.propTypes = {
  isEmbedded: PropTypes.bool,
  searchedByLinkTypeId: PropTypes.number,
};

export default SearchResults;
