const NumberPicker = ({ inputRef, numbers, value, setValue, show, setShow, width = null }) => {
  const style = { display: show ? "block" : "none" };
  if (width) {
    style.width = width;
  }
  return (
    <div className="toolbar__number-picker" style={style} ref={inputRef}>
      {numbers.map((number) => {
        return (
          <div
            key={number}
            onClick={() => {
              setValue(number);
              setShow(false);
            }}
          >
            {number}
          </div>
        );
      })}
    </div>
  );
};

export default NumberPicker;
