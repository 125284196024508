import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { getAxisKeys } from "utilities/charting";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";

function getData(axisArray1, pivotData) {
  let series = [];
  if (axisArray1.length > 0) {
    series = axisArray1.map((axis1) => {
      let value = 0;

      const totalResultSetRow = pivotData.find((resultRow) => resultRow.keys[axis1.keyColumn] === axis1.value);
      if (totalResultSetRow) {
        value = totalResultSetRow.values[0];
      }

      return { name: axis1.label, value: value };
    });
  }
  return series;
}

const colors = scaleOrdinal(schemeCategory10).range();

function D3JSPie({ output }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const xAxisKeys = getAxisKeys(output.xAxisColumns, output.rowData);
    const yAxisKeys = getAxisKeys(output.yAxisColumns, output.rowData);

    if (yAxisKeys.length > 0) {
      setData(getData(yAxisKeys, output.rowData));
    } else if (xAxisKeys.length > 0) {
      setData(getData(xAxisKeys, output.rowData));
    }
  }, [output]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={800} height={800}>
        {data !== null && (
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            label={({ name }) => name}
            outerRadius={120}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
        )}
      </PieChart>
    </ResponsiveContainer>
  );
}

D3JSPie.propTypes = {
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default D3JSPie;
