import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import MatterRecordComparison from "./Comparison";
import MatterRecordOverview from "./Overview";
import { useEffect, useState } from "react";
import { getMatterById } from "api/matter";
import { addRecord, setComparing, setRecords, setSelectedIndex } from "redux/matterSlice";
import MatterPaginator from "./Paginator";
import Switch from "components/global/Switch";
import { t } from "locale/dictionary";
import Slider from "components/global/Slider";
import { recordsSortChange } from "utilities/matter";
import LoadingModal from "components/global/LoadingModal";
import { searchViewTypes } from "utilities/constants";

import PropTypes from "prop-types";
import { idsAreEqual } from "utilities/stringAndArray";

// Container Component for all records
function MatterRecordContainer({ popupRecordId }) {
  const appState = useSelector((state) => state.app);
  const searchResultsState = useSelector((state) => state.searchResults);
  const matterState = useSelector((state) => state.matter);
  const dispatch = useDispatch();

  const { paramsRecordId, section } = useParams();

  const [isAllMattersLoaded, setIsAllMattersLoaded] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const numRecords = matterState?.records?.length ?? 0;
  const searchResults = searchResultsState?.searchesByViewType?.find((s) => s.viewType === searchViewTypes.MAIN);
  const selectedMatterIds = searchResults?.selected;

  useEffect(() => {
    setRecordId(popupRecordId ?? paramsRecordId);
  }, [paramsRecordId, popupRecordId]);

  useEffect(() => {
    const loadMatters = async () => {
      // Single selection mode or popup mode
      if (selectedMatterIds.length === 0 || popupRecordId) {
        const matter = await getMatterById(recordId);
        dispatch(addRecord({ matter, selected: true, replace: true }));
        return;
      }

      // Multi selection mode
      selectedMatterIds.forEach((selectedMatterId) => {
        getMatterById(selectedMatterId).then((selectedMatter) => {
          if (idsAreEqual(selectedMatterId, recordId)) dispatch(addRecord({ matter: selectedMatter, selected: true }));
          else dispatch(addRecord({ matter: selectedMatter }));
        });
      });
      // Now filter out any records that are no longer checked in search results
      let recordsCopy = [...matterState.records];
      recordsCopy = recordsCopy.filter((record) =>
        selectedMatterIds.some((selectedMatterId) => idsAreEqual(selectedMatterId, record.id))
      );
      dispatch(setRecords(recordsCopy));
    };

    if (recordId && appState.customerId && matterState.requiredMatterData) {
      setIsAllMattersLoaded(false);
      loadMatters();
    }
  }, [recordId, appState.customerId, matterState.requiredMatterData, selectedMatterIds, dispatch]);

  useEffect(() => {
    if (isAllMattersLoaded === false) {
      // Multiple records selected and loaded
      if (numRecords > 1 && numRecords === selectedMatterIds.length) {
        setIsAllMattersLoaded(true);
        recordsSortChange(recordId);
      }
      // only a single record navigated (none "selected")
      if (numRecords === 1 && selectedMatterIds.length === 0) {
        setIsAllMattersLoaded(true);
        dispatch(setSelectedIndex(0));
      }
    }
  }, [numRecords, selectedMatterIds, isAllMattersLoaded]);

  const onChangeIsComparing = () => {
    dispatch(setComparing({ isComparing: !matterState.isComparing, numberComparing: 2 }));
  };

  const onChangeComparingNumber = (n) => {
    dispatch(setComparing({ isComparing: true, numberComparing: n }));
  };

  return (
    <div className="content__matters">
      {numRecords > 1 && (
        <div className="matters__toolbar">
          <MatterPaginator />
          <Switch text={t("Compare")} isOn={matterState.isComparing} onSwitchChange={onChangeIsComparing} />
          {matterState.isComparing && numRecords > 2 && (
            <div className="matters__compare-slider">
              <Slider
                value={matterState.numberComparing}
                minValue={2}
                maxValue={Math.min(numRecords, 4)}
                text={t("Comparing")}
                onChangeValue={onChangeComparingNumber}
              />
            </div>
          )}
        </div>
      )}

      <div className="matters__record">
        {numRecords > 0 ? (
          <>
            {!matterState.isComparing && <MatterRecordOverview />}
            <MatterRecordComparison section={section} popupRecordId={popupRecordId} />
          </>
        ) : appState.bearerToken ? (
          <LoadingModal />
        ) : null}
      </div>
    </div>
  );
}

MatterRecordContainer.propTypes = {
  popupRecordId: PropTypes.number,
};

export default MatterRecordContainer;
