import PropTypes from "prop-types";
import FieldListGroupHeader from "./FieldListGroupHeader";
import FieldListItem from "./FieldListItem";

function FieldGroup({ groupName, groupLabel, isVisible, filterInput, onHeaderClick, dataFields, filtered }) {
  return (
    <>
      <FieldListGroupHeader
        isVisible={isVisible}
        groupName={groupName}
        label={groupLabel}
        onHeaderClick={onHeaderClick}
        filtered={filtered}
        dataFields={dataFields}
      />
      {isVisible &&
        dataFields.map((dataField) => {
          return (
            <FieldListItem
              key={dataField.name}
              label={dataField.displayName}
              fieldName={dataField.name}
              filterInput={filterInput}
            />
          );
        })}
    </>
  );
}

FieldGroup.propTypes = {
  groupName: PropTypes.string,
  groupLabel: PropTypes.string,
  isVisible: PropTypes.bool,
  filterInput: PropTypes.string,
  onHeaderClick: PropTypes.func,
  dataFields: PropTypes.array,
  filtered: PropTypes.bool,
};

export default FieldGroup;
