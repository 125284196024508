import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getDataSource } from "utilities/reportEditor";
import { getTranslation } from "utilities/reportShared";

function ReportSubsectionHeader({ format, sectionGroup }) {
  const [title, setTitle] = useState("");

  useEffect(() => {
    const dataSource = getDataSource(sectionGroup.dataSourceName);
    if (dataSource) {
      setTitle(getTranslation(dataSource.translationCode));
    }
  }, [sectionGroup.dataSourceName]);

  return (
    <>
      <tr className="body__blank-row--sub-table">
        <td colSpan={99}>&nbsp;</td>
      </tr>
      <tr className="body__subsection-header-row">
        <td className="subsection-header-row__cell">{`Sub-Report: ${title}`}</td>
        <td className="subsection-header-row__cell" colSpan={format.gridColumns.length + 2} />
      </tr>
    </>
  );
}

ReportSubsectionHeader.propTypes = {
  format: PropTypes.object,
  sectionGroup: PropTypes.object,
};

export default ReportSubsectionHeader;
