import React from "react";
import { sectionDefinitions } from "utilities/sectionDefinitions";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { exportedSectionDefinitions, projectStatuses } from "utilities/constants";
import Panel from "components/content/matter-record/Panel";
import {
  createProjectLegalTeamContact,
  deleteProjectLegalTeamContact,
  updateProjectLegalTeamContact,
} from "api/project";
import { findById, hasMatchingId, idsAreEqual } from "utilities/stringAndArray";

// Shared component for legal team changes in both batch create and batch import
function ProjectLegalTeam() {
  const projectState = useSelector((state) => state.project);
  const batchCreateState = useSelector((state) => state.batchCreate);
  const [keyProps, setKeyProps] = useState(null);

  const project = projectState.project;

  const secDef = findById(sectionDefinitions, exportedSectionDefinitions.LEGAL_TEAM);

  useEffect(() => {
    // Trick the data panel into thinking we have a standard matter record legal team contact link
    if (project?.id) {
      const projectMatterMapped = {
        ...project,
        legalTeamContactLinks: project?.legalTeamContactLinks?.map((contact) => {
          return {
            id: contact.id,
            matterLegalTeamContactLink_MatterContactId: contact.matterContactId,
            matterLegalTeamContactLink_LegalTeamContactLinkTypeId: contact.legalTeamContactLinkTypeId,
            matterLegalTeamContactLink_FullName: contact.fullName,
            matterLegalTeamContactLink_Email: contact.email,
          };
        }),
      };
      const props = {
        sectionType: secDef.type,
        sectionName: secDef.name,
        sectionId: secDef.id,
        tableName: secDef.tableName,
        fieldList: secDef.fieldList,
        record: projectMatterMapped,
        isProject: true,
      };
      setKeyProps(props);
    }
  }, [project]);

  // Receiving change events within the DataPanel hierarchy, we have to remap back to Project space the field names
  const handleFieldChange = (changedFieldName, rowData, value) => {
    const changedRowData = { ...rowData, [changedFieldName]: value };
    const legalTeamContactLink = {
      id: changedRowData.id,
      matterContactId: changedRowData.matterLegalTeamContactLink_MatterContactId,
      legalTeamContactLinkTypeId: changedRowData.matterLegalTeamContactLink_LegalTeamContactLinkTypeId,
    };

    // Minimum requirement for any DB operation is to have both these fields set
    if (!legalTeamContactLink.matterContactId || !legalTeamContactLink.legalTeamContactLinkTypeId) return null;

    let legalTeamContactLinks = project.legalTeamContactLinks ? [...project.legalTeamContactLinks] : [];

    let changedFieldNameProject =
      changedFieldName === "matterLegalTeamContactLink_MatterContactId"
        ? "matterContactId"
        : "legalTeamContactLinkTypeId";

    // We can't mutate the array of legalTeamContactLinks so we create a new array and modify the matching item in the process
    legalTeamContactLinks = legalTeamContactLinks?.map((contact) => {
      if (idsAreEqual(contact.id, legalTeamContactLink.id)) {
        // Found the matching contact, create a new object with the updated field
        const updatedContact = {
          ...contact,
          [changedFieldNameProject]: legalTeamContactLink[changedFieldNameProject],
        };

        updateProjectLegalTeamContact(
          project.id,
          legalTeamContactLink.id,
          changedFieldNameProject,
          legalTeamContactLink[changedFieldNameProject]
        );

        return updatedContact;
      }
      return contact; // Return the original contact if it's not the one we're looking for
    });

    // Check if the matching item was found and updated, if not, add a new one
    if (!hasMatchingId(legalTeamContactLinks, legalTeamContactLink.id))
      createProjectLegalTeamContact(project.id, legalTeamContactLink);
  };

  const handleRecordDelete = async (id) => {
    deleteProjectLegalTeamContact(project.id, id);
  };

  console.log("readonly", project?.project_Status);

  return (
    keyProps !== null && (
      <Panel
        keyProps={keyProps}
        onChange={handleFieldChange}
        onDelete={handleRecordDelete}
        isReadOnly={project?.project_Status === projectStatuses.MATTER_GENERATED}
      />
    )
  );
}

export default ProjectLegalTeam;
