import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import MatterRecordContainer from "./Container";
import { setComparing, setSelectedIndex } from "redux/matterSlice";
import { FaTimes } from "react-icons/fa";
import { useEffect } from "react";

// Universal Popup Component for any matter record
function MatterRecordUniversalPopup({ recordId, onClose }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setComparing(false));
    dispatch(setSelectedIndex(0));
  }, []);

  return (
    <div className="modal-mask">
      <div className="record-universal-popup">
        <div className="record-universal-popup__close-icon" onClick={onClose}>
          <FaTimes />
        </div>
        <MatterRecordContainer popupRecordId={recordId} />
      </div>
    </div>
  );
}

MatterRecordUniversalPopup.propTypes = {
  recordId: PropTypes.number,
  onClose: PropTypes.func,
};

export default MatterRecordUniversalPopup;
