import {
  createCustomerAccount,
  createNewUserInCustomer,
  deleteCustomerAccount,
  updateCustomerAccount,
  updateUserDetails,
} from "api/security";
import Checkbox from "components/global/Checkbox";
import Delete from "components/global/Delete";
import SuperSelect from "components/global/SuperSelect";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { useState } from "react";
import { FaAngleDown, FaAngleUp, FaCaretDown, FaCaretUp, FaEdit, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setAccounts, setUsers } from "redux/securitySlice";

import { userStatusTypes } from "utilities/constants";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { compareByObjectFieldName, findById, idsAreEqual } from "utilities/stringAndArray";
import MatterExport from "./MatterExport";

// BR TODO: Too big. Break up into at least Accounts and Users components
export default function CustomerDetails() {
  const securityState = useSelector((state) => state.security);
  const customerState = useSelector((state) => state.customer);
  const { customerId } = useParams();
  const dispatch = useDispatch();

  const [currentlyEditing, setCurrentlyEditing] = useState({});
  const [addAccountInputValue, setAddAccountInputValue] = useState("");
  const [addUserInputValues, setAddUserInputValues] = useState({ name: "", email: "" });
  const [isAddingAccount, setIsAddingAccount] = useState(false);
  const [isAddingNewUser, setIsAddingNewUser] = useState(false);
  const [showHiddenUsers, setShowHiddenUsers] = useState(false);
  const [usersSortOrder, setUsersSortOrder] = useState({});
  const [accountsSortOrder, setAccountsSortOrder] = useState({});

  const checkHiddenUsers = showHiddenUsers
    ? [...securityState.currentCustomer.users]
    : securityState.currentCustomer?.users?.filter(
        (user) =>
          idsAreEqual(user.customerUserStatusId, userStatusTypes.ACTIVE) ||
          idsAreEqual(user.customerUserStatusId, userStatusTypes.PENDING)
      );

  const hiddenUsers = securityState.currentCustomer?.users?.filter(
    (user) =>
      idsAreEqual(user.customerUserStatusId, userStatusTypes.ACTIVE) ||
      idsAreEqual(user.customerUserStatusId, userStatusTypes.PENDING)
  );

  const customerStatuses = getAllLookupValuesForSource("CustomerStatuses");

  const userTableHeaders = [
    { name: "Name", fieldName: "fullName", isSortable: true },
    { name: "Email", fieldName: "email", isSortable: true },
    { name: "Admin User", fieldName: "isAdminUser", isSortable: true },
    { name: "App User", fieldName: "isAppUser", isSortable: true },
    { name: "Status", fieldName: "customerUserStatusId", isSortable: true },
    { name: "Linked Accounts", fieldName: "linkedAccountsCount", isSortable: true },
  ];

  const accountTableHeaders = [
    { name: "Name", fieldName: "name", isSortable: true },
    { name: "Groups", fieldName: "groupsCount", isSortable: true },
    { name: "Users", fieldName: "usersCount", isSortable: true },
    { name: "Records", fieldName: "matterCount", isSortable: true },
    { name: "Edit", fieldName: "edit", isSortable: false },
    { name: "Delete", fieldName: "delete", isSortable: false },
  ];

  const handleAddNewAccount = async () => {
    await createCustomerAccount(customerId, addAccountInputValue);
    setIsAddingAccount(false);
    setAddAccountInputValue("");
  };

  const handleAddingNewUser = () => {
    createNewUserInCustomer(securityState.currentCustomer.id, {
      id: addUserInputValues.email,
      fullName: addUserInputValues.name,
      phoneNumber: null,
      timeZone: null,
      isAdminUser: false,
      isAppUser: true,
    });

    setIsAddingNewUser(false);
    setAddUserInputValues({ name: "", email: "" });
  };

  const handleEditAccountName = async (account) => {
    updateCustomerAccount(customerId, account.id, "name", account.name, currentlyEditing[account.id].name);
    setCurrentlyEditing({
      ...currentlyEditing,
      [account.id]: { ...currentlyEditing[account.id], isEditing: false },
    });
  };

  const handleSortUsers = (fieldName) => {
    const sortedUsers = compareByObjectFieldName(
      [...securityState.currentCustomer.users],
      fieldName,
      usersSortOrder[fieldName]?.isAscending
    );
    dispatch(setUsers(sortedUsers));
    setUsersSortOrder({ [fieldName]: { isAscending: !usersSortOrder[fieldName]?.isAscending } });
  };

  const handleSortAccounts = (fieldName) => {
    const sortedAccounts = compareByObjectFieldName(
      [...securityState.currentCustomer.accounts],
      fieldName,
      accountsSortOrder[fieldName]?.isAscending
    );
    dispatch(setAccounts(sortedAccounts));
    setAccountsSortOrder({ [fieldName]: { isAscending: !accountsSortOrder[fieldName]?.isAscending } });
  };

  const toggleUserCheckbox = (fieldName, user) => {
    if (!user.isAdminUser && fieldName === "isAppUser") return;
    if (!user.isAppUser && fieldName === "isAdminUser") {
      updateUserDetails(customerId, user.id, "isAppUser", user.isAppUser, true);
    }
    updateUserDetails(customerId, user.id, fieldName, user[fieldName], !user[fieldName]);
  };

  const renderSortOrderIcon = (table, fieldName) => {
    const isAscending =
      table === "users" ? usersSortOrder[fieldName]?.isAscending : accountsSortOrder[fieldName]?.isAscending;
    if (isAscending === undefined) return null;
    return isAscending ? <FaCaretUp /> : <FaCaretDown /> ?? null;
  };

  const renderAccountsTableBody =
    securityState.currentCustomer?.accounts?.length > 0 &&
    securityState.currentCustomer.accounts.map((account) => (
      <tr className="security__table__body-row" key={account.id}>
        {currentlyEditing[account.id]?.isEditing ? (
          <td>
            <input
              id="editNameInput"
              type="text"
              value={currentlyEditing[account.id]?.name ?? account.name}
              onChange={(e) =>
                setCurrentlyEditing({
                  ...currentlyEditing,
                  [account.id]: { ...currentlyEditing[account.id], name: e.target.value },
                })
              }
            ></input>
          </td>
        ) : (
          <td className="security__table__body-row-cell link">
            <Link to={`/customers/${securityState.currentCustomer.id}/accounts/${account.id}`}>{account.name}</Link>
          </td>
        )}

        {/* GROUPS */}
        <td className="security__table__body-row-cell ">{account.groupsCount ?? 0}</td>
        {/* USERS */}
        <td className="security__table__body-row-cell ">{account.usersCount ?? 0}</td>
        {/* RECORDS */}
        <td className="security__table__body-row-cell ">{account.matterCount ?? 0}</td>
        {currentlyEditing[account.id]?.isEditing ? (
          <td className="security__table__body-row-cell ">
            <button onClick={() => handleEditAccountName(account)}>{t("Confirm")}</button>
          </td>
        ) : (
          <td
            className="security__table__body-row-cell security__table__body-row-cell--edit clickable"
            onClick={() =>
              setCurrentlyEditing({
                ...currentlyEditing,
                [account.id]: { ...currentlyEditing[account.id], isEditing: true },
              })
            }
          >
            <Tooltip content="Edit this Account" direction="left">
              <FaEdit />
            </Tooltip>
          </td>
        )}
        {account.groupsCount > 0 || account.usersCount > 0 || account.matterCount > 0 ? (
          <td className="security__table__body-row-cell ">
            <Tooltip content="Cannot delete this user group as it contains groups, users or records" direction="right">
              <FaTimes className="security__table__body-row-cell--disabled" />
            </Tooltip>
          </td>
        ) : (
          <td className="security__table__body-row-cell ">
            <Delete
              message={"remove this Account"}
              onConfirm={() => deleteCustomerAccount(securityState.currentCustomer.id, account.id)}
            />
          </td>
        )}
      </tr>
    ));

  const renderUserTableBody =
    securityState.currentCustomer?.users?.length > 0 &&
    checkHiddenUsers.map((user) => {
      const linkedAccountCountClassNameBase = "security__table__body-row-cell";
      let linkedAccountCountClassName = linkedAccountCountClassNameBase;
      if (user.linkedAccountsCount === 0)
        linkedAccountCountClassName = `${linkedAccountCountClassNameBase} ${linkedAccountCountClassNameBase}--red color-text-red`;
      return (
        <tr className="security__table__body-row" key={user.id}>
          <td className="security__table__body-row-cell">
            <Link to={`/customers/${securityState.currentCustomer.id}/users/${user.id}`}>{user.fullName}</Link>
          </td>
          <td className="security__table__body-row-cell">{user.email}</td>
          <td className="security__table__body-row-cell">
            <Checkbox
              position="center"
              id={user.userId}
              onCheckChange={() => toggleUserCheckbox("isAdminUser", user)}
              isChecked={user.isAdminUser}
            />
          </td>
          <td className="security__table__body-row-cell">
            <Checkbox
              position="center"
              id={user.userId}
              onCheckChange={() => toggleUserCheckbox("isAppUser", user)}
              isChecked={user.isAppUser}
              isDisabled={!user.isAdminUser}
            />
          </td>
          {customerState.fullName === user.fullNames ? null : (
            <td className="security__table__body-row-cell">
              <SuperSelect
                id={user.userId}
                selectedOptionId={findById(customerStatuses, user.customerUserStatusId).id}
                options={customerStatuses}
                onChange={(value) =>
                  updateUserDetails(customerId, user.id, "customerUserStatusId", user.customerUserStatusId, value)
                }
              />
            </td>
          )}
          <td className={linkedAccountCountClassName}>{user.linkedAccountsCount}</td>
        </tr>
      );
    });

  const renderAccountsTable = (
    <>
      <div className="security__section-title">{t("Accounts")}</div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              {accountTableHeaders.map((header) =>
                header.isSortable ? (
                  <th
                    key={header.fieldName}
                    onClick={() => handleSortAccounts(header.fieldName)}
                    className="security__table__header-row-cell security__table__header-row-cell--clickable"
                  >
                    {t(header.name)}
                    {renderSortOrderIcon("accounts", header.fieldName)}
                  </th>
                ) : (
                  <th key={header.fieldName} className="security__table__header-row-cell">
                    {t(header.name)}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderAccountsTableBody}
            {isAddingAccount && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell ">
                  <input
                    placeholder="Account Name"
                    id="addNameInput"
                    type="text"
                    onChange={(e) => setAddAccountInputValue(e.target.value)}
                  ></input>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {!isAddingAccount ? (
        <button className="flex-row-center" onClick={() => setIsAddingAccount(true)}>
          {t("Add Account")}
        </button>
      ) : (
        <button onClick={() => handleAddNewAccount()}>{t("Confirm")}</button>
      )}
    </>
  );

  const renderUsersTable = (
    <>
      <div className="security__title-container">
        <div className="security__section-title">{t("Users")}</div>
        <div className="security__show-hidden-users link" onClick={() => setShowHiddenUsers(!showHiddenUsers)}>
          {t(
            `${showHiddenUsers ? "Hide " : "Show "}Hidden Users (${
              securityState.currentCustomer.users ? securityState.currentCustomer.users.length - hiddenUsers.length : 0
            })`
          )}
        </div>
      </div>
      <div className="security__table-container">
        <table className="security__table">
          <thead className="security__table__header">
            <tr className="security__table__header-row">
              {userTableHeaders.map((header) => (
                <th
                  key={header.fieldName}
                  onClick={() => handleSortUsers(header.fieldName)}
                  className="security__table__header-row-cell security__table__header-row-cell--clickable"
                >
                  {t(header.name)}
                  {renderSortOrderIcon("users", header.fieldName)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="security__table__body">
            {renderUserTableBody}
            {isAddingNewUser && (
              <tr className="security__table__body-row">
                <td className="security__table__body-row-cell">
                  <input
                    placeholder="Name"
                    id="addUserName"
                    type="text"
                    value={addUserInputValues.givenName}
                    onChange={(e) => setAddUserInputValues({ ...addUserInputValues, name: e.target.value })}
                  ></input>
                </td>
                <td className="security__table__body-row-cell">
                  <input
                    placeholder="Email"
                    id="addUserEmail"
                    type="text"
                    value={addUserInputValues.email}
                    onChange={(e) => setAddUserInputValues({ ...addUserInputValues, email: e.target.value })}
                  ></input>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {!isAddingNewUser ? (
        <button className="flex-row-center" onClick={() => setIsAddingNewUser(true)}>
          {t("Add User")}
        </button>
      ) : (
        <button onClick={() => handleAddingNewUser()}>{t("Confirm")}</button>
      )}
    </>
  );

  //MAIN RENDER
  return (
    <>
      {renderAccountsTable}
      {renderUsersTable}
      <MatterExport />
    </>
  );
}
