import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getCellText, getCellImage, setOutputStyle } from "utilities/reportOutput";

function Cell({ cell, row, output }) {
  const colSpan = cell.props?.colSpan ?? 1;
  const rowSpan = cell.props?.rowSpan ?? 1;

  const [cellStyle, setCellStyle] = useState({});

  useEffect(() => {
    if (cell.props?.style || cell.props?.style === 0) {
      setCellStyle(setOutputStyle(output, {}, cell.props.style));
    }
  }, [cell.props, output]);

  const [cellText, setCellText] = useState(null);

  useEffect(() => {
    setCellText(getCellText(output, cell));
  }, [cell, output]);

  const [imageControl, setImageControl] = useState(null);

  /* useEffect(async () => {
    setImageControl(getCellImage(output, row, cell));
  }, [output, row, cell]); */

  return (
    <td key={cell.col} className="table__cell" style={cellStyle} colSpan={colSpan} rowSpan={rowSpan < 1 ? 1 : rowSpan}>
      {cellText}
      {imageControl}
    </td>
  );
}

Cell.propTypes = {
  cell: PropTypes.object,
  output: PropTypes.object,
  row: PropTypes.object,
};

export default Cell;
