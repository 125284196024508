import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MatterRecord from "./MatterRecord";
import PropTypes from "prop-types";

// Container Component for comparing 2 or more matter records
function MatterRecordComparison({ section }) {
  const matterState = useSelector((state) => state.matter);

  const [record, setRecord] = useState(null);
  const [pinnedRecords, setPinnedRecords] = useState([]);
  const [unpinnedRecords, setUnpinnedRecords] = useState([]);

  useEffect(() => {
    if (matterState?.records) {
      setRecord(matterState.records[matterState.selectedRecordIndex]);

      // We always display ALL pinned records
      const pinnedRecordsLocal = matterState?.records?.filter((r) => r?.isPinned === true);
      setPinnedRecords(pinnedRecordsLocal);

      // We only display unpinned records that will fit within the window of:
      // - (positive) number of records to browse (user control)
      // - (negative) number of pinned records
      // e.g. if we have 5 records, but the user says only browse 3 at a time, and we have 1 pinned, we need to display 3 - 1 = 2 unpinned
      const unpinnedCompareCount = matterState.numberComparing - pinnedRecordsLocal.length;
      const unpinnedRecordsLocal = [];
      for (let i = matterState.selectedRecordIndex; i < matterState.records.length; i++) {
        if (matterState.records[i]?.isPinned === true) continue;
        if (unpinnedRecordsLocal.length >= unpinnedCompareCount) break;
        unpinnedRecordsLocal.push(matterState.records[i]);
      }
      setUnpinnedRecords(unpinnedRecordsLocal);
    } else {
      setPinnedRecords([]);
      setUnpinnedRecords([]);
    }
  }, [matterState.records, matterState.selectedRecordIndex, matterState.numberComparing]);

  const comparisonRenderPinned = pinnedRecords.map((r) => <MatterRecord key={r?.id} record={r} />);
  const comparisonRenderUnPinned = unpinnedRecords.map((r) => <MatterRecord key={r?.id} record={r} />);

  return (
    <div className="record__comparison">
      {matterState.isComparing ? (
        <>
          {comparisonRenderPinned} {comparisonRenderUnPinned}
        </>
      ) : (
        <MatterRecord record={record} section={section} />
      )}
    </div>
  );
}

MatterRecordComparison.propTypes = {
  section: PropTypes.string,
};

export default MatterRecordComparison;
