import { useEffect, useState } from "react";
import {
  saveMatterChildTableLinksChanges,
  saveMatterChildObjectFieldChanges,
  saveMatterSimpleFieldChanges,
} from "api/matter";
import { getAllLookupValuesForField, getLookupValueForField } from "utilities/datafield";
import SuperSelect from "components/global/SuperSelect";
import PropTypes from "prop-types";
import { getCompanyLinkTypes, getMatterLinkTypes } from "utilities/links";
import { useSelector } from "react-redux";
import { sectionTypes } from "utilities/constants";
import { formatControlId } from "utilities/stringAndArray";

// Container Component for the display of a lookup field within a matter data panel
function PanelFieldLookup({ keyProps, displayName, canEdit, onChange }) {
  const localeState = useSelector((state) => state.locale);

  const [selectedOptionId, setSelectedOptionId] = useState(null);

  useEffect(() => {
    setSelectedOptionId(keyProps.data);
  }, [keyProps.data]);

  const matterTypeId = keyProps.record.matter_MatterTypeId;
  const controlId = formatControlId(keyProps);

  let lookupOptions = null;
  if (keyProps.tableName === "companyLinks" && keyProps.field.fieldName === "matterCompanyLink_LinkTypeId") {
    //console.log("In render of DataPanelFieldLookup for id: " + keyProps.rowData.matterCompanyLink_LinkTypeId);
    lookupOptions = getCompanyLinkTypes(matterTypeId).map((clt) => {
      return { id: clt.id, displayValue: localeState.translations[clt.translationCode] };
    });
  } else if (keyProps.tableName === "links" && keyProps.field.fieldName === "matterLink_LinkTypeId") {
    lookupOptions = getMatterLinkTypes(matterTypeId, keyProps.rowData.matterLink_MatterTypeId).map((clt) => {
      return { id: clt.id, displayValue: localeState.translations[clt.translationCode] };
    });
  } else lookupOptions = getAllLookupValuesForField(keyProps.field.fieldName, [matterTypeId]);
  lookupOptions = [{ id: null, displayValue: "" }, ...lookupOptions];

  const onSelectChange = (optionId) => {
    setSelectedOptionId(optionId);
    if (keyProps.isAdding) onChange(keyProps.field.fieldName, optionId);
    else if (keyProps.isProject) onChange(keyProps.field.fieldName, keyProps.rowData, optionId, keyProps.tableName);
    else if (keyProps.sectionType === sectionTypes.TABLE) {
      if (keyProps.parentRowData) {
        saveMatterChildTableLinksChanges(
          keyProps.record.id,
          keyProps.parentTableName,
          keyProps.parentRowData.id,
          keyProps.tableName,
          keyProps.rowData.id,
          keyProps.field.fieldName,
          optionId,
          keyProps.data
        );
      } else
        saveMatterChildObjectFieldChanges(
          keyProps.record.id,
          keyProps.tableName,
          keyProps.rowData.id,
          keyProps.field.fieldName,
          optionId,
          keyProps.data
        );
    } else saveMatterSimpleFieldChanges(keyProps.record.id, keyProps.field.fieldName, optionId, keyProps.data);
  };

  const renderValueContents = canEdit
    ? lookupOptions && (
        <SuperSelect
          id={controlId}
          options={lookupOptions}
          selectedOptionId={selectedOptionId}
          onChange={onSelectChange}
          tabIndex={0}
          sortValuesById={keyProps.field.sortValuesById}
        />
      )
    : getLookupValueForField(keyProps.field.fieldName, keyProps.data);

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <>{renderValueContents}</>
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value">{renderValueContents}</div>
    </>
  );
}

PanelFieldLookup.propTypes = {
  keyProps: PropTypes.object,
  canEdit: PropTypes.bool,
  displayName: PropTypes.string,
  onChange: PropTypes.func,
};

export default PanelFieldLookup;
