import store from "redux/store";
import { clearDraftRecords, setBatchCreateProject, setDraftRecords, updateProjectField } from "redux/batchCreateSlice";
import { fetchWrapper } from "utilities/fetchWrapper";
import { projectStatuses } from "utilities/constants";

// LINKED MATTERS

export async function runBatchMatterSearch(accountId, matterTypeId, searchWords) {
  const state = store.getState();
  const body = { accountId, matterTypeId, searchWords };
  const response = await fetchWrapper.post(`customers/${state.app.customerId}/projects/mattersearch`, body);
  return response;
}

export async function getBatchLinkedMatters(projectId) {
  const state = store.getState();
  const response = await fetchWrapper.get(`customers/${state.app.customerId}/projects/${projectId}/linkedmatters`);
  return response;
}

export async function createBatchLinkedMatter(projectId, matterId) {
  const state = store.getState();
  const body = { projectId, matterId };
  const response = await fetchWrapper.post(
    `customers/${state.app.customerId}/projects/${projectId}/linkedmatters`,
    body
  );

  return response;
}

export async function deleteBatchLinkedMatter(projectId, linkId) {
  const state = store.getState();
  const response = await fetchWrapper.delete(
    `customers/${state.app.customerId}/projects/${projectId}/linkedmatters/${linkId}`
  );
  return response;
}

// MANUALLY ADDED LINKED MATTERS

export async function getBatchNewLinkedMatters(projectId) {
  const state = store.getState();
  const response = await fetchWrapper.get(`customers/${state.app.customerId}/projects/${projectId}/newlinkedmatters`);
  return response;
}

export async function createBatchNewLinkedMatter(projectId) {
  const state = store.getState();
  const body = { applicationDate: "", applicationNo: "", class: "" };
  const response = await fetchWrapper.post(
    `customers/${state.app.customerId}/projects/${projectId}/newlinkedmatters`,
    body
  );
  return response;
}

export async function updateBatchLinkedMatter(projectId, applicationId, fieldName, value) {
  const state = store.getState();
  const body = [
    //  { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  const response = await fetchWrapper.patch(
    `customers/${state.app.customerId}/projects/${projectId}/newlinkedmatters/${applicationId}`,
    body
  );
  return response;
}

export async function deleteBatchNewLinkedMatter(projectId, applicationId) {
  const state = store.getState();
  const response = await fetchWrapper.delete(
    `customers/${state.app.customerId}/projects/${projectId}/newlinkedmatters/${applicationId}`
  );
  return response;
}

// MASTER RECORD / TEMPLATE
export async function createMasterTemplate(project) {
  const state = store.getState();
  const response = await fetchWrapper.post(`customers/${state.app.customerId}/projects/${project.id}/buildtemplate`, {
    linkedMatters: project.linkedMatters.map((lm) => lm.matterId),
  });
  return response;
}

// IMAGES

// export async function getBatchImages(projectId) {
//   debugger;
//   const state = store.getState();
//   const response = await fetchWrapper.get(`customers/${state.app.customerId}/projects/${projectId}/images`);
//   return response;
// }

export async function getBatchImageData(projectId, imageId, asBase64 = false) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const requestOptions = {
    method: "GET",
    headers: { Authorization: state.app.bearerToken },
  };

  let url = `${process.env.REACT_APP_API_ROOT}customers/${customerId}/projects/${projectId}/imagedata/${imageId}`;
  if (!asBase64) url += "/formdata";

  const response = await fetch(url, requestOptions);

  if (asBase64) {
    const base64response = await response.text();
    return `data:image;base64,${base64response}`;
  }
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

export async function createBatchImage(projectId, batchImage) {
  const state = store.getState();
  const body = { ...batchImage };
  const response = await fetchWrapper.post(`customers/${state.app.customerId}/projects/${projectId}/images`, body);
  return response;
}

export async function updateBatchImage(projectId, imageId, fieldName, value) {
  const state = store.getState();
  const body = [
    //  { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  const response = await fetchWrapper.patch(
    `customers/${state.app.customerId}/projects/${projectId}/images/${imageId}`,
    body
  );
  return response;
}

export async function deleteBatchImage(projectId, imageId) {
  const state = store.getState();
  const response = await fetchWrapper.delete(
    `customers/${state.app.customerId}/projects/${projectId}/images/${imageId}`
  );
  return response;
}

// COMPANY LINKS

export async function getBatchCompanyLinks(projectId) {
  const state = store.getState();
  const response = await fetchWrapper.get(`customers/${state.app.customerId}/projects/${projectId}/companylinks`);
  return response;
}

export async function createBatchCompanyLink(projectId, batchCompanyLink) {
  const state = store.getState();
  const body = { ...batchCompanyLink };
  const response = await fetchWrapper.post(
    `customers/${state.app.customerId}/projects/${projectId}/companylinks`,
    body
  );
  return response;
}

export async function updateBatchCompanyLink(projectId, companyLinkId, fieldName, value) {
  const state = store.getState();
  const body = [
    //  { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  const response = await fetchWrapper.patch(
    `customers/${state.app.customerId}/projects/${projectId}/companylinks/${companyLinkId}`,
    body
  );
  return response;
}

export async function deleteBatchCompanyLink(projectId, companyLinkId) {
  const state = store.getState();
  const response = await fetchWrapper.delete(
    `customers/${state.app.customerId}/projects/${projectId}/companylinks/${companyLinkId}`
  );
  return response;
}

// COMPANY CONTACT LINKS

export async function getBatchCompanyContactLinks(projectId, companyLinkId) {
  const state = store.getState();
  const response = await fetchWrapper.get(
    `customers/${state.app.customerId}/projects/${projectId}/companylinks/${companyLinkId}/contactlinks`
  );
  return response;
}

export async function createBatchCompanyContactLink(projectId, companyLinkId, batchCompanyContactLink) {
  const state = store.getState();
  const body = { ...batchCompanyContactLink };
  const response = await fetchWrapper.post(
    `customers/${state.app.customerId}/projects/${projectId}/companylinks/${companyLinkId}/contactlinks`,
    body
  );
  return response;
}

export async function updateBatchCompanyContactLink(projectId, companyLinkId, contactLinkId, fieldName, value) {
  const state = store.getState();
  const body = [
    //  { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  const response = await fetchWrapper.patch(
    `customers/${state.app.customerId}/projects/${projectId}/companylinks/${companyLinkId}/contactlinks/${contactLinkId}`,
    body
  );
  return response;
}

export async function deleteBatchCompanyContactLink(projectId, companyLinkId, contactLinkId) {
  const state = store.getState();
  const response = await fetchWrapper.delete(
    `customers/${state.app.customerId}/projects/${projectId}/companylinks/${companyLinkId}/contactlinks/${contactLinkId}`
  );
  return response;
}

// GOODS

export async function getBatchGoods(projectId) {
  const state = store.getState();
  const response = await fetchWrapper.get(`customers/${state.app.customerId}/projects/${projectId}/goods`);
  return response;
}

export async function createBatchGood(projectId, batchGood) {
  const state = store.getState();
  const body = { ...batchGood };
  const response = await fetchWrapper.post(`customers/${state.app.customerId}/projects/${projectId}/goods`, body);
  return response;
}

export async function updateBatchGood(projectId, goodId, fieldName, value) {
  const state = store.getState();
  const body = [
    //  { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  const response = await fetchWrapper.patch(
    `customers/${state.app.customerId}/projects/${projectId}/goods/${goodId}`,
    body
  );
  return response;
}

export async function deleteBatchGood(projectId, goodId) {
  const state = store.getState();
  const response = await fetchWrapper.delete(`customers/${state.app.customerId}/projects/${projectId}/goods/${goodId}`);
  return response;
}

// COUNTRIES

export async function getBatchCountries(projectId) {
  const state = store.getState();
  const response = await fetchWrapper.get(`customers/${state.app.customerId}/projects/${projectId}/countries`);
  return response;
}

export async function createBatchCountry(projectId, batchCountry) {
  const state = store.getState();
  const body = { ...batchCountry };
  const response = await fetchWrapper.post(`customers/${state.app.customerId}/projects/${projectId}/countries`, body);
  return response;
}

export async function updateBatchCountry(projectId, countryId, fieldName, value) {
  const state = store.getState();
  const body = [{ path: `/${fieldName}`, op: "replace", value }];
  const response = await fetchWrapper.patch(
    `customers/${state.app.customerId}/projects/${projectId}/countries/${countryId}`,
    body
  );
  return response;
}

export async function removeBatchCountry(projectId, countryId) {
  const state = store.getState();
  const response = await fetchWrapper.delete(
    `customers/${state.app.customerId}/projects/${projectId}/countries/${countryId}`
  );
  return response;
}

// SUMMARY

let timeoutId = null;
let isRequestPending = false;

export async function refreshBatchSummary(project) {
  if (project?.project_Status === projectStatuses.MATTER_GENERATED) return;
  //debugger;
  if (isRequestPending) {
    // If a request is pending, reset the timeout
    clearTimeout(timeoutId);
  } else {
    // If no request is pending, set the flag to true
    isRequestPending = true;
  }

  timeoutId = setTimeout(async () => {
    try {
      // Only generate the summary if a jurisdiction and a good are present
      if (
        !project ||
        !project.countries ||
        !project.goods ||
        project.countries.length === 0 ||
        project.goods.length === 0
      ) {
        store.dispatch(clearDraftRecords());
        return;
      }

      const state = store.getState();
      const response = await fetchWrapper.get(
        `customers/${state.app.customerId}/projects/${project.id}/generatepreview`
      );
      // We only want to update the draft records, status and matter name if the response is successful because if we set the whole project,
      //it triggers and endless loop of generate preview due to useEffects detecting change in other project properties
      response && store.dispatch(setDraftRecords(response.draftRecords));
      if (response.project_Status !== project.project_Status) {
        store.dispatch(updateProjectField({ fieldName: "project_Status", value: response.project_Status }));
      }
      if (response.project_MatterName !== project.project_MatterName) {
        store.dispatch(updateProjectField({ fieldName: "project_MatterName", value: response.project_MatterName }));
      }
    } catch (error) {
      console.error("Error refreshing batch summary:", error);
    } finally {
      // Reset the flag and timeout after the request is complete
      isRequestPending = false;
      clearTimeout(timeoutId);
    }
  }, 2000); // 2 seconds timeout
}

export async function updateDraftRecord(projectId, draftRecordId, fieldName, value) {
  const state = store.getState();
  const body = [{ path: `/${fieldName}`, op: "replace", value }];
  const response = await fetchWrapper.patch(
    `customers/${state.app.customerId}/projects/${projectId}/draftrecords/${draftRecordId}`,
    body
  );
  return response;
}
