import { createMatterTypeAccessRight, deleteMatterTypeAccessRight, updateMatterTypeAccessRight } from "api/security";
import Delete from "components/global/Delete";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import { useState } from "react";
import { useSelector } from "react-redux";
import { accessRightTypes } from "utilities/constants";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { findById, idsAreEqual } from "utilities/stringAndArray";

export default function MatterTypeAccessPanel() {
  const security = useSelector((state) => state.security);

  const currentUserGroup = security.currentUserGroup;

  const [isAdding, setIsAdding] = useState(false);

  const matterTypes = getAllLookupValuesForSource("MatterTypes");
  const permissions = getAllLookupValuesForSource("Permissions");

  const accessRightsByMatterType = currentUserGroup?.accessRight?.matterTypes.map((type) => {
    return {
      id: type.id,
      matterTypeId: type.matterTypeId,
      permissionId: type.permissionId,
      displayValue: findById(matterTypes, type.matterTypeId)?.displayValue,
    };
  });

  const handleMatterTypeAccessChange = (optionId, typeAccess, field) => {
    updateMatterTypeAccessRight(
      security.currentCustomer.id,
      currentUserGroup.accessRight.id,
      typeAccess.id,
      typeAccess.permissionId,
      optionId
    );
  };

  const handleAddingMatterTypeChange = (optionId) => {
    createMatterTypeAccessRight(security.currentCustomer.id, currentUserGroup.accessRight.id, optionId);
    setIsAdding(false);
  };

  const renderAccessRightMatterTypeRow = (typeAccess) => {
    return (
      <tr key={`matterTypeAccessSelectionContainer-${typeAccess.id}`}>
        <td>{typeAccess?.displayValue}</td>
        <td>
          <SuperSelect
            selectedOptionId={typeAccess?.permissionId ?? accessRightTypes.NO_ACCESS}
            id={typeAccess.id}
            options={permissions ?? []}
            onChange={(id) => handleMatterTypeAccessChange(id, typeAccess, "permissionId")}
          />
        </td>
        <td>
          <Delete
            message={"remove this Matter Type Access"}
            onConfirm={() =>
              deleteMatterTypeAccessRight(security.currentCustomer.id, currentUserGroup.accessRight.id, typeAccess.id)
            }
          />
        </td>
      </tr>
    );
  };

  const renderAddMatterTypeAccessRow = () => {
    const filteredMatterTypes = matterTypes.filter(
      (mt) => !accessRightsByMatterType.some((armt) => idsAreEqual(armt.matterTypeId, mt.id))
    );
    return (
      <tr>
        <td>
          <SuperSelect
            selectedOptionId={null}
            id={`matterTypeAccessPanelTypeSelect`}
            options={filteredMatterTypes ?? []}
            placeholderText="Select Matter Type"
            onChange={handleAddingMatterTypeChange}
          />
        </td>
      </tr>
    );
  };

  //MAIN RENDER
  return (
    <div className="security__section">
      <div className="security__section-title">{t("Access By Matter Type Overrides")}</div>
      <div className="security__section-body">
        <table>
          <tbody>
            {accessRightsByMatterType?.length > 0 &&
              permissions?.length > 0 &&
              accessRightsByMatterType?.map(
                (typeAccess) => currentUserGroup.id && renderAccessRightMatterTypeRow(typeAccess)
              )}
            {isAdding && renderAddMatterTypeAccessRow()}
          </tbody>
        </table>
        {!isAdding && <button onClick={() => setIsAdding(true)}>{t("Add")}</button>}
      </div>
    </div>
  );
}
