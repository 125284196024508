import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { browseNavigation } from "redux/matterSlice";
import { getFirstUnpinnedRecord, getLastUnpinnedRecord } from "utilities/matter";
import PageNumber from "./PageNumber";

// Component for paginating/browsing of multiply selected
export default function MatterPaginator() {
  const matterState = useSelector((state) => state.matter);
  const dispatch = useDispatch();

  const selectedRecordIndex = matterState.selectedRecordIndex;
  const numRecords = matterState.records.length;
  const numPinnedRecords = matterState.records.filter((r) => r.isPinned === true).length;
  const hasAllSlotsFilled = numPinnedRecords >= matterState.numberComparing;

  const isShowPrevFirst = matterState.isComparing
    ? !hasAllSlotsFilled && selectedRecordIndex > getFirstUnpinnedRecord(matterState.records)
    : selectedRecordIndex > 0;

  const isShowNextLast = matterState.isComparing
    ? !hasAllSlotsFilled &&
      selectedRecordIndex < getLastUnpinnedRecord(matterState.records, matterState.numberComparing)
    : selectedRecordIndex < numRecords - 1;

  const onRecordIndexChangeClick = (verb) => {
    dispatch(browseNavigation(verb));
  };

  return (
    <div className="matters__paginator">
      {isShowPrevFirst ? (
        <>
          <div
            className="matters__page-action matters__page-action--active"
            onClick={() => onRecordIndexChangeClick("first")}
          >
            <FiChevronsLeft />
          </div>
          <div
            className="matters__page-action matters__page-action--active"
            onClick={() => onRecordIndexChangeClick("prev")}
          >
            <FiChevronLeft />
          </div>
        </>
      ) : (
        <>
          <div className="matters__page-action">
            <FiChevronsLeft />
          </div>
          <div className="matters__page-action">
            <FiChevronLeft />
          </div>
        </>
      )}
      <PageNumber />
      {isShowNextLast ? (
        <>
          <div
            className="matters__page-action matters__page-action--active"
            onClick={() => onRecordIndexChangeClick("next")}
          >
            <FiChevronRight />
          </div>
          <div
            className="matters__page-action matters__page-action--active"
            onClick={() => onRecordIndexChangeClick("last")}
          >
            <FiChevronsRight />
          </div>
        </>
      ) : (
        <>
          <div className="matters__page-action">
            <FiChevronRight />
          </div>
          <div className="matters__page-action">
            <FiChevronsRight />
          </div>
        </>
      )}
    </div>
  );
}
