import { deleteBatchProject, getBatchProject, getBatchProjects } from "api/project";
import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLookupValueBySource } from "utilities/lookup";
import { compareByFieldValue, getBooleanDisplayValue, idsAreEqual } from "utilities/stringAndArray";
import Delete from "components/global/Delete";
import { projectTypes } from "utilities/constants";
import { useState } from "react";
import { getDataTypeForField, getDisplayNameForField } from "utilities/datafield";
import { getUserFullNameFromId } from "utilities/customer";
import { getDateDisplayValue } from "utilities/dateTime";
import { FaSearch } from "react-icons/fa";
import SuperSelect from "components/global/SuperSelect";
import { setBatchCreateProject } from "redux/batchCreateSlice";
import { setBatchImportProject } from "redux/batchImportSlice";
import { clearCollapsedSections, setProject } from "redux/projectSlice";
import { clearResults } from "redux/searchResultsSlice";
import ProjectCreatePopup from "./ProjectCreatePopup";
import Paginator from "components/global/Paginator";

function ProjectBrowser({ projectTypeId }) {
  const projectState = useSelector((state) => state.project);

  const [draftProjectsSorted, setDraftProjectsSorted] = useState([]);
  const [sortColAsc, setSortColAsc] = useState(true);
  const [selectedMatterTypeId, setSelectedMatterTypeId] = useState(null);

  const isBatchCreate = idsAreEqual(projectTypeId, projectTypes.BATCH_CREATE);
  const [isShowingCreatePopup, setIsShowingCreatePopup] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [projectsThisPage, setProjectsThisPage] = useState([]);

  const matterTypesDisplay = projectState.matterTypesDisplay;

  const dispatch = useDispatch();

  const pageSize = 10;

  useEffect(() => {
    getBatchProjects();
  }, []);

  useEffect(() => {
    const draftProjectsLocal = projectState.draftProjects.filter((p) =>
      idsAreEqual(p.project_ProjectTypeId, projectTypeId)
    );
    setDraftProjectsSorted(draftProjectsLocal);
  }, [projectState.draftProjects]);

  useEffect(() => {
    const projectsThisPageLocal = draftProjectsSorted.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    setProjectsThisPage(projectsThisPageLocal);
    setTotalPages(Math.ceil(draftProjectsSorted.length / pageSize));
  }, [currentPage, draftProjectsSorted]);

  const handleColumnSortClick = (fieldName) => {
    const draftProjectsSortedLocal = [...draftProjectsSorted];
    const fieldType = getDataTypeForField(fieldName);
    draftProjectsSortedLocal.sort((a, b) => compareByFieldValue(a, b, fieldName, fieldType, sortColAsc));
    setSortColAsc(!sortColAsc);
    setDraftProjectsSorted(draftProjectsSortedLocal);
  };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const draftProjectsLocal = projectState?.draftProjects?.filter(
      (p) =>
        p?.project_ProjectName?.toLowerCase().includes(searchValue) ||
        p?.project_MatterName?.toLowerCase().includes(searchValue)
    );
    setDraftProjectsSorted(draftProjectsLocal);
  };

  const handleMatterTypeChange = (matterTypeId) => {
    setSelectedMatterTypeId(matterTypeId);
    const draftProjectsLocal = projectState.draftProjects.filter((p) =>
      idsAreEqual(p.project_MatterTypeId, matterTypeId)
    );
    setDraftProjectsSorted(draftProjectsLocal);
  };

  const handleSelectProject = async (projectId) => {
    const batchProject = await getBatchProject(projectId);
    dispatch(setProject(batchProject));
    idsAreEqual(projectTypeId, projectTypes.BATCH_CREATE)
      ? dispatch(setBatchCreateProject(batchProject))
      : dispatch(setBatchImportProject(batchProject));
    dispatch(clearCollapsedSections());
    dispatch(clearResults());
  };

  const renderMatterTypeFilter = (
    <>
      <div className="filter-bar__label">{t("Filter by Matter Type")}</div>
      <div className="filter-bar__select">
        {matterTypesDisplay && (
          <SuperSelect
            id="matter-type"
            options={matterTypesDisplay}
            selectedOptionId={selectedMatterTypeId}
            onChange={handleMatterTypeChange}
          />
        )}
      </div>
    </>
  );

  const renderSearchPanel = (
    <>
      <div className="filter-bar__label">{t("Search existing projects")}</div>
      <div className="filter-bar__search">
        <input onChange={handleSearchChange} />
        <div className="search__icon">
          <FaSearch />
        </div>
      </div>
    </>
  );

  const renderFilterBar = (
    <div className="project__filter-bar">
      {renderSearchPanel}
      {renderMatterTypeFilter}
    </div>
  );

  const renderTableColumnHeader = (fieldName) => {
    return (
      <th key={fieldName} onClick={() => handleColumnSortClick(fieldName)}>
        {getDisplayNameForField(fieldName)}
      </th>
    );
  };

  const renderTableHeader = (
    <>
      {renderTableColumnHeader("project_ProjectName")}
      {renderTableColumnHeader("project_CreatedUtcDate")}
      {renderTableColumnHeader("project_CreatedUserId")}
      {renderTableColumnHeader("project_GeneratedUtcDate")}
      {renderTableColumnHeader("project_ProjectTypeId")}
      {renderTableColumnHeader("project_MatterTypeId")}
      {isBatchCreate ? (
        <>
          {renderTableColumnHeader("project_MatterName")}
          {renderTableColumnHeader("project_IsPriorityClaimed")}
        </>
      ) : (
        <>
          {renderTableColumnHeader("project_ImportSourceTypeId")}
          {renderTableColumnHeader("project_Criteria")}
          {renderTableColumnHeader("project_SearchRecordCount")}
          {renderTableColumnHeader("project_ImportedRecordCount")}
        </>
      )}
      {renderTableColumnHeader("project_Status")}
      <th></th>
    </>
  );

  const renderTableRow = (p) => {
    const projectStatusText = getLookupValueBySource("ProjectStatuses", p.project_Status);
    const projectCreatedDateText = getDateDisplayValue(p.project_CreatedUtcDate);

    if (!p) return;

    return (
      <tr key={p.id}>
        <td className="link" onClick={() => handleSelectProject(p.id)}>
          {p.project_ProjectName}
        </td>
        <td>{projectCreatedDateText}</td>
        <td>{getUserFullNameFromId(p.project_CreatedUserId)}</td>
        <td>{getDateDisplayValue(p.project_GeneratedUtcDate)}</td>
        <td>{getLookupValueBySource("ProjectTypes", p.project_ProjectTypeId)}</td>
        <td>{getLookupValueBySource("MatterTypes", p.project_MatterTypeId)}</td>
        {isBatchCreate ? (
          <>
            <td>{p.project_MatterName}</td>
            <td>{getBooleanDisplayValue(p.project_IsPriorityClaimed)}</td>
          </>
        ) : (
          <>
            <td>{getLookupValueBySource("ProjectImportSourceTypes", p.project_ImportSourceTypeId)}</td>
            <td>{p.project_Criteria}</td>
            <td>{p.project_SearchRecordCount}</td>
            <td>{p.project_ImportedRecordCount}</td>
          </>
        )}
        <td>{projectStatusText}</td>
        <td>
          <Delete
            message={`Delete Project ${p.project_ProjectName}, Created on ${projectCreatedDateText} with Status ${projectStatusText}`}
            onConfirm={() => deleteBatchProject(p.id)}
          />
        </td>
      </tr>
    );
  };

  const renderDataRows = projectsThisPage?.length > 0 && projectsThisPage.map((p) => renderTableRow(p));

  return (
    <>
      <div className="project-body__home">
        {!isShowingCreatePopup && renderFilterBar}
        <div className="project__table">
          {draftProjectsSorted?.length === 0 ? (
            <div className="table__no-records">{t("No projects found")}</div>
          ) : (
            <>
              <table className="table__inner">
                <thead>
                  <tr>{renderTableHeader}</tr>
                </thead>
                <tbody>{renderDataRows}</tbody>
              </table>
              <div className="flex-row-center">
                <Paginator
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageFirstClick={() => setCurrentPage(0)}
                  onPageNextClick={() => setCurrentPage(Math.max(totalPages - 1, currentPage))}
                  onPagePrevClick={() => setCurrentPage(Math.min(0, currentPage - 1))}
                  onPageLastClick={() => setCurrentPage(totalPages - 1)}
                />
              </div>
            </>
          )}
        </div>
        <span className="link" onClick={() => setIsShowingCreatePopup(true)}>
          {t("Create New")}
        </span>
      </div>
      {isShowingCreatePopup && <ProjectCreatePopup onClose={() => setIsShowingCreatePopup(false)} />}
    </>
  );
}
ProjectBrowser.propTypes = {
  projectTypeId: PropTypes.number,
};

export default ProjectBrowser;
