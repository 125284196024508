import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { baseMenuItems, getHighchartsGlobeGraticule, highchartsGlobeRenderSea } from "utilities/charting";
import { getCountryIsoCodeFromAlpha2Code } from "utilities/countries";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { idsAreEqual } from "utilities/stringAndArray";

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

function HighchartsMap3d({ widgetId, output, onClick }) {
  const lookup = useSelector((state) => state.lookup);
  const mapData = useSelector((state) => state.mapData);
  const homeState = useSelector((state) => state.home);

  const chartRef = useRef(null);

  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chart = chartRef.current.chart;
      if (chart) {
        chart.reflow();
      }
    }
  }, [isZoomed]);

  useEffect(() => {
    if (idsAreEqual(homeState.zoomedWidgetId, widgetId)) {
      if (!isZoomed) setIsZoomed(true);
    } else if (isZoomed) {
      setIsZoomed(false);
    }
  }, [homeState.zoomedWidgetId]);

  /* useEffect(() => {
    // Attach the resize event listener to the chart container div on mount
    window.addEventListener("resize", handleChartResize);

    // Cleanup the resize event listener on unmount
    return () => {
      window.removeEventListener("resize", handleChartResize);
    };
  }, []); */

  const [mapOptions, setMapOptions] = useState(null);

  useEffect(() => {
    const recordCount = output.data.reduce((accumulator, countryData) => accumulator + countryData.value, 0);

    setMapOptions({
      title: {
        text: `Total: ${recordCount} records`,
        style: { fontSize: "1.25rem", fontWeight: "bold" },
      },
      chart: {
        backgroundColor: "transparent",
        type: "map",
        map: mapData.worldMap3dData,
        events: {
          render: function (chart, event) {
            highchartsGlobeRenderSea(this);
          },
        },
      },
      mapView: {
        maxZoom: 30,
        projection: {
          name: "Orthographic",
          rotation: [60, -30],
        },
      },
      mapNavigation: {
        enabled: true,
        enableDoubleClickZoomTo: true,
      },
      colorAxis: {
        minColor: "#BFCFAD",
        maxColor: "#31784B",
      },
      legend: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormatter: function () {
          return this.name + "- Count " + this.value;
        },
      },
      plotOptions: {
        series: {
          events: {
            click: function (event) {
              if (onClick) {
                const countryId = getCountryIsoCodeFromAlpha2Code(event.point["iso-a2"]);
                const filters = [{ fieldName: "matterCountry_Id", operator: "=", valueArray: [countryId] }];

                onClick(filters);
              }
            },
          },
        },
      },
      series: [
        {
          name: "Graticule",
          id: "graticule",
          type: "mapline",
          data: getHighchartsGlobeGraticule(),
          nullColor: "rgba(0, 0, 0, 0.05)",
          accessibility: {
            enabled: false,
          },
          enableMouseTracking: false,
        },
        {
          name: "",
          cursor: "pointer",
          joinBy: ["iso-a2", "hc-key"],
          data: output.data.map((countryData) => [countryData.key, countryData.value]),
        },
      ],

      exporting: {
        buttons: {
          contextButton: {
            menuItems: baseMenuItems,
          },
        },
      },
    });
  }, [output, mapData, onClick, lookup.global, isZoomed]);

  return mapOptions ? (
    <HighchartsReact
      ref={chartRef}
      containerProps={{
        style: { height: "100%", width: isZoomed ? "100%" : document.documentElement.clientWidth > 1800 ? 425 : 350 },
      }}
      highcharts={Highcharts}
      constructorType={"mapChart"}
      options={mapOptions}
      allowChartUpdate={false}
    />
  ) : null;
}

HighchartsMap3d.propTypes = {
  widgetId: PropTypes.number,
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default HighchartsMap3d;
