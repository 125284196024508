import store from "redux/store";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setFormatProperty } from "redux/reportSlice";
import Tooltip from "components/global/Tooltip";

function FontPicker({ value }) {
  const state = store.getState();
  const dispatch = useDispatch();
  const formatFonts = state.lookup.global.find((lookup) => lookup.name === "FormatFonts");

  return (
    <Tooltip content="Select font face" direction="left">
      <div className="toolbar__font-picker">
        <select
          name="fontFamily"
          className="font-picker__select"
          value={value}
          onChange={(event) => {
            dispatch(setFormatProperty({ name: "fontFamily", value: event.target.value }));
          }}
        >
          <option value="">Default</option>
          {formatFonts &&
            formatFonts.lookup.map((font) => {
              return (
                <option style={{ fontFamily: font.name }} key={font.name} value={font.name}>
                  {font.name}
                </option>
              );
            })}
        </select>
      </div>
    </Tooltip>
  );
}

FontPicker.propTypes = {
  value: PropTypes.string,
};

export default FontPicker;
