import { useState } from "react";

import PropTypes from "prop-types";
import MatterRecordUniversalPopup from "./UniversalPopup";

// Link component to display the Universal Popup Component for any matter record
function MatterRecordUniversalLink({ recordId, children }) {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  return (
    <>
      <div className="link" onClick={() => setIsPopupVisible(true)}>
        {children}
      </div>
      {isPopupVisible && <MatterRecordUniversalPopup recordId={recordId} onClose={() => setIsPopupVisible(false)} />}
    </>
  );
}

MatterRecordUniversalLink.propTypes = {
  recordId: PropTypes.number,
  children: PropTypes.node,
};

export default MatterRecordUniversalLink;
