import { useDispatch, useSelector } from "react-redux";
import { deleteToast } from "redux/appSlice";
import { FaTimes } from "react-icons/fa";
import { severities } from "utilities/constants";
import { t } from "locale/dictionary";

// Component for displaying temporary "toast" notifications on API responses, form updates etc.
function Toast() {
  const toasts = useSelector((state) => state.app).toasts;
  const dispatch = useDispatch();

  //const [toasts, setToasts] = useState([]);

  // To create fade-in/out effect, we have to "show" the toast in both hidden and active state within the DOM
  // useEffect(() => {
  //   const newToasts = appState.toasts.filter((appStateToast) => !toasts.includes(appStateToast));
  //   newToasts.forEach((newToast) => dispatch(showToast(newToast)));
  //   setToasts(appState.toasts);
  // }, [appState.toasts]);

  const onToastDelete = (toast) => {
    dispatch(deleteToast(toast.message));
  };

  const renderToasts = toasts.map((toast) => {
    let severityClassModifier = "info";
    if (toast.severity === severities.WARNING) severityClassModifier = "warning";
    if (toast.severity === severities.ERROR) severityClassModifier = "error";
    const outerClassNameBase = "toast";
    let outerClassName = `${outerClassNameBase} ${outerClassNameBase}--${severityClassModifier}`;
    if (toast.hidden === true) outerClassName += ` ${outerClassNameBase}--hidden`;

    return (
      <div key={toast.id} className={outerClassName}>
        <div className="toast__header">
          <div className="clickable" onClick={() => onToastDelete(toast)}>
            <FaTimes />
          </div>
        </div>
        <div className="toast__body">{t(toast.message)}</div>
      </div>
    );
  });

  return <div className="toast-list">{renderToasts}</div>;
}

export default Toast;
