import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { findById } from "utilities/stringAndArray";

export default function SecurityHeader() {
  const securityState = useSelector((state) => state.security);
  const location = useLocation();
  const { userId } = useParams();
  const [headerName, setHeaderName] = useState("");

  const pathDictionary = {
    customers: securityState?.currentCustomer?.name,
    accounts: securityState?.currentAccount?.name,
    usergroups: securityState?.currentUserGroup?.name,
    users: findById(securityState?.currentCustomer?.users, userId)?.fullName,
  };
  useEffect(() => {
    if (securityState.currentCustomer?.id) {
      const paths = location.pathname.split("/").filter((path) => path !== "");
      let names = [];
      if (paths.length === 1) names = ["Customers"];
      else {
        paths.forEach((path) => {
          if (pathDictionary[path]) {
            names.push(pathDictionary[path.toLocaleLowerCase()]);
          }
        });
      }
      setHeaderName(names?.at(-1));
    }
  }, [securityState.currentCustomer, securityState.currentUserGroup]);

  return <div className="security__header">{headerName}</div>;
}
