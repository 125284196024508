import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SearchResults from "../search-results/SearchResults";
import { getMatterLinkTypes } from "utilities/links";
import { t } from "locale/dictionary";
import SearchPanel from "components/simple-search/SearchPanel";
import PopupHeader from "components/global/PopupHeader";
import { addSectionTableExpandByMatterId, setConnectedMatterPopupVisible } from "redux/matterSlice";
import { clearResults, initialiseSearch, setViewTypeSearchResults } from "redux/searchResultsSlice";
import { addFilter, clearAllFilters, setViewTypeSimpleSearch } from "redux/simpleSearchSlice";
import SuperSelect from "components/global/SuperSelect";
import { saveNewChildObject } from "api/matter";
import { getAllLookupValuesForSource } from "utilities/lookup";
import { matterTypes, searchViewTypes } from "utilities/constants";
import { reloadFieldsAndFilterOnMatterType } from "utilities/simpleSearch";
import { filterOutOnId, findById, idsAreEqual } from "utilities/stringAndArray";

// Container Component for the connected matter search and results popup
function ConnectedMatterSearchPopup({ record, onSetPopupVisible }) {
  const localeState = useSelector((state) => state.locale);
  const searchResultsState = useSelector((state) => state.searchResults).currentSearch;
  const dispatch = useDispatch();

  const [selectedLinkTypeId, setSelectedLinkTypeId] = useState(null);
  const [selectedLinkType, setSelectedLinkType] = useState(null);
  const [selectedMatterTypeId, setSelectedMatterTypeId] = useState(null);
  const [numSelected, setNumSelected] = useState(0);
  const [numAdded, setNumAdded] = useState(0);
  const [availableLinkTypes, setAvailableLinkTypes] = useState([]);
  const [selectMatterTypeOptions, setSelectMatterTypeOptions] = useState([]);
  const [selectLinkTypeOptions, setSelectLinkTypeOptions] = useState([]);

  useEffect(() => {
    const matterTypesNoCompanies = filterOutOnId(getAllLookupValuesForSource("MatterTypes"), matterTypes.COMPANY);
    setSelectMatterTypeOptions(matterTypesNoCompanies);
  }, []);

  useEffect(() => {
    if (selectedMatterTypeId) {
      dispatch(
        addFilter({
          fieldName: "matter_MatterTypeId",
          operator: "=",
          valueArray: [selectedMatterTypeId],
        })
      );
      reloadFieldsAndFilterOnMatterType([selectedMatterTypeId]);
    }
    const availableLinkTypesLocal = getMatterLinkTypes(record.matter_MatterTypeId, selectedMatterTypeId);
    const selectLinkTypeOptionsLocal = availableLinkTypesLocal.map((alt) => {
      return { id: alt.id, displayValue: localeState.translations[alt.translationCode] };
    });
    setAvailableLinkTypes(availableLinkTypesLocal);
    setSelectLinkTypeOptions(selectLinkTypeOptionsLocal);

    // Special rule: Auto-select the link type if only one available, or if two and second is "Related Record"
    if (
      availableLinkTypesLocal.length === 1 ||
      (availableLinkTypesLocal.length === 2 && idsAreEqual(availableLinkTypesLocal[1].id, 2600)) // Related Record = 2600
    ) {
      setSelectedLinkTypeId(availableLinkTypesLocal[0].id);
      setSelectedLinkType(availableLinkTypesLocal[0]);
    } else {
      setSelectedLinkTypeId(null);
      setSelectedLinkType(null);
    }
  }, [selectedMatterTypeId]);

  useEffect(() => {
    setNumSelected(searchResultsState?.selected ? searchResultsState.selected.length : 0);
  }, [searchResultsState?.selected]);

  useEffect(() => {
    if (numAdded > 0 && numAdded === numSelected) handlePopupClose();
  }, [numAdded]);

  const onSelectedMatterTypeChange = (optionId) => {
    dispatch(clearResults());
    setSelectedMatterTypeId(optionId);
  };

  const onSelectedLinkTypeChange = (optionId) => {
    dispatch(clearResults());
    setSelectedLinkTypeId(optionId);
    const selectedLinkType = findById(availableLinkTypes, optionId);
    setSelectedLinkType(selectedLinkType);
  };

  const handlePopupClose = () => {
    dispatch(setConnectedMatterPopupVisible(false));
    dispatch(clearAllFilters());
    dispatch(setViewTypeSimpleSearch(searchViewTypes.MAIN));
    dispatch(setViewTypeSearchResults(searchViewTypes.MAIN));
    dispatch(initialiseSearch(searchViewTypes.CONNECTED_MATTERS));
    if (selectedMatterTypeId) {
      const linkedMattersSectionId = 80;
      dispatch(
        addSectionTableExpandByMatterId({
          sectionId: linkedMattersSectionId,
          matterTypeId: selectedMatterTypeId,
          isExpanded: true,
        })
      );
    }
    onSetPopupVisible(false);
  };

  const handleAddSelected = () => {
    searchResultsState.selected.forEach((id) => {
      const newLinkObject = {
        recordId: record.id,
        matterLink_MatterId: id,
        matterLink_LinkTypeId: selectedLinkTypeId,
      };
      saveNewChildObject(record.id, null, "links", newLinkObject);
      setNumAdded((prevNumAdded) => prevNumAdded + 1);
    });
  };

  const numSelectedString = numSelected > 0 ? `(${numSelected})` : "";
  const saveButtonText = `${t("Save All and Close")} ${numSelectedString}`;

  const classNameSelectLinkTypeBase = "add-connected__type-selector-value";
  let classNameSelectLinkType = classNameSelectLinkTypeBase;
  if (!selectedMatterTypeId)
    classNameSelectLinkType = `${classNameSelectLinkTypeBase} ${classNameSelectLinkTypeBase}--disabled`;

  return (
    <div className="add-connected">
      <PopupHeader title={t("Add New Linked Matter")} onClose={handlePopupClose} />
      <div className="add-connected__search-container">
        <div className="add-connected__type-selector">
          <div className="add-connected__type-selector-name">{t("Select Matter Type:")}</div>
          <div className="add-connected__type-selector-value">
            <SuperSelect
              id="matterTypeSelect"
              options={selectMatterTypeOptions}
              selectedOptionId={selectedMatterTypeId}
              onChange={(optionId) => onSelectedMatterTypeChange(optionId)}
            />
          </div>
        </div>

        <div className="add-connected__type-selector">
          <div className="add-connected__type-selector-name">{t("Select the Type of Link:")}</div>
          <div className={classNameSelectLinkType}>
            <SuperSelect
              id="linkTypeSelect"
              options={selectLinkTypeOptions}
              selectedOptionId={selectedLinkTypeId}
              onChange={(optionId) => onSelectedLinkTypeChange(optionId)}
              disabled={!selectedMatterTypeId}
            />
          </div>
        </div>

        <div className="add-connected__search-panel">
          <SearchPanel
            viewType={searchViewTypes.CONNECTED_MATTERS}
            record={record}
            isEmbedded={true}
            parentContainer="add-connected__search-panel"
            isEnabled={selectedLinkTypeId}
            selectedLinkType={selectedLinkType}
            matterTypeId={selectedMatterTypeId}
            disabled={!selectedMatterTypeId || !selectedLinkTypeId}
          />
        </div>
        {numSelected > 0 && (
          <div className="add-connected__actions">
            <button onClick={handleAddSelected}>{saveButtonText}</button>
            <button className="cancel" onClick={handlePopupClose}>
              {t("Cancel All and Close")}
            </button>
          </div>
        )}
      </div>
      <SearchResults isEmbedded={true} searchedByLinkTypeId={selectedLinkTypeId} />
    </div>
  );
}

ConnectedMatterSearchPopup.propTypes = {
  matterTypeId: PropTypes.number,
  onSetPopupVisible: PropTypes.func,
};

export default ConnectedMatterSearchPopup;
