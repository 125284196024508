// Date utility functions

import ar from "date-fns/locale/ar";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import it from "date-fns/locale/it";
import ja from "date-fns/locale/ja";
import ko from "date-fns/locale/ko";
import pt from "date-fns/locale/pt";
import ru from "date-fns/locale/ru";
import tr from "date-fns/locale/tr";
import zhCN from "date-fns/locale/zh-CN";
import zhTW from "date-fns/locale/zh-TW";
import zhHK from "date-fns/locale/zh-HK";
import store from "redux/store";
import { parse } from "date-fns";
import { registerLocale } from "react-datepicker";

// Get locale-specific date string
export function getDateDisplayValue(dateString, includeTime) {
  if (!dateString || dateString.length === 0) {
    return "";
  }

  const state = store.getState();
  try {
    const date = new Date(dateString);
    let options = { year: "numeric", month: "short", day: "numeric" };
    if (includeTime) {
      options.hour = "numeric";
      options.minute = "numeric";
    }
    return date.toLocaleDateString(state.locale.selectedLocale, options);
  } catch {
    return "";
  }
}

// Get difference in days between two dates
export function getDateDiffNumDays(date1, date2) {
  return Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24));
}

// Ensure correct timezone is applied when saving dates
export function toISOStringWithOffset(date) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
}

// Check if value is a date or not
export function isDate(value) {
  return value instanceof Date;
}

export const checkForValidDateEntered = (value, locale) => {
  // Prevent single digit or no supplied years from being processed
  // const valArray = newValue.split(/[/.\s]/);
  // if (valArray[valArray.length - 1].length < 2) return;
  // Try to parse the typed date with one of those associated with the user's locale
  const dateFormats = getDateFormats(locale);
  let newDate;
  dateFormats.forEach((dateFormat) => {
    let dateAttempt = parse(value, dateFormat, Date.now());
    const year = dateAttempt.getFullYear();
    if (isNaN(year)) return;
    if (year <= 70) dateAttempt.setFullYear(year + 2000);
    else if (year > 70 && year < 100) dateAttempt.setFullYear(year + 1900);
    else if (year < 1800) return;
    newDate = dateAttempt;
  });
  return newDate;
};

export const getDateFormats = (locale) => {
  let language = locale.substring(0, 2);
  let dateFormats = [
    "dd/MM",
    "dd.MM",
    "dd MM",
    "dd-MM",
    "ddMMMMyyyy",
    "dd MMMMyyyy",
    "ddMMMM yyyy",
    "dd MMMM yyyy",
    "dd-MMMM-yyyy",
    "dd/MMMM/yyyy",
    "dd\\MMMM\\yyyy",
    "dd.MMMM.yyyy",
    "ddMMMyyyy",
    "dd MMMyyyy",
    "ddMMM yyyy",
    "dd MMM yyyy",
    "dd-MMM-yyyy",
    "dd/MMM/yyyy",
    "dd\\MMM\\yyyy",
    "dd.MMM.yyyy",
    "dd MM yyyy",
    "dd-MM-yyyy",
    "dd/MM/yyyy",
    "dd\\MM\\yyyy",
    "dd.MM.yyyy",
    "ddMMyy",
    "ddMM",
  ];
  if (locale === "en-US") {
    dateFormats = [
      "MM/dd",
      "MM.dd",
      "MM dd",
      "MM-dd",
      "MMMMdd yyyy",
      "MMMM dd yyyy",
      "MMMM dd, yyyy",
      "MMMM-dd-yyyy",
      "MMMM/dd/yyyy",
      "MMMM\\dd\\yyyy",
      "MMMM.dd.yyyy",
      "MMMdd yyyy",
      "MMMdd yyyy",
      "MMM dd yyyy",
      "MMM-dd-yyyy",
      "MMM/dd/yyyy",
      "MMM\\dd\\yyyy",
      "MMM.dd.yyyy",
      "MM dd yyyy",
      "MM-dd-yyyy",
      "MM/dd/yyyy",
      "MM\\dd\\yyyy",
      "MM.dd.yyyy",
    ];
  } else if (language === "ja" || language === "ko" || language === "zh") {
    dateFormats = [
      "yyyyMMMMdd",
      "yyyyMMMM dd",
      "yyyy MMMMdd",
      "yyyy MMMM dd",
      "yyyy-MMMM-dd",
      "yyyy\\MMMM\\dd",
      "yyyy/MMMM/dd",
      "yyyy.MMMM.dd",
      "yyyyMMMdd",
      "yyyyMMM dd",
      "yyyy MMMdd",
      "yyyy MMM dd",
      "yyyy-MMM-dd",
      "yyyy\\MMM\\dd",
      "yyyy/MMM/dd",
      "yyyy.MMM.dd",
      "yyyy MM dd",
      "yyyy-MM-dd",
      "yyyy/MM/dd",
      "yyyy\\MM\\dd",
      "yyyy.MM.dd",
    ];
  }
  switch (locale) {
    case "ja-JP":
      registerLocale("ja", ja);
      break;
    case "ko-KR":
      registerLocale("ko", ko);
      break;
    case "zh-CN": {
      language = locale;
      registerLocale("zh-CN", zhCN);
      break;
    }
    case "zh-HK": {
      language = locale;
      registerLocale("zh-HK", zhHK);
      break;
    }
    case "zh-TW": {
      language = locale;
      registerLocale("zh-TW", zhTW);
      break;
    }
    default:
      break;
  }

  switch (language) {
    case "ar":
      registerLocale("ar", ar);
      break;
    case "de":
      registerLocale("de", de);
      break;
    case "es":
      registerLocale("es", es);
      break;
    case "fr":
      registerLocale("fr", fr);
      break;
    case "it":
      registerLocale("it", it);
      break;
    case "pt":
      registerLocale("pt", pt);
      break;
    case "ru":
      registerLocale("ru", ru);
      break;
    case "tr":
      registerLocale("tr", tr);
      break;
    default:
      break;
  }

  return dateFormats;
};
