import { useDispatch } from "react-redux";
import { setFormatProperty } from "redux/reportSlice";
import useComponentVisible from "components/global/useComponentVisible";
import ToolbarPropertyButton from "./ToolbarPropertyButton";
import ColorPicker from "./ColorPicker";
import { FaCaretDown, FaPaintRoller } from "react-icons/fa";
import PropTypes from "prop-types";
import Tooltip from "components/global/Tooltip";

function ToolbarColorPicker({ name, color, background, tooltipText }) {
  const dispatch = useDispatch();

  const [refColorPicker, showColorPicker, setShowColorPicker] = useComponentVisible(false);

  return (
    <Tooltip content={tooltipText} direction="left">
      <>
        {background ? (
          <ToolbarPropertyButton name={name} value={color} style={{ backgroundColor: color }} selected={false}>
            <FaPaintRoller className="button__icon" />
          </ToolbarPropertyButton>
        ) : (
          <ToolbarPropertyButton
            name={name}
            value={color}
            style={{
              marginBottom: "2px",
              fontWeight: "bold",
              borderBottomStyle: "solid",
              borderBottomWidth: "3px",
              borderBottomColor: color,
              height: "calc(100% - 5px)",
              lineHeight: "21px",
              verticalAlign: "top",
            }}
            selected={false}
          >
            A
          </ToolbarPropertyButton>
        )}

        <div
          className="toolbar__button"
          onClick={() => setShowColorPicker(!showColorPicker)}
          style={{
            width: "18px",
          }}
        >
          <FaCaretDown className="button__icon" />
        </div>
        <ColorPicker
          inputRef={refColorPicker}
          show={showColorPicker}
          setShow={setShowColorPicker}
          color={color}
          setColor={(dirtyColor) => {
            dispatch(setFormatProperty({ name: name, value: dirtyColor }));
          }}
        />
      </>
    </Tooltip>
  );
}

ToolbarColorPicker.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  background: PropTypes.bool,
  tooltipText: PropTypes.string,
};

export default ToolbarColorPicker;
