import { useEffect, useState } from "react";
import { saveMatterChildObjectFieldChanges, saveMatterSimpleFieldChanges } from "api/matter";
import PropTypes from "prop-types";
import { useBeforeunload } from "react-beforeunload";
import { usePrompt } from "utilities/react-router-hacks";
import { sectionTypes } from "utilities/constants";
import { formatControlId } from "utilities/stringAndArray";

// Container Component for the display of a text field within a matter data panel
function PanelFieldText({ keyProps, canEdit, displayName, onChange }) {
  const [value, setValue] = useState(keyProps.data ?? "");

  const [isEditing, setIsEditing] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const controlId = formatControlId(keyProps);

  useBeforeunload((event) => {
    //If contents have changed, sends a warning dialog to the user if they try to close the browser or the hosting browser tab or navigate to a different page (excluding within same React App - see "usePrompt")
    if (isChanged) {
      event.preventDefault();
    }
  });

  usePrompt("You have unsaved changes. Are you sure you want to leave?", isChanged);

  useEffect(() => {
    setValue(keyProps.data ?? "");
  }, [keyProps.data]);

  // We have to manually set focus on a textarea when the readonly equivalent (simple HTML text) is clicked on, as the textarea isn't in the DOM on first click
  useEffect(() => {
    if (isEditing) {
      const el = document.getElementById(controlId);
      if (el) el.focus();
    }
  }, [isEditing, controlId]);

  const onTextChange = (newValue) => {
    if (newValue === value && isChanged) setIsChanged(false);
    if (newValue !== value && !isChanged) setIsChanged(true);

    setValue(newValue);
  };

  const saveTextChanges = () => {
    if (keyProps.isAdding) onChange(keyProps.field.fieldName, value);
    else if (keyProps.isProject) onChange(keyProps.field.fieldName, keyProps.rowData, value);
    else if (keyProps.sectionType === sectionTypes.TABLE)
      saveMatterChildObjectFieldChanges(
        keyProps.record.id,
        keyProps.tableName,
        keyProps.rowData.id,
        keyProps.field.fieldName,
        value,
        keyProps.data
      );
    else saveMatterSimpleFieldChanges(keyProps.record.id, keyProps.field.fieldName, value, keyProps.data);

    setIsChanged(false);
    setIsEditing(false);
  };

  const renderSimpleTextInput = (
    <input
      id={controlId}
      type="text"
      value={value}
      onChange={(e) => onTextChange(e.target.value)}
      onBlur={(e) => saveTextChanges()}
      tabIndex={0}
      autoComplete="off"
    />
  );

  const renderValueContentsStandard =
    keyProps.field.isReadOnly || keyProps.isPanelReadOnly /*&& keyProps.isAdding !== true*/
      ? value
      : renderSimpleTextInput;

  const textareaRowCalc = keyProps.field.longText && Math.max(value.length / keyProps.field.width, 2);

  const renderValueContentsLongText =
    (canEdit && isEditing) || keyProps.isAdding ? (
      <textarea
        id={controlId}
        value={value}
        tabIndex={0}
        onChange={(e) => onTextChange(e.target.value)}
        onBlur={(e) => saveTextChanges()}
        rows={textareaRowCalc}
      />
    ) : (
      <div className="elem-to-focus" tabIndex={0} onFocus={() => setIsEditing(true)}>
        {value}
      </div>
    );

  const renderValueContents = keyProps.field.longText ? renderValueContentsLongText : renderValueContentsStandard;

  return keyProps.sectionType === sectionTypes.TABLE ? (
    <div className="field__input" onClick={() => setIsEditing(true)}>
      {renderValueContents}
    </div>
  ) : (
    <>
      <div className="field__display-name">{displayName}</div>
      <div className="field__display-value" onClick={() => setIsEditing(true)}>
        {renderValueContents}
      </div>
    </>
  );
}

PanelFieldText.propTypes = {
  keyProps: PropTypes.object,
  canEdit: PropTypes.bool,
  displayName: PropTypes.string,
  onChange: PropTypes.func,
};

export default PanelFieldText;
