import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getDefaultStyleItem, pushStyle } from "utilities/reportEditor";
import ReportColumnGroup from "./ReportColumnGroup";
import ReportColumnHeader from "./ReportColumnHeader";
import ReportSectionGroup from "./ReportSectionGroup";
import { setSectionRowHeight } from "redux/reportSlice";
import { useDispatch } from "react-redux";

function ReportTable({ format, matterTypeIds }) {
  const dispatch = useDispatch();
  const [style, setStyle] = useState({});
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [dragState, setDragState] = useState({
    isDragging: false,
    row: null,
    sectionId: null,
    startHeight: null,
    newHeight: null,
  });

  useEffect(() => {
    // Calculate the table width
    const gridWidth = format.gridColumns.reduce((total, gridColumn) => total + gridColumn.width, 40);

    setStyle({ width: gridWidth + "px" });
  }, [format.gridColumns]);

  const [styleItems, setStyleItems] = useState([]);

  useEffect(() => {
    // Get the section group style
    const styleItems = [];
    const styleItem = getDefaultStyleItem(format.gridSectionGroup.style);
    if (styleItem) {
      pushStyle(styleItems, "table", styleItem);
    }
    setStyleItems(styleItems);
  }, [format.gridSectionGroup.style]);

  const [columnStyleItems, setColumnStyleItems] = useState([]);

  useEffect(() => {
    // Get the column styling
    setColumnStyleItems(
      format.gridColumns
        .map((col) => {
          return { column: col.column, styleItem: getDefaultStyleItem(col.style) };
        })
        .filter((result) => result !== null)
    );
  }, [format.gridColumns]);

  const handleDragMouseDown = (event, row, sectionId) => {
    const initialHeight = row.rowHeight ?? 10;
    setDragState({
      isDragging: true,
      row: row.row,
      sectionId,
      startHeight: initialHeight,
      newHeight: initialHeight,
    });
    setStartPos({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = (event) => {
    if (dragState.isDragging) {
      const diffY = event.clientY - startPos.y;
      const newHeight = dragState.startHeight + diffY;
      if (newHeight < 10) return;
      const newDragState = { ...dragState, newHeight };
      handleDragChange(newDragState);
    }
  };

  const handleMouseUp = () => {
    if (dragState.isDragging) {
      dispatch(setSectionRowHeight({ value: dragState.newHeight }));
      setDragState({ isDragging: false, row: null, sectionId: null, startHeight: null, newHeight: null });
    }
  };

  const handleDragChange = (dragState) => {
    setDragState(dragState);
  };

  return (
    <div className="report-editor__table-container">
      <table className="table-container__table" style={style}>
        <ReportColumnGroup format={format} dragState={dragState} />
        <thead className="table__header">
          <ReportColumnHeader format={format} onDragChange={handleDragChange} />
        </thead>
        <tbody className="table__body" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
          <tr className="body__blank-row">
            <td>&nbsp;</td>
          </tr>
          <ReportSectionGroup
            key={format.gridSectionGroup.id}
            format={format}
            sectionGroup={format.gridSectionGroup}
            styleItems={styleItems}
            columnStyleItems={columnStyleItems}
            matterTypeIds={matterTypeIds}
            isTopLevel={true}
            onDragMouseDown={handleDragMouseDown}
            dragState={dragState}
          />
        </tbody>
      </table>
    </div>
  );
}

ReportTable.propTypes = {
  format: PropTypes.object,
  matterTypeIds: PropTypes.array,
};

export default ReportTable;
