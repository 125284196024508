import { t } from "locale/dictionary";
import Lookups from "./Lookups";

// Configuration container
export default function Configuration() {
  return (
    <div className="configuration">
      <div className="configuration__title">{t("Configuration")}</div>
      <Lookups />
    </div>
  );
}
