import { useDispatch, useSelector } from "react-redux";
import { FaChevronLeft } from "react-icons/fa";
import ViewTypeSelector from "./ViewTypeSelector";
import { t } from "locale/dictionary";
import Switch from "components/global/Switch";
import { toggleAbbreviated } from "redux/searchResultsSlice";
import { FormatTypes } from "utilities/constants";
import { useNavigate } from "react-router-dom";
import { searchViewTypes } from "utilities/constants";

// Component for the Search Results Information Header (not column headers - see DataGridColumnHeader.jsx)
export default function SearchResultsHeader() {
  const searchResults = useSelector((state) => state.searchResults).currentSearch;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const numSearchResults =
    searchResults?.results && searchResults.results[0] ? searchResults.results[0].data.length : 0;
  const viewTypeId = searchResults.viewType;

  const handleBackChevronClick = () => {
    navigate(`/`, { replace: false });
  };

  const handleNumberSelectedClick = () => {
    alert("Number Selected Clicked!");
  };

  const resultWording = numSearchResults === 1 ? t("result found") : t("results found");

  return (
    <div className="search-results__header">
      <div className="header__left-section">
        {viewTypeId !== searchViewTypes.BATCH_IMPORT && (
          <div className="header__back-chevron" onClick={handleBackChevronClick}>
            <FaChevronLeft />
          </div>
        )}
        <div className="header__results-found">
          {numSearchResults} {resultWording} &nbsp;·
        </div>
        <div className="header__number-selected">
          &nbsp;[&nbsp;
          <span className="header__number-selected__inner" onClick={handleNumberSelectedClick}>
            {searchResults?.selected?.length}&nbsp;
            {t("selected")}
          </span>
          &nbsp;]
        </div>
        {viewTypeId !== searchViewTypes.BATCH_IMPORT && (
          <div className="header__abbreviated">
            <Switch
              isOn={searchResults?.abbreviated}
              onSwitchChange={() => dispatch(toggleAbbreviated())}
              text={t("Abbreviated Column Names")}
            />
          </div>
        )}
      </div>
      {viewTypeId !== searchViewTypes.BATCH_IMPORT && (
        <div className="header__right-section">
          <div className="header__results-type-selector">
            <ViewTypeSelector viewType={FormatTypes.LIST} />
            <ViewTypeSelector viewType={FormatTypes.GRID} />
            <ViewTypeSelector viewType={FormatTypes.MAP} />
            <ViewTypeSelector viewType={FormatTypes.PIVOT} />
          </div>
        </div>
      )}
    </div>
  );
}
