import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { formatInsert, setGridSectionRowSelected } from "redux/reportSlice";
import { isGridSelected, isSectionSelected, isRowSelected, isAnyRowCellSelected } from "utilities/reportEditor";
import { FaArrowsAltV, FaPlusCircle } from "react-icons/fa";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";

function ReportSectionRowHeader({ row, prevRow, section, isTopLevel, onDragMouseDown, dragState }) {
  const reportState = useSelector((state) => state.report);
  const selectedGridElements = reportState.selectedGridElements;

  const dispatch = useDispatch();

  const borderClassName = row.row === 1 ? "table__border-left-right" : "table__border-top-left-right";

  const [headerSelectedClassName, setHeaderSelectedClassName] = useState("");
  const [isInsertingRowBefore, setIsInsertingRowBefore] = useState(false);
  const [isInsertingRowAfter, setIsInsertingRowAfter] = useState(false);
  const [prevRowIsSelected, setPrevRowIsSelected] = useState(false);
  const [rowIsSelected, setRowIsSelected] = useState(false);

  useEffect(() => {
    const rowIsSelectedLocal = isRowSelected(selectedGridElements, section.id, row.row, true);
    setRowIsSelected(rowIsSelectedLocal);
    setPrevRowIsSelected(isRowSelected(selectedGridElements, section.id, row.row - 1, true));

    setHeaderSelectedClassName(
      isGridSelected(selectedGridElements) ||
        isSectionSelected(selectedGridElements, section.id) ||
        rowIsSelectedLocal ||
        isAnyRowCellSelected(selectedGridElements, section.id, row.row, true)
        ? "table__object--selected"
        : ""
    );

    if (isInsertingRowBefore) {
      dispatch(formatInsert({ isAfter: false }));
      setIsInsertingRowBefore(false);
    }

    if (isInsertingRowAfter) {
      dispatch(formatInsert({ isAfter: true }));
      setIsInsertingRowAfter(false);
    }
  }, [selectedGridElements, section.id, row.row]);

  const handleInsertRowBefore = (e) => {
    e.stopPropagation();
    dispatch(setGridSectionRowSelected({ row, section }));
    setIsInsertingRowBefore(true);
  };

  const handleInsertRowAfter = (e) => {
    e.stopPropagation();
    dispatch(setGridSectionRowSelected({ row, section }));
    setIsInsertingRowAfter(true);
  };

  const renderBeforeDivider = prevRowIsSelected ? (
    <div
      className="row-header-cell__resize row-header-cell__resize--before"
      onMouseDown={(e) => {
        onDragMouseDown(e, prevRow, section?.id);
      }}
    >
      <FaArrowsAltV />
    </div>
  ) : (
    <div className="row-header-cell__insert row-header-cell__insert--before" onClick={handleInsertRowBefore}>
      <FaPlusCircle />
    </div>
  );

  const renderAfterDivider =
    row.row === section.rowCount &&
    (rowIsSelected ? (
      <div
        className="row-header-cell__resize row-header-cell__resize--after"
        onMouseDown={(e) => onDragMouseDown(e, row, section?.id)}
      >
        <FaArrowsAltV />
      </div>
    ) : (
      <div className="row-header-cell__insert row-header-cell__insert--after" onClick={handleInsertRowAfter}>
        <FaPlusCircle />
      </div>
    ));

  return (
    <>
      <td
        colSpan={2}
        className={["body__row-header-cell", borderClassName, headerSelectedClassName].join(" ")}
        onClick={(event) => {
          event.stopPropagation();
          if (dragState.isDragging) return;
          dispatch(
            setGridSectionRowSelected({
              ctrlKey: event.ctrlKey,
              shiftKey: event.shiftKey,
              section: section,
              row: row,
            })
          );
        }}
      >
        {row.row}
        {renderBeforeDivider}
        {renderAfterDivider}
      </td>
    </>
  );
}

ReportSectionRowHeader.propTypes = {
  row: PropTypes.object,
  prevRow: PropTypes.object,
  section: PropTypes.object,
  isTopLevel: PropTypes.bool,
  onDragMouseDown: PropTypes.func,
  dragState: PropTypes.object,
};

export default ReportSectionRowHeader;
