import { FaExclamationTriangle } from "react-icons/fa";
import PropTypes from "prop-types";

// Control for display warning or error symbols inline with text
// Set level in props to one of the following: "warning", "error"
function InlineAlert({ level, hasAbsolutePosition }) {
  const classNameBase = "inline-alert";
  let className = `${classNameBase} ${classNameBase}--${level}`;

  if (hasAbsolutePosition) {
    className = ` ${className} ${classNameBase}--absolute`;
  }

  return (
    <div className={className}>
      <FaExclamationTriangle />
    </div>
  );
}

InlineAlert.propTypes = {
  level: PropTypes.string,
  hasAbsolutePosition: PropTypes.bool,
};

export default InlineAlert;
