import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { search } from "api/search";
import ReportOutput from "../report-output/ReportOutput";

function Map({ widget, onSearchClick }) {
  const [output, setOutput] = useState(null);

  useEffect(() => {
    const queryObject = {
      ...widget.queryObject,
      searchFilters: widget.filters,
    };

    search(queryObject).then((response) => {
      setOutput(response);
    });
  }, [widget]);

  return output ? <ReportOutput widgetId={widget.id} output={output} onClick={onSearchClick} /> : null;
}

Map.propTypes = {
  widget: PropTypes.object,
  onSearchClick: PropTypes.func,
};

export default Map;
