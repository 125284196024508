import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clearProperties, formatDelete, mergeCells, setFormatProperty } from "redux/reportSlice";
import { findSection, getDefaultStyleItem, mergeStyleItems } from "utilities/reportEditor";
import PropTypes from "prop-types";
import FontPicker from "./FontPicker";
import ToolbarBorderPicker from "./ToolbarBorderPicker";
import ToolbarButton from "../../toolbar/ToolbarButton";
import ToolbarColorPicker from "./ToolbarColorPicker";
import ToolbarPropertyButton from "./ToolbarPropertyButton";
import ToolbarSeparator from "./Separator";
import ToolbarGrid from "./ToolbarGrid";
import ToolbarGridColumn from "./ToolbarGridColumn";
import { FaAlignCenter, FaAlignLeft, FaAlignRight, FaEraser } from "react-icons/fa";
import { AiOutlineVerticalAlignTop, AiOutlineVerticalAlignMiddle, AiOutlineVerticalAlignBottom } from "react-icons/ai";
import ToolbarSection from "./ToolbarSection";
import ToolbarSectionRow from "./ToolbarSectionRow";
import ToolbarSectionCell from "./ToolbarSectionCell";
import InputNumberPicker from "./InputNumberPicker";
import { t } from "locale/dictionary";
import { RiMergeCellsHorizontal, RiMergeCellsVertical } from "react-icons/ri";
import Delete from "components/global/Delete";

function GridToolbar({ reportId, format, selected, outputType, onOutputTypeClick }) {
  const dispatch = useDispatch();

  const [fontSize, setFontSize] = useState(12);
  const [fontFamily, setFontFamily] = useState("");
  const [fontStyle, setFontStyle] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [textAlign, setTextAlign] = useState("");
  const [textDecoration, setTextDecoration] = useState("");
  const [verticalAlign, setVerticalAlign] = useState("");

  useEffect(() => {
    if (selected.length > 0) {
      // Get the last selected item
      const lastSelected = selected[selected.length - 1];

      const mergedStyle = {};

      let style = null;
      switch (lastSelected.type) {
        case "cell":
          {
            const section = findSection(format.gridSectionGroup, lastSelected.sectionId);
            let cell = null;
            if (section.cells) {
              cell = section.cells.find((cell) => cell.row === lastSelected.row && cell.column === lastSelected.col);
            }
            if (cell) {
              style = cell.style;
              if (style) {
                const styleItem = getDefaultStyleItem(style);
                mergeStyleItems(mergedStyle, styleItem);
              }
            }
          }
          break;
        default:
      }

      switch (lastSelected.type) {
        case "cell":
        case "row":
          {
            const section = findSection(format.gridSectionGroup, lastSelected.sectionId);
            let row = null;
            if (section.rows) {
              row = section.rows.find((row) => row.row === lastSelected.row);
            }
            if (row) {
              style = row.style;
              if (style) {
                const styleItem = getDefaultStyleItem(style);
                mergeStyleItems(mergedStyle, styleItem);
              }
            }
          }
          break;
        default:
      }

      switch (lastSelected.type) {
        case "cell":
        case "row":
        case "section":
          {
            const section = findSection(format.gridSectionGroup, lastSelected.sectionId);
            if (section) {
              style = section.style;
              if (style) {
                const styleItem = getDefaultStyleItem(style);
                mergeStyleItems(mergedStyle, styleItem);
              }
            }
          }
          break;
        default:
      }

      switch (lastSelected.type) {
        case "cell":
        case "column":
          {
            const column = format.gridColumns.find((col) => col.column === lastSelected.col);
            if (column) {
              style = column.style;
              if (style) {
                const styleItem = getDefaultStyleItem(style);
                mergeStyleItems(mergedStyle, styleItem);
              }
            }
          }
          break;
        default:
      }

      const grid = format.gridSectionGroup;
      if (grid) {
        style = grid.style;
        if (style) {
          const styleItem = getDefaultStyleItem(style);
          mergeStyleItems(mergedStyle, styleItem);
        }
      }

      if (mergedStyle?.fontFamily) {
        setFontFamily(mergedStyle.fontFamily);
      } else {
        setFontFamily("");
      }
      if (mergedStyle?.fontSize) {
        setFontSize(mergedStyle.fontSize);
      } else {
        setFontSize(12);
      }
      if (mergedStyle?.fontStyle) {
        setFontStyle(mergedStyle.fontStyle);
      } else {
        setFontStyle(mergedStyle.fontStyle);
      }
      if (mergedStyle?.fontWeight) {
        setFontWeight(mergedStyle.fontWeight);
      } else {
        setFontWeight("");
      }
      if (mergedStyle?.textAlign) {
        setTextAlign(mergedStyle.textAlign);
      } else {
        setTextAlign("");
      }
      if (mergedStyle?.textDecoration) {
        setTextDecoration(mergedStyle.textDecoration);
      } else {
        setTextDecoration("");
      }
      if (mergedStyle?.verticalAlign) {
        setVerticalAlign(mergedStyle.verticalAlign);
      } else {
        setVerticalAlign("");
      }
    } else {
      setFontFamily("");
      setFontSize(12);
      setFontStyle("");
      setFontWeight("");
      setTextAlign("");
      setTextDecoration("");
      setVerticalAlign("");
    }
  }, [selected, format]);

  const [optionalToolbarItems, setOptionalToolbarItems] = useState(null);

  useEffect(() => {
    if (selected.length > 0 && selected.every((element, i, array) => element.type === array[0].type)) {
      switch (selected[0].type) {
        case "cell":
          setOptionalToolbarItems(<ToolbarSectionCell format={format} selected={selected} />);
          break;
        case "column":
          setOptionalToolbarItems(<ToolbarGridColumn format={format} selected={selected} />);
          break;
        case "grid":
          setOptionalToolbarItems(<ToolbarGrid format={format} />);
          break;
        case "row":
          setOptionalToolbarItems(<ToolbarSectionRow format={format} selected={selected} />);
          break;
        case "section":
          setOptionalToolbarItems(<ToolbarSection format={format} selected={selected} />);
          break;
        default:
          setOptionalToolbarItems(null);
      }
    } else {
      setOptionalToolbarItems(null);
    }
  }, [selected, format]);

  return (
    <>
      <div className="toolbar__section">
        <div className="section__title">{t("Font")}</div>
        <div className="section__content">
          <ToolbarPropertyButton
            name="fontWeight"
            value={fontWeight === "bold" ? "normal" : "bold"}
            tooltipText="Font weight bold"
            selected={fontWeight === "bold"}
          >
            <strong>B</strong>
          </ToolbarPropertyButton>
          <ToolbarPropertyButton
            name="fontStyle"
            value={fontStyle === "italic" ? "normal" : "italic"}
            tooltipText="Font style italic"
            selected={fontStyle === "italic"}
          >
            <em>I</em>
          </ToolbarPropertyButton>
          <ToolbarPropertyButton
            name="textDecoration"
            value={textDecoration === "underline" ? "none" : "underline"}
            tooltipText="Font decoration underline"
            selected={textDecoration === "underline"}
          >
            <u>U</u>
          </ToolbarPropertyButton>
          <ToolbarSeparator />

          <FontPicker value={fontFamily} />
          <ToolbarSeparator />

          <InputNumberPicker
            label=""
            value={fontSize}
            onChange={(value) => dispatch(setFormatProperty({ name: "fontSize", value: value }))}
            numbers={[8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 28, 32, 36, 48, 72, 96]}
            tooltipText="Select font size"
          />
        </div>
      </div>

      <div className="toolbar__section">
        <div className="section__title">{t("Cell Formatting")}</div>
        <div className="section__content">
          <ToolbarBorderPicker value={"bottom"} />
          <ToolbarSeparator />

          <ToolbarColorPicker
            tooltipText="Select cell background colour"
            name="backgroundColor"
            color={"#ff0000"}
            background={true}
          />
          <ToolbarColorPicker tooltipText="Select cell text colour" name="textColor" color={"#000000"} />
          <ToolbarSeparator />

          <ToolbarPropertyButton
            name="textAlign"
            value="left"
            tooltipText="Align cell text left"
            selected={textAlign === "left"}
          >
            <FaAlignLeft className="button__icon" />
          </ToolbarPropertyButton>

          <ToolbarPropertyButton
            name="textAlign"
            value="center"
            tooltipText="Align cell text center"
            selected={textAlign === "center"}
          >
            <FaAlignCenter className="button__icon" />
          </ToolbarPropertyButton>

          <ToolbarPropertyButton
            name="textAlign"
            value="right"
            tooltipText="Align cell text right"
            selected={textAlign === "right"}
          >
            <FaAlignRight className="button__icon" />
          </ToolbarPropertyButton>

          <ToolbarSeparator />

          <ToolbarPropertyButton
            name="verticalAlign"
            value="top"
            tooltipText="Align cell content top"
            selected={verticalAlign === "top"}
          >
            <AiOutlineVerticalAlignTop className="button__icon" />
          </ToolbarPropertyButton>

          <ToolbarPropertyButton
            name="verticalAlign"
            value="middle"
            tooltipText="Align cell content middle"
            selected={verticalAlign === "middle"}
          >
            <AiOutlineVerticalAlignMiddle className="button__icon" />
          </ToolbarPropertyButton>

          <ToolbarPropertyButton
            name="verticalAlign"
            value="bottom"
            tooltipText="Align cell content bottom"
            selected={verticalAlign === "bottom"}
          >
            <AiOutlineVerticalAlignBottom className="button__icon" />
          </ToolbarPropertyButton>

          <ToolbarSeparator />

          <ToolbarButton
            tooltipText="Merge/Unmerge selected cells"
            style={{ width: "auto" }}
            onClick={() => dispatch(mergeCells())}
          >
            <RiMergeCellsHorizontal className="button__icon" />
            <RiMergeCellsVertical className="button__icon" />
          </ToolbarButton>
          <ToolbarSeparator />

          <ToolbarButton tooltipText="Erase cell content" onClick={() => dispatch(clearProperties())}>
            <FaEraser className="button__icon" />
          </ToolbarButton>
          <Delete message="Delete Column/Row" onConfirm={() => dispatch(formatDelete())} />
          <ToolbarSeparator />
        </div>
      </div>

      {optionalToolbarItems && (
        <div className="toolbar__section">
          <div className="section__title">{t("Contextual")}</div>
          <div className="section__content">{optionalToolbarItems}</div>
        </div>
      )}
    </>
  );
}

GridToolbar.propTypes = {
  reportId: PropTypes.number,
  format: PropTypes.object,
  selected: PropTypes.arrayOf(PropTypes.object),
  outputType: PropTypes.number,
  onOutputTypeClick: PropTypes.func,
};

export default GridToolbar;
