import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ReactDatePicker from "react-datepicker";

import { findNextInput } from "utilities/findNextTabStop";
import { useEffect, useState } from "react";

import { checkForValidDateEntered, getDateDisplayValue, getDateFormats } from "utilities/dateTime";
import useComponentVisible from "components/global/useComponentVisible";

// Wrapper for react-datepicker to fix issue over locale-specific main input text display and to abstract locale selection
function DatePicker({ keyProps, selected, onDateChange, isSimpleSearchParent, selectsRange, tabIndex }) {
  const locale = useSelector((state) => state.locale).selectedLocale;
  let language = locale.substring(0, 2);
  const dateFormats = getDateFormats(locale);
  const [refMainPopup, isShowingPopup, setIsShowingPopup] = useComponentVisible(false);
  //const [inputDate, setInputDate] = useState(null); // The date object identified by the user's input
  const [inputDisplayValue, setInputDisplayValue] = useState(""); // The display value of the stored date, set to "medium" format for user's locale
  const [caretPosition, setCaretPosition] = useState(null);
  //const [lastKeyWasBackspaceOrDelete, setLastKeyWasBackspaceOrDelete] = useState(false);
  const [lastKeydownTime, setLastKeydownTime] = useState(null); // Monitor keystroke times to prevent double-save when entering date via keyboard
  const [typingTimer, setTypingTimer] = useState(null);

  useEffect(() => {
    setInputDisplayToSelectedDate();
  }, [selected]);

  useEffect(() => {
    if (isShowingPopup) {
      var popup = document.querySelector(".react-datepicker-popper");
      if (popup) popup.style.position = "fixed";
    }
  }, [isShowingPopup]);

  useEffect(() => {
    if (caretPosition) {
      caretPosition.target.selectionStart = caretPosition.index;
      caretPosition.target.selectionEnd = caretPosition.index;
    }
  }, [inputDisplayValue, caretPosition]);

  const setInputDisplayToSelectedDate = () => {
    setInputDisplayValue(selected && typeof selected !== "undefined" ? getDateDisplayValue(selected) : "");
  };

  const handleKeyDown = (e) => {
    // Monitor keyboard clicks for date entry (excludes navigation keys while browsing calendar with keyboard)
    if (
      e.code !== "Enter" &&
      e.code !== "ArrowUp" &&
      e.code !== "ArrowDown" &&
      e.code !== "ArrowLeft" &&
      e.code !== "ArrowRight" &&
      e.code !== "PageUp" &&
      e.code !== "PageDown" &&
      e.code !== "End" &&
      e.code !== "Home"
    ) {
      setLastKeydownTime(Date.now());
    }
    if (e.code === "Tab" && !isSimpleSearchParent) {
      e.preventDefault();
      if (inputDisplayValue.length === 0) {
        onDateChange(null);
      }
      const elDisplayValueParent = e.target.closest("div.field__display-value");
      const elInputThisDatePicker = elDisplayValueParent.querySelector("input");

      const nextEl = findNextInput(elInputThisDatePicker, e.shiftKey);
      nextEl.focus();
      setIsShowingPopup(false);
      setInputDisplayToSelectedDate();
    }
    if (e.code === "Enter") {
      setIsShowingPopup(!isShowingPopup);
    }
    //setLastKeyWasBackspaceOrDelete(e.code === "Backspace" || e.code === "Delete");
  };

  const handleFocus = (e) => {
    setIsShowingPopup(!isSimpleSearchParent);
  };

  const handleBlur = (e) => {
    if (inputDisplayValue.length > 0) setInputDisplayToSelectedDate();
    setIsShowingPopup(false);
  };

  // Handles user keyboard input within the text box
  const handleCustomInputChange = (e) => {
    const originalText = e.target.value;
    const originalCaretPos = e.target.selectionStart;
    clearTimeout(typingTimer);
    if (!isShowingPopup) setIsShowingPopup(true);
    if (e.type === "click" || e.code === "Enter") return; // Don't do anything here if the user is clicking or pressing Enter on the calendar dropdown
    //if (lastKeyWasBackspaceOrDelete) e.preventDefault(); // Don't allow ANYTHING to happen if user is deleting text
    const newDate = checkForValidDateEntered(e.target.value, locale);
    if (typeof newDate === "undefined")
      e.preventDefault(); // Prevents DatePicker from guessing partial date in US-format
    else e.target.value = newDate.toLocaleDateString("en-US"); // We have to mangle the ongoing event down into the DatePicker to make it think we've entered the date in US-format, because the library sucks
    // Insert short lag before saving valid date
    const typingTimerLocal = setTimeout(() => {
      clearTimeout(typingTimer);
      if (typeof newDate !== "undefined") {
        onDateChange(newDate); // Sends update to API/DB
      }
    }, 1500);
    setTypingTimer(typingTimerLocal);
    setInputDisplayValue(originalText);
    // We have to manually re-fix the caret position on any edits as the line forcing the en-US e.target.value date above causes caret to go to the end of the line
    setCaretPosition({ target: e.target, index: originalCaretPos });
  };

  // Handles user clicks on the calendar
  const handleDatePickerChange = (e) => {
    // Prevent double saving of date if keyboard entry of date has just occurred in the last second
    if (Date.now() - lastKeydownTime > 1000) {
      onDateChange(e);
      setIsShowingPopup(false);
    } // Sends update to API/DB only if it's a calendar click, and not keyboard entry
  };

  const handleDivClick = () => {
    if (!isShowingPopup) setIsShowingPopup(true);
  };

  return (
    <div id={keyProps?.controlId} className="react-datepicker-contained" ref={refMainPopup} onClick={handleDivClick}>
      <ReactDatePicker
        locale={language}
        dateFormat={dateFormats[0]}
        value={inputDisplayValue}
        selected={selected}
        onChange={handleDatePickerChange}
        onChangeRaw={handleCustomInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        showYearDropdown={selected?.getFullYear() > 1899}
        showMonthDropdown
        dropdownMode="select"
        open={isShowingPopup}
        shouldCloseOnSelect={false}
      />
    </div>
  );
}

DatePicker.propTypes = {
  keyProps: PropTypes.object,
  selected: PropTypes.any, // TODO: fix so it's only object?
  onChange: PropTypes.func,
  isSimpleSearchParent: PropTypes.bool,
  selectsRange: PropTypes.bool,
};

export default DatePicker;
