import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getSectionChildDataSources } from "utilities/reportEditor";
import { getTranslation } from "utilities/reportShared";
import { compareByDisplayValueAsc } from "utilities/stringAndArray";

function ReportSubsectionPicker({ show, inputRef, onSubsectionInsertClick }) {
  const [availableDataSources, setAvailableDataSources] = useState([]);

  useEffect(() => {
    const childDataSources = getSectionChildDataSources("Matter");

    setAvailableDataSources(
      childDataSources
        .map((dataSource) => {
          return { name: dataSource.name, displayValue: getTranslation(dataSource.translationCode) };
        })
        .sort(compareByDisplayValueAsc)
    );
  }, []);

  return (
    <div className="add-sub-report__section-picker" style={{ display: show ? "block" : "none" }} ref={inputRef}>
      {availableDataSources.map((dataSource) => {
        return (
          <div
            key={dataSource.name}
            className="section-picker__item"
            onClick={() => onSubsectionInsertClick(dataSource)}
          >
            {dataSource.displayValue}
          </div>
        );
      })}
    </div>
  );
}

ReportSubsectionPicker.propTypes = {
  show: PropTypes.bool,
  inputRef: PropTypes.object,
  onSubsectionInsertClick: PropTypes.func,
};

export default ReportSubsectionPicker;
