import { t } from "locale/dictionary";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDocumentTitle } from "redux/appSlice";
import { linkTypes, projectStatuses, projectTypes } from "utilities/constants";
import { useEffect } from "react";
import BatchCreateMasterRecord from "./BatchCreateMasterRecord";
import BatchCreateJurisdiction from "./BatchCreateJurisdiction";
import BatchCreateCoreDetails from "./BatchCreateCoreDetails";
import BatchCreateCompanyLinks from "./BatchCreateCompanyLinks";
import BatchCreateImages from "./BatchCreateImages";
import ProjectLegalTeam from "../ProjectLegalTeam";
import BatchCreateGoods from "./BatchCreateGoods";
import BatchCreateSummary from "./BatchCreateSummary";
import ProjectGenerateMatters from "../ProjectGenerateMatters";
import { clearResults } from "redux/searchResultsSlice";
import ProjectBrowser from "../ProjectBrowser";
import { idsAreEqual } from "utilities/stringAndArray";

export default function BatchCreateContainer() {
  const dispatch = useDispatch();
  const batchCreateState = useSelector((state) => state.batchCreate);

  const status = batchCreateState?.project?.project_Status;

  // Number of records (for info display only on confirmation modal) is:
  // Batch Create: the number in the draft records list (summary)
  const numRecords = batchCreateState?.project?.draftRecords?.length;

  useEffect(() => {
    dispatch(setDocumentTitle(t("Batch Create Records")));
    dispatch(clearResults());
  }, []);

  return (
    <div className="batch-create">
      <h2>{t("Batch Create Records")}</h2>
      {/* <Project /> */}
      <div className="record__data-panel-list">
        {batchCreateState?.project && status !== projectStatuses.PRE_CREATE ? (
          <div className="data-panel-list__outer">
            <BatchCreateMasterRecord />
            {(status === projectStatuses.TEMPLATE_GENERATED ||
              status === projectStatuses.PREVIEW_GENERATED ||
              status === projectStatuses.MATTER_GENERATED) && (
              <>
                <BatchCreateCoreDetails />
                <BatchCreateJurisdiction />
                <BatchCreateCompanyLinks />
                <BatchCreateImages />
                <ProjectLegalTeam />
                <BatchCreateGoods />
                <BatchCreateSummary />
              </>
            )}
            {status === projectStatuses.PREVIEW_GENERATED && <ProjectGenerateMatters numRecords={numRecords} />}
          </div>
        ) : (
          <ProjectBrowser projectTypeId={projectTypes.BATCH_CREATE} />
        )}
      </div>
    </div>
  );
}
