import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ToolbarButton from "../../toolbar/ToolbarButton";
import { ImSortAlphaAsc } from "react-icons/im";
import Tooltip from "components/global/Tooltip";
import { addSortOrderProperty, removeSortOrderProperty, setSortOrderProperty } from "redux/reportSlice";
import { getSortDataFields } from "utilities/reportEditor";
import SortOrderKey from "./SortOrderKey";
import PopupHeader from "components/global/PopupHeader";
import { t } from "locale/dictionary";
import { getDisplayNameForField } from "utilities/datafield";

// Modal for selecting sort order and grouping in reports
function ToolbarSortOrder({ format, report }) {
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  const [sortDataFields, setSortDataFields] = useState([]);

  const newSortOrderKey = { fieldName: null, descending: false, outputHeader: false, outputFooter: false };
  const [currentNewSortOrderKey, setCurrentNewSortOrderKey] = useState({ ...newSortOrderKey });

  const handleClosePopupClick = () => {
    setShowPopup(false);
  };

  const handleSortOrderToolbarButtonClick = () => {
    setShowPopup(true);
  };

  const handleSelectChange = (index, sortColumn, name, value) => {
    if (index === null) {
      if (name === "fieldName" && value) {
        setCurrentNewSortOrderKey({ ...newSortOrderKey });
        dispatch(
          addSortOrderProperty({
            sortColumn: { ...sortColumn, [name]: value },
          })
        );
      } else {
        setCurrentNewSortOrderKey((prevValue) => {
          return { ...prevValue, [name]: value };
        });
      }
    } else {
      if (name !== "fieldName" || value) {
        dispatch(
          setSortOrderProperty({
            index,
            sortColumn: { ...sortColumn, [name]: value },
          })
        );
      }
    }
  };

  const handleReverseSort = (index) => {
    if (index !== null) {
      dispatch(
        setSortOrderProperty({
          index,
          sortColumn: { ...format.sortColumns[index], descending: !format.sortColumns[index].descending },
        })
      );
    }
  };

  const handleDeleteSortKey = (index) => {
    if (index !== null) {
      dispatch(removeSortOrderProperty({ index }));
    }
  };

  useEffect(() => {
    const sortDataFields = getSortDataFields(format.queryType);

    setSortDataFields(
      sortDataFields.map((dataField) => ({
        id: dataField.name,
        displayValue: getDisplayNameForField(dataField.name, report?.matterTypeIds),
      }))
    );
  }, [format.queryType]);

  const renderForm = (
    <table>
      <thead></thead>
      <tbody>
        {format.sortColumns?.map((sortColumn, index) => (
          <SortOrderKey
            key={sortColumn.id}
            label={`${index === 0 ? "First" : "Then"} sort by:`}
            index={index}
            sortColumn={sortColumn}
            options={sortDataFields}
            // showGrouping checkboxes if this is the first sort column or the previous sort column has either outputHeader or outputFooter set to true
            showGrouping={
              index === 0 || format.sortColumns[index - 1].outputHeader || format.sortColumns[index - 1].outputFooter
            }
            onSelectChange={handleSelectChange}
            onDelete={handleDeleteSortKey}
            onReverseSort={handleReverseSort}
          />
        ))}

        <SortOrderKey
          key={0}
          label={`${format?.sortColumns?.length > 0 ? "Then s" : "S"}ort by:`}
          index={null}
          sortColumn={currentNewSortOrderKey}
          options={sortDataFields}
          onSelectChange={handleSelectChange}
          onReverseSort={handleReverseSort}
        />
        <tr className="modal__selection-row">
          <td>
            <button onClick={handleClosePopupClick}>{t("Close")}</button>
          </td>
        </tr>
      </tbody>
    </table>
  );

  const renderPopup = (
    <div className="modal-mask">
      <div className="modal__selection-popup modal__selection-popup--wide">
        <PopupHeader title="Sorting and Grouping" onClose={handleClosePopupClick} />
        <div className="modal__selection-body">{renderForm}</div>
      </div>
    </div>
  );

  return (
    <Tooltip content="Sort order" direction="left">
      <ToolbarButton onClick={() => handleSortOrderToolbarButtonClick()}>
        <ImSortAlphaAsc className="button__icon" />
      </ToolbarButton>
      {showPopup && renderPopup}
    </Tooltip>
  );
}

ToolbarSortOrder.propTypes = {
  format: PropTypes.object,
  report: PropTypes.object,
};

export default ToolbarSortOrder;
