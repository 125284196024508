import { createSlice } from "@reduxjs/toolkit";

// Redux global state component for storing all lookups from the database
export const lookupSlice = createSlice({
  name: "lookup",
  initialState: {
    global: [],
    customerSpecific: null,
  },
  reducers: {
    setLookup: (state, action) => {
      if (state.global.find((lookup) => lookup.name === action.payload.name)) return;
      let lookups = [...state.global];
      lookups.push({ name: action.payload.name, lookup: action.payload.lookup });
      state.global = lookups;
    },    
  },
});

export const { setLookup } = lookupSlice.actions;

export default lookupSlice.reducer;
