import { t } from "locale/dictionary";
import ProjectSection from "../ProjectSection";
import { useSelector } from "react-redux";
import SuperSelect from "components/global/SuperSelect";
import { updateBatchImportStatusMapping } from "api/batchImport";
import { getAllLookupValuesForField } from "utilities/datafield";

export default function BatchImportStatusMapping() {
  const appState = useSelector((state) => state.app);
  const batchImportState = useSelector((state) => state.batchImport);

  const statusOptions = getAllLookupValuesForField(
    "matter_StatusId",
    [batchImportState.project.project_MatterTypeId],
    false
  );
  const subStatusOptions = getAllLookupValuesForField(
    "matter_SubstatusId",
    [batchImportState.project.project_MatterTypeId],
    false
  );

  const handleChange = (value, initialValue, fieldName, mapId) => {
    updateBatchImportStatusMapping(
      appState.customerId,
      batchImportState.project.id,
      mapId,
      fieldName,
      initialValue,
      value
    );
  };

  const renderStatusTableHeader = (
    <thead>
      <tr className="data-panel__table-row">
        <th className="data-panel__table-header">{t("Source Status")}</th>
        <th className="data-panel__table-header">{t("Source Sub-status")}</th>
        <th className="data-panel__table-header">{t("Count")}</th>
        <th className="data-panel__table-header">{t("CedarIP Status")}</th>
        <th className="data-panel__table-header">{t("CedarIP Sub-status")}</th>
      </tr>
    </thead>
  );

  const renderStatusTableBody = batchImportState?.project?.statusMaps?.map((statusMap) => {
    return (
      <tr key={statusMap.id} className="data-panel__table-row">
        <td className="data-panel__table-cell data-panel__table-cell--20">{statusMap.sourceStatusDisplay}</td>
        <td className="data-panel__table-cell data-panel__table-cell--20">{statusMap.sourceSubstatusDisplay}</td>
        <td className="data-panel__table-cell data-panel__table-cell--20">{statusMap.count}</td>
        <td className="data-panel__table-cell data-panel__table-cell--20 editable">
          <div className="table-cell__field-container--select">
            <SuperSelect
              id={`batch-import-status-${statusMap.id}`}
              selectedOptionId={statusMap.destinationStatusId}
              options={statusOptions ?? []}
              onChange={(value) =>
                handleChange(value, statusMap.destinationStatusId, "destinationStatusId", statusMap.id)
              }
            />
          </div>
        </td>
        <td className="data-panel__table-cell data-panel__table-cell--20 editable">
          <div className="table-cell__field-container--select">
            <SuperSelect
              id={`batch-import-substatus-${statusMap.id}`}
              selectedOptionId={statusMap.destinationSubstatusId}
              options={subStatusOptions ?? []}
              onChange={(value) =>
                handleChange(value, statusMap.destinationSubstatusId, "destinationSubstatusId", statusMap.id)
              }
            />
          </div>
        </td>
      </tr>
    );
  });

  //MAIN RENDER
  return (
    <ProjectSection title="Trademark Status Mappings">
      <div className="data-panel__table data-panel__table--100 ">
        <div className="data-panel__table-scrollable-container">
          <table className="data-panel__table-inner">
            {renderStatusTableHeader}
            <tbody>{renderStatusTableBody}</tbody>
          </table>
        </div>
      </div>
    </ProjectSection>
  );
}
