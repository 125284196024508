import { useSelector } from "react-redux";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { columnSortChange } from "redux/searchResultsSlice";
import { getDisplayNameForField } from "utilities/datafield";
import { recordsSortChange } from "utilities/matter";

// Column Header component for Search Results Data Grid
export default function DataGridColumnHeader(props) {
  const searchResults = useSelector((state) => state.searchResults).currentSearch;
  const dispatch = useDispatch();

  const sort = searchResults.sort;

  const onColumnClick = () => {
    let ascending = true;
    if (sort.column === props.resultField) ascending = !sort.ascending;
    dispatch(columnSortChange({ column: props.resultField, columnIndex: props.columnIndex, ascending }));
    recordsSortChange();
  };

  return (
    <th onClick={onColumnClick}>
      {getDisplayNameForField(props.resultField, null, searchResults.abbreviated)}
      {sort.column === props.resultField && (sort.ascending ? <FaCaretUp /> : <FaCaretDown />)}
    </th>
  );
}
