import PropTypes from "prop-types";
import D3JSPie from "./d3js/D3JSPie";
import HighchartPie from "./highcharts/HighchartPie";

function PivotPieChart({ widgetId, output, onClick, highcharts = true }) {
  if (highcharts) {
    return <HighchartPie widgetId={widgetId} output={output} onClick={onClick} />;
  } else {
    return <D3JSPie output={output} />;
  }
}

PivotPieChart.propTypes = {
  output: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  highcharts: PropTypes.bool,
};

export default PivotPieChart;
